import { Component } from '@angular/core';
import { NgbOffcanvas, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AddEditEntryComponent } from '../add-edit-entry/add-edit-entry.component';
import { FlatFinancial } from 'src/app/shared/models/FlatFinancial';
import { FinancialService } from 'src/app/services/financial.service';

@Component({
  selector: 'app-journal-actions-renderer',
  templateUrl: './journal-actions-renderer.component.html',
  styleUrls: ['./journal-actions-renderer.component.css']
})
export class JournalActionsRendererComponent implements ICellRendererAngularComp {
  financial?: FlatFinancial;
  params!: ICellRendererParams<any, any, any>;

  constructor(private offcanvasService: NgbOffcanvas,
              private modalService: NgbModal,
              private financialService: FinancialService,
              private notificationService: NotificationService,
              private translate: TranslateService) {}


  agInit(params: ICellRendererParams<any, any, any>): void {
    this.financial = params.data;
    this.params = params;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return true;
  }

  deleteEntry() {
    this.financialService.deleteEntry(this.financial!.financialId, this.financial!.organizationId)
      .subscribe(() => {
        this.notificationService.showSuccessToast(this.translate.instant('FinanceData.JournalActionsRenderer.DeleteEntrySuccess'));
        this.params.context.journalEntryGridComponent.refreshGrid();
        this.modalService.dismissAll();
      });
  }

  editJournalEntry() {
    const offcanvasRef = this.offcanvasService.open(AddEditEntryComponent, { ariaLabelledBy: 'offcanvas-basic-title', position: 'end', backdrop: 'static' });
    offcanvasRef.componentInstance.params = this.params;
  }

  openDeleteeModal() {
    const modalRef = this.modalService.open(ConfirmModalComponent, { centered: true });
    modalRef.componentInstance.title = 'FinanceData.JournalActionsRenderer.DeleteEntry';
    modalRef.componentInstance.bodyConfirm = 'ConfirmModal.ConfirmBody';
    modalRef.componentInstance.bodyMessage = 'FinanceData.JournalActionsRenderer.DeleteModalMessage';
    modalRef.componentInstance.noText = 'ConfirmModal.Close';
    modalRef.componentInstance.yesText = 'ConfirmModal.Yes';
    modalRef.componentInstance.yesAction.subscribe(() => {
      this.deleteEntry();
    });
  }
}
