import { Component } from '@angular/core';

@Component({
  selector: 'app-no-assigned-organization',
  templateUrl: './no-assigned-organization.component.html',
  styleUrls: ['./no-assigned-organization.component.css']
})
export class NoAssignedOrganizationComponent {

}
