import { Component, OnDestroy, OnInit } from '@angular/core';
import { SolutionListing } from '../../../shared/models/SolutionListing';
import { SolutionListingService } from '../../../services/solution.listings.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  map,
  Observable,
  pipe,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NotificationService } from '../../../shared/services/notification.service';

@Component({
  selector: 'app-solutions-detail',
  templateUrl: './solutions-detail.component.html',
  styleUrls: ['./solutions-detail.component.css'],
})
export class SolutionsDetailComponent implements OnInit, OnDestroy {
  solution$?: Observable<SolutionListing>;
  solution?: SolutionListing;
  videoEmbedUrl?: SafeResourceUrl | null;

  _destroying$ = new Subject<void>();

  carouselOptions: OwlOptions = {
    items: 1,
    dots: true,
    loop: false,
  };

  constructor(
    private solutionListingService: SolutionListingService,
    private activatedRoute: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        switchMap((params) =>
          this.solutionListingService.getSolutionListing(params.id)
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((solution) => {
        this.solution = solution;
        this.videoEmbedUrl = this.sanitizeVideoUrl(solution.embeddedVideoUrl);
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  sanitizeVideoUrl(url?: string) {
    if (!url) return null;

    if (url.startsWith('https://www.youtube.com/watch?v=')) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(
        url.replace('watch?v=', 'embed/')
      );
    }

    if (url.startsWith('https://www.youtube.com/embed/')) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }

    return null;
  }

  toggleFavourite() {
    if (!this.solution) return;

    this.solutionListingService
      .toggleFavourite(this.solution.solutionListingId)
      .subscribe((isFavourite) => {
        if (!this.solution) return;
        this.solution.isFavourite = isFavourite;
      });
  }

  copyLink() {
    window.navigator.clipboard
      .writeText(window.location.href)
      .then(() =>
        this.notificationService.showSuccessToast('Link copied to clipboard')
      );
  }
}
