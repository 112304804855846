import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, forkJoin, map, Observable, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { AppContext } from 'src/app/shared/context/AppContext';
import { OrganizationType } from 'src/app/shared/models/OrganizationType';
import { PropertySubType } from 'src/app/shared/models/PropertySubType';
import { Variable } from 'src/app/shared/models/Variable';
import { MasterDataService } from 'src/app/shared/services/master-data.service';
import { SaveToViewModalComponent } from '../save-to-view-modal/save-to-view-modal.component';
import { UserMediaView } from 'src/app/shared/models/UserMediaView';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { UsersavedviewService } from 'src/app/services/usersavedview.service';
import { FilterParams } from 'src/app/shared/models/FindMediaFilterParams';
import * as _ from 'lodash';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
})
export class FilterComponent implements OnInit {
  languageId!: string;
  selectedViewId: string = '';
  emptyOptionText: string = 'FindMedia.SelectView';
  propertySubTypes: PropertySubType[] = [];
  organizationTypes: OrganizationType[] = [];
  organizationAges: Variable[] = [];
  selectedOrganizationTypes: string[] = [];
  selectedPropertySubTypes: string[] = [];
  selectedOrganizationAges: string[] = [];
  selectedNoOfEmployee: string[] = [];
  sectionsState :{[key:string]:boolean} = { savedView: true, organizationType: true, propertiesType: true, noOfEmployeeType: true, orgAgeType: true };
  singleSaveView: UserMediaView | null = { id: '', filter: '', name: '', isDefault: false };
  destroy$ = new Subject<void>();
  @Input() SavedView: UserMediaView[] = [];
  @Input() Favourite: boolean = false
  @Input() SearchText: string = ''
  @Output() filtersChanged = new EventEmitter<FilterParams>();
  @Output() reloadFilter = new EventEmitter<void>();
  @Output() reloadUserSavedViews = new EventEmitter<void>();
  @ViewChild('filterDrawer') filterDrawer!: NgbOffcanvas;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private appContext: AppContext,
    private masterDataService: MasterDataService,
    private offcanvasService: NgbOffcanvas,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private userSavedViewService: UsersavedviewService,
    private offCanvasService: NgbOffcanvas
  ) {}

  ngOnInit(): void {
    this.loadInitialData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['SavedView']) {
      this.updateCurrentViewBasedOnFilters()
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  // Initialization
  private loadInitialData(): void {
      this.loadUserLanguageId().pipe(
        switchMap(() => forkJoin({
          properties: this.loadProperties(),
          organizationTypes: this.loadOrganizationTypes(),
          organizationAges: this.loadOrganizationAges()
        }))
      ).subscribe(({ properties, organizationTypes, organizationAges }) => {
        this.propertySubTypes = properties;
        this.organizationTypes = organizationTypes;
        this.organizationAges = organizationAges;
      });    
  }

  private loadUserLanguageId(): Observable<void> {
    return this.appContext.user.pipe(
      takeUntil(this._destroying$),
      tap(user => {
        this.languageId = user.languageId; 
      }),
      map(() => undefined) 
    )
  }

  private loadProperties(): Observable<any> {
    return this.masterDataService.getPropertySubTypes(this.languageId).pipe(
      takeUntil(this._destroying$)
    );
  }
  
  private loadOrganizationTypes(): Observable<any> {
    return this.masterDataService.getOrganizationTypes(this.languageId).pipe(
      takeUntil(this._destroying$)
    );
  }
  
  private loadOrganizationAges(): Observable<any> {
    return this.masterDataService.getOrganizationAgesList(this.languageId).pipe(
      takeUntil(this._destroying$)
    );
  }
  private updateCurrentViewBasedOnFilters(): void {
    const matchingView = this.SavedView.find(view =>
      JSON.stringify(JSON.parse(view.filter)) === JSON.stringify(this.getFilterData())
    );
    const defaultView = this.SavedView.find(view => view.isDefault
      && this.selectedViewId !== view.id
    );
    const selectedView = matchingView || defaultView;
    if (selectedView) {
      this.selectedViewId = selectedView.id;
      this.singleSaveView = selectedView;
      this.setSelectedFilters(JSON.parse(selectedView.filter) as FilterParams);
    } else {
      
      this.selectedViewId = '';
      this.singleSaveView = null;
    }
  }
  // Filter Logic
  private setSelectedFilters(filter: FilterParams): void {
    this.selectedOrganizationTypes = filter.organizationTypes || [];
    this.selectedPropertySubTypes = filter.propertySubTypes || [];
    this.selectedOrganizationAges = filter.organizationAges || [];
    this.selectedNoOfEmployee = filter.noOfEmployee || [];
  }

  onCheckboxChange(event: any, selectionArray: string[]): void {
    console.log(event)
    const value = event.target.value;
    if (!this.selectedViewId) {
        this.emptyOptionText = 'FindMedia.Custom';
    }
    const previousSelection = 
    [...this.selectedOrganizationTypes, ...this.selectedPropertySubTypes, ...this.selectedOrganizationAges, ...this.selectedNoOfEmployee];  
    console.log(previousSelection)
    if (event.target.checked) {
        selectionArray.push(value);
    } else {
        selectionArray.splice(selectionArray.indexOf(value), 1);
    }
    if (this.isArrayChanged(selectionArray, previousSelection)) {
       this.emptyOptionText = 'FindMedia.Custom'; 
       this.selectedViewId = '';
     } 
  }
  isArrayChanged(currentArray: string[], originalArray: string[]): boolean {
    if (currentArray.length !== originalArray.length) {
      return true;
    }
    return !currentArray.every((value, index) => value === originalArray[index]);
  }
  applyFilter(): void {
    this.closeFilterDrawer();
  }

  resetFilters(): void {
    this.selectedOrganizationTypes = [];
    this.selectedPropertySubTypes = [];
    this.selectedOrganizationAges = [];
    this.selectedNoOfEmployee = [];
    this.applyFilter();
  }

  getFilterData(): FilterParams {
    return {
      organizationTypes: this.selectedOrganizationTypes,
      propertySubTypes: this.selectedPropertySubTypes,
      organizationAges: this.selectedOrganizationAges,
      noOfEmployee: this.selectedNoOfEmployee,
      searchText: this.SearchText,
      isFavorite : this.Favourite
    };
  }

  get isFilterParamsEmpty(): boolean {
    return !(this.selectedOrganizationTypes.length || this.selectedPropertySubTypes.length || this.selectedOrganizationAges.length || this.selectedNoOfEmployee.length);
  }

  get totalFilteredOrganizations(): number {
    return this.selectedOrganizationTypes.length + this.selectedPropertySubTypes.length + this.selectedOrganizationAges.length + this.selectedNoOfEmployee.length;
  }
  // Offcanvas and Modal Operations
  closeFilterDrawer(): void {
    this.offcanvasService.dismiss();
    //this.reloadFilter.emit();
    this.filtersChanged.emit(this.getFilterData());
  }
  reloadSavedViews(){
    this.reloadUserSavedViews.emit();
  }
  showCreateViewModal(): void {
    this.showSaveToViewModal('save');
  }

  showSaveToViewModal(action: string): void {
    const filterData = JSON.stringify(this.getFilterData());
    const modalRef = this.modalService.open(SaveToViewModalComponent, { centered: true });
    modalRef.componentInstance.action = action;
    modalRef.componentInstance.yesAction.subscribe(() => this.modalService.dismissAll());
    modalRef.componentInstance.viewName.subscribe((text: string) => {
     this.createFilterView({ name: text, filter: filterData, id: "", isDefault: false });
    });
    modalRef.componentInstance.renameAction.subscribe((text: any) => {
      this.renameFilterView(this.selectedViewId,{name : text,filter:filterData,id:this.selectedViewId, isDefault:false})
      this.modalService.dismissAll();
   });
   
  }

  showConfirmModal(action: string): void {
    const modalRef = this.modalService.open(ConfirmModalComponent, { centered: true });
    modalRef.componentInstance.title = this.translate.instant("Common.Confirm");
    modalRef.componentInstance.bodyMessage = this.translate.instant("Common.ProceedConfirm");
    modalRef.componentInstance.yesText = this.translate.instant("Common.ConfirmYes")
    modalRef.componentInstance.noText = this.translate.instant("Common.ConfirmNo")
    modalRef.componentInstance.yesAction.subscribe(() => {
      action === "delete" ? this.deleteSavedView() : this.makeDefault();
      this.modalService.dismissAll()
    });
  }

  // Saved View Management
  createFilterView(view: UserMediaView): void {
    this.userSavedViewService.createFilterView(view).subscribe({
      next: () => {
        this.notificationService.showSuccessToast(this.translate.instant('FindMedia.SaveToViewSuccess'))
        this.modalService.dismissAll()
        this.reloadSavedViews();
        this.closeFilterDrawer();
      },
      error: () => this.notificationService.showErrorToast(this.translate.instant('FindMedia.Error'))
    });
  }

  renameFilterView(id: string, view: UserMediaView): void {
    this.userSavedViewService.renameSavedView(id, view).subscribe({
      next: () => {
        this.notificationService.showSuccessToast(this.translate.instant('FindMedia.RenameViewSuccess'));
        this.reloadSavedViews();
        this.closeFilterDrawer();
      },
      error: () => this.notificationService.showErrorToast(this.translate.instant('FindMedia.Error'))
    });
  }

  deleteSavedView(): void {
    this.userSavedViewService.deleteSavedMediaView(this.selectedViewId).subscribe({
      next: () => {
        this.notificationService.showSuccessToast(this.translate.instant('FindMedia.ViewDeletedSuccess'));
        this.reloadSavedViews();
        this.resetFilters();
      },
      error: () => this.notificationService.showErrorToast(this.translate.instant('FindMedia.Error'))
    });
  }

  makeDefault(): void {
    this.userSavedViewService.makeSavedViewAsDefault(this.selectedViewId).subscribe({
      next: () => {
        this.notificationService.showSuccessToast(this.translate.instant('FindMedia.SaveToViewDefaultSuccess'));
        this.reloadSavedViews();
        this.closeFilterDrawer();
      },
      error: () => this.notificationService.showErrorToast(this.translate.instant('FindMedia.Error'))
    });
  }

  onSelectSavedView(event: Event): void {
    this.setSelectedFilters({} as FilterParams);
    const target = event.target as HTMLSelectElement;
    this.selectedViewId = target.value;
    const selectedView = this.SavedView.find(view => view.id === this.selectedViewId);
    if (selectedView) {
      this.setSelectedFilters(JSON.parse(selectedView.filter) as FilterParams);
    }
  }
  getOrganizationTypeCount() : number{
    return this.selectedOrganizationTypes.length
  }
  getPropertyTypeCount() : number{
    return this.selectedPropertySubTypes.length
  }
  getNoOfEmployeeCount() : number{
    return this.selectedNoOfEmployee.length
  }
  getOrganizationAgeCount() : number{
    return this.selectedOrganizationAges.length
  }
  open() {
    const ref = this.offCanvasService.open(this.filterDrawer, {
      position: 'end',
      ariaLabelledBy: 'offcanvas-basic-title',
      animation: true,
      panelClass: 'custom-offcanvas-width',
    });
    ref.hidden.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.filtersChanged.emit(this.getFilterData());
    });
  }
}
