import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from '../models/ApiResponse';

const httpOptions = {
  headers: new HttpHeaders({ 'content-type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  get<T>(
    path: string,
    params: HttpParams = new HttpParams(),
    skipSpinner = false
  ): Observable<ApiResponse> {
    let headers: any;
    if (skipSpinner) {
      headers = {
        'skip-spinner': 'true',
      };
    }
    return this.http.get<ApiResponse>(path, {
      params,
      headers: headers,
    });
  }

  getEntity<T>(
    path: string,
    params: HttpParams = new HttpParams(),
    skipSpinner = false
  ): Observable<T> {
    let headers: any;
    if (skipSpinner) {
      headers = {
        'skip-spinner': 'true',
      };
    }

    return this.http.get<T>(path, { params, headers: headers });
  }

  getData<T>(
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<ApiResponse<T>> {
    return this.http.get<ApiResponse<T>>(path, {
      params,
      headers: httpOptions.headers,
    });
  }

  search<T>(
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<{ data: T[]; totalRecords: number }> {
    return this.http.get<{ data: T[]; totalRecords: number }>(path, {
      params,
    });
  }

  getAll<T>(
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<T[]> {
    return this.http.get<T[]>(path, { params });
  }

  put<T>(path: string, body: object, skipSpinner = false): Observable<T> {
    let headers: any;
    if (skipSpinner) {
      headers = {
        'skip-spinner': 'true',
      };
    }
    return this.http.put<T>(path, body, { headers: headers });
  }

  post<T>(path: string, body: T): Observable<T> {
    return this.http.post<T>(path, JSON.stringify(body), httpOptions);
  }

  postData<T>(path: string, body?: Object): Observable<ApiResponse<T>> {
    return this.http.post<ApiResponse<T>>(path, body, httpOptions);
  }

  postUpload(path: string, body: Object) {
    return this.http.post(path, body, {
      reportProgress: true,
      observe: 'events',
      responseType: 'text',
    });
  }

  delete(path: string): Observable<any> {
    return this.http.delete(path);
  }
}
