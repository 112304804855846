<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="offcanvas offcanvas-end show">
        <div class="offcanvas-header border-bottom">
            <h4 class="offcanvas-title" id="offcanvas-basic-title" translate>MvaSystemAdmin.EditPartner.Title</h4>
            <div type="button" class="btn-off" data-coreui-dismiss="offcanvas" aria-label="Close" (click)="activeOffcanvas.close()"></div>
        </div>
        <div class="offcanvas-body p-4">
            <div class="mb-3 row">
                <label for="userName" class="col-sm-3 col-form-label" translate>MvaSystemAdmin.EditPartner.Name</label>
                <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="userName" [value]="partner.partnerName" name="userName">
                </div>
            </div>
            <div class="row mb-4">
                <legend class="col-form-label col-sm-3 fw-semibold" translate>MvaSystemAdmin.EditPartner.Status</legend>
                <div class="col-sm-9">
                    <div class="form-check pt-2" [attr.aria-label]="'MvaSystemAdmin.EditPartner.Status' | translate" *ngFor="let status of statuses">
                        <input type="radio" class="form-check-input" formControlName="partnerStatusId" [id]="status.id" [value]="status.id">
                        <label class="form-check-label" [for]="status.id" translate>{{status.name}}</label>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal-footer p-3">
            <button type="button" class="btn btn-secondary mr-1" data-coreui-dismiss="offcanvasEdit" (click)="activeOffcanvas.dismiss()" aria-controls="offcanvasRightedit" translate>Buttons.Cancel</button>
            <button type="submit" class="btn btn-primary" translate>Buttons.Save</button>
        </div>
    </div>

</form>
