import { Component, Input } from '@angular/core';
import { Property } from '../../models/property.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { propertySubTypes } from 'src/app/shared/app-constants/constants';

@Component({
  selector: 'app-missing-permissions-properties',
  templateUrl: './missing-permissions-properties.component.html',
  styleUrls: ['./missing-permissions-properties.component.css']
})
export class MissingPermissionsPropertiesComponent {

  @Input() missingPermissionsProperties!: Property[];

  facebookSubTypeId = propertySubTypes.Facebook;
  instaSubTypeId = propertySubTypes.Instagram;

  constructor(public activeModal: NgbActiveModal) { }

}
