import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, combineLatest, takeUntil } from 'rxjs';
import { DataDashbordService } from 'src/app/services/datadashboard.service';
import { PropertyManagementService } from 'src/app/services/propertymanagement.service';
import { Solution } from 'src/app/shared/models/Solution';
import { SolutionProvider } from 'src/app/shared/models/SolutionProvider';
import { Property } from 'src/app/shared/models/property.model';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SelectGoogleAnalyticsPropertyComponent } from '../google-analytics/ga-select-property/ga-select-property.component';
import { SelectGoogleSearchSiteComponent } from './gs-select-site/gs-select-site.component';

@Component({
  selector: '[app-google-search-console]',
  templateUrl: './google-search-console.component.html',
  styleUrls: ['./google-search-console.component.css']
})
export class GoogleSearchConsoleComponent implements OnInit, OnDestroy {
  @Input() property!: Property;
  @Input() organizationId!: string;

  @ViewChild('revokeGoogleModal') revokeGoogleModal: ElementRef | undefined;

  private readonly _destroying$ = new Subject<void>();
  private readonly _onAuthWindowClose = new Subject<boolean>();
  _onAuthWindowCloseSubscrition?: Subscription;
  authorizationCode!: string;
  authorized!: boolean;
  grantedScopes!: string;
  requestedScopes: string[] = ['https://www.googleapis.com/auth/webmasters.readonly', 'https://www.googleapis.com/auth/userinfo.profile'];
  // popup related
  private googleSearchConsoleProvider!: SolutionProvider;
  private windowHandle?: Window;   // reference to the window object we will create
  private intervalId: any = null;  // For setting interval time between we check for authorization code or token
  private intervalLength = 100;   // the gap in which the check will be done for code.
  showAuthorization = false;
  googlePropertyId!: string;
  solution?: Solution;
  constructor(private notificationService: NotificationService,
    private dataDashbordService: DataDashbordService,
    private propertyManagementService: PropertyManagementService,
    private modalService: NgbModal) {
    this.onAuthHandlerRegister();
  }


  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();

    if (this._onAuthWindowCloseSubscrition) {
      this._onAuthWindowCloseSubscrition.unsubscribe();
    }
  }

  ngOnInit(): void {
    combineLatest([
      this.propertyManagementService.getProperty(this.property.propertyId, this.property.organizationId),
      this.dataDashbordService.brandSelectionChanged
    ]).pipe(
      takeUntil(this._destroying$)
    ).subscribe(result => {
      if (result[1] === this.property.brandId) {
        this.googleSearchConsoleProvider = this.dataDashbordService.googleSearchConsoselProvider;
        this.showAuthorization = true;

        if (result[0].isSuccess) {
          this.property = result[0].result as Property;
        }

        let currentSolution = this.property.solutions
          .find(x => x.propertyId == this.property.propertyId &&
            x.solutionProviderId == this.googleSearchConsoleProvider.solutionProviderId &&
            x.isActive);

        if (currentSolution?.accessTokenCredetialsSecret) {
          this.authorized = true;
        }

        this.solution = {
          propertyId: this.property.propertyId,
          solutionProviderId: this.googleSearchConsoleProvider.solutionProviderId,
          accessTokenCredetialsSecret: currentSolution?.accessTokenCredetialsSecret ?? '',
          platformId: currentSolution?.platformId ?? '',
          platformIdDisplayName: currentSolution?.platformIdDisplayName ?? '',
          platformUserId: currentSolution?.platformUserId ?? '',
          solutionId: currentSolution?.solutionId ?? '00000000-0000-0000-0000-000000000000',
          authCode: '',
          isActive: true
        };

        this.googlePropertyId = this.solution.platformId;
      }
    });
  }

  createOauthWindow(url: string, name = 'Authorization', width = 550, height = 700, left = 0, top = 0) {
    var left = (screen.width / 2) - (width / 2);
    var top = (screen.height / 2) - (height / 2);

    const options = `width=${width},height=${height},left=${left},top=${top}`;
    return window.open(url, name, options);
  }

  getQueryString(field: any, url: string) {
    const windowLocationUrl = url;
    const reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
    const string = reg.exec(windowLocationUrl);
    return string ? string[1] : null;
  };

  authorize() {
    let url = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=' +
      this.googleSearchConsoleProvider.mvaAppConfig.clientId +
      '&redirect_uri=' + this.googleSearchConsoleProvider.mvaAppConfig.redirectUri +
      '&scope=' + this.requestedScopes.join(' ') +
      '&state=GS-Auth' +
      '&include_granted_scopes=true' +
      '&response_type=code' +
      '&prompt=consent' +
      '&access_type=offline';

    this.windowHandle = this.createOauthWindow(url, 'Google Authorization')!;

    window.clearInterval(this.intervalId);

    this.intervalId = window.setInterval(() => {
      let href!: string;  // For referencing window url
      try {
        href = this.windowHandle?.location.href!; // set window location to href string
      } catch (e) {
        console.log('Error:', e); // Handle any errors here
      }

      if (href != null) {
        if (href.match('code')) {
          this.authorizationCode = this.getQueryString('code', href) ?? '';
          this.grantedScopes = this.getQueryString('scope', href) ?? '';
          window.clearInterval(this.intervalId);
          this.windowHandle?.close();
          this._onAuthWindowClose.next(true);
        }
        if (href.match('error')) {
          var error_Code = this.getQueryString('error', href) ?? '';
          this.notificationService.showErrorToast('Authorization failed with error code : ' + error_Code);
          window.clearInterval(this.intervalId);
          this.windowHandle?.close();
          this._onAuthWindowClose.next(false);
        }
      }

    }, this.intervalLength);
  }

  onAuthHandlerRegister() {
    this._onAuthWindowCloseSubscrition = this._onAuthWindowClose.subscribe(res => {
      if (res) {
        console.log('Saving GS Authorization');

        //check if user have granted all requested scopes
        let decodedScopes = decodeURIComponent(this.grantedScopes);
        let grantedScopesArray = decodedScopes.split(' ');
        if (!this.requestedScopes.every(scope => grantedScopesArray.includes(scope))) {
          this.notificationService.showErrorToast('Insufficient scopes granted , please try again.');
          return;
        }

        this.solution!.authCode = this.authorizationCode;
        this.dataDashbordService.authorizeGooglePlatform(this.solution!,this.organizationId).subscribe({
          next: (successResponse) => {
            if (successResponse.isSuccess) {
              this.notificationService.showSuccessToast('Property Authorized.');
              this.authorized = true;
            }
          },
          error: (errorResponse) => {
            {
              this.notificationService.showErrorToast('Failed to authorize ,please try again or contact administrator.');
            }
          }
        });
      }
    });
  }

  revoke() {
    this.dataDashbordService.revokeGooglePlatform(this.solution!,this.organizationId).subscribe({
      next: (successResponse) => {
        if (successResponse.isSuccess) {
          this.notificationService.showSuccessToast('Property authorization revoked.');
          this.authorized = false;
          this.googlePropertyId = '';
          this.closeDialog();
        }
      },
      error: (erroResponse) => {
        this.closeDialog();
        this.notificationService.showErrorToast('Property authorization revoke failed, please try again or contact administrator.');
      }
    });
  }

  chooseProperty() {
    const modalRef = this.modalService.open(SelectGoogleSearchSiteComponent);
    modalRef.componentInstance.solution = this.solution;
    modalRef.componentInstance.organizationId = this.organizationId;
    (modalRef.componentInstance as SelectGoogleSearchSiteComponent).SiteSelected.subscribe((siteUrl) => {
      this.onSiteSelected(siteUrl);
    });
  }

  onSiteSelected(siteUrl: string) {
    this.solution!.platformId = siteUrl;
    this.dataDashbordService.setPlatformIdForSolution(this.solution!,this.organizationId).subscribe(res => {
      if (res.isSuccess) {
        this.notificationService.showSuccessToast('PlatformId saved successfully.');
        this.googlePropertyId = siteUrl;
      }
    })
  }


  showRevokeDialog() {
    this.revokeGoogleModal!.nativeElement.style.display = 'block';
  }

  closeDialog() {
    this.revokeGoogleModal!.nativeElement.style.display = 'none';
  }
}
