import { Validators } from '@angular/forms';

export class Roles {
  public static MVASYSADMIN = '037ebe2f-23aa-48c3-af8c-030c63207cde';
  public static MVAPARTNER = 'c75e4771-d66c-4efc-ad91-ec731f2b5eb6';
  public static ORGADMIN = 'd7750bc6-9d72-484d-a313-531c7018e0c2';
  public static ORGUSER = '86e3716c-3db1-4ade-b190-ed8ff59998f0';
}

export class UserStatus {
  public static Active = '84ba1998-8928-415d-b02f-fbacd9757226';
  public static Inactive = 'e60dbcf2-fdaa-43f8-aaa4-cc83be057201';
  public static AwaitApproval = '40a36baa-3024-41bd-a482-074c0e48dcf9';
}

export class PartnerStatus {
  public static Active = '84ba1998-8928-415d-b02f-fbacd9757226';
  public static Inactive = 'e60dbcf2-fdaa-43f8-aaa4-cc83be057201';
}

export enum Report {
  Country = '157468EF-173F-4D71-A24A-6D3481F816F7',
  Property = '5662FD17-EF7A-4EAD-9605-3A7F2120A53C',
}

export class propertySubTypes {
  public static Facebook = 'bc9756de-7cd7-4651-b8b5-1fb160c55706';
  public static Tiktok = '6f361a50-7870-4386-a966-28f93108af84';
  public static Youtube = '8042a380-18af-4cb3-8330-30c4017e4aa8';
  public static Instagram = 'dc8de72a-21bc-44db-90ff-343633736a32';
  public static Website = '9d95ea67-6643-4d80-ba43-3c6e494ab658';
}

export enum ReportPageNames {
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  YouTube = 'YouTube',
  WebsiteContent = 'Website Content',
}

export const ReportPagePropertySubTypeMap: Map<string, string> = new Map([
  [ReportPageNames.Facebook, propertySubTypes.Facebook],
  [ReportPageNames.Instagram, propertySubTypes.Instagram],
  [ReportPageNames.YouTube, propertySubTypes.Youtube],
]);

export class SolutionProviderMasterList {
  public static solutionProviders = [
    { name: 'GOOGLEANALYTICS', id: '338D3024-6F80-4163-A9EB-7D7BCF1E998F' },
    { name: 'GOOGLESEARCHCONSOLE', id: '37C115ED-DEDB-416C-9BF8-8697308248CB' },
    { name: 'YOUTUBE', id: '0E55F337-5034-4C30-8ED2-F4A509476DFE' },
    { name: 'FACEBOOK', id: '480A93DE-70D4-4C7E-9C96-4353803061F6' },
    { name: 'INSTAGRAM', id: 'AF38A079-9751-427A-8B13-C91CC575E2A1' },
    { name: 'TIKTOK', id: '1D9FE5A1-5F65-4D4E-93BF-9B1E78B1CF93' },
  ];
}

export enum ReportType {
  Country,
  SocialMedia,
  Website,
  Journal,
}

export class RoutePath {
  public static ChangePassword = 'changepassword';
  public static CountryReport = 'countryreport';
  public static SocialMediaReport = 'socialmediareport';
  public static WebsiteReport = 'websitereport';
  public static ProfitLoss = 'profitloss';
  public static Empty = '';
  public static Organization = 'organization';
  public static Brands = 'brands';
  public static BrandAdd = 'add';
  public static BrandEdit = 'edit';
  public static Manage = 'manage';
  public static Solutions = 'solutions';
  public static SolutionAdd = 'add';
  public static SolutionEdit = 'edit';
  public static Badges = 'badges';
  public static BadgesAdd = 'add';
  public static BadgesEdit = 'edit/:id';
  public static ManageBadgeDetail = 'detail/:id'
  public static Visibility = 'visibility';
  public static ApprovalCenter = 'approvalcenter';
  public static UserProfile = 'userprofile';
  public static Users = 'users';
  public static TrustCenter = 'trustcenter';
  public static DataLinks = 'datalinks';
  public static Partners = 'partners';
  public static Journal = 'journal';
  public static OrgNotAssigned = 'orgnotassigned';
  public static PrivacyPolicy = 'privacy-policy';
  public static TermsOfService = 'terms-of-service';
  public static Home = 'home';
  public static Contact = 'contact';
  public static FindSolutions = 'find-solutions';
  public static FindMedia = 'find-media';
  public static FindMediaSingle = 'find-media/single/:id';
}

export const revenue = 'd2c519ff-a588-457b-a199-37553a52fcef';
export const cost = '8ceee529-adc8-44ce-8bd3-1d8a1b344ff4';
export const PatternValidator = Validators.pattern('^[a-zA-Z0-9 ]*$');
export const DateFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
  timeZone: 'UTC',
};
