<div class="d-flex justify-content-between border-bottom align-items-center p-2">
  <h6 class="card-title pt-05">TikTok
    <span class="mx-auto pl-02">
      <img src="../../../../assets/icons/info.svg" alt="Details of the google analytics propertoies"
        class="btn-icon" /></span>
  </h6>
  <img src="../../../../assets/icons/success.svg" alt="Fetched Successfully" class="d-none">
</div>
<img src="../../../../assets/icons/tiktok.svg" alt="YouTube Channel Logo" class="mx-auto h-45 p-3">
<div class="card-body" *ngIf="showAuthorization">
  <div class="row">
      <button type="button" a href="#" class="col-10 mx-auto btn btn-secondary mb-01" translate>DataConnections.Revoke</button>
      <button type="button" a href="#" class="col-10 mx-auto btn btn-secondary mb-01" translate>DataConnections.GetChannelId</button>
  </div>
  <div class="d-flex g-3 col-sm-12 d-none">
    <div class="fw-semibold pr-01" translate>DataConnections.ChannelId</div>
    <div class="text-break">Gdff32EfeTeeD453786</div>
  </div>
</div>
<div class="card-body" *ngIf="!showAuthorization">
  <div class="clearfix">
    <span class="sr-only" translate>DataConnections.ProviderNotImplemented</span>
    <div class="spinner-border spinner-border-sm float-right" role="status">
    </div>
  </div>
</div>
