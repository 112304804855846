<div class="container p-4" *ngIf="!isLoading && organization">
  <!-- Breadcrumb -->
  <nav aria-label="breadcrumb" class="mb-3 mt-2">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/find-media']">Find Media</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{ organization.name }}
      </li>
    </ol>
  </nav>

  <!-- Organization Header Section -->
  <div class="card mb-3 border-0">
    <div class="d-flex flex-sm-row flex-column align-items-start">
      <div class="d-flex flex-row align-items-start w-100">
        <!-- Organization Logo -->
        <img [src]="
            organization.primaryImageUrl
              ? organization.primaryImageUrl
              : '../../../../assets/img/nologo.png'
          " alt="Organization Logo" style="width: 50px; height: 50px" class="me-3 flex-shrink-0" />

        <!-- Organization Details -->
        <div class="w-100">
          <h5 class="mb-0 custom-header">{{ organization.name }}</h5>
          <p class="header-text mb-1" *ngIf="organization.locations">
            {{ "FindMedia.Location" | translate }}: {{ organization.locations }}
          </p>
          <p class="header-text mb-1" *ngIf="orgLanguages">
            {{ "FindMedia.Languages" | translate }}: {{ orgLanguages }}
          </p>
          <p class="header-text" *ngIf="
              organization.organizationTypeVisible &&
              organization.organizationType
            ">
            {{ "FindMedia.OrganizationType" | translate }}:
            {{ organization.organizationType.name }}
          </p>
        </div>
      </div>


      <!-- Button container, set to the right on large screens -->
      <div class="mt-2 mt-md-0 d-flex flex-column flex-md-row w-100">
        <button (click)="copyToClipboard()" class="btn btn-primary button-font mb-2 mb-md-0 me-md-2 ms-md-auto"
          translate>
          FindMedia.CopyLink
        </button>
        <button (click)="onOrgFavourite(organization.organizationId)" class="btn btn-primary button-font">
          <img class="button-image" [src]="
            organization.isFavorite
              ? '../../../../assets/img/activefav.png'
              : '../../../../assets/img/fav.png'
          " width="20" height="20" alt="{{ 'FindMedia.Favorites' | translate }}" />
          {{ (organization.isFavorite ? 'FindMedia.RemoveFromFavorite' : 'FindMedia.AddToFavorites') | translate }}
        </button>
      </div>
    </div>

    <hr />
    <div class="d-flex flex-wrap align-items-start gap-3 mt-1 p-3 ps-0" style="background-color: #f8f8f8">
      <!-- Organization Details -->
      <div class="d-flex flex-column justify-content-center" style="width: 350px">
        <p class="my-0" *ngIf="organization.foundationYear && organization.foundationYearVisible">
          <strong translate>FindMedia.Founded</strong>:
          {{ organization.foundationYear }}
        </p>
        <p class="my-0" *ngIf="organization.totalFte && organization.totalFteVisible">
          <strong translate>FindMedia.Employees</strong>:
          {{ organization.totalFte }}
        </p>
        <p class="my-0" *ngIf="organization.organizationTypeVisible">
          <strong translate>FindMedia.OrganizationType</strong>:
          {{ organization.organizationType.name }}
        </p>
      </div>

      <!-- Badges Section -->
      <div class="d-flex flex-column badge-images-container"
        *ngIf="organization.badgesVisible && organization.badges.length > 0">
        <strong class="" translate>FindMedia.Badges</strong>
        <div class="d-flex flex-wrap mt-2 align-items-center gap-2">
          <a *ngFor="let badge of organization.badges" [routerLink]="['/badges', badge.badgeId]">
            <img [src]="badge.primaryImageUrl" alt="badge" class="round-image border border-secondary-subtle image-container" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Brands Section -->
  <div class="card mb-3 card-custom" *ngIf="organization.brands.length > 0">
    <div class="card-header d-flex align-items-center">
      <h6 class="mb-0 card-title-text" translate>FindMedia.Brands</h6>
    </div>
    <div class="card-body p-0">
      <div class="card border-0" *ngFor="let brand of organization.brands">
        <div class="border-bottom">
          <div class="d-flex  align-items-center justify-content-between p-3">
            <div class="d-flex align-items-start ms-2 me-3" style="width: 330px;">
              <!-- Toggle Button for Brand Properties -->
              <button class="btn btn-link p-0 me-3" (click)="toggleBrand(brand.brandId)">
                <i class="bi me-1" [ngClass]="
                    toggledBrands[brand.brandId]
                      ? 'bi-chevron-up'
                      : 'bi-chevron-down'
                  " style="font-size: 0.9rem"></i>
              </button>
              <img [src]="
                  brand.logoUrl
                    ? brand.logoUrl
                    : '../../../../assets/img/nologo.png'
                " alt="Brand Logo" style="flex-shrink: 0;
                   width:50px" class="me-2" />
              <div class="ms-2 w-100">
                <h6 class="mb-0 custom-header">{{ brand.name }}</h6>
                <p class="text-muted mb-0">{{ getBrandExile(brand.inExile) }}</p>
              </div>
            </div>
            <div *ngIf="organization.brandBadgesVisible"
              class="d-none d-md-flex w-md-auto align-items-center badge-images-container">
              <a *ngFor="let badge of brand.badges" [routerLink]="['/badges', badge.badgeId]">
                <img [src]="badge.primaryImageUrl" alt="badge" class="round-image border border-secondary-subtle image-container" />
              </a>
            </div>
            <div class="text-end ms-auto" style="margin-right: 2em">
              <button class="btn btn-default" (click)="onBrandFavourite(brand)" aria-label="Toggle Favorite">
                <img [src]="
                    brand.isFavorite
                      ? '../../../../assets/img/activefav.png'
                      : '../../../../assets/img/fav.png'
                  " width="20" height="20" alt="FindMedia.Favorites" />
              </button>
            </div>
          </div>
          <div *ngIf="organization.brandBadgesVisible && brand.badges.length > 0"
            class="w-100 d-block d-md-none flex-wrap align-items-center ms-2">
            <strong class="d-block mb-1" translate>FindMedia.Badges</strong>
            <a *ngFor="let badge of brand.badges" [routerLink]="['/badges', badge.badgeId]">
              <img [src]="badge.primaryImageUrl" alt="badge" class="round-image border border-secondary-subtle image-container" />
            </a>
          </div>
        </div>
        <div style="background-color: #f9f9f9;">
          <!-- Properties Section (Toggles with chevron for each brand) -->
          <div *ngIf="toggledBrands[brand.brandId] && brand.properties.length > 0" class="card m-3 mt-4 card-custom">
            <div class="card-header">
              <h6 class="card-title-text" translate>FindMedia.Properties</h6>
            </div>
            <div class="mt-2" *ngFor="let property of brand.properties">
              <div *ngIf="property.visible" class="d-flex align-items-center justify-content-between p-3 pt-0 border-0">
                <div class="d-flex align-items-start ms-4" style="width: 310px">
                  <img [src]="getIcon(property.propertySubTypeId)" alt="{{property.propertyName}}" class="me-2" style="flex-shrink: 0;
         width:45px">
                  <div class="ms-3">
                    <h6 class="mb-0 custom-header">
                      {{ property.propertyName }}
                    </h6>
                    <p class="text-muted mb-0">
                      {{property.location}}, {{ property.primaryLanguage }}
                    </p>
                    <a class="website-link" href="{{ property.propertyUrl }}" target="_blank"
                      translate>FindMedia.VisitWebsite</a>
                  </div>
                </div>

                <div class="d-none d-md-flex d-flex align-items-center badge-images-container img">
                  <a *ngFor="let badge of property.badges" [routerLink]="['/badges', badge.badgeId]">
                    <img [src]="badge.primaryImageUrl" alt="badge" class="round-image border border-secondary-subtle image-container" />
                  </a>
                </div>
                <div class="text-end ms-auto">
                  <button class="btn btn-default" (click)="onPropertyFavourite(property)">
                    <img [src]="
              property.isFavorite
                ? '../../../../assets/img/activefav.png'
                : '../../../../assets/img/fav.png'
            " width="20" height="20" alt="FindMedia.Favorites" />
                  </button>
                </div>
              </div>
              <div class="w-100 d-block d-md-none flex-wrap align-items-center ms-2" *ngIf="property.badges.length > 0">
                <strong class="d-block mb-1" translate>FindMedia.Badges</strong>
                <a *ngFor="let badge of property.badges" [routerLink]="['/badges', badge.badgeId]">
                  <img [src]="badge.primaryImageUrl" alt="badge" class="round-image border border-secondary-subtle image-container" />
                </a>
              </div>
              <hr class="no-margin">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Organization Users Section -->
  <div *ngIf="organization.organizationUsers.length > 0">
    <div class="bg-white mt-2 pb-3">
      <h6 class="mb-0 card-title-text" translate>FindMedia.OrganizationUsers</h6>
    </div>

    <div class="d-flex flex-wrap align-items-center justify-content-between p-3 ps-0 border"
      *ngFor="let orgUser of organization.organizationUsers">

      <!-- User Details -->
      <div class="d-flex align-items-center ms-2 me-3 max-screen">
        <i class="bi bi-person-circle me-4" style="font-size: 25px"></i>
        <div>
          <h6 class="mb-0">
            {{ orgUser.user.firstName }} {{ orgUser.user.lastName }}
          </h6>
        </div>
      </div>

      <!-- Badges for Large Screens (Same Row) -->
      <div class="d-none d-md-flex flex-wrap align-items-center badge-images-container">
        <a *ngFor="let badge of orgUser.badges" [routerLink]="['/badges', badge.badgeId]">
          <img [src]="badge.primaryImageUrl" alt="badge" class="round-image border border-secondary-subtle image-container" />
        </a>
      </div>

      <div class="text-end ms-auto align-self-center align-self-md-start" style="margin-right: 2em">
        <a [href]="'mailto:' + orgUser.user.email" class="no-link-style">
          <i class="bi bi-envelope" style="font-size: 1.5rem; cursor: pointer"></i>
        </a>
      </div>

      <!-- Badges for Small Screens (Move to a New Line) -->
      <div class="w-100 d-block d-md-none mt-2" *ngIf="orgUser.badges.length">
        <strong class="d-block mb-1" translate>FindMedia.Badges</strong>
        <div class="d-flex flex-wrap gap-2">
          <a *ngFor="let badge of orgUser.badges" [routerLink]="['/badges', badge.badgeId]">
            <img [src]="badge.primaryImageUrl" alt="badge" class="round-image border border-secondary-subtle image-container" />
          </a>
        </div>
      </div>

    </div>
  </div>

</div>