import { FacebookLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, combineLatest, takeUntil } from 'rxjs';
import { DataDashbordService } from 'src/app/services/datadashboard.service';
import { PropertyManagementService } from 'src/app/services/propertymanagement.service';
import { FacebookPage, IData } from 'src/app/shared/models/FacebookPage';
import { Solution } from 'src/app/shared/models/Solution';
import { SolutionProvider } from 'src/app/shared/models/SolutionProvider';
import { Property } from 'src/app/shared/models/property.model';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { FbSelectPagesComponent } from './fb-select-pages/fb-select-pages.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: '[app-facebook]',
  templateUrl: './facebook.component.html',
  styleUrls: ['./facebook.component.css']
})
export class FacebookComponent implements OnInit, OnDestroy {
  @Input() property!: Property;
  @Input() organizationId!: string;

  @ViewChild('revokeFacebookModal') revokeFacebookModal: ElementRef | undefined;

  private readonly _destroying$ = new Subject<void>();

  solution?: Solution;
  private facebookProvider!: SolutionProvider;
  showAuthorization = false;
  authorized!: boolean;
  reconnect!: boolean;
  facebokLoginResponse: any;
  facebookPage?: string;

  missingPermissionsSubscription?: Subscription;

  constructor(private notificationService: NotificationService,
    private dataDashbordService: DataDashbordService,
    private propertyManagementService: PropertyManagementService,
    private modalService: NgbModal,
    private authService: SocialAuthService,
    private http: HttpClient) { }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();

    if (this.missingPermissionsSubscription && !this.missingPermissionsSubscription.closed)
      this.missingPermissionsSubscription.unsubscribe();
  }

  ngOnInit(): void {
    combineLatest([
      this.propertyManagementService.getProperty(this.property.propertyId, this.property.organizationId),
      this.dataDashbordService.brandSelectionChanged
    ]).pipe(
      takeUntil(this._destroying$)
    ).subscribe(result => {
      if (result[1] === this.property.brandId) {
        this.facebookProvider = this.dataDashbordService.facebookProvider;
        this.showAuthorization = true;

        if (result[0].isSuccess) {
          this.property = result[0].result as Property;
        }

        let currentSolution = this.property.solutions
          .find(x => x.propertyId == this.property.propertyId &&
            x.solutionProviderId == this.facebookProvider.solutionProviderId &&
            x.isActive);

        if (currentSolution?.accessTokenCredetialsSecret) {
          this.authorized = true;
        }

        this.solution = {
          propertyId: this.property.propertyId,
          solutionProviderId: this.facebookProvider.solutionProviderId,
          accessTokenCredetialsSecret: currentSolution?.accessTokenCredetialsSecret ?? '',
          platformId: currentSolution?.platformId ?? '',
          platformIdDisplayName: currentSolution?.platformIdDisplayName ?? '',
          platformUserId: currentSolution?.platformUserId ?? '',
          solutionId: currentSolution?.solutionId ?? '00000000-0000-0000-0000-000000000000',
          authCode: '',
          isActive: true,
          isPlatformPermisssionMissing: currentSolution?.isPlatformPermisssionMissing
        };

        if (this.solution.platformIdDisplayName) {
          this.facebookPage = this.solution.platformIdDisplayName;
        }

        if (this.solution.isPlatformPermisssionMissing) {
          this.reconnect = true;
        }
      }
    });

    this.registerMissingPermissionsSubscription();
  }

  authorize() {
    this.authService
      .signIn(FacebookLoginProvider.PROVIDER_ID, {
        config_id: this.facebookProvider.mvaAppConfig.fbLoginConfigId
      })
      .then((result) => {
        this.solution!.authCode = result.authToken;
        this.solution!.platformUserId = result.id; // facebook userId
        this.solution!.platformId = '';
        this.solution!.platformIdDisplayName = '';

        // try to get facebook userId again with authToken
        if (!result.id) {
          this.http.get<{ name: string, id: string }>('https://graph.facebook.com/me?fields=name,id&access_token=' + result.authToken).subscribe(idRes => {
            this.solution!.platformUserId = idRes.id; // facebook userId
            this.saveLongLivedToken();
          });
        }
        else{
          this.saveLongLivedToken();
        }

      })
      .catch((error) => {
        this.notificationService.showErrorToast('Facebook authorization failed : ' + error);
      });
  }

  private saveLongLivedToken() {
    this.dataDashbordService.authorizeFacebookPlatform(this.solution!, this.organizationId).subscribe({
      next: (successResponse: string[]) => {
        this.authorized = true;
        this.dataDashbordService.missingFBPermissionsSubject$.next(successResponse);
        this.notificationService.showSuccessToast('Property Authorized.');
        if (successResponse && successResponse.length > 0) {
          this.notificationService.showErrorToast('Missing permissions for few properties, please reconnect.');
        }
      },
      error: (errorResponse) => {
        {
          this.notificationService.showErrorToast('Failed to authorize ,please try again or contact administrator.');
        }
      }
    });
  }

  selectPages() {
    const modalRef = this.modalService.open(FbSelectPagesComponent);
    modalRef.componentInstance.solution = this.solution;
    modalRef.componentInstance.organizationId = this.organizationId;
    (modalRef.componentInstance as FbSelectPagesComponent).PagesSelected.subscribe((page: IData) => {
      this.onPagesSelected(page);
    });
  }

  onPagesSelected(page: IData) {
    this.solution!.platformId = page.id!;
    this.solution!.platformIdDisplayName = page.name!;
    this.dataDashbordService.setPlatformIdForSolution(this.solution!, this.organizationId).subscribe(res => {
      if (res.isSuccess) {
        this.notificationService.showSuccessToast('PlatformId saved successfully');
        this.facebookPage = page.name;
      }
    })
  }

  revoke() {
    this.dataDashbordService.revokeFacebookPlatform(this.solution!, this.organizationId).subscribe({
      next: (successResponse) => {
        this.notificationService.showSuccessToast('Property authorization revoked.');
        this.authorized = false;
        this.facebookPage = '';
        this.solution!.platformId = '';
        this.solution!.platformIdDisplayName = '';
        this.solution!.platformUserId = '';
        this.closeDialog();
      },
      error: (erroResponse) => {
        this.closeDialog();
        this.notificationService.showErrorToast('Property authorization revoke failed, please try again or contact administrator.');
      }
    });
  }

  registerMissingPermissionsSubscription() {
    this.missingPermissionsSubscription = this.dataDashbordService.missingFBPermissionsSubject$.subscribe((res: string[]) => {
      if (res && res.length > 0) {
        if (res.includes(this.solution?.solutionId!)) {
          this.reconnect = true;
        }
        else {
          this.reconnect = false;
        }
      }
      else {
        this.reconnect = false;
      }
    });
  }

  showRevokeDialog() {
    this.revokeFacebookModal!.nativeElement.style.display = 'block';
  }

  closeDialog() {
    this.revokeFacebookModal!.nativeElement.style.display = 'none';
  }
}
