<div class="body flex-grow-1 text-start">
    <div class="container-lg">
        <div class="row hp-1">
          <div>
            <h2 translate class="wp-block-heading">Home.Heading</h2>
            <div class="row">
              <div class="row mb-2 d-inline-flex col-md-12">
                <div class="entry-content mb-4">
                  <h3 translate style="font-weight: bold; font-size: 16px;">Home.SubheadingNews</h3>
                  <p>
                    <a href="{{ 'Home.SurveyUrl' | translate }}" target="_blank" class="btn btn-primary" style="margin-top: 8px" translate>
                      Home.Survey
                    </a>
                  </p>
                  <p [innerHTML]="'Home.FundingOpportunities' | translate" style="color: #404040;"></p>
                  <p [innerHTML]="'Home.FeedbackJoin' | translate" style="color: #404040;"></p>
                  <h3 translate style="font-weight: bold; font-size: 16px;">Home.SubheadingUpdates</h3>
                  <ul>
                    <li style="color: #404040;">
                      <span [innerHTML]="'Home.UpdateChange' | translate"></span>
                    </li>
                    <li style="color: #404040;">
                      <span [innerHTML]="'Home.UpdateImprovement' | translate"></span>
                    </li>
                    <li style="color: #404040;">
                      <span [innerHTML]="'Home.UpdateBugFix' | translate"></span>
                    </li>
                  </ul>

                  <h3 translate style="font-weight: bold; font-size: 16px;">Home.SubheadingKnowledge</h3>
                  <ul>
                    <li style="color: #404040;">
                      <span [innerHTML]="'Home.KnowledgeCompare' | translate"></span>
                    </li>
                  </ul>

                  <h3 translate style="font-weight: bold; font-size: 16px;">Home.SubheadingQuickStart</h3>
                  <ol>
                    <li style="color: #404040;">
                      <span [innerHTML]="'Home.QuickStartManage' | translate"></span>
                    </li>
                    <li style="color: #404040;">
                      <span [innerHTML]="'Home.QuickStartWait' | translate"></span>
                    </li>
                    <li style="color: #404040;">
                      <span [innerHTML]="'Home.QuickStartShare' | translate"></span>
                    </li>
                    <li style="color: #404040;">
                      <span [innerHTML]="'Home.QuickStartVideo' | translate"></span>
                    </li>
                  </ol>

                  <h3 translate style="font-weight: bold; font-size: 16px;">Home.SubheadingContact</h3>
                  <p [innerHTML]="'Home.Contact' | translate"></p>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>
