  <div class="table-responsive mt-4">
    <table class="table">
        <thead class="table-header-custom">
      <tr>
         <th class="heading-padding" translate>Badge.TableName</th>
          <th class="text-center" translate>Badge.TableCreated</th>
          <th class="text-center" translate>Badge.TableUploaded</th>
          <th class="text-center" translate>Badge.TableStatus</th>
          <th class="text-center"></th>
        </tr>
        </thead>
        <tbody>
            <tr class="custom-row" *ngFor="let badge of badges" (click)="goToDetails(badge.badgeId!)">
               
                <td>
                    <span style="display: inline-flex; align-items: center; ;">
                            <img
                            [src]="
                            badge.primaryImageUrl
                                ? badge.primaryImageUrl
                                : '/assets/icons/no-logo.svg'
                            "
                            [id]="badge.badgeId"
                            [title]="'Common.ViewDetails' | translate"
                            class="round-image border border-secondary-subtle me-3 d-none d-md-inline"
                          />
                    <span style="display: inline-block; padding-left: 4px;">  {{ badge.name }}</span>
                      </span>
                </td>
                <td class="text-center"><p style="margin-top: 1.8em;">{{ badge.createdOn | date : "MMM d, y" }}</p></td>
                <td class="text-center"><p style="margin-top: 1.8em;">{{ badge.createdByUser?.firstName }}
                    {{ badge.createdByUser?.lastName }}</p></td>
                <td class="text-center pt-4">
                    <active-status
                    [active]="badge.active ?? false"
                  ></active-status>
                </td>
                <td class="text-center pt-4">
                    <!-- Three-dot Dropdown Icon -->
                    <div ngbDropdown container="body">
                      <button
                        dropdown-toggle
                        btn
                        p-0
                        class="btn p-0 hide-arrow"
                        ngbDropdownToggle
                        aria-expanded="false"
                        (click)="$event.stopPropagation()"
                      >
                        <i class="bi bi-three-dots"></i>
                      </button>
                      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
                        <button
                          ngbDropdownItem
                          translate
                          (click)="editBadge(badge.badgeId!)"
                        >
                       
                          <i class="bi bi-bookmark me-2"></i>Common.Edit
                        </button>
                        <div class="dropdown-divider"></div>
                        <button
                          ngbDropdownItem
                          translate
                          class="text-danger"
                        (click)="deleteBadge(badge.badgeId!)"
                        >
                          <i class="bi bi-trash me-2"></i>Common.Delete
                        </button>
                      </div>
                    </div>
                  </td>
        </tr>
        </tbody>
    </table>
</div>