<div class="container p-md-4 p-2">
  <!-- Title and Subtitle -->
  <div class="header-section">
    <h3 translate>
      <img src="../../../../assets/img/approvalcenter.png" />
      ApprovalCenter.Title
    </h3>
    <p translate>ApprovalCenter.WelcomeMessage</p>
  </div>

  <!-- Sort and filter -->
  <form>
    <div class="row mb-2 mb-lg-4">
      <!-- Sort by Dropdown -->
      <div class="col-12 col-lg-4 column-gap-2 column-gap-lg-0 align-self-center">
        <select class="form-select my-auto" (change)="fetchApplications($event)" aria-label="Sort select">
          <option value="" selected>
            {{ "ApprovalCenter.ShowAll" | translate }}
          </option>
          <option *ngFor="let dropdown of dropdownApplications" [value]="dropdown.badgeId">
            {{ dropdown.badgeName }} <span *ngIf="dropdown.count > 0">({{ dropdown.count }})</span>
          </option>
        </select>
      </div>

      <!-- Spacer -->
      <div class="col-0 col-lg"></div>

    </div>
  </form>
  <hr>

  <div class="table-responsive mt-4" infiniteScroll (scrolled)="onScroll()" [infiniteScrollDistance]="0.5"
    [infiniteScrollThrottle]="300">
    <table class="table">
      <thead class="table-header-custom">
        <tr>
          <th class="heading-padding" translate>ApprovalCenter.Table.Organization</th>
          <th translate>ApprovalCenter.Table.Request</th>
          <th translate>ApprovalCenter.Table.Pending</th>
        </tr>
      </thead>
      <tbody *ngIf="applications.length >= 0">
        <ng-container *ngFor="let application of applications; let i = index">
          <tr class="custom-row">
            <td><!-- Toggle Button-->

              <button class="btn btn-link p-0 me-3"
                (click)="toggleRequest(i,application.organizationId, application.badgeId)">
                <i class="bi me-1" [ngClass]="
              toggledRequest[i]
                  ? 'bi-chevron-up'
                  : 'bi-chevron-down'
              " style="font-size: 0.9rem"></i>
              </button>
              <img [src]="
              application.organizationLogoUrl
                ? application.organizationLogoUrl
                : '../../../../assets/img/nologo.png'
            " alt="{{application.organizationName}}" class="me-3 d-none d-md-inline" />
              <span class="text-center ms-1">
                {{application.organizationName}}
              </span>

            </td>
            <td>
              <p class="tr-spacing">
                {{application.badgeName}}
              </p>
            </td>
            <td>
              <p class="tr-spacing">
                {{application.pendingRequest}}
              </p>

            </td>
            <!-- Nested Table -->
          <tr *ngIf="toggledRequest[i]">
            <td colspan="4" style="background-color: #F8F8F8;">
              <table class="table mt-1">
                <thead class="table-header-custom">
                  <tr>
                    <th class="inner-heading-padding" translate>ApprovalCenter.Table.RequestingFor</th>
                    <th translate>ApprovalCenter.Table.Type</th>
                    <th translate>ApprovalCenter.Table.IssueDate</th>
                    <th translate>ApprovalCenter.Table.ExpiryDate</th>
                    <th translate>ApprovalCenter.Table.Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let request of badgeApplications">
                    <td>
                      <div class="d-flex align-items-center ms-4 ps-2">
                        <!-- Icon based on request type -->
                        <img *ngIf="request.type.toLowerCase() == applyTypes.property"
                          [src]="getIcon(request.propertySubTypeId)" alt="{{application.organizationName}}"
                          class="me-2 image-icon-size" />
                        <i *ngIf="request.type.toLowerCase() == applyTypes.user"
                          class="bi bi-person-circle me-2 iconsize"></i>
                        <img *ngIf="request.type.toLowerCase() == applyTypes.brand"
                          src="../../../../assets/icons/brandbadge.svg" class="me-2 image-icon-size" />
                        <img *ngIf="request.type.toLowerCase() == applyTypes.organization"
                          src="../../../../assets/icons/orgbadge.svg" class="me-2 image-icon-size" />
                        <span>{{ request.requestingFor }}</span>
                      </div>
                    </td>
                    <td>
                      <p class="tr-spacing">
                        {{request.type}}
                      </p>
                    </td>
                    <td>
                      <p class="tr-spacing" *ngIf="request.status == Approved">
                        {{request.validFrom | date : "MMM d, y"}}
                      </p>
                      <p class="tr-spacing" *ngIf="request.status == Pending">
                        -
                      </p>
                    </td>
                    <td>
                      <p class="tr-spacing">
                        {{request.validTo | date : "MMM d, y"}}
                      </p>
                    </td>
                    <td>
                      <span class="span">
                        <button class="btn btn-secondary me-3" *ngIf="request.status == Pending"
                          (click)="rejectApplication(request.applicationId,request.requestingFor)"
                          translate>ApprovalCenter.Reject</button>
                      </span>
                      <span>
                        <button class="btn btn-primary ms-3" *ngIf="request.status == Pending"
                          (click)="approveApplication(request.applicationId, request.requestingFor)"
                          translate>ApprovalCenter.Approve</button>
                        <button class="btn btn-primary ms-3" *ngIf="request.status == Approved"
                          (click)="revokeBadge(request.applicationId, request.requestingFor)"
                          translate>ApprovalCenter.Revoke</button>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="row text-center m-5" *ngIf="applications.length=== 0">
    <h3 class="empty-state" translate>ApprovalCenter.NoMatchingRecords.Title</h3>
    <p translate>ApprovalCenter.NoMatchingRecords.Caption</p>
  </div>
</div>