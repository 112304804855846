<div class="modal-header">
    <h4 class="modal-title title" translate>{{ badge.name }}</h4>
    <div type="button" class="btn-off" data-coreui-dismiss="offcanvas" aria-label="Close"
        (click)="activeModal.dismiss()"></div>
</div>
<div class="modal-body">
    <div class="p-2">
        <div class="text-center mb-4">
            <img [src]="badge.image" width="60" height="60" alt="badge.name">
        </div>
        <span class="content">
            <strong>{{ 'FindMedia.Provider' | translate }}:</strong> {{badge.provider}}
        </span><br>
        <span class="content">
            <strong>{{ 'FindMedia.IssueDate' | translate}}:</strong> {{badge.validFrom | date : "MMM d, y"}}
        </span><br>
        <span class="content">
            <strong >{{'FindMedia.ExpiryDate'|translate}}:</strong> {{badge.validTo | date : "MMM d, y"}}
        </span>
    </div>

</div>