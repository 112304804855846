import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { GridApi, ColDef, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { Subject, mergeMap, takeUntil } from 'rxjs';
import { DateFormatOptions } from 'src/app/shared/app-constants/constants';
import { AppContext } from 'src/app/shared/context/AppContext';
import { JournalActionsRendererComponent } from '../journal-actions-renderer/journal-actions-renderer.component';
import { AddEditEntryComponent } from '../add-edit-entry/add-edit-entry.component';
import { FlatFinancial } from 'src/app/shared/models/FlatFinancial';
import { FinancialService } from 'src/app/services/financial.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { JournalLevelCellrendererComponent } from './journal-level-cellrenderer/journal-level-cellrenderer.component';

var selectedYear = 0;
@Component({
  selector: 'app-journal-entry-grid',
  templateUrl: './journal-entry-grid.component.html',
  styleUrls: ['./journal-entry-grid.component.css']
})
export class JournalEntryGridComponent implements OnInit, OnDestroy {

  private readonly _destroying$ = new Subject<void>();
  private gridApi!: GridApi<FlatFinancial>;
  financialList: FlatFinancial[] = [];
  paginationPageSize = 50;
  organizationList!: any[];
  organizationId: string = "";
  baseCurrency?: string = "";
  startYear = 2023;
  currentYear = new Date().getFullYear();
  yearFilter: number[] = [];

  columnDefs: ColDef[] = [
    { headerName: this.translate.instant('FinanceData.JournalEntryGrid.FromDate'), field: 'fromDate', valueGetter: param => this.formatDate(param.data.fromDate), filter: true, width: 150, sort: 'desc', comparator: this.utilityService.stringDateComparator },
    { headerName: this.translate.instant('FinanceData.JournalEntryGrid.ToDate'), field: 'toDate', valueGetter: param => this.formatDate(param.data.toDate), width: 150, filter: true, comparator: this.utilityService.stringDateComparator },
    { headerName: this.translate.instant('FinanceData.JournalEntryGrid.Account'), field: 'accountName', width: 90 },
    { headerName: this.translate.instant('FinanceData.JournalEntryGrid.Type'), field: 'revenueTypeName', width: 300 },
    { headerName: `${this.translate.instant('FinanceData.JournalEntryGrid.Amount')} (${this.baseCurrency ?? ""})`, field: 'amount', width: 200, },
    { headerName: this.translate.instant('FinanceData.JournalEntryGrid.Level'), field: '', cellRenderer : JournalLevelCellrendererComponent, width: 200, },
    { headerName: this.translate.instant('FinanceData.JournalEntryGrid.Actions'), cellRenderer: JournalActionsRendererComponent, cellRendererParams: {context: { journalEntryGridComponent: this }}, width: 150 },
  ];

  defaultColDef = {
    sortable: true,
    resizable:true,
  };

  constructor(private appContext: AppContext,
    private financialService: FinancialService,
    private offcanvasService: NgbOffcanvas,
    private utilityService: UtilityService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.appContext.organization.pipe(
      mergeMap((organization) => {
        this.organizationId = organization!.organizationId;
        return this.financialService.getFinancialData(organization!.organizationId);
      }),
      takeUntil(this._destroying$)
    ).subscribe((financial) => {
      selectedYear = 0;
      if (financial[0].financialId !== '00000000-0000-0000-0000-000000000000') {
        this.financialList = financial;
        this.baseCurrency = this.financialList[0]?.baseCurrencyName;
      } else {
        this.baseCurrency = financial[0]?.baseCurrencyName;
        this.financialList = [];
      }
      if (financial) {
        this.updateColumnHeader();
        this.financialList.forEach((f) => {
          f.fromDate = new Date(f.fromDate);
          f.toDate = new Date(f.toDate);
        });
      }
    });

    this.yearFilter.push(this.currentYear);
    for (let index = 1; index < 4; index++) {
      this.yearFilter.push(this.currentYear + index);
    }
  }

  onGridReady(params: GridReadyEvent<FlatFinancial>) {
    this.gridApi = params.api;
    this.gridApi.addEventListener('firstDataRendered', () => {
      this.updateColumnHeader();
    });
  }

  refreshGrid() {
    this.financialService.getFinancialData(this.organizationId).subscribe((financial) => {
      this.financialList = financial;
      this.financialList.forEach((f) => {
        f.fromDate = new Date(f.fromDate);
        f.toDate = new Date(f.toDate);
      });
    });
    this.gridApi.refreshCells();
  }

  isExternalFilterPresent(): boolean {
    return selectedYear !== 0;
  }

  doesExternalFilterPass(node: IRowNode<FlatFinancial>): boolean {
    if (node.data) {
      if (selectedYear === 0) {
        return true;
      } else {
        return node.data.year === selectedYear;
      }
    }
    return true;
  }

  externalFilterChanged(event: Event) {
    selectedYear = +((event.target) as HTMLSelectElement).value;
    this.gridApi.onFilterChanged();
  }

  updateColumnHeader() {
    if (this.gridApi) {
      const column = this.gridApi.getColumnDef('amount');

      if (column) {
        column.headerName = `${this.translate.instant('FinanceData.JournalEntryGrid.Amount')} (${this.baseCurrency ?? ""})`;
        this.gridApi.refreshHeader();
      }
    }
  }

  formatDate(date: Date) {
    return date.toLocaleDateString(this.translate.currentLang, DateFormatOptions);
  }

  getLevel(data: FlatFinancial) {
    if (data.propertyName) {
      return data.propertyName;
    } else if (data.brandName) {
      return data.brandName;
    } else {
      return data.organizationName;
    }
  }

  addEntry() {
    const offcanvasRef = this.offcanvasService.open(AddEditEntryComponent, { ariaLabelledBy: 'offcanvas-basic-title', position: 'end', backdrop: 'static' });
    offcanvasRef.componentInstance.entryAdded.subscribe(() => {
      this.refreshGrid();
    })
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
