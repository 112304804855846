<powerbi-report [embedConfig]="reportConfig"
                [cssClassName]="'report-container mx-auto'"
                [phasedEmbedding]="phasedEmbeddingFlag"
                [eventHandlers]="eventHandlersMap" *ngIf="!hideReport">
</powerbi-report>
<div *ngIf="hideReport">
    <div *ngIf="isOrgAdmin" class="hpr-1">
        <button class="btn btn-primary" type="button" [routerLink]="brandsLink" id="addPropertyButton" translate>
            Brand.AddProperty
        </button>
    </div>
    <div *ngIf="!isOrgAdmin" class="hpm-1" translate>
        <img class="btn-info" src="../../../../assets/icons/info.svg">
        Report.ContactAdminMessage
    </div>
</div>
