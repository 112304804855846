import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveOffcanvas, NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Subject, combineLatest, mergeMap, takeUntil } from 'rxjs';
import { FinancialService } from 'src/app/services/financial.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { propertySubTypes, revenue } from 'src/app/shared/app-constants/constants';
import { AppContext } from 'src/app/shared/context/AppContext';
import { Account } from 'src/app/shared/models/Account';
import { AddEntry } from 'src/app/shared/models/AddEntry';
import { FlatFinancial } from 'src/app/shared/models/FlatFinancial';
import { OrganizationForJournalEntry } from 'src/app/shared/models/OrganizationForJournalEntry';
import { RevenueType } from 'src/app/shared/models/RevenueType';
import { MasterDataService } from 'src/app/shared/services/master-data.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-add-edit-entry',
  templateUrl: './add-edit-entry.component.html',
  styleUrls: ['./add-edit-entry.component.css']
})
export class AddEditEntryComponent implements OnInit, OnDestroy {

  @Input() params!: ICellRendererParams<any, any, any>;
  @Output() entryAdded = new EventEmitter();
  private readonly _destroying$ = new Subject<void>();
  addEntryForm!: FormGroup;
  @Input() userId!: string;
  vaidationErrors: string[] = [];
  partnerNameExistsError = false;
  IdentityUserCreateError = false;
  UserNotFoundError = false;
  accounts: Account[] = [];
  revenueTypes: RevenueType[] = [];
  revenue = revenue;
  organization: OrganizationForJournalEntry[] = [];
  organizationId!: string;
  financial?: FlatFinancial;
  baseCurrency: string = "";
  organizationName: string = "";
  isFocused = false;

  get account() { return this.addEntryForm.get('account'); }
  get revenueType() { return this.addEntryForm.get('revenueType'); }
  get amount() { return this.addEntryForm.get('amount'); }
  get level() { return this.addEntryForm.get('level'); }

  constructor(private appContext: AppContext,
    private financialService: FinancialService,
    private organizationService: OrganizationService,
    private masterDataService: MasterDataService,
    public activeOffcanvas: NgbActiveOffcanvas,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    combineLatest([
      this.appContext.user.pipe(
        mergeMap((user) => {
          return this.masterDataService.getFinanceMasterData(user.languageId);
        })
      ),
      this.appContext.organization.pipe(
        mergeMap((organization) => {
          this.organizationId = organization!.organizationId;
          return this.organizationService.getOrganizationBrandsAndProperties(organization!.organizationId)
        })
      )
    ]).pipe(
      takeUntil(this._destroying$)
    ).subscribe(([financeMasterData, organization]) => {
        this.accounts = financeMasterData.accounts;
        this.revenueTypes = financeMasterData.revenueTypes;
        this.organization = organization;
        if (organization) {
          this.baseCurrency = organization[0]?.baseCurrency;
          this.organizationName = organization[0]?.organizationName;
        }
    });

    if (this.params) {
      this.financial = this.params.data;
    }

    this.startDate = this.financial ? new NgbDate(this.financial.fromDate.getFullYear(), this.financial.fromDate.getMonth() + 1, this.financial.fromDate.getDate()) : this.calendar.getToday();
    this.endDate = this.financial ? new NgbDate(this.financial.toDate.getFullYear(), this.financial.toDate.getMonth() + 1, this.financial.toDate.getDate()) : null;

    this.initializeForm();
  }

  initializeForm() {
    this.addEntryForm = new FormGroup({
      account: new FormControl(this.financial ? this.financial?.accountId : '', [Validators.required]),
      revenueType: new FormControl(this.financial ? this.financial?.revenueTypeId : ''),
      amount: new FormControl(this.financial ? this.financial?.amount : '', [Validators.required, Validators.min(0.01), Validators.max(1000000000000.00), Validators.pattern(/^\d+(\.\d{0,2})?$/)]),
      level: new FormControl(this.financial ? this.getLevel(this.financial) : '', [Validators.required])
    });
  }

  onSubmit() {
    const fromDate = this.startDate ? new Date(this.startDate!.year, this.startDate!.month - 1, this.startDate!.day + 1).toUTCString() : "";
    const toDate = this.endDate ? new Date(this.endDate!.year, this.endDate!.month - 1, this.endDate!.day + 1).toUTCString() : "";

    if (!fromDate || !toDate) {
      this.isFocused = true;
      return;
    }

    this.addEntryForm.markAllAsTouched();

    let addEditEntry = this.addEntryForm.value as AddEntry;
    addEditEntry.fromDate = fromDate;
    addEditEntry.toDate = toDate;

    if (this.financial) {
      if (this.addEntryForm.value.account != revenue) {
        this.revenueType?.clearValidators();
        this.revenueType?.updateValueAndValidity();
        addEditEntry.revenueType = "";
      } else {
        this.revenueType?.addValidators(Validators.required);
        this.revenueType?.updateValueAndValidity();
      }

      if (this.addEntryForm.valid) {
        this.financialService.updateEntry(addEditEntry, this.financial.financialId, this.organizationId).subscribe({
          next: () => {
            this.notificationService.showSuccessToast(this.translate.instant('FinanceData.AddEntry.UpdateEntrySuccess'));
            this.params.context.journalEntryGridComponent.refreshGrid();
            this.activeOffcanvas.close();
          },
          error: error => {
            this.notificationService.showErrorToast(this.translate.instant('ErrorCodes.FINANCIAL_ERROR'));
          }
        });
      }
    }

    if (!this.financial) {
      if (this.addEntryForm.value.account != revenue) {
        this.revenueType?.removeValidators(Validators.required);
        this.revenueType?.updateValueAndValidity();
      } else {
        this.revenueType?.addValidators(Validators.required);
        this.revenueType?.updateValueAndValidity();
      }

      if (this.addEntryForm.valid) {
        this.financialService.addEntry(addEditEntry as AddEntry, this.organizationId).subscribe({
          next: () => {
            this.notificationService.showSuccessToast(this.translate.instant('FinanceData.AddEntry.AddEntrySuccess'));
            this.entryAdded.emit();
            this.activeOffcanvas.close();
          },
          error: error => {
            this.notificationService.showErrorToast(this.translate.instant('ErrorCodes.FINANCIAL_ERROR'));
          }
        });
      }
    }
  }

  getLevel(data?: FlatFinancial) {
    if (data?.propertyUrl) {
      return data.propertyId;
    } else if (data?.brandName) {
      return data.brandId;
    } else if (data?.organizationId) {
      return data.organizationId;
    }
    return null;
  }

  calendar = inject(NgbCalendar);
  formatter = inject(NgbDateParserFormatter);

  hoveredDate: NgbDate | null = null;
  startDate: NgbDate | null = this.calendar.getToday();
  endDate: NgbDate | null = null;

  onDateSelection(date: NgbDate) {
    if (!this.startDate && !this.endDate) {
      this.startDate = date;
    } else if (this.startDate && !this.endDate && date && date.after(this.startDate)) {
      this.endDate = date;
    } else {
      this.endDate = null;
      this.startDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.startDate && !this.endDate && this.hoveredDate && date.after(this.startDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.endDate && date.after(this.startDate) && date.before(this.endDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.startDate) ||
      (this.endDate && date.equals(this.endDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  getPropertyIcon(propertySubTypeId: string) {
    switch (propertySubTypeId) {
      case propertySubTypes.Facebook:
        return "../../../../assets/icons/fb-icn.svg";
      case propertySubTypes.Instagram:
        return "../../../../assets/icons/insta-icn.svg";
      case propertySubTypes.Youtube:
        return "../../../../assets/icons/yt-icn.svg";
      default:
        return "../../../../assets/icons/website-icn.svg";
    }
  }
}
