import { Component } from '@angular/core';
import { UserStatus } from 'src/app/shared/app-constants/constants';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { FlatUser } from '../../../shared/models/FlatUser';
import { OrganizationService } from '../../../services/organization.service';

@Component({
  selector: 'app-user-actions-renderer',
  templateUrl: './user-actions-renderer.component.html',
  styleUrls: ['./user-actions-renderer.component.css']
})
export class UserActionsRendererComponent implements ICellRendererAngularComp {
  user!: FlatUser;
  userStatus = UserStatus;
  params!: ICellRendererParams<any, any, any>;
  userId!: string;

  constructor(private offcanvasService: NgbOffcanvas,
    private modalService: NgbModal,
    private organizationService: OrganizationService,
    private notificationService: NotificationService,
    private translate: TranslateService) {}


  agInit(params: ICellRendererParams<any, any, any>): void {
    this.user = params.data;
    this.params = params;
    this.userId = params.context.userListComponent.userId;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return true;
  }

  approveUser() {
    this.organizationService.approveUser(this.params.context.userListComponent.organizationId as string, this.user.userId)
      .subscribe(() => {
        this.notificationService.showSuccessToast(this.translate.instant('UserManagement.UserActions.UserUpdated'));
        this.params.context.userListComponent.refreshGrid();
        this.modalService.dismissAll();
      });
  }

  declineUser() {
    this.organizationService.declineUser(this.params.context.userListComponent.organizationId as string, this.user.userId)
      .subscribe(() => {
        this.notificationService.showSuccessToast(this.translate.instant('UserManagement.UserActions.UserDeleted'));
        this.params.context.userListComponent.refreshGrid();
        this.modalService.dismissAll();
      });
  }

  EditUser() {
    const offcanvasRef = this.offcanvasService.open(EditUserComponent, { ariaLabelledBy: 'offcanvas-basic-title', position: 'end', backdrop: 'static' });
    offcanvasRef.componentInstance.params = this.params;
  }

  openApproveModal() {
    const modalRef = this.modalService.open(ConfirmModalComponent, { centered: true });
    modalRef.componentInstance.title = 'UserManagement.UserActions.ApproveUser';
    modalRef.componentInstance.bodyConfirm = 'ConfirmModal.ConfirmBody';
    modalRef.componentInstance.bodyMessage = 'UserManagement.UserActions.ApproveModalBodyMessage';
    modalRef.componentInstance.noText = 'ConfirmModal.Close';
    modalRef.componentInstance.yesText = 'ConfirmModal.Yes';
    modalRef.componentInstance.yesAction.subscribe(() => {
      this.approveUser();
    });

  }

  openDeclineModal() {
    const modalRef = this.modalService.open(ConfirmModalComponent, { centered: true });
    modalRef.componentInstance.title = 'UserManagement.UserActions.DeclineUser';
    modalRef.componentInstance.bodyConfirm = 'ConfirmModal.ConfirmBody';
    modalRef.componentInstance.bodyMessage = 'UserManagement.UserActions.DeclineModalBodyMessage';
    modalRef.componentInstance.noText = 'ConfirmModal.Close';
    modalRef.componentInstance.yesText = 'ConfirmModal.Yes';
    modalRef.componentInstance.yesAction.subscribe(() => {
      this.declineUser();
    });
  }
}
