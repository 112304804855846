<form (submit)="onSubmit()" [formGroup]="editPartnerUserForm">
  <div class="offcanvas-header border-bottom">
    <h4 class="offcanvas-title" id="offcanvas-basic-title" translate>MvaSystemAdmin.EditPartnerUser.EditPartnerUser</h4>
    <div type="button" class="btn-off" data-coreui-dismiss="offcanvas" aria-label="Close" (click)="activeOffcanvas.close()"></div>
  </div>
  <div class="offcanvas-body">
    <div class="offcanvas-body p-4">
      <div class="mb-3 row">
        <label for="PartnerOrganization" class="col-sm-3 col-form-label fw-semibold" translate>MvaSystemAdmin.EditPartnerUser.PartnerOrganization</label>
        <div class="col-sm-9">
          <input type="text" readonly class="form-control-plaintext" id="PartnerOrganization" formControlName="partnerName">
        </div>
      </div>
      <div class="mb-3 row">
        <label for="firstName" class="col-sm-3 col-form-label fw-semibold" translate>MvaSystemAdmin.EditPartnerUser.FirstName</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="firstName" formControlName="firstName" [ngClass]="lastName?.invalid && (lastName?.dirty || lastName?.touched) ? 'invalid-input' : '' ">
          <span class="text-danger" *ngIf="firstName?.invalid && firstName?.touched && firstName?.value == ''">
            {{ 'UserProfile.FirstNameRequired' | translate}}
          </span>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="lastName" class="col-sm-3 col-form-label fw-semibold" translate>MvaSystemAdmin.EditPartnerUser.LastName</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="lastName" formControlName="lastName" [ngClass]="lastName?.invalid && (lastName?.dirty || lastName?.touched) ? 'invalid-input' : '' ">
          <span class="text-danger" *ngIf="lastName?.invalid && lastName?.touched && lastName?.value == ''">
            {{ 'UserProfile.LastNameRequired' | translate}}
          </span>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="userEmailID" class="col-sm-3 col-form-label fw-semibold" translate>MvaSystemAdmin.EditPartnerUser.PartnerEmail</label>
        <div class="col-sm-9">
          <input type="text" readonly class="form-control-plaintext" id="userEmailID" formControlName="email">
        </div>
      </div>
      <div class="row mb-3">
        <legend class="col-form-label col-sm-3 fw-semibold" translate>UserManagement.EditUser.UserStatus</legend>
        <div class="col-sm-9">
          <div class="form-check pt-2" [attr.aria-label]="'UserManagement.EditUser.UserStatusAriaLabel' | translate"  *ngFor="let status of userStatusConstant">
            <input type="radio" class="form-check-input" formControlName="userStatusId" [id]="status" autocomplete="off" [value]="status">
            <label class="form-check-label" [for]="status">{{ status === userStatus.Active ? 'Active' : 'Inactive' }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer p-3">
      <button type="button" class="btn btn-secondary mr-1" data-coreui-dismiss="offcanvasEdit" (click)="activeOffcanvas.dismiss()" aria-controls="offcanvasRightedit" translate>MvaSystemAdmin.EditPartnerUser.Cancel</button>
      <button type="button" class="btn btn-secondary mr-1" (click)="onDelete()" translate>MvaSystemAdmin.EditPartnerUser.Delete</button>
      <button type="submit" class="btn btn-primary" translate>MvaSystemAdmin.EditPartnerUser.Save</button>
    </div>
  </div>
</form>



