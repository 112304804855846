<div class="body flex-grow-1 px-3">
  <div class="container-lg hpm-1">
    <h4 class="fw-semibold pb-4" translate>
      <img [src]="'/assets/icons/properties-icn.svg'" [alt]="'Brand.BrandTitle' | translate" class="pr-01 page-title-icon" />
      Brand.BrandTitle
    </h4>
    <div class="row">
      <div class="row border-bottom mb-4">
        <div class="col-lg-10 col-sm-10">
          <p translate>Brand.BrandTitleNotes</p>
        </div>
        <div class="col-lg-2 col-sm-2">
          <div class="d-flex justify-content-end pb-4">
            <button class="btn btn-primary" type="button" (click)="addBrand()" translate>Brand.AddNewBrand</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="row mb-4">
            <div *ngFor="let brand of brands" class="col-lg-3 col-md-5 col-sm-12 d-inline-flex mb-4">
              <div class="card w-100 brand-card pointer" [id]="brand.brandId" (click)="editBrand($event)"
                [title]="'Brand.ViewDetails' | translate">
                <img [src]="brand.logoUrl ? brand.logoUrl : '/assets/icons/no-logo.svg'" [id]="brand.brandId"
                  (click)="editBrand($event)" [title]="'Brand.ViewDetails' | translate"
                  class="card-img-top mx-auto pb-1 pt-1 brands-img">
                <div class="card-body border-top" [id]="brand.brandId" (click)="editBrand($event)"
                  [title]="'Brand.ViewDetails' | translate">
                  <h5 class="card-title text-center" [id]="brand.brandId" (click)="editBrand($event)">{{brand.name}}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
