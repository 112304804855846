
export class AppConfig {
  appInsights!: AppInsights;
  facebook?: Facebook;
  buildNumber!: string;
  buildPollInterval!: number;
  sessionIdleSeconds!: number;
  sessionTimeoutSeconds!: number;
  azureb2cClient!: AzureB2CClient;
}

export class Facebook {
  appId?: string;
  facebookLoginConfigID?: string;
}

export class AppInsights {
  instrumentationKey!: string;
}

export class AzureB2CClient {
  signUpSignInAuthority!: string;
  passwordResetAuthority!: string;
  authorityDomain!: string;
  apiScope!: string;
  clientId!: string;
}
