<body>
  <ng-container *ngIf="isLoggedIn">
    <div app-sidebar class="sidebar sidebar-dark sidebar-fixed" [ngClass]="menuService.isMenuOpen?'show':'hide'" id="sidebar">
    </div>
    <app-body-container></app-body-container>
    <div *ngIf="menuService.isMenuOpen" class="sidebar-backdrop fade" [ngClass]="menuService.isMenuOpen?'show':''" (click)="hideMenu()"></div>
  </ng-container>
  <ng-container *ngIf="!isLoggedIn">
    <div id="content-div">
      <router-outlet></router-outlet>
    </div>
  </ng-container>
</body>
<app-toaster></app-toaster>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#ff9841" type="square-loader" [fullScreen]="true"
  template="<img src='assets/icons/mstile.png' />">
  <p class="spinner-p"> Loading... </p>
</ngx-spinner>
