import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subject, mergeMap, takeUntil, combineLatest, catchError, EMPTY } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { PatternValidator, propertySubTypes } from 'src/app/shared/app-constants/constants';
import { AppContext } from 'src/app/shared/context/AppContext';
import { Partner } from 'src/app/shared/models/Partner';
import { PropertySubType } from 'src/app/shared/models/PropertySubType';
import { MasterDataService } from 'src/app/shared/services/master-data.service';
import { PartnerService } from '../../../services/partner.service';
import { AddToOrganization } from '../../../shared/models/AddToOrganization';
import { NotificationService } from '../../../shared/services/notification.service';
import { DomainValidator } from '../../../shared/validators/domain.Validator';

@Component({
  selector: 'app-add-organization',
  templateUrl: './add-organization.component.html',
  styleUrls: ['./add-organization.component.css']
})
export class AddOrganizationComponent implements OnInit, OnDestroy {

  private readonly _destroying$ = new Subject<void>();
  properties: PropertySubType[] = [];
  addOrganizationForm!: FormGroup;
  partners: Partner[] = [];
  propSubTypeValue: string | undefined;

  get organizationName() { return this.addOrganizationForm.get('organizationName'); }
  get property() { return this.addOrganizationForm.get('property'); }
  get propertyUrl() { return this.addOrganizationForm.get('propertyUrl'); }
  get brand() { return this.addOrganizationForm.get('brand'); }
  get partnerId() { return this.addOrganizationForm.get('partnerId'); }
  get reviewerEmail() { return this.addOrganizationForm.get('reviewerEmail'); }

  errorCodesMap = new Map<string, () => (AbstractControl<any, any> | null)>([
    ["REGISTER_PROPERTYURL_INVALID", () => this.propertyUrl],
    ["REGISTER_REVIEWEREMAIL_INVALID", () => this.reviewerEmail],
    ["REGISTER_REVIEWEREMAIL_MATCH", () => this.reviewerEmail],
    ["INVALID_DOMAIN", () => this.propertyUrl],
    ["REVIEWER_EMAIL_INVALID", () => this.reviewerEmail]
  ]);

  constructor(private appContext: AppContext,
    private masterDataService: MasterDataService,
    private userService: UserService,
    private partnerService: PartnerService,
    public activeOffcanvas: NgbActiveOffcanvas,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
      combineLatest([
        this.appContext.user.pipe(
          mergeMap(user => this.masterDataService.getPropertySubTypes(user.languageId))
        ),
        this.partnerService.getPartners()
      ]).pipe(
        takeUntil(this._destroying$)
      ).subscribe(([propertySubType, partner]) => {
        this.properties = propertySubType;
        this.partners = partner;
    });

    this.addOrganizationForm = new FormGroup({
      organizationName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      property: new FormControl('', [Validators.required]),
      propertyUrl: new FormControl('', [Validators.required, DomainValidator(this.propSubTypeValue)]),
      partnerId: new FormControl('', [Validators.required]),
      brand: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      reviewerEmail: new FormControl('', [Validators.email])
    })
  }

  getExampleUrl(id: string) {
    let exampleUrl = '';
    if (id === propertySubTypes.Facebook)
      exampleUrl = 'https://www.facebook.com/nytimes/';
    else if (id == propertySubTypes.Tiktok)
      exampleUrl = 'https://www.nytimes.com/spotlight/tiktok';
    else if (id == propertySubTypes.Website)
      exampleUrl = 'https://www.nytimes.com/';
    else if (id == propertySubTypes.Instagram)
      exampleUrl = 'https://www.instagram.com/nytimes';
    else if (id == propertySubTypes.Youtube)
      exampleUrl = 'https://www.youtube.com/@nytimes';

    return exampleUrl;
  }

  onSubmit() {
    this.addOrganizationForm.markAllAsTouched();
    if (this.addOrganizationForm.valid) {
      this.userService.addUserToOrganization(this.addOrganizationForm.value as AddToOrganization)
        .pipe(
          catchError((error) => {
            if (error?.status === 400 && error.error?.errorCodes) {
              error.error.errorCodes.forEach((errorCode: string) => {
                this.errorCodesMap.get(errorCode)?.call(this)?.setErrors({ [errorCode]: true });
              });
              this.addOrganizationForm.setErrors({ Errors: true, ErrorCodes: error.error.errorCodes.map((c: string) => `ErrorCodes.${c}`) });
              return EMPTY;
            }
            throw error;
          })
        )
        .subscribe(() => {
          this.notificationService.showSuccessToast(this.translate.instant('UserProfile.AddOrganizationSaveSuccess'))
          this.activeOffcanvas.close();
        });
    }
  }
  onSelectChange(event: any) {
    this.propSubTypeValue = this.properties.find(e => e.propertySubtypeId === event.target.value)?.name;
    this.addOrganizationForm.get('propertyUrl')?.setValidators([Validators.required, DomainValidator(this.propSubTypeValue)]);
    this.addOrganizationForm.get('propertyUrl')?.updateValueAndValidity();
  }
  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
