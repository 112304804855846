import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CreateSolutionListing } from 'src/app/shared/models/CreateSolutionListing';
import { SolutionListingService } from '../../../services/solution.listings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { AppContext } from 'src/app/shared/context/AppContext';
import { combineLatest, of, Subject } from 'rxjs';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NgForm } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { MasterDataService } from 'src/app/shared/services/master-data.service';
import { UserOrganization } from 'src/app/shared/models/UserOrganization';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ApplyType } from 'src/app/shared/models/ApplyType';
import { TermType } from 'src/app/shared/models/TermType';
import { Tag } from 'src/app/shared/models/Tag';
import { PublishType } from 'src/app/shared/models/PublishType';
import { Country } from 'src/app/shared/models/Country';
import { Base64File } from 'src/app/shared/models/Base64File';
import { RoutePath } from '../../../shared/app-constants/constants';
import { GlobalLanguage } from 'src/app/shared/models/GlobalLanguage';

@Component({
  selector: 'app-add-solution',
  templateUrl: './add-edit-solution.component.html',
  styleUrls: ['./add-edit-solution.component.css'],
})
export class AddEditSolutionListingComponent implements OnInit, OnDestroy {
  @ViewChild('solutionForm') ngForm: NgForm | null | undefined;
  @ViewChild('deleteSolutionModal') deleteSolutionModal: ElementRef | undefined;
  private readonly _destroying$ = new Subject<void>();
  solutionListingId!: string;
  solutionListing!: CreateSolutionListing;

  validFrom: NgbDate | null = null;
  validTo: NgbDate | null = null;

  organizationName?: string;
  organizationId!: string;
  primaryImageErrors: string[] = [];
  allowedImageFileTypes: string[] = ['image/jpeg', 'image/png'];
  selectedPropertyId!: string;
  isEditMode: boolean = false;
  vaidationErrors: string[] = [];
  applyTypes: ApplyType[] = [];
  termTypes: TermType[] = [];
  tags: Tag[] = [];
  publishTypes: PublishType[] = [];
  countries: Country[] = [];
  languages: GlobalLanguage[] = [];
  applyToSettings: IDropdownSettings = {};
  languagesSettings: IDropdownSettings = {};
  locationsSettings: IDropdownSettings = {};
  tagsSettings: IDropdownSettings = {};
  solutionImageErrors: string[] = [];
  urlListValid: boolean = true;
  isDateRangeValid: boolean = true;
  readonly SOLUTIONS_PATH = `/${RoutePath.Manage}/${RoutePath.Solutions}`;

  constructor(
    private masterDataService: MasterDataService,
    private solutionListingService: SolutionListingService,
    private appContext: AppContext,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService
  ) {}

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  ngOnInit(): void {
    this.applyToSettings = {
      enableCheckAll: false,
      idField: 'applyTypeId',
      textField: 'name',
    } as IDropdownSettings;

    this.languagesSettings = {
      enableCheckAll: false,
      idField: 'languageId',
      textField: 'englishName',
    } as IDropdownSettings;

    this.locationsSettings = {
      enableCheckAll: false,
      idField: 'countryId',
      textField: 'name',
    } as IDropdownSettings;

    this.tagsSettings = {
      enableCheckAll: false,
      idField: 'tagId',
      textField: 'name',
    } as IDropdownSettings;

    this.solutionListing = new CreateSolutionListing();
    this.route.queryParamMap.subscribe((params) => {
      if (params.get('id') != null) {
        this.solutionListingId = params.get('id') as string;
        this.isEditMode = true;
      }
    });

    combineLatest([
      this.appContext.user.pipe(
        mergeMap((user) =>
          this.masterDataService.getSolutionMasterData(user.languageId)
        )
      ),
      this.appContext.organization.pipe(
        filter(
          (organization): organization is UserOrganization => !!organization
        ),
        tap((organization) => {
          this.organizationName = organization.organizationName;
          this.organizationId = organization.organizationId;
        }),
        mergeMap(() => {
          return this.solutionListingId
            ? this.solutionListingService.getEditSolutionListing(
                this.solutionListingId
              )
            : of(null);
        })
      ),
    ])
      .pipe(takeUntil(this._destroying$))
      .subscribe(([solutionMasterData, solutionListing]) => {
        this.solutionListing =
          (solutionListing?.result as CreateSolutionListing | undefined) ??
          new CreateSolutionListing();

        this.validFrom = this.stringToNgbDate(this.solutionListing.validFrom);
        this.validTo = this.stringToNgbDate(this.solutionListing.validTo);

        const masterdata = solutionMasterData;
        this.applyTypes = masterdata.applyTypes;
        this.termTypes = masterdata.termTypes;
        this.tags = masterdata.tags;
        this.publishTypes = masterdata.publishTypes;
        this.countries = masterdata.countries;
        this.languages = masterdata.languages;

        if (!this.isEditMode) {
          // Defaults
          if (this.publishTypes && this.publishTypes.length > 0)
            this.solutionListing.publishTypeId =
              this.publishTypes[0].publishTypeId;
        }
      });
  }

  onPrimaryImageSelect(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.primaryImageErrors = [];
      var filename = event.target.files[0].name;
      var extension = filename.split('.').pop();
      if (!this.allowedImageFileTypes.includes(event.target.files[0].type)) {
        this.primaryImageErrors.push(
          this.translate.instant('Common.SupportedImageFile')
        );
      }
      if (extension === 'jfif') {
        this.primaryImageErrors.push(
            this.translate.instant('Common.SupportedImageFile')
        );
    }
      if (event.target.files[0].size > 2000000) {
        this.primaryImageErrors.push(
          this.translate.instant('Common.SupportedImageFileSize')
        );
      }

      if (this.primaryImageErrors.length > 0) {
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        if (reader?.result !== null) {
          this.solutionListing.primaryImageUrl = reader?.result?.toString();
          this.solutionListing.primaryImageAsBase64 = new Base64File(
            reader?.result?.toString() ?? '',
            extension
          );
        }
      };
    }
  }
  isFormValid(): boolean {
    if(this.ngForm?.valid && this.isDateRangeValid && this.urlListValid){
      return true;
    };
    return false;
  }
  onSave() {
    if (this.ngForm && this.ngForm.controls) {
      this.markAllControlsAsTouched();
    }

    if (this.isFormValid()) {
      if (!this.solutionListingId) {
        this.solutionListingService
          .addSolutionListing(this.solutionListing)
          .subscribe({
            next: (response) => {
              if ((response as ApiResponse).isSuccess) {
                this.notificationService.showSuccessToast(
                  this.translate.instant('Common.Created')
                );
                this.router.navigateByUrl(this.SOLUTIONS_PATH);
              }
            },
            error: (error) => {
              if (!error.error.isSuccess) {
                if (error.status == '400') {
                  error.error.errorCodes.forEach((errorCode: any) => {
                    this.vaidationErrors.push(
                      this.translate.instant('ErrorCodes.' + errorCode)
                    );
                  });
                } else if (error.status == '404') {
                  let errorMessage: string = '';
                  errorMessage = this.translate.instant(
                    'ErrorCodes.' + error.error.errorCode
                  );
                  this.notificationService.showErrorToast(errorMessage);
                }
              }
            },
          });
      } else {
        this.solutionListingService
          .saveSolutionListing(this.solutionListingId, this.solutionListing)
          .subscribe({
            next: (response) => {
              if (response.isSuccess) {
                let solutionName = (
                  (response as ApiResponse).result as CreateSolutionListing
                ).name;
                this.notificationService.showSuccessToast(
                  this.translate.instant('Common.Saved')
                );
                this.router.navigateByUrl(this.SOLUTIONS_PATH);
              }
            },
            error: (error) => {
              if (!error.error.isSuccess) {
                if (error.status == '400') {
                  error.error.errorCodes.forEach((errorCode: any) => {
                    this.vaidationErrors.push(
                      this.translate.instant('ErrorCodes.' + errorCode)
                    );
                  });
                } else if (error.status == '404') {
                  let errorMessage: string = '';
                  errorMessage = this.translate.instant(
                    'ErrorCodes.' + error.error.errorCode
                  );
                  this.notificationService.showErrorToast(errorMessage);
                }
              }
            },
          });
      }
    }
  }

  private markAllControlsAsTouched(): void {
    const formControls = this.ngForm?.controls ?? {};
    Object.values(formControls).forEach((control) => {
      control.markAsTouched();
    });
  }

  onDelete() {
    this.solutionListingService
      .deleteSolutionListing(this.solutionListing?.solutionListingId!)
      .subscribe({
        next: (response) => {
          if (response.isSuccess) {
            this.notificationService.showSuccessToast(
              this.translate.instant('Common.Deleted')
            );
            this.router.navigateByUrl(this.SOLUTIONS_PATH);
          }
        },
        error: (error) => {
          if (!error.error.isSuccess) {
            if (error.status == '404') {
              let errorMessage: string = '';
              errorMessage = this.translate.instant(
                'ErrorCodes.' + error.error.errorCodes[0]
              );
              this.notificationService.showErrorToast(errorMessage);
            }
          }
        },
      });

    this.closeDialog();
  }

  showDeleteDialog() {
    this.deleteSolutionModal!.nativeElement.style.display = 'block';
  }

  closeDialog() {
    this.deleteSolutionModal!.nativeElement.style.display = 'none';
  }
  onDateRangeChange(newRange: { from: NgbDate | null; to: NgbDate | null; valid: boolean  }) {
    this.validFrom = newRange.from;
    this.validTo = newRange.to;
    this.solutionListing.validFrom = this.ngbDateToString(this.validFrom);
    this.solutionListing.validTo = this.ngbDateToString(this.validTo);
    this.isDateRangeValid = newRange.valid
  }
  onLinksChange(newValues: string[]) {
    this.solutionListing.links = newValues;
  }
  onUrlValidationStatus(isValid: boolean): void {
    this.urlListValid = isValid; 
  }
  ngbDateToString(ngbDate: NgbDate | null): string | undefined {
    if (!ngbDate) {
      return undefined;
    }

    const year = ngbDate.year;
    const month = ngbDate.month.toString().padStart(2, '0'); // Adds leading zero if needed
    const day = ngbDate.day.toString().padStart(2, '0'); // Adds leading zero if needed

    return `${year}-${month}-${day}`; // Formats as "YYYY-MM-DD"
  }

  stringToNgbDate(dateString: string | null | undefined): NgbDate | null {
    if (!dateString) {
      return null;
    }
    const date = new Date(dateString);
    console.log('ds');
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }
  onImagesSelect(event: any) {
    if (event.target.files) {
      this.solutionImageErrors = [];
      const selectedFiles = Array.from(event.target.files) as File[];
  
      // Validate file count (selected + existing)
      if ((this.solutionListing.images?.length ?? 0) + selectedFiles.length > 3) {
        this.solutionImageErrors.push(
          this.translate.instant('Common.MaximumThreeImages')
        );
        return;
      }
  
      const validFiles: File[] = [];
      selectedFiles.forEach((file) => {
        const filename = file.name.toLowerCase();
        const extension = filename.split('.').pop();
        // Validate file type
        if (!this.allowedImageFileTypes.includes(file.type)) {
          this.solutionImageErrors.push(
            this.translate.instant('Common.SupportedImageFile', {
              fileName: file.name,
            })
          );
          return;
        }
        if (extension === 'jfif') {
          this.solutionImageErrors.push(
              this.translate.instant('Common.SupportedImageFile', {
                fileName: file.name,
              })
          );
          return;
      }
        // Validate file size
        if (file.size > 2000000) {
          this.solutionImageErrors.push(
            this.translate.instant('Common.SupportedImageFileSize', {
              fileName: file.name,
            })
          );
          return;
        }
        // If file passes validation, add to validFiles
        validFiles.push(file);
      });
  
      // If there are validation errors, stop processing
      if (this.solutionImageErrors.length > 0) {
        return;
      }
  
      // Initialize arrays if not already initialized
      if (!this.solutionListing.images) {
        this.solutionListing.images = [];
      }
      if (!this.solutionListing.imagesAsBase64) {
        this.solutionListing.imagesAsBase64 = [];
      }
  
      validFiles.forEach((file) => {
        const reader = new FileReader();
        const extension = file.name.split('.').pop() || 'unknown';
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (reader.result) {
            this.solutionListing.images!.push(reader.result.toString());
            this.solutionListing.imagesAsBase64!.push(
              new Base64File(reader.result.toString(), extension)
            );
          }
        };
      });
    }
  }
  removeImage(index: number): void {
    console.log(this.solutionListing.imagesAsBase64)
    console.log(index)
    if (this.solutionListing.images && index >= 0 && index < this.solutionListing.images.length) {
      this.solutionListing.images.splice(index, 1); 
    }
  
    if (this.solutionListing.imagesAsBase64) {
      if (index >= 0 && index < this.solutionListing.imagesAsBase64.length) {
        this.solutionListing.imagesAsBase64.splice(index, 1);
      } else if (this.solutionListing.imagesAsBase64.length > 0) {
        this.solutionListing.imagesAsBase64.pop();
      }
    }
  }
  get normalizedLinks(): string[] {
    if (!this.solutionListing.links || this.solutionListing.links.length === 0) {
      this.solutionListing.links = [''];
    }
    return this.solutionListing.links;
  }
  
}
