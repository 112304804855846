import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  distinctUntilChanged,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { FindSolutionsFilterService } from 'src/app/services/find-solutions-filter.service';
import { Panel } from 'src/app/shared/components/checkbox-filter-drawer/checkbox-filter-drawer.component';
import { AppContext } from 'src/app/shared/context/AppContext';
import { SolutionMasterData } from 'src/app/shared/models/SolutionMasterData';
import { MasterDataService } from 'src/app/shared/services/master-data.service';

@Component({
  selector: 'app-find-solutions',
  templateUrl: './find-solutions.component.html',
  styleUrls: ['./find-solutions.component.css'],
})
export class FindSolutionsComponent implements OnInit, OnDestroy {
  form: FormGroup;
  filterPanels: Panel[] = [];
  sortOptions = [{ value: 'MOST_RECENT', label: 'Most Recent' }];

  totalFilterCount = 0;
  totalItemCount: number | null = null;

  destroying$ = new Subject<void>();

  solutionMasterData?: SolutionMasterData;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private masterDataService: MasterDataService,
    private appContext: AppContext,
    private filterService: FindSolutionsFilterService
  ) {
    this.form = this.fb.group({
      sort: ['MOST_RECENT'],
      fav: ['0'],
      tags: [[]],
      languages: [[]],
      locations: [[]],
      terms: [[]],
    });
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroying$))
      .subscribe((value) => {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: value,
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });

        this.filterService.filter = value;
      });

    this.activatedRoute.queryParams
      .pipe(
        switchMap((params) => {
          this.form.patchValue({
            sort: params.sort || 'MOST_RECENT',
            fav: params.fav || '0',
            tags: this.toStringArray(params.tags),
            languages: this.toStringArray(params.languages),
            locations: this.toStringArray(params.locations),
            terms: this.toStringArray(params.terms),
          });

          return this.appContext.user;
        }),
        switchMap(({ languageId }) =>
          this.masterDataService.getSolutionMasterData(languageId)
        ),
        take(1)
      )
      .subscribe((data) => {
        this.solutionMasterData = data;
        this.filterPanels = this.toFilterPanels(data);
      });

    // Get total item count w/o pagination
    this.filterService.totalItems$
      .pipe(takeUntil(this.destroying$))
      .subscribe((totalItems) => {
        this.totalItemCount = totalItems;
      });
  }

  ngOnDestroy(): void {
    this.destroying$.next();
    this.destroying$.complete();
  }

  onFilterDrawerUpdate(event: Record<string, boolean[]>): void {
    if (!this.solutionMasterData) return;

    const { tags, languages, countries, termTypes } = this.solutionMasterData;

    const selectedTags = tags.filter((_, i) => event.tags[i]);
    const selectedLanguages = languages.filter((_, i) => event.languages[i]);
    const selectedLocations = countries.filter((_, i) => event.locations[i]);
    const selectedTerms = termTypes.filter((_, i) => event.terms[i]);

    this.form.patchValue({
      tags: selectedTags.map((t) => t.tagId),
      languages: selectedLanguages.map((l) => l.languageId),
      locations: selectedLocations.map((c) => c.countryId),
      terms: selectedTerms.map((t) => t.termTypeId),
    });
  }

  private toFilterPanels(data: SolutionMasterData): Panel[] {
    const { tags, languages, termTypes, countries } = data;
    const chosen = this.form.value;
    return [
      {
        title: 'Tags',
        options: tags.map((t) => [t.name, chosen.tags.includes(t.tagId)]),
        formArrayName: 'tags',
      },
      {
        title: 'Languages',
        options: languages.map((l) => [
          l.englishName,
          chosen.languages.includes(l.languageId),
        ]),
        formArrayName: 'languages',
      },
      {
        title: 'Locations',
        options: countries.map((c) => [
          c.name,
          chosen.locations.includes(c.countryId),
        ]),
        formArrayName: 'locations',
      },
      {
        title: 'Terms',
        options: termTypes.map((t) => [
          t.name,
          chosen.terms.includes(t.termTypeId),
        ]),
        formArrayName: 'terms',
      },
    ];
  }

  private toStringArray(value: string | string[] | null | undefined): string[] {
    if (!value) return [];
    return Array.isArray(value) ? value : [value];
  }
}
