<div class="offcanvas offcanvas-end show">
  <div class="offcanvas-header border-bottom">
    <h5 class="offcanvas-title fw-bold" id="selectSegment-title" translate>Segment.Select.Title</h5>
    <div type="button" class="btn-off" data-coreui-dismiss="offcanvas" aria-label="Close"
      (click)="activeOffcanvas.close()"></div>
  </div>
  <div class="offcanvas-body p-4">
    <div class="mb-4">
      <span translate>
        <img class="btn-info" src="../../../../assets/icons/info.svg">
        Segment.Select.TitleDescription
      </span>
    </div>
    <form>
      <fieldset class="row mb-3">

        <legend class="col-form-label col-sm-3 pt-0 fw-semibold" translate>Segment.Select.MySegment</legend>
        <div class="col-sm-9">
          <div class="form-check pb-2" *ngFor="let segment of segments; trackBy: trackBySegment;">
            <input class="form-check-input" type="radio" name="segment" [id]="segment.segmentId"
              [value]="segment.segmentId" [(ngModel)]="selectedSegmentId">
            <label class="form-check-label" [for]="segment.segmentId">
              {{segment.name}}
            </label>
            <a class="btn-icon pl-1" role="button" (click)="openSegment(segment)"><img src="assets/icons/link-open.svg"
                alt="Open segment" /></a>
          </div>
        </div>
      </fieldset>
    </form>

  </div>
  <!--
  <div class="modal-footer p-3">
    <div class="row text-danger" *ngIf="showError">
      <span translate>Segment.Select.ApplyError</span>
      <ul>
        <li translate>Segment.Select.SelectAtleast</li>
      </ul>
    </div>
    <div class="d-inline-flex">
      <button type="button" class="btn btn-primary mr-1" (click)="addSegment()"
        translate>Segment.Select.AddSegmentButton</button>
      <button type="button" class="btn btn-primary" (click)="applySegment()"
        translate>Segment.Select.ApplyButton</button>
    </div>
  </div>
  -->
</div>
