import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RoutePath } from 'src/app/shared/app-constants/constants';
import { CreateBadge } from 'src/app/shared/models/CreateBadge';

@Component({
  selector: 'app-manage-badge-list-table',
  templateUrl: './manage-badge-list-table.component.html',
  styleUrls: ['./manage-badge-list-table.component.css']
})
export class ManageBadgeListTableComponent {
  readonly BADGE_PATH = `/${RoutePath.Manage}/${RoutePath.Badges}`;
  constructor(private router: Router) { }
  @Input() badges?: CreateBadge[] = [];
  @Output() onBadgeDelete = new EventEmitter<string>();
  editBadge(badgeId: string) {
    this.router.navigate([`${this.BADGE_PATH}/edit/${badgeId}`]);
  }
  deleteBadge(badgeId: string) {
    this.onBadgeDelete.emit(badgeId)
  }
  goToDetails(badgeId: string) {
    this.router.navigate([`${this.BADGE_PATH}/detail/${badgeId}`]);
  }
}
