<ngb-toast *ngFor="let toast of notificationService.toasts" [autohide]="true" [delay]="toast.timeToLiveInMilliseconds"
  (hide)="notificationService.removeNotification(toast)"
  [ngClass]="{'supx-error-toast': toast.toasterType === 'error', 'supx-success-toast' : toast.toasterType === 'success' }">
  <div *ngIf="toast.toasterType === 'error'" class="alert-danger">
    <img src="../../../../assets/icons/error-icon.svg" alt="sucessfully updated" class="pr-1">
    {{ toast.body }}
  </div>
  <div *ngIf="toast.toasterType === 'success'" class="alert-success">
    <img src="../../../../assets/icons/sucess-icon.svg" alt="sucessfully updated" class="pr-1">
    {{ toast.body }}
  </div>
</ngb-toast>
