import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { GridApi, ColDef, GridReadyEvent, IRowNode, DomLayoutType } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { Subject, mergeMap, takeUntil } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { AppContext } from 'src/app/shared/context/AppContext';
import { FlatPartner } from 'src/app/shared/models/FlatPartner';
import { Organization } from 'src/app/shared/models/Organization';
import { PartnerNameRendererComponent } from '../partner-name-renderer/partner-name-renderer.component';
import { AddPartnerComponent } from '../add-partner/add-partner.component';
import { PrimarypartnerNameRendererComponent } from '../primarypartner-name-renderer/primarypartner-name-renderer.component';
import { PartnerActionsRendererComponent } from '../partner-actions-renderer/partner-actions-renderer.component';

var selectedOrganization = 'all';
@Component({
  selector: 'app-partners-grid',
  templateUrl: './partners-grid.component.html',
  styleUrls: ['./partners-grid.component.css']
})
export class PartnersGridComponent implements OnInit, OnDestroy {

  private readonly _destroying$ = new Subject<void>();
  private gridApi!: GridApi<any>;
  partnerList: FlatPartner[] = [];
  paginationPageSize = 50;
  organizationList!: any[];
  partnersFilter!: any[];
  userId: string = '';

  columnDefs: ColDef[] = [
    { headerName: this.translate.instant('MvaSystemAdmin.PartnersGrid.Partner'), field: 'partnerName', width: 250, filter: true },
    { headerName: this.translate.instant('MvaSystemAdmin.PartnersGrid.EmailDomain'), field: 'emailDomain', width: 250 },
    { headerName: this.translate.instant('MvaSystemAdmin.PartnersGrid.PrimaryContact'), cellRenderer: PrimarypartnerNameRendererComponent, cellRendererParams: {context: { partnersGridComponent: this }}, width: 220, },
    { headerName: this.translate.instant('MvaSystemAdmin.PartnersGrid.PrimaryContactEmail'), field: 'primaryUser.email', width: 250 },
    { headerName: this.translate.instant('MvaSystemAdmin.PartnersGrid.OtherUsers'), cellRenderer: PartnerNameRendererComponent, width: 220, cellRendererParams: {context: { partnersGridComponent: this }}, autoHeight: true, wrapText: true },
    { headerName: this.translate.instant('MvaSystemAdmin.PartnersGrid.Actions'), cellRenderer: PartnerActionsRendererComponent, cellRendererParams: {context: { partnersGridComponent: this }}, width: 150 },
  ];

  defaultColDef = {
    sortable: true,
    resizable:true,
  };

  constructor(private appContext: AppContext,
    private userService: UserService,
    private offcanvasService: NgbOffcanvas,
    private translate: TranslateService) {}

  ngOnInit(): void {
    this.appContext.user.pipe(
    mergeMap((user) => {
      this.userId = user.userId;
      return this.userService.getPartnersForManage(user.userId)
    }),
    takeUntil(this._destroying$)
    ).subscribe((partnerList) => {
      selectedOrganization = 'all';
      this.partnerList = partnerList;

      this.partnersFilter = partnerList.map(partner => ({
        id: partner.partnerId,
        name: partner.partnerName
      }));
    });
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }

  refreshGrid() {
    this.userService.getPartnersForManage(this.userId).subscribe((partnerList) => {
      this.partnerList = partnerList;
    });
    this.gridApi.refreshCells();
  }

  isExternalFilterPresent(): boolean {
    return selectedOrganization !== 'all';
  }

  doesExternalFilterPass(node: IRowNode<any>): boolean {
    if (node.data) {
      if (selectedOrganization === 'all') {
        return true;
      } else {
        return node.data.partnerId === selectedOrganization;
      }
    }
    return true;
  }

  externalFilterChanged(event: Event) {
    selectedOrganization = ((event.target) as HTMLSelectElement).value;
    this.gridApi.onFilterChanged();
  }

  getOrganizationNames(organization: Organization[]) {
    const organizationNames = organization.map(organization => `<div>${organization.name}</div>`).join('');
    return organizationNames;
  }

  addPartnerOrganization() {
    const offcanvas = this.offcanvasService.open(AddPartnerComponent, { ariaLabelledBy: 'offcanvas-basic-title', position: 'end', backdrop: 'static' });
    offcanvas.componentInstance.userId = this.userId;
    offcanvas.componentInstance.partnerAdded.subscribe(() => {
      this.refreshGrid();
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
