import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AppContext } from '../context/AppContext';
import { combineLatest, filter, map } from 'rxjs';
import { RoutePath, UserStatus } from '../app-constants/constants';
import { MenuCategory, MenuItem, MenuService } from '../services/menu.service';

export const RouteGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const appContext = inject(AppContext);
  const router = inject(Router);
  const menuService = inject(MenuService);

  return combineLatest([appContext.organization, appContext.user]).pipe(
    map(([org, user]) => {
      const findMenuItem = (
        items: (MenuCategory | MenuItem)[]
      ): MenuItem | undefined => {
        for (const item of items) {
          if (item instanceof MenuItem) {
            if (
              item.path.toLowerCase().startsWith(state.url.toLowerCase()) ||
              state.url.toLowerCase().startsWith(item.path.toLowerCase())
            ) {
              return item;
            }
          } else if (item instanceof MenuCategory) {
            const foundItem = findMenuItem(item.menuItems);
            if (foundItem) return foundItem;
          }
        }
        return undefined;
      };

      // Get menuitem for user If menu item is available for user then redirect to route or redirect to home
      const menuCategories = menuService.getUserProfileMenu(user, org);
      const menuItems = menuCategories.flatMap((mc) => mc.menuItems);
      menuItems.push(...menuService.getSideBarMenuItems(org));

      const menuItem = findMenuItem(menuItems);

      if (menuItem == null) {
        router.navigateByUrl(RoutePath.Empty);
        return false;
      } else {
        return true;
      }
    })
  );
};
