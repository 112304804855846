<div class="modal-header">
  <h4 class="modal-title fw-bold">Missing permissions</h4>
  <div type="button" class="btn-off" data-coreui-dismiss="offcanvas" aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"></div>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div *ngFor="let prop of missingPermissionsProperties" class="card border-light mb-3">
        <div class="card-header">{{prop.brandName}} | {{prop.propertyName}} | {{prop.propertyUrl}}
        </div>
        <div class="card-body">
          <p class="card-text ">Page/Account : {{ prop.solutions[0].platformIdDisplayName}}</p>
          <div class="d-flex g-3 col-sm-12">
            <div class="pr-01 invalid-input-message">
              <img src="/assets/icons/fb.svg" *ngIf="prop.propertySubTypeId === facebookSubTypeId"
                alt="Facebook Analytics Logo" class="mx-auto h-25  pr-2">
              <img src="/assets/icons/insta.svg" *ngIf="prop.propertySubTypeId === instaSubTypeId"
                alt="Facebook Analytics Logo" class="mx-auto h-25  pr-2">
              <img src="/assets/icons/error-small.svg" class="pb-03" alt="Missing permissions">
              Missing permissions
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
