<form name="createBranForm">
  <div class="offcanvas offcanvas-end show">
    <div class="offcanvas-header border-bottom">
      <h6 class="offcanvas-title" id="offcanvas-basic-title">
        <h6 class="offcanvas-title" id="offcanvas-basic-title">
          <p class="filter-text">
            {{ "FindMedia.Filters" | translate }}
            <span>({{ totalFilteredOrganizations }})</span>
          </p>
        </h6>
      </h6>
      <div
        type="button"
        class="btn-off"
        data-coreui-dismiss="offcanvas"
        aria-label="Close"
        (click)="closeFilterDrawer()"
      ></div>
    </div>

    <div class="offcanvas-body d-flex flex-column custom-checkbox">
      <div class="container my-1">
        <div class="content-wrapper flex-grow-1">
        <div class="container my-1">
          <div class="filter-section">
            <!-- Collapsible Section (Saved Views) -->
            <div id="savedView">
              <h6 class="d-flex align-items-center">
                <button
                  class="btn btn-link p-0 d-flex align-items-center"
                  type="button"
                  (click)="toggleSection('savedView')"
                >
                  <i
                    class="bi"
                    [ngClass]="
                      sectionsState['savedView']
                        ? 'bi-chevron-down'
                        : 'bi-chevron-right'
                    "
                  ></i>
                </button>
                <span class="ms-2 filter-text" translate
                  >FindMedia.SavedViews</span
                >
              </h6>

              <!-- Collapsible Content for Saved Views -->
              <div
                class="collapse show"
                [ngbCollapse]="!sectionsState['savedView']"
              >
                <div
                  *ngIf="SavedView.length == 0"
                  class="filter-options my-1 p-1"
                  style="background-color: #f2f2f2"
                >
                  <div class="mt-1 mb-3">
                    <p class="text-center fw-bold my-2" translate>
                      FindMedia.NoSavedViews
                    </p>
                    <p class="text-center mb-3" translate>
                      FindMedia.NoSavedViewEmptyState
                    </p>
                  </div>
                </div>
                <div
                  class="d-flex align-items-center mb-3"
                  *ngIf="SavedView.length > 0"
                >
                  <!-- Dropdown -->

                  <select
                    class="form-select"
                    id="savedViewsSelect"
                    [(ngModel)]="selectedViewId"
                    name="select"
                    (change)="onSelectSavedView($event)"
                  >
                    <option *ngFor="let view of SavedView" [value]="view.id">
                      {{ view.name }}
                      {{
                        view.isDefault ? ("FindMedia.Default" | translate) : ""
                      }}
                    </option>
                  </select>

                  <!-- Three-dot Dropdown Icon -->
                  <div ngbDropdown>
                    <button
                      dropdown-toggle
                      btn
                      p-0
                      class="btn p-0"
                      ngbDropdownToggle
                      aria-expanded="false"
                    >
                      <i class="bi bi-three-dots"></i>
                    </button>
                    <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
                      <li *ngIf="isNotDefaultView">
                        <a
                          class="dropdown-item side-action"
                          (click)="showConfirmModal('default')"
                          translate
                        >
                          <i class="bi bi-bookmark"></i> FindMedia.MakeMyDefault
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item side-action"
                          (click)="showSaveToViewModal('rename')"
                          translate
                        >
                          <i class="bi bi-pencil"></i> FindMedia.Rename
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item text-danger side-action"
                          (click)="showConfirmModal('delete')"
                          translate
                        >
                          <i class="bi bi-trash"></i> FindMedia.Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <!-- Collapsible Section (Organization Type) -->
            <div id="organizationType">
              <h6 class="d-flex align-items-center">
                <button
                  class="btn btn-link p-0 d-flex align-items-center"
                  type="button"
                  (click)="toggleSection('organizationType')"
                >
                  <i
                    class="bi"
                    [ngClass]="
                      sectionsState['organizationType']
                        ? 'bi-chevron-down'
                        : 'bi-chevron-right'
                    "
                  ></i>
                </button>
                <span class="ms-2 filter-text" translate
                  >FindMedia.OrganizationType
                </span>
                <span class="filter-text">
                  ({{ getOrganizationTypeCount() }})</span
                >
              </h6>

              <!-- Collapsible Content for Organization Type -->
              <div
                class="collapse show"
                [ngbCollapse]="!sectionsState['organizationType']"
              >
                <div class="filter-options mt-2">
                  <label
                    *ngFor="let orgType of organizationTypes; let i = index"
                  >
                    <input
                      type="checkbox"
                      [value]="orgType.organizationTypeId"
                      [checked]="
                        selectedOrganizationTypes.includes(
                          orgType.organizationTypeId
                        )
                      "
                      name="organizationType{{ i }}"
                      (change)="
                        onCheckboxChange($event, selectedOrganizationTypes)
                      "
                    />
                    <span class="check-label">{{ orgType.name }}</span>
                  </label>
                </div>
              </div>
            </div>
            <hr />
            <!-- Collapsible Section (Properties Type) -->
            <div id="propertiesType">
              <h6 class="d-flex align-items-center">
                <button
                  class="btn btn-link p-0 d-flex align-items-center"
                  type="button"
                  (click)="toggleSection('propertiesType')"
                >
                  <i
                    class="bi"
                    [ngClass]="
                      sectionsState['propertiesType']
                        ? 'bi-chevron-down'
                        : 'bi-chevron-right'
                    "
                  ></i>
                </button>
                <span class="ms-2 filter-text" translate
                  >FindMedia.Properties</span
                >
                <span class="filter-text"> ({{ getPropertyTypeCount() }})</span>
              </h6>

              <!-- Collapsible Content for Properties -->
              <div
                class="collapse show"
                [ngbCollapse]="!sectionsState['propertiesType']"
              >
                <div class="filter-options mt-2">
                  <label
                    *ngFor="let property of propertySubTypes; let i = index"
                  >
                    <input
                      type="checkbox"
                      [value]="property.propertySubtypeId"
                      [checked]="
                        selectedPropertySubTypes.includes(
                          property.propertySubtypeId
                        )
                      "
                      name="propertySubType{{ i }}"
                      (change)="
                        onCheckboxChange($event, selectedPropertySubTypes)
                      "
                    />
                    <span class="check-label">{{ property.name }}</span>
                  </label>
                </div>
              </div>
            </div>
            <hr />
            <div id="numberOFEmployee">
              <!-- Collapsible Content for noOfEmployeeType -->
              <h6 class="d-flex align-items-center">
                <button
                  class="btn btn-link p-0 d-flex align-items-center"
                  type="button"
                  (click)="toggleSection('noOfEmployeeType')"
                >
                  <i
                    class="bi"
                    [ngClass]="
                      sectionsState['noOfEmployeeType']
                        ? 'bi-chevron-down'
                        : 'bi-chevron-right'
                    "
                  ></i>
                </button>
                <span class="ms-2 filter-text" translate
                  >FindMedia.NoOfEmployeeTitle</span
                >
                <span class="filter-text"> ({{ getNoOfEmployeeCount() }})</span>
              </h6>
              <div
                class="collapse show"
                [ngbCollapse]="!sectionsState['noOfEmployeeType']"
              >
                <div class="filter-options mt-2">
                  <label>
                    <input
                      type="checkbox"
                      value="100"
                      [checked]="selectedNoOfEmployee.includes('100')"
                      name="noOfEmployee100"
                      (change)="onCheckboxChange($event, selectedNoOfEmployee)"
                    />
                    <span class="check-label"><100</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      value="100-499"
                      name="noOfEmployee499"
                      [checked]="selectedNoOfEmployee.includes('100-499')"
                      (change)="onCheckboxChange($event, selectedNoOfEmployee)"
                    />
                    <span class="check-label">100-499</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      value="500"
                      name="noOfEmployee500"
                      [checked]="selectedNoOfEmployee.includes('500')"
                      (change)="onCheckboxChange($event, selectedNoOfEmployee)"
                    />
                    <span class="check-label">>500</span>
                  </label>
                </div>
              </div>
            </div>
            <hr />
            <div id="orgAge">
              <h6 class="d-flex align-items-center">
                <button
                  class="btn btn-link p-0 d-flex align-items-center"
                  type="button"
                  (click)="toggleSection('orgAgeType')"
                >
                  <i
                    class="bi"
                    [ngClass]="
                      sectionsState['orgAgeType']
                        ? 'bi-chevron-down'
                        : 'bi-chevron-right'
                    "
                  ></i>
                </button>
                <span class="ms-2 filter-text" translate
                  >FindMedia.OrganizationAgeTitle</span
                >
                <span class="filter-text">
                  ({{ getOrganizationAgeCount() }})</span
                >
              </h6>
              <!-- Collapsible Content for orgAgeType -->
              <div
                class="collapse show"
                [ngbCollapse]="!sectionsState['orgAgeType']"
              >
                <div class="filter-options mt-2">
                  <label *ngFor="let age of organizationAges; let i = index">
                    <input
                      type="checkbox"
                      [value]="age.name"
                      [checked]="selectedOrganizationAges.includes(age.name)"
                      name="organizationAge{{ i }}"
                      (change)="
                        onCheckboxChange($event, selectedOrganizationAges)
                      "
                    />
                    <span class="check-label">{{ age.name }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <p class="filter-text" style="font-weight: 400 !important" translate>
          {{ "FindMedia.Total" | translate }}
          <span class="me-1">{{ totalFilteredOrganizations }}</span>
          <span>{{ "FindMedia.TotalMessage" | translate }} ??</span>
        </p>
      </div>
        <div class="button-row border-top pt-2">
          <button
            class="btn btn-filter-custom me-1 me-md-0"
            style="border: 1px"
            (click)="resetFilters()"
            translate
          >
            FindMedia.ResetFilters
          </button>
          <button
            class="btn btn-primary btn-filter-custom"
            (click)="showCreateViewModal()"
            [disabled]="isFilterParamsEmpty"
            translate
          >
            FindMedia.SaveToMyViews
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
