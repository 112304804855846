<div class="offcanvas offcanvas-end show">
  <div class="offcanvas-header border-bottom mb-4">
    <h5 class="offcanvas-title fw-bold" id="addsegment-title" translate>Segment.Save.Title</h5>
    <div type="button" class="btn-off" data-coreui-dismiss="offcanvas" aria-label="Close"
      (click)="activeOffcanvas.close()"></div>
  </div>
  <div class="offcanvas-body p-4" *ngIf="variableCategories">
    <form [formGroup]="formGroup">
      <fieldset class="row mb-4">
        <label for="segmentName" class="col-sm-3 col-form-label fw-semibold" translate>
          Segment.Save.Name<span class="text-danger required-field">*</span>
        </label>
        <div class="col-sm-9">
          <input class="form-control" type="text" id="segmentName" formControlName="segmentName"
            aria-label="segment name" required maxlength="30"
            [ngClass]="segmentName?.invalid && (segmentName?.dirty || segmentName?.touched) ? 'invalid-input' : ''">
          <div *ngIf="segmentName?.invalid && (segmentName?.dirty || segmentName?.touched)">
            <span class="text-danger" *ngIf="segmentName?.errors?.['required']" translate>
              Segment.Save.NameRequired
            </span>
            <span class="text-danger" *ngIf="segmentName?.errors?.['pattern']" translate>
              Segment.Save.NameInvalid
            </span>
            <span class="text-danger" *ngIf="segmentName?.errors?.['SEGMENT_NAME_EXISTS']" translate>
              Segment.Save.SEGMENT_NAME_EXISTS
            </span>
          </div>
        </div>
      </fieldset>
      <fieldset class="row mb-3">
        <label for="variablesSearchInput" class="col-form-label col-sm-3 pt-0 fw-semibold"
          translate>Segment.Save.Variables</label>
        <div class="col-sm-9">

          <div class="input-group flex-nowrap">
            <span class="input-group-text">
              <i class="bi bi-search"></i>
            </span>
            <input id="variablesSearchInput" class="form-control" type="text" formControlName="searchVariable"
              aria-label="segment name">
          </div>
        </div>
      </fieldset>
      <div class="row">
        <div class="col-sm-3"></div>
        <div class="col-sm-9">
          <div class="accordion" id="accordionSegment" formGroupName="variables">
            <div class="accordion-item"
              *ngFor="let category of variableCategories; index as i; trackBy: trackByCategory;">
              <h2 class="accordion-header">
                <button class="accordion-button" type="button" data-coreui-toggle="collapse"
                  [attr.data-coreui-target]="'#category'+i" aria-expanded="true" [attr.aria-controls]="'category'+i">
                  {{category.name}}
                </button>
              </h2>
              <div [id]="'category'+i" class="accordion-collapse collapse show">
                <div class="accordion-body">
                  <div class="form-check pb-2" *ngFor="let variable of category.variables; trackBy: trackByVariable;">
                    <input [formControlName]="variable.variableId" class="form-check-input" type="checkbox"
                      [id]="variable.variableId">
                    <label class="form-check-label" [for]="variable.variableId">
                      {{variable.name}}
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="row mb-2">
          <span translate>
            <img class="btn-info" src="../../../../assets/icons/info.svg">
            Segment.Save.CriteriaHelpText
          </span>
        </div>
        <div class="row mb-2">
          <span translate>
            <img class="btn-info" src="../../../../assets/icons/info.svg">
            Segment.Save.DelayHelpText
          </span>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer p-3" *ngIf="variableCategories">
    <div class="row text-danger" *ngIf="formGroup?.errors?.['Errors']">
      <span translate>Segment.Save.SaveError</span>
      <ul>
        <li *ngFor="let error of formGroup.errors?.['ErrorCodes']" translate>{{error}}</li>
      </ul>
    </div>
    <button type="button" class="btn btn-secondary mr-1" (click)="activeOffcanvas.close()"
      translate>Segment.Save.CancelButton</button>
    <button type="button" class="btn btn-primary mr-1" (click)="saveSegment()" translate>
      Segment.Save.SaveButton
    </button>
    <button type="button" class="btn btn-primary" *ngIf="inputSegment" (click)="deleteSegment()" translate>
      Segment.Save.DeleteButton
    </button>
  </div>
</div>
