import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { protectedResourceEndpoints } from "../shared/auth-config/auth-config";
import { ApiService } from "../shared/http/api.service";
import { EmbedParams } from "../shared/models/EmbedParams";

@Injectable({
  providedIn: 'root'
})
export class PbiService {

  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  private _reportUrl = protectedResourceEndpoints.report.url;

  getCountryReportParams(organizationId: string) {
    return this.apiService.getEntity<EmbedParams>(`${this._reportUrl}${organizationId}/country`, new HttpParams(), true);
  }

  getPropertyWebsiteReportParams(organizationId: string) {
    return this.apiService.getEntity<EmbedParams>(`${this._reportUrl}${organizationId}/property/website`, new HttpParams(), true);
  }

  getPropertySocialMediaReportParams(organizationId: string) {
    return this.apiService.getEntity<EmbedParams>(`${this._reportUrl}${organizationId}/property/socialmedia`, new HttpParams(), true);
  }

  getJournalReport(organizationId: string) {
    return this.apiService.getEntity<EmbedParams>(`${this._reportUrl}${organizationId}/journal`, new HttpParams(), true);
  }
}
