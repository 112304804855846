import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataDashbordService } from 'src/app/services/datadashboard.service';
import { GoogleProperty } from 'src/app/shared/models/GoogleProperty';
import { Solution } from 'src/app/shared/models/Solution';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-ga-select-property',
  templateUrl: './ga-select-property.component.html',
  styleUrls: ['./ga-select-property.component.css']
})
export class SelectGoogleAnalyticsPropertyComponent implements OnInit {

  @Input() solution!: Solution;
  @Input() organizationId!: string;
  @Output() PropertySelected = new EventEmitter<GoogleProperty>();
  googleAccounts: GoogleProperty[] = [];
  googleProperties: GoogleProperty[] = []

  selectedProperty?: GoogleProperty;

  constructor(public activeModal: NgbActiveModal,
    private dataDashboardService: DataDashbordService,
    private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.dataDashboardService.getGoogleAnalyticsAccounts(this.solution, this.organizationId).subscribe({
      next: (successResponse) => {
        if (successResponse.isSuccess) {
          this.googleAccounts = successResponse.result as GoogleProperty[];
        }
      },
      error: (errorResponse) => {
        this.activeModal.close();
        this.notificationService.showErrorToast('Get accounts API call failed ,please try again.');
      }
    });
  }

  onAccountSelect(event: any) {
    if (event.target.value != '0') {
      const accountId = event.target.value;
      this.dataDashboardService.getGoogleAnalyticsProperties(this.solution, accountId, this.organizationId).subscribe({
        next: (successResponse) => {
          if (successResponse.isSuccess) {
            this.googleProperties = successResponse.result as GoogleProperty[];
          }
        },
        error: (errorResponse) => {
          this.activeModal.close();
          this.notificationService.showErrorToast('Get properties API call failed ,please try again.');
        }
      });
    }
  }

  onPropertySelect(event: any) {
    if (event.target.value != '0') {
      if (this.googleProperties.find(x => x.name === 'properties/' + event.target.value))
        this.selectedProperty = this.googleProperties.find(x => x.name === 'properties/' + event.target.value)!;
    }
    else{
      this.selectedProperty = undefined
    }
  }

  selectGoogleAnalyticsProperty() {
    this.PropertySelected.emit(this.selectedProperty);
    this.activeModal.close();
  }
}
