import { Injectable } from '@angular/core';
import { Toast } from '../components/toaster/toast.model';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public toasts: Toast[] = [];

  constructor() { }

  //#region toaster notification methods

  showErrorToast(body: string, header = "", hideOthers = true) {
    const toast = new Toast();
    toast.header = header;
    toast.body = body;
    toast.toasterType = 'error';
    toast.timeToLiveInSeconds = 4;
    if (hideOthers) {
      this.clearAllNotifications();
    }

    this.toasts.push(toast);
  }

  showSuccessToast(body: string, header = "", hideOthers = true) {
    const toast = new Toast();
    toast.header = header;
    toast.body = body;
    toast.toasterType = 'success';
    toast.timeToLiveInSeconds = 4;
    if (hideOthers) {
      this.clearAllNotifications();
    }

    this.toasts.push(toast);
  }

  removeNotification(notification: any) {
    this.toasts = this.toasts.filter(t => t != notification);
  }

  clearAllNotifications() {
    this.toasts = [];
  }

  //#endregion
}


