import { Injectable } from '@angular/core';
import { protectedResourceEndpoints } from '../shared/auth-config/auth-config';
import { Brand } from 'src/app/shared/models/brand.model';
import { ApiService } from '../shared/http/api.service';
import { ApiResponse } from '../shared/models/ApiResponse';
import { FilterParams } from '../shared/models/FindMediaFilterParams';
import { FindMediaBrand } from '../shared/models/FindMediaBrand';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrandManagementService {
  constructor(private apiservice: ApiService, private http: HttpClient) {}

  getBrand(brandId: string, organizationId: string) {
    return this.apiservice.getEntity<ApiResponse>(
      protectedResourceEndpoints.brand.url +
        brandId +
        '?organizationId=' +
        organizationId
    );
  }

  removeBadge(brandId: string, badgeId: string): Observable<boolean> {
    return this.http
      .delete<ApiResponse<boolean>>(
        `${protectedResourceEndpoints.brand.url}${brandId}/badge/${badgeId}`
      )
      .pipe(map((res) => res.result));
  }

  addBrand(brand: Brand) {
    return this.apiservice.postData(
      protectedResourceEndpoints.brand.url,
      brand
    );
  }

  saveBrand(id: string, brand: Brand) {
    return this.apiservice.put<Brand>(
      protectedResourceEndpoints.brand.url + id,
      brand
    );
  }

  deleteBrand(brandId: string, organizationId: string) {
    return this.apiservice.delete(
      protectedResourceEndpoints.brand.url +
        brandId +
        '?organizationId=' +
        organizationId
    );
  }
  getMediaBrand(
    page: number,
    pageSize: number,
    filter: FilterParams = {
      organizationTypes: [],
      propertySubTypes: [],
      organizationAges: [],
      noOfEmployee: [],
      searchText: '',
      isFavorite: false,
    }
  ) {
    const params = this.buildQueryParams(page, pageSize, filter);
    return this.apiservice.getEntity<ApiResponse>(
      `${protectedResourceEndpoints.brand.url}summary`,
      params
    );
  }
  favouriteBrandMedia(brandId: string) {
    return this.apiservice.put<ApiResponse>(
      `${protectedResourceEndpoints.brand.url}${brandId}/toggle-favorite`,
      {}
    );
  }
  private buildQueryParams(
    page: number,
    pageSize: number,
    filter: FilterParams
  ): HttpParams {
    let params = new HttpParams()
      .set('pageNumber', page.toString())
      .set('pageSize', pageSize.toString());

    if (filter.searchText) {
      params = params.append('searchText', filter.searchText);
    }

    if (filter.isFavorite) {
      params = params.append('isFavorite', filter.isFavorite.toString());
    }

    if (filter.organizationTypes && filter.organizationTypes.length > 0) {
      filter.organizationTypes.forEach((type) => {
        if (type) params = params.append('organizationTypes', type);
      });
    }

    if (filter.organizationAges && filter.organizationAges.length > 0) {
      filter.organizationAges.forEach((orgAge) => {
        if (orgAge) params = params.append('organizationAges', orgAge);
      });
    }

    if (filter.noOfEmployee && filter.noOfEmployee.length > 0) {
      filter.noOfEmployee.forEach((employee) => {
        if (employee) params = params.append('noOfEmployee', employee);
      });
    }

    if (filter.propertySubTypes && filter.propertySubTypes.length > 0) {
      filter.propertySubTypes.forEach((property) => {
        if (property) params = params.append('propertySubTypes', property);
      });
    }

    return params;
  }
}
