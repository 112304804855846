import { Injectable } from '@angular/core';
import { protectedResourceEndpoints } from '../shared/auth-config/auth-config';
import { UserMediaView } from '../shared/models/UserMediaView';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../shared/http/api.service';

@Injectable({
  providedIn: 'root'
})
export class UsersavedviewService {

  constructor(private apiservice: ApiService,
               private http: HttpClient) { }

  createFilterView(filter:UserMediaView) {
    return this.apiservice.post(`${protectedResourceEndpoints.userSavedView.url}`,filter);
  }
  getUserSavedViews(){
    return this.http.get<UserMediaView[]>(`${protectedResourceEndpoints.userSavedView.url}`)
  }
  deleteSavedMediaView(viewId : string){
    return this.http.delete(`${protectedResourceEndpoints.userSavedView.url}${viewId}`)
  }
  makeSavedViewAsDefault(viewId:string){
    return this.http.put(`${protectedResourceEndpoints.userSavedView.url}${viewId}/default`,null)
  }
  renameSavedView(viewId:string, view: UserMediaView){
    return this.http.put(`${protectedResourceEndpoints.userSavedView.url}${viewId}/rename`,view)
  }
}
