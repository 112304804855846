import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';

export type Panel = {
  title: string;
  formArrayName: string;
  options: [label: string, selected: boolean][];
};

@Component({
  selector: 'app-checkbox-filter-drawer',
  templateUrl: './checkbox-filter-drawer.component.html',
  styleUrls: ['./checkbox-filter-drawer.component.css'],
})
export class CheckboxFilterDrawerComponent
  implements OnInit, OnDestroy, OnChanges
{
  form: FormGroup;

  destroy$ = new Subject<void>();

  @Input() panels: Panel[] = [];

  @Output() onClose = new EventEmitter<Record<string, boolean[]>>();
  @Output() onCheckCountUpdate = new EventEmitter<number>();

  @ViewChild('filterDrawer') filterDrawer!: NgbOffcanvas;

  constructor(private offCanvasService: NgbOffcanvas, private fb: FormBuilder) {
    this.form = this.fb.group({});
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.panels) {
      this.initializeForm();

      /**
       * This is to trigger the initial count update
       * It is in a timeout to avoice infinite loop with change detection
       */
      setTimeout(() => this.onCheckCountUpdate.emit(this.totalChecked));
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initializeForm() {
    const controlGroups = this.panels.reduce((acc, panel) => {
      const options = panel.options.map(
        ([_, selected]) => new FormControl(selected)
      );
      acc[panel.formArrayName] = this.fb.array(options);
      return acc;
    }, {} as Record<string, FormArray>);

    this.form = this.fb.group(controlGroups);
  }

  getControl(name: string): FormArray | null {
    return this.form.get(name) as FormArray | null;
  }

  getCheckedCount(name: string): number {
    const control = this.getControl(name);
    return control?.controls.filter((control) => control.value).length ?? 0;
  }

  get totalChecked(): number {
    const formArrayNames = Object.keys(this.form.controls);
    return formArrayNames.reduce(
      (acc, name) => acc + this.getCheckedCount(name),
      0
    );
  }

  open() {
    const ref = this.offCanvasService.open(this.filterDrawer, {
      position: 'end',
      ariaLabelledBy: 'offcanvas-basic-title',
      animation: true,
      panelClass: 'custom-offcanvas-width',
    });

    ref.hidden.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.onClose.emit(this.form.value);
      this.onCheckCountUpdate.emit(this.totalChecked);
    });
  }

  close() {
    this.offCanvasService.dismiss();
  }

  reset() {
    this.form.reset();
  }
}
