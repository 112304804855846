<div class="modal-header ms-1 pb-1">
    <p class="apply-text" translate>BadgeDetail.ApplyModalTitle</p>
    <div type="button" class="btn-off" data-coreui-dismiss="offcanvas" aria-label="Close"
        (click)="activeModal.dismiss()"></div>
</div>
<div class="modal-body ms-1 mb-1">
    <p translate class="description-text">
        BadgeDetail.ApplyModalDescription
    </p>

    <form (ngSubmit)="submitForm()">
        <!-- Organization -->
        <div *ngIf="summary.organization">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" id="organization"
                    [disabled]="summary.organization.pendingApplication || summary.organization.approvedApplication"
                    [value]="summary.organization.organizationId" name="organizationId"
                    (change)="onOrgCheckboxChange($event)" />
                <label class="form-check-label">
                    <img src="../../../../assets/icons/orgbadge.svg"> {{summary.organization.name}}
                    <span class="fst-italic">
                        <ng-container *ngIf="summary.organization.pendingApplication"><span
                                translate>Badge.AwaitingApproval</span></ng-container>
                        <ng-container *ngIf="summary.organization.approvedApplication"><span
                                translate>Badge.AlreadyHasBadge</span></ng-container>
                    </span>
                </label>
            </div>
        </div>

        <!-- Brand -->
        <div class="checkbox-top-spacing ms-3">
            <div *ngIf="summary.brands.length > 0">
                <div class="form-check" *ngFor="let brand of summary.brands; let i = index">
                    <input type="checkbox" class="form-check-input" id="brandCheckbox" [value]="brand.brandId"
                        [disabled]="brand.pendingApplication || brand.approvedApplication" name="brand{{ i }}" (change)="
                        onCheckboxChange($event, brandIds)
                      " />
                    <label class="form-check-label" for="brandCheckbox">
                        <img src="../../../../assets/icons/brandbadge.svg"> {{brand.name}}
                        <span class="fst-italic">

                            <ng-container *ngIf="brand.pendingApplication"><span
                                    translate>Badge.AwaitingApproval</span></ng-container>
                            <ng-container *ngIf="brand.approvedApplication"><span
                                    translate>Badge.AlreadyHasBadge</span></ng-container>
                        </span>
                    </label>
                </div>
            </div>
            <!-- Properties under Brand -->
            <div *ngIf="summary.properties.length > 0">
                <div class="checkbox-top-spacing ms-3" *ngFor="let property of summary.properties;let i = index">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input"
                            [disabled]="property.pendingApplication || property.approvedApplication"
                            name="property{{ i }}" [value]="property.propertyId" (change)="
                        onCheckboxChange($event, propertyIds)
                      " />
                        <label class="form-check-label">
                            <i [ngClass]="getPropertyIconClass(property.propertyType)"></i> {{property.name}}
                            <span class="fst-italic">

                                <ng-container *ngIf="property.pendingApplication"><span
                                        translate>Badge.AwaitingApproval</span></ng-container>
                                <ng-container *ngIf="property.approvedApplication"><span
                                        translate>Badge.AlreadyHasBadge</span></ng-container>
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <!-- Users -->
        <div class="checkbox-top-spacing" *ngIf="summary.users.length > 0">
            <div class="form-check ms-3" *ngFor="let user of summary.users; let i = index">
                <input type="checkbox" class="form-check-input"
                    [disabled]="user.pendingApplication || user.approvedApplication" [value]="user.userId"
                    name="user{{ i }}" (change)="
                        onCheckboxChange($event, organizationUsers)
                      " />
                <label class="form-check-label">
                    <i class="bi bi-person-circle"></i> {{user.fullName}}
                    <span class="fst-italic">
                        <ng-container *ngIf="user.pendingApplication"><span
                                translate>Badge.AwaitingApproval</span></ng-container>
                        <ng-container *ngIf="user.approvedApplication"><span
                                translate>Badge.AlreadyHasBadge</span></ng-container>
                    </span>
                </label>
            </div>
        </div>
        <hr class="mt-1">
        <!-- Buttons -->
        <div class="d-flex justify-content-center mt-1 gap-5">
            <button type="button" class="btn btn-secondary me-2" (click)="activeModal.dismiss()" translate>
                Common.Cancel
            </button>
            <button type="submit" class="btn btn-primary" translate>
                BadgeDetail.ApplyForBadge
            </button>
        </div>
    </form>
</div>