import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';
import { AppConfigService } from './app.config.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private idleSeconds: number = 900;
  private timeoutSeconds: number = 300;
  private onTimeOutWarningMinutes: number = 0;
  private modalRef: any;

  constructor(
    private idle: Idle,
    private modalService: NgbModal,
    private authService: AuthService,
    private configService: AppConfigService
  ) {
    this.idleSeconds = this.configService.appConfig.sessionIdleSeconds;
    this.timeoutSeconds = this.configService.appConfig.sessionTimeoutSeconds;
  }

  register() {
    this.idle.setIdle(this.idleSeconds);
    this.idle.setTimeout(this.timeoutSeconds);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);


    this.idle.onIdleStart.subscribe(() => {
      this.idle.clearInterrupts();
      this.showWarningModal();
    });
    //this.idle.onIdleEnd.subscribe(() => {
    //});
    this.idle.onTimeoutWarning.subscribe((secondsLeft: number) => {
      const onTimeOutWarningMinutes = Math.floor((secondsLeft + 60) / 60);
      if (this.onTimeOutWarningMinutes != onTimeOutWarningMinutes) {
        this.onTimeOutWarningMinutes = onTimeOutWarningMinutes;
        this.modalRef.componentInstance.bodyMessageParams = { onTimeOutWarningMinutes: onTimeOutWarningMinutes };
      }
    });
    this.idle.onTimeout.subscribe(() => {
      this.authService.logout(false);
      this.showLoggedOutModal();
    });

    this.idle.watch();
  }

  private showWarningModal() {
    this.modalService.dismissAll();
    this.modalRef = this.modalService.open(ConfirmModalComponent, { centered: true });
    this.modalRef.componentInstance.title = 'SessionInactivity.Title';
    this.modalRef.componentInstance.bodyMessage = 'SessionInactivity.Message';
    this.modalRef.componentInstance.bodyMessageParams = { onTimeOutWarningMinutes: this.onTimeOutWarningMinutes };
    this.modalRef.componentInstance.yesText = 'ConfirmModal.Yes';
    this.modalRef.componentInstance.yesAction.subscribe(() => {
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      this.idle.watch();
      this.modalService.dismissAll();
    });
  }

  private showLoggedOutModal() {
    this.modalService.dismissAll();
    this.modalRef = this.modalService.open(ConfirmModalComponent, { centered: true });
    this.modalRef.componentInstance.title = 'SessionInactivity.Title';
    this.modalRef.componentInstance.bodyMessage = 'SessionInactivity.LoggedOutMessage';
    this.modalRef.componentInstance.yesText = 'SessionInactivity.SignInButton';
    this.modalRef.componentInstance.yesAction.subscribe(() => {
      this.authService.login();
    });
  }

}


