<div class="container sol-container" *ngIf="solution">
  <!-- Breadcrumbs -->
  <div class="mb-3">
    <a routerLink="/find-solutions/solutions" translate
      >SolutionDetail.FindSolutions</a
    >
    &#47; {{ solution.name }}
  </div>
  <!-- Title Row -->
  <div class="d-flex justify-content-between title-row">
    <div class="d-flex align-items-center sol-title">
      <img [src]="solution.primaryImageUrl" width="50px" height="50px" />
      <h2>{{ solution.name }}</h2>
    </div>
    <!-- Action Buttons -->
    <div class="d-flex">
      <button translate class="btn btn-primary" (click)="copyLink()">
        SolutionDetail.CopyLink
      </button>
      <button
        translate
        class="btn btn-primary mx-3"
        (click)="toggleFavourite()"
      >
        <ng-container *ngIf="!solution.isFavourite">
          <img src="/assets/icons/favorite.png" />
          SolutionDetail.AddToFavorites
        </ng-container>

        <ng-container *ngIf="solution.isFavourite">
          <img src="/assets/icons/favorite-filled.png" />
          SolutionDetail.RemoveFromFavorites
        </ng-container>
      </button>
    </div>
  </div>

  <div class="divider"></div>

  <div>
    <!-- Description -->
    <div class="row">
      <p>{{ solution.description }}</p>
    </div>

    <!-- Valid From/To -->
    <div class="row">
      <p>
        <b translate> SolutionDetail.ValidFrom</b> <b>:</b>
        {{ solution.validFrom | date }}
        {{ "SolutionDetail.ValidUntil" | translate }}
        {{ solution.validTo | date }}
      </p>
    </div>

    <!-- Applicable To -->
    <div class="row">
      <p>
        <b translate>SolutionDetail.ApplicableTo</b><b>:</b>
        <ng-container *ngFor="let val of solution.applyTo; let last = last">
          {{ val.name }}{{ !last ? ", " : "" }}
        </ng-container>
      </p>
    </div>

    <!-- Languages -->
    <div class="row">
      <p>
        <b translate>SolutionDetail.Languages</b><b>:</b>
        <ng-container *ngFor="let lang of solution.languages; let last = last">
          {{ lang.englishName }}{{ !last ? ", " : "" }}
        </ng-container>
      </p>
    </div>

    <!-- Locations -->
    <div class="row">
      <p>
        <b translate>SolutionDetail.Locations</b><b>:</b>
        <ng-container *ngFor="let val of solution.locations; let last = last">
          {{ val.name }}{{ !last ? ", " : "" }}
        </ng-container>
      </p>
    </div>

    <!-- Tags -->
    <div class="row" *ngIf="solution.tags">
      <p>
        <b translate>SolutionDetail.Tags</b><b>: </b>
        <span
          *ngFor="let tag of solution.tags"
          class="badge badge-pill badge-pill-primary"
        >
          {{ tag.name }}
        </span>
      </p>
    </div>

    <!-- Terms -->
    <div class="row" *ngIf="solution.termType; let termType">
      <p>
        <b translate>SolutionDetail.Terms</b><b>:</b>
        {{ termType.name }}
      </p>
    </div>

    <!-- Link to T&C -->
    <div class="row">
      <p>
        <b translate>SolutionDetail.TermsAndConditions</b><b>:</b>
        <a href="{{ solution.termsUrl }}" target="_blank">
          {{ solution.termsUrl }}
        </a>
      </p>
    </div>

    <!-- How to get solution -->
    <div class="row">
      <p>
        <b translate>SolutionDetail.HowToGet</b><b>:</b>
        {{ solution.getDescription }}
      </p>
    </div>

    <!-- Solution Links -->
    <div class="row">
      <p class="mb-2"><b translate>SolutionDetail.Links</b><b>:</b></p>
      <ul>
        <li *ngFor="let link of solution.links">
          <a href="{{ link }}" target="_blank">{{ link }}</a>
        </li>
      </ul>
    </div>

    <!-- Media -->
    <div class="row">
      <p><b translate>SolutionDetail.Media</b><b>:</b></p>
      <owl-carousel-o [options]="carouselOptions">
        <ng-template carouselSlide *ngIf="videoEmbedUrl; let url">
          <div class="sol-media-slide">
            <iframe
              width="560"
              height="320"
              [src]="url"
              title="YouTube video player"
              frameborder="0"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </ng-template>
        <ng-template
          carouselSlide
          *ngFor="let img of solution.images; let i = index"
        >
          <div class="sol-media-slide">
            <img [src]="img" />
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>
