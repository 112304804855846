import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, mergeMap, takeUntil, filter, combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppContext } from 'src/app/shared/context/AppContext';
import { DateFormatOptions, UserStatus } from 'src/app/shared/app-constants/constants';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationUserListForManage } from 'src/app/shared/models/OrganizationUserListForManage';
import { Role } from 'src/app/shared/models/Role';
import { ColDef } from 'ag-grid-community';
import { UserOrganization } from '../../../shared/models/UserOrganization';
import { InviteUserComponent } from '../invite-user/invite-user.component';
import { UserActionsRendererComponent } from '../user-actions-renderer/user-actions-renderer.component';
import { OrganizationService } from '../../../services/organization.service';
import { FlatUser } from '../../../shared/models/FlatUser';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  organizationName!: string;
  userList: FlatUser[] = [];
  userRoles: Role[] = [];
  userStatus = UserStatus;
  closeResult: string = '';
  selectedUser?: OrganizationUserListForManage;
  paginationPageSize = 50;
  agGrid: any;
  organizationId!: string;
  userId!: string;

  columnDefs: ColDef[] = [
    { headerName: this.translate.instant('UserManagement.UsersList.FirstName'), field: 'firstName', width: 120},
    { headerName: this.translate.instant('UserManagement.UsersList.LastName'), field: 'lastName', width: 120},
    { headerName: this.translate.instant('UserManagement.UsersList.UserEmail'), field: 'email', width: 230 },
    { headerName: this.translate.instant('UserManagement.UsersList.Role'), field: 'organizationUserRoleName', width: 180 },
    { headerName: this.translate.instant('UserManagement.UsersList.Status'), field: 'organizationUserStatusName', width: 160 },
    { headerName: this.translate.instant('UserManagement.UsersList.LastSeen'), field: 'lastSeen', valueGetter: param => this.formatDate(param.data.lastSeen), width: 130, comparator: this.utilityService.stringDateComparator },
    { headerName: this.translate.instant('UserManagement.UsersList.JoinedDate'), field: 'joinDate', valueGetter: param => this.formatDate(param.data.joinDate), width: 130, comparator: this.utilityService.stringDateComparator },
    { headerName: this.translate.instant('UserManagement.UsersList.Actions'), cellRenderer: UserActionsRendererComponent, cellRendererParams: {context: { userListComponent: this }, userId: this.userId}, width: 100 },
  ];

  defaultColDef = {
    sortable: true,
    resizable:true
  };

  onGridReady(params: any) {
    this.agGrid = params.api;
  }

  refreshGrid() {
    this.organizationService.getUsers(this.organizationId).subscribe((users) => {
      this.userList = users;
      this.userList.forEach((u) => {
        u.joinDate = new Date(u.joinDate);
        u.lastSeen = new Date(u.lastSeen);
      });
    });
    this.agGrid.refreshCells();
  }

  constructor(private appContext: AppContext,
    private utilityService: UtilityService,
    private organizationService: OrganizationService,
    private offcanvasService: NgbOffcanvas,
    private translate: TranslateService) {}

  ngOnInit(): void {
    combineLatest([
      this.appContext.user.pipe(
        mergeMap((user) => this.userId = user.userId)
      ),
      this.appContext.organization.pipe(
        filter((organization): organization is UserOrganization => !!organization),
        mergeMap((organization) => {
          this.organizationName = organization.organizationName;
          this.organizationId = organization.organizationId;
          return this.organizationService.getUsers(organization.organizationId)
        })
      )
    ]).pipe(
      takeUntil(this._destroying$)
    ).subscribe(([_, usersList]) => {
      this.userList = usersList;
      this.userList.forEach((u) => {
        u.joinDate = new Date(u.joinDate);
        u.lastSeen = new Date(u.lastSeen);
      });
    });
  }

  formatDate(date: Date) {
    return date.toLocaleDateString(this.translate.currentLang, DateFormatOptions);
  }

  InviteUser() {
    const offcanvasRef = this.offcanvasService.open(InviteUserComponent, { ariaLabelledBy: 'offcanvas-basic-title', position: 'end', backdrop: 'static' });
    offcanvasRef.componentInstance.organizationId = this.organizationId;
    offcanvasRef.componentInstance.userInvited.subscribe(() => {
      this.refreshGrid();
    })
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
