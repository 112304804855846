<div class="body flex-grow-1 px-3">
  <div class="container-lg hpr-1">
    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <div class="d-flex justify-content-start pb-4">
          <label class="col-sm-1 col-md-1 col-lg-auto col-form-label fw-semibold" translate>FinanceData.JournalEntryGrid.Year</label>
          <div class="col-sm-2 col-md-2 col-lg-auto pl-05">
            <select class="form-select" (change)="externalFilterChanged($event)">
              <option selected value="0" translate>FinanceData.JournalEntryGrid.All</option>
              <option *ngFor="let year of yearFilter" [value]="year">{{ year }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-bottom">
      <div class="d-flex justify-content-between align-items-center">
          <h5 class="card-title mb-0 fw-semibold" translate>
            <img [src]="'/assets/icons/journal-icn.svg'" [alt]="'FinanceData.JournalEntryGrid.JournalEntry' | translate"
            class="pr-01 page-title-icon" />
            FinanceData.JournalEntryGrid.JournalEntry
          </h5>
          <button class="btn btn-primary" type="button"(click)="addEntry()" translate>FinanceData.JournalEntryGrid.AddEntry</button>
      </div>
      <p translate class="pt-05">FinanceData.JournalEntryGrid.JournalEntryText</p>
    </div>
  </div>
  <div class="row grid-container-70">
    <div class="col-md-12 col-sm-12 d-inline-flex mb-4">
        <ag-grid-angular
                         class="ag-theme-alpine journal-grid"
                         [rowData]="financialList"
                         [columnDefs]="columnDefs"
                         [defaultColDef]="defaultColDef"
                         [pagination]="true"
                         [paginationPageSize]="paginationPageSize"
                         [animateRows]="true"
                         [isExternalFilterPresent]="isExternalFilterPresent"
                         [doesExternalFilterPass]="doesExternalFilterPass"
                         (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
  </div>
</div>

