export class Toast {
  public header?: string;
  public body?: string;
  public timeToLiveInSeconds!: number;
  public toasterType?: string;

  public get timeToLiveInMilliseconds(): number {
    return this.timeToLiveInSeconds * 1000;
  }
}
