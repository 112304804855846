import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Subject, mergeMap, takeUntil, combineLatest } from 'rxjs';
import { PatternValidator, Roles } from 'src/app/shared/app-constants/constants';
import { AppContext } from 'src/app/shared/context/AppContext';
import { Language } from 'src/app/shared/models/Language';
import { Role } from 'src/app/shared/models/Role';
import { UserInvite } from 'src/app/shared/models/UserInvite';
import { MasterDataService } from 'src/app/shared/services/master-data.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { OrganizationService } from '../../../services/organization.service';
import { ICellRendererParams } from 'ag-grid-community';
import { FlatPartner } from 'src/app/shared/models/FlatPartner';
import { PartnerService } from 'src/app/services/partner.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.css']
})
export class InviteUserComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  @Output() userInvited = new EventEmitter();
  @Input() organizationId?: string;
  @Input() params?: ICellRendererParams<any, any, any>;
  partner?: FlatPartner;
  emptyGuid: string = '00000000-0000-0000-0000-000000000000';
  vaidationErrors: string[] = [];
  PartnerNotFoundError = false;
  PartnerUserExistsError = false;
  organizationUserExistsError = false;
  IdentityUserCreateError = false;
  UserRoleInvalidError = false;

  roles: Role[] = [];
  languages: Language[] = [];

  orgUserRoles = [Roles.ORGUSER, Roles.ORGADMIN];
  inviteUserForm = new FormGroup({
    partnerOrganization: new FormControl(''),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    languageId: new FormControl('', [Validators.required]),
    roleId: new FormControl('', [Validators.required])
  });

  get partnerOrganization() { return this.inviteUserForm.get('partnerOrganization'); }
  get firstName() { return this.inviteUserForm.get('firstName'); }
  get lastName() { return this.inviteUserForm.get('lastName'); }
  get email() { return this.inviteUserForm.get('email'); }
  get languageId() { return this.inviteUserForm.get('languageId'); }
  get roleId() { return this.inviteUserForm.get('roleId'); }

  constructor(
    private appContext: AppContext,
    private masterDataService: MasterDataService,
    private organizationService: OrganizationService,
    private partnerService: PartnerService,
    private notificationService: NotificationService,
    public activeOffcanvas: NgbActiveOffcanvas,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    combineLatest([
      this.appContext.user.pipe(
        mergeMap((user) => this.masterDataService.getRoles(user.languageId)),
      ),
      this.masterDataService.getLanguages()
    ]).pipe(
      takeUntil(this._destroying$)
    ).subscribe(([roles, languages]) => {
      this.roles = roles.filter(item => this.orgUserRoles.includes(item.roleId));
      this.languages = languages;
    });
    this.partner = this.params?.data;
    if (this.partner) {
      this.partnerOrganization?.setValue(this.partner!.partnerName);
    }
  }


  onSubmit() {
    this.inviteUserForm.markAllAsTouched();
    if (this.partner) {
      this.roleId?.clearValidators();
      this.roleId?.updateValueAndValidity();
      if (this.inviteUserForm.valid) {
        this.inviteUserForm.value.roleId = this.emptyGuid;
        this.partnerService.inviteUserToPartner(this.inviteUserForm.value as UserInvite, this.partner.partnerId)
          .subscribe({
            next: () => {
              this.notificationService.showSuccessToast(`User invited to ${this.partner?.partnerName} partner organization successfully.`);
              this.params?.context.partnersGridComponent.refreshGrid();
              this.activeOffcanvas.close();
            },
            error: error => {
              if (error.status == '400') {
                error.error.errorCodes.forEach((errorCode: any) => {
                  this.vaidationErrors.push(this.translate.instant('ErrorCodes.' + errorCode));
                  if (errorCode === 'PARTNER_NOT_FOUND') {
                    this.PartnerNotFoundError = true;
                  }
                  if (errorCode === 'PARTNER_USER_EXISTS') {
                    this.PartnerUserExistsError = true;
                  }
                  if (errorCode === 'IDENTITY_USER_CREATE_ERROR') {
                    this.IdentityUserCreateError = true;
                  }
                });
              }
              else if (error.status == '404') {
                let errorMessage: string = "";
                errorMessage = this.translate.instant('ErrorCodes.' + error.error.errorCode);
                this.notificationService.showErrorToast(errorMessage);
              }
            }
          });
      }
    }

    if (this.organizationId) {
      if (this.inviteUserForm.valid) {
        this.organizationService.inviteUser(this.inviteUserForm.value as UserInvite, this.organizationId)
          .subscribe({
            next: () => {
              this.notificationService.showSuccessToast("User invited successfully.");
              this.userInvited.emit();
              this.activeOffcanvas.close();
            },
            error: error => {
              if (error.status == '400') {
                error.error.errorCodes.forEach((errorCode: any) => {
                  this.vaidationErrors.push(this.translate.instant('ErrorCodes.' + errorCode));
                  if (errorCode === 'INVALID_USER_ROLE') {
                    this.UserRoleInvalidError = true;
                  }
                  if (errorCode === 'ORGANIZATION_USER_EXISTS') {
                    this.organizationUserExistsError = true;
                  }
                  if (errorCode === 'IDENTITY_USER_CREATE_ERROR') {
                    this.IdentityUserCreateError = true;
                  }
                });
              }
              else if (error.status == '404') {
                let errorMessage: string = "";
                errorMessage = this.translate.instant('ErrorCodes.' + error.error.errorCode);
                this.notificationService.showErrorToast(errorMessage);
              }
            }
          });
      }
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
