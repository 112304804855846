import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { filter, map, Subject, switchMap, takeUntil } from 'rxjs';
import { BadgeService } from 'src/app/services/badge.service';
import { FindSolutionsFilterService } from 'src/app/services/find-solutions-filter.service';
import { BadgeSummary } from 'src/app/shared/models/BadgeSummary';

@Component({
  selector: 'app-badges-table',
  templateUrl: './badges-table.component.html',
  styleUrls: ['./badges-table.component.css'],
})
export class BadgesTableComponent implements OnInit, OnDestroy {
  badges: BadgeSummary[] = [];
  totalPages = Infinity;
  currentPage = 1;
  pageSize = 15;

  tags: string[] = [];
  languages: string[] = [];
  locations: string[] = [];
  terms: string[] = [];
  sortBy: string = '';
  favourite = false;

  destroying$ = new Subject<void>();

  constructor(
    private badgeService: BadgeService,
    private activatedRoute: ActivatedRoute,
    private filterService: FindSolutionsFilterService,
    private gaService: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    this.filterService.filter$
      .pipe(
        filter((v): v is NonNullable<typeof v> => Boolean(v)),
        switchMap((filter) => {
          this.currentPage = 1;

          this.tags = filter.tags;
          this.languages = filter.languages;
          this.locations = filter.locations;
          this.terms = filter.terms;
          this.favourite = filter.fav === '1';
          this.sortBy = filter.sortBy;
          return this.fetchBadges();
        }),
        takeUntil(this.destroying$)
      )
      .subscribe((badges) => {
        this.badges = badges;
      });
  }

  ngOnDestroy() {
    this.destroying$.next();
    this.destroying$.complete();

    this.filterService.totalItems = null;
  }

  onScroll() {
    const nextPage = this.currentPage + 1;
    if (nextPage > this.totalPages) {
      return;
    }

    this.currentPage = nextPage;
    this.fetchBadges().subscribe((badges) => {
      this.badges = [...this.badges, ...badges];
    });
  }

  getBadgeLink(badge: BadgeSummary) {
    return `/badges/${badge.badgeId}`;
  }

  onLinkClick(popover: NgbPopover, badge: BadgeSummary) {
    const origin = window.location.origin;
    const path = this.activatedRoute.snapshot.url;
    const id = badge.badgeId;

    window.navigator.clipboard
      .writeText(`${origin}/${path}/${id}`)
      .then(() => popover.open());
  }

  toggleFavourite(popover: NgbPopover, badge: BadgeSummary) {
    const id = badge.badgeId;

    this.badgeService.toggleFavourite(id).subscribe((res) => {
      badge.isFavourite = res;
      popover.open();
    });
  }

  fetchBadges() {
    return this.badgeService
      .getAllBadgeSummaries({
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
        filter: {
          tagIds: this.tags,
          locationIds: this.locations,
          termTypeIds: this.terms,
          languageIds: this.languages,
          favourites: this.favourite || undefined,
          sortBy: this.sortBy,
        },
      })
      .pipe(
        map((res) => {
          if (res.totalItems === 0) {
            this.gaService.event('solutions_badges_empty');
          }
          this.totalPages = res.totalPages;
          this.filterService.totalItems = res.totalItems;
          return res.results;
        })
      );
  }
}
