<form (submit)="onSubmit()" [formGroup]="addOrganizationForm">
  <div class="offcanvas offcanvas-end show">
    <div class="offcanvas-header border-bottom">
      <h4 class="offcanvas-title" id="offcanvas-basic-title" translate>UserProfile.AddOrganization</h4>
      <div type="button" class="btn-off" data-coreui-dismiss="offcanvas" [attr.aria-label]="'UserProfile.Close' | translate" (click)="activeOffcanvas.close()"></div>
    </div>
    <div class="offcanvas-body organization-help-text">
      <div>
        <fieldset class="row mb-4">
          <legend class="col-form-label col-sm-3 fw-semibold" translate>UserProfile.OrganizationName<span class="text-danger required-field">*</span></legend>
          <div class="col-sm-9">
            <input class="form-control" type="text" formControlName="organizationName" maxlength="100"
                  [attr.aria-label]="'UserProfile.OrganizationName' | translate" [ngClass]="organizationName?.invalid && (organizationName?.dirty || organizationName?.touched) ? 'invalid-input' : '' ">
            <span class="text-danger" *ngIf="organizationName?.invalid && organizationName?.touched && organizationName?.value == ''">
              {{ 'UserProfile.OrganizationNameRequired' | translate }}
            </span>
            <span class="text-danger" *ngIf="organizationName?.errors?.max">
              {{ 'UserProfile.NameInvalid' | translate }}
            </span>
          </div>
        </fieldset>
        <div class="row mb-4">
          <legend class="col-form-label col-sm-3 d-inline-flex fw-semibold" translate>UserProfile.Property<span class="text-danger required-field">*</span>
            <span class="pl-05">
              <img src="../../../../assets/icons/info.svg" [alt]="'UserProfile.PropertyAltText' | translate" class="btn-icon" type="button"
              placement="top" [ngbPopover]="'UserProfile.PropertyHelpText' | translate" popoverClass="userprofile-property-popover">
            </span>
          </legend>
          <div class="col-sm-9">
            <select class="form-select"  (change)="onSelectChange($event)" formControlName="property" [attr.aria-label]="'UserProfile.PropertyAreaLabel' | translate"
                [ngClass]="property?.invalid && (property?.dirty || property?.touched) ? 'invalid-input' : '' ">
              <option selected value="">{{ 'UserProfile.SelectProperty' | translate }}</option>
              <option *ngFor="let property of properties" [value]="property.propertySubtypeId">{{ property.name }}</option>
            </select>
            <span class="text-danger" *ngIf="property?.invalid && property?.touched && property?.value == ''">
              {{ 'UserProfile.PropertyRequired' | translate }}
            </span>
          </div>
        </div>
        <fieldset class="row mb-4">
          <legend class="col-form-label col-sm-3 d-inline-flex fw-semibold" translate>UserProfile.Url<span class="text-danger required-field">*</span>
            <span class="pl-05">
              <img src="../../../../assets/icons/info.svg" [alt]="'UserProfile.UrlIconAltText' | translate" class="btn-icon" type="button"
                  placement="top" [ngbPopover]="'UserProfile.UrlHelpText' | translate" popoverClass="userprofile-url-popover">
            </span>
          </legend>
          <div class="col-sm-9">
            <input class="form-control" type="text" formControlName="propertyUrl"
                  [attr.aria-label]="'UserProfile.Url' | translate" [ngClass]="propertyUrl?.invalid && (propertyUrl?.dirty || propertyUrl?.touched) ? 'invalid-input' : '' ">
            <span class="text-danger" *ngIf="propertyUrl?.invalid && propertyUrl?.touched && propertyUrl?.value == ''">
              {{ 'UserProfile.UrlRequired' | translate }}
            </span>
            <!--<span class="text-danger" *ngIf="propertyUrl?.errors?.['pattern']">
      {{ 'UserProfile.UrlInvalid' | translate }}
    </span>-->
            <span class="text-danger" *ngIf="propertyUrl?.errors?.['invalidUrl']">
              {{ propertyUrl?.errors?.['invalidUrl']?.message | translate }}
            </span>
            <span class="text-danger" *ngIf="reviewerEmail?.errors?.['REGISTER_PROPERTYURL_INVALID']" translate>
              ErrorCodes.REGISTER_PROPERTYURL_INVALID
            </span>
            <span class="text-danger" *ngIf="reviewerEmail?.errors?.['INVALID_DOMAIN']" translate>
              ErrorCodes.INVALID_DOMAIN
            </span>
            <!--<span class="text" *ngIf="property?.value != ''">
      {{  (property?.value) }}
    </span>-->
          </div>
        </fieldset>
        <fieldset class="row mb-4">
          <legend class="col-form-label col-sm-3 fw-semibold" translate>
            UserProfile.Brand<span class="text-danger required-field">*</span>
            <span class="pl-05">
              <img src="../../../../assets/icons/info.svg"  class="btn-icon" type="button"
                  placement="top" [ngbPopover]="'UserProfile.BrandHelpText' | translate" popoverClass="userprofile-brand-popover">
            </span>
          </legend>
          <div class="col-sm-9">
              <input class="form-control" type="text" formControlName="brand" maxlength="100"
                    [attr.aria-label]="'UserProfile.Brand' | translate" [ngClass]="brand?.invalid && (brand?.dirty || brand?.touched) ? 'invalid-input' : '' ">
            <span class="text-danger" *ngIf="brand?.invalid && brand?.touched && brand?.value == ''">
                {{ 'UserProfile.BrandNameRequired' | translate}}
            </span>
            <span class="text-danger" *ngIf="brand?.errors?.max">
              {{ 'UserProfile.NameInvalid' | translate }}
            </span>
          </div>
        </fieldset>
        <div class="row mb-4">
          <legend class="col-form-label col-sm-3 fw-semibold" translate>
            UserProfile.Reviewer<span class="text-danger required-field">*</span>
            <span class="pl-05">
              <img src="../../../../assets/icons/info.svg" class="btn-icon" type="button"
                  placement="top" [ngbPopover]="'UserProfile.ReviewerHelpText' | translate" popoverClass="userprofile-reviewer-popover">
            </span>
          </legend>
          <div class="col-sm-9">
              <select class="form-select" formControlName="partnerId" [attr.aria-label]="'UserProfile.ReviewerAreaLabel' | translate"
                      [ngClass]="partnerId?.invalid && (partnerId?.dirty || partnerId?.touched) ? 'invalid-input' : '' ">
                  <option selected value="">{{ 'UserProfile.SelectReviewer' | translate }}</option>
                  <option *ngFor="let partner of partners" [value]="partner.key">{{ partner.value }}</option>
              </select>
            <span class="text-danger" *ngIf="partnerId?.invalid && partnerId?.touched && partnerId?.value == ''">
              {{ 'UserProfile.ReviewerRequired' | translate }}
            </span>
          </div>
        </div>
        <fieldset class="row mb-4">
          <legend class="col-form-label col-sm-3 fw-semibold" translate>
            UserProfile.ReviewerEmail
            <span class="pl-05">
              <img src="../../../../assets/icons/info.svg" class="btn-icon" type="button"
                  placement="top" [ngbPopover]="'UserProfile.ReviewerEmailHelpText' | translate" popoverClass="userprofile-revieweremail-popover">
            </span>
          </legend>
          <div class="col-sm-9">
            <input class="form-control" type="text" formControlName="reviewerEmail" [attr.aria-label]="'UserProfile.ReviewerEmailAreaLabel' | translate"
                  [ngClass]="reviewerEmail?.invalid && (reviewerEmail?.dirty || reviewerEmail?.touched) ? 'invalid-input' : '' ">
            <span class="text-danger" *ngIf="reviewerEmail?.errors?.['email']">
              {{ 'UserProfile.ReviewerEmailInvalid' | translate }}
            </span>
            <span class="text-danger" *ngIf="reviewerEmail?.errors?.['REGISTER_REVIEWEREMAIL_INVALID']" translate>
              ErrorCodes.REGISTER_REVIEWEREMAIL_INVALID
            </span>
            <span class="text-danger" *ngIf="reviewerEmail?.errors?.['REGISTER_REVIEWEREMAIL_MATCH']" translate>
              ErrorCodes.REGISTER_REVIEWEREMAIL_MATCH
            </span>
            <span class="text-danger" *ngIf="reviewerEmail?.errors?.['REVIEWER_EMAIL_INVALID']" translate>
              ErrorCodes.REVIEWER_EMAIL_INVALID
            </span>
          </div>
        </fieldset>
      </div>
      <div>
        <span translate>
          <img class="btn-info" src="../../../../assets/icons/info.svg">
          UserProfile.AddOrganizationHelpText
        </span>
      </div>
    </div>
    <div class="modal-footer p-3">
      <div class="row text-danger" *ngIf="addOrganizationForm?.errors?.['Errors']">
        <span translate>UserProfile.AddOrganizationSaveError</span>
        <ul>
          <li *ngFor="let error of addOrganizationForm.errors?.['ErrorCodes']" translate>{{error}}</li>
        </ul>
      </div>
      <button type="button" class="btn btn-secondary mr-1" (click)="activeOffcanvas.close()" translate>UserManagement.InviteUser.Cancel</button>
      <button type="submit" class="btn btn-primary" translate>UserProfile.Submit</button>
    </div>
  </div>
</form>

