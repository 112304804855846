import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, interval, switchMap, takeUntil, timer } from 'rxjs';
import { AppConfigService } from './app.config.service';

@Injectable({ providedIn: 'root' })
export class UpToDateBuildService implements OnDestroy {

  private buildIsUpToDateSubject = new BehaviorSubject<boolean>(true);
  private readonly _destroying$ = new Subject<void>();
  buildNumberAtStartup: string | undefined;

  constructor(private configService: AppConfigService, private httpClient: HttpClient) {
    this.buildNumberAtStartup = this.configService.appConfig.buildNumber;
    this.pollForBuildNumber(this.configService.appConfig.buildPollInterval);
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  public get buildIsUpToDate(): Observable<boolean> {
    return this.buildIsUpToDateSubject;
  }

  private pollForBuildNumber(pollInterval: number):void {
    interval(pollInterval)
      .pipe(
        switchMap(() => this.configService.loadConfig()),
        takeUntil(this._destroying$)
    ).subscribe(data => {
        const newBuildNumber = data.buildNumber;
        if (this.buildNumberAtStartup !== newBuildNumber) {
          this.buildIsUpToDateSubject.next(false);
        }
      });
  }
}
