import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-save-to-view-modal',
  templateUrl: './save-to-view-modal.component.html',
  styleUrls: ['./save-to-view-modal.component.css']
})
export class SaveToViewModalComponent {
  @Output() yesAction = new EventEmitter();
  @Output() viewName = new EventEmitter<string>();
  @Output() renameAction = new EventEmitter<string>()
  @Input() action?: string;
  viewForm: FormGroup;
  constructor(private fb: FormBuilder) {
    this.viewForm = this.fb.group({
      viewName: ['', [Validators.required, Validators.maxLength(24)]]
    });
  }
  onSubmit(): void {
    if (!this.viewForm.valid) return;

    const viewNameValue = this.viewForm.get('viewName')?.value;
    this.action === 'save' ? this.viewName.emit(viewNameValue) : this.renameAction.emit(viewNameValue);
  }
  handleCancel() {
    this.viewForm.reset();
    this.yesAction.emit();
  }
}
