import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { error } from 'console';
import { BadgeApplicationService } from 'src/app/services/badgeapplication.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { GetBadgeApplicationSummary } from 'src/app/shared/models/GetBadgeApplicationSummary';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-get-badge',
  templateUrl: './get-badge.component.html',
  styleUrls: ['./get-badge.component.css'],
})
export class GetBadgeComponent {
  @Input() summary!: GetBadgeApplicationSummary;
  organizationId?: string | null;
  brandIds: string[] = [];
  propertyIds: string[] = [];
  organizationUsers: string[] = [];
  vaidationErrors: string[] = [];
  constructor(
    private translate: TranslateService,
    public activeModal: NgbActiveModal,
    private notificationService: NotificationService,
    private badgeApplicationService: BadgeApplicationService,
    private modalService: NgbModal
  ) {}
  onCheckboxChange(event: any, selectionArray: string[]): void {
    const value = event.target.value;
    if (event.target.checked) {
      selectionArray.push(value);
    } else {
      selectionArray.splice(selectionArray.indexOf(value), 1);
    }
  }
  onOrgCheckboxChange(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    this.organizationId = checkbox.checked ? checkbox.value : null;
  }
  isValid(): boolean {
    return (
      !!this.organizationId ||
      this.brandIds.length > 0 ||
      this.propertyIds.length > 0 ||
      this.organizationUsers.length > 0
    );
  }
  submitForm(): void {
    if (!this.isValid()) {
      this.notificationService.showErrorToast(
        this.translate.instant('ErrorCodes.BADGE_APPLICATION_VALIDATION_ERROR')
      );
      return;
    }
    this.badgeApplicationService
      .apply({
        organizationId: this.organizationId,
        brandIds: this.brandIds,
        propertyIds: this.propertyIds,
        organizationUsers: this.organizationUsers,
        badgeId: this.summary.badgeId,
      })
      .subscribe({
        next: (response) => {
          if (response.isSuccess) {
            this.showConfirmModal(
              this.translate.instant('BadgeDetail.ApplyModalTitle'),
              this.translate.instant('Badge.ApplyBadgeSuccessMessage', {
                BadgeName: this.summary.badgeName,
              })
            );
          }
        },
        error: (error) => {
          error.error.errorCodes.forEach((errorCode: any) => {
            this.vaidationErrors.push(
              this.translate.instant('ErrorCodes.' + errorCode)
            );
          });
        },
      });
  }
  showConfirmModal(title: string, message: string) {
    return new Promise<void>((resolve) => {
      this.modalService.dismissAll();
      const modalRef = this.modalService.open(ConfirmModalComponent, {
        centered: true,
        size: 'lg',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.bodyMessage = message;
      modalRef.componentInstance.center = true;
      modalRef.componentInstance.yesText =
        this.translate.instant('Common.Close');
      modalRef.componentInstance.yesAction.subscribe((text: any) => {
        this.modalService.dismissAll();
        resolve();
      });
    });
  }
  getPropertyIconClass(propertyType: string): string {
    switch (propertyType.toLowerCase()) {
      case 'website':
        return 'bi bi-globe';
      case 'facebook':
        return 'bi bi-facebook';
      case 'instagram':
        return 'bi bi-instagram';
      case 'twitter':
        return 'bi bi-twitter';
      case 'youtube':
        return 'bi bi-youtube';
      default:
        return 'bi bi-globe';
    }
  }
}
