<ng-template #filterDrawer let-offcanvas>
  <form [formGroup]="form">
    <div class="offcanvas offcanvas-end show filter-drawer">
      <div class="offcanvas-header border-bottom">
        <h6 class="offcanvas-title" id="offcanvas-basic-title">
          {{ "Solutions.FilterDrawer.Heading" | translate }} ({{
            totalChecked
          }})
        </h6>
        <div
          type="button"
          class="btn-off"
          data-coreui-dismiss="offcanvas"
          aria-label="Close"
          (click)="close()"
        ></div>
      </div>

      <div class="offcanvas-body">
        <!-- Accordion -->
        <div ngbAccordion>
          <!-- Accordion Item -->
          <div
            ngbAccordionItem
            [collapsed]="true"
            *ngFor="let panel of panels"
            class="checkbox-panel"
          >
            <!-- Accordion Header -->
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>
                {{ panel.title | translate }} ({{
                  getCheckedCount(panel.formArrayName)
                }})
              </button>
            </h2>

            <!-- Accordion Body -->
            <div ngbAccordionCollapse>
              <div ngbAccordionBody class="checkbox-container">
                <div [formArrayName]="panel.formArrayName">
                  <div
                    class="form-check"
                    *ngFor="let opt of panel.options; let i = index"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [formControlName]="i"
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      {{ opt[0] }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="position-absolute bottom-0 start-50 translate-middle-x w-100 justify-content-center align-items-center d-flex"
        style="height: 64px; border-top: 1px solid #e2e2e2"
      >
        <button class="btn btn-reset" translate (click)="reset()">
          Reset Filters
        </button>
      </div>
    </div>
  </form>
</ng-template>
