import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { RoutePath } from '../app-constants/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';
import { AppContext } from '../context/AppContext';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private appContext: AppContext,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const clonedRequest = request.clone({
      headers: request.headers.append(
        'X-Organization',
        sessionStorage.getItem('OrganizationId') ?? ''
      ),
    });

    if (clonedRequest.headers.get('skip-spinner') === 'true') {
      return next.handle(clonedRequest);
    }

    this.spinner.show(); // Show loading spinner

    return next.handle(clonedRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.spinner.hide(); // hide loading spinner
        }

        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        // If error is 403 Forbidden from API redirect user to empty path this will reload the page.
        // On redirect i User have any organization assigned then deafult org will be set or user will be redirected to no org component
        if (error && error.status === 403 && error.url?.includes('/api')) {
          var modalRef = this.modalService.open(ConfirmModalComponent);
          modalRef.componentInstance.title = 'Access Denied.';
          modalRef.componentInstance.bodyConfirm =
            'You do not have access to this page or Organization access is revoked.';
          modalRef.componentInstance.bodyMessage =
            'You will be redirected to home screen on close of this window.';
          modalRef.componentInstance.yesText = 'Close';

          modalRef.componentInstance.yesAction.subscribe((res: any) => {
            window.location.href = RoutePath.Empty;
          });

          modalRef.dismissed.subscribe((res) => {
            window.location.href = RoutePath.Empty;
          });

          modalRef.closed.subscribe((res) => {
            window.location.href = RoutePath.Empty;
          });
        }

        this.spinner.hide();
        return throwError(error);
      })
    );
  }
}
