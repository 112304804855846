<div class="d-flex justify-content-between border-bottom align-items-center p-2">
  <h6 class="card-title pt-05">{{SolutionProviderName}}
  </h6>
  <span class="spinner-border spinner-border-sm float-right" role="status"></span>
</div>
<div class="card-body">
  <h5 class="card-title placeholder-glow">
    <span class="placeholder col-6"></span>
  </h5>
  <p class="card-text placeholder-glow">
    <span class="placeholder col-7"></span>
    <span class="placeholder col-4"></span>
    <span class="placeholder col-4"></span>
    <span class="placeholder col-6"></span>
    <span class="placeholder col-8"></span>
  </p>
  <a class="col-10 mx-auto btn btn-primary mb-01 disabled placeholder" aria-disabled="true" translate>DataConnections.Authorize</a>
  <a class="col-10 mx-auto btn btn-secondary mb-01 disabled placeholder" aria-disabled="true" translate>DataConnections.Revoke</a>
</div>
