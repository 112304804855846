import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataDashbordService } from 'src/app/services/datadashboard.service';
import { FacebookPage, IData } from 'src/app/shared/models/FacebookPage';
import { GoogleProperty } from 'src/app/shared/models/GoogleProperty';
import { Solution } from 'src/app/shared/models/Solution';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-fb-select-pages',
  templateUrl: './fb-select-pages.component.html',
  styleUrls: ['./fb-select-pages.component.scss']
})
export class FbSelectPagesComponent implements OnInit {
  @Input() solution!: Solution;
  @Input() organizationId!: string;
  @Output() PagesSelected = new EventEmitter<IData>();
  facebookPages: IData[] = [];
  selectedPage?: IData;


  dropdownSettings: any = {};
  constructor(public activeModal: NgbActiveModal,
    private dataDashboardService: DataDashbordService,
    private notificationService: NotificationService) { }

  ngOnInit(): void {

    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

    this.dataDashboardService.getFacebookPages(this.solution, this.organizationId).subscribe({
      next: (successResponse) => {
        if (successResponse?.data)
          this.facebookPages = successResponse?.data;
      },
      error: (errorResponse) => {
        this.activeModal.close();
        this.notificationService.showErrorToast('Get Facebook pages API call failed ,please try again.');
      }
    });
  }

  get getItems(): IData {
    return this.facebookPages.reduce((acc, curr) => {
      return curr
    }, {});
  }

  onPageSelect(event: any) {
    if (event.target.value != '0') {
      this.selectedPage = this.facebookPages.find(x => x.id === event.target.value);
    }
    else{
      this.selectedPage = undefined;
    }
  }

  selectFacebookPages() {
    this.PagesSelected.emit(this.selectedPage);
    this.activeModal.close();
  }

}
