import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent {

  @Input() title?: string;
  @Input() bodyConfirm?: string;
  @Input() bodyMessage?: string;
  @Input() bodyMessageParams?: Record<string, string>;
  @Input() noText?: string;
  @Input() yesText?: string;
  @Input() center:boolean = false
  @Output() yesAction = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {}

  handleConfirm() {
    this.yesAction.emit();
  }
}
