import { Injectable } from "@angular/core";
import { protectedResourceEndpoints } from "../shared/auth-config/auth-config";
import { Brand } from "src/app/shared/models/brand.model";
import { ApiService } from "../shared/http/api.service";
import { ApiResponse } from "../shared/models/ApiResponse";
import { SolutionProvider } from "../shared/models/SolutionProvider";
import { SolutionProviderMasterList } from "../shared/app-constants/constants";
import { BehaviorSubject, Observable, ReplaySubject, Subject, tap } from "rxjs";
import { Solution } from "../shared/models/Solution";
import { FacebookPage } from "../shared/models/FacebookPage";
import { Property } from "../shared/models/property.model";

@Injectable({
  providedIn: 'root'
})
export class DataDashbordService {

  googleAnalyticsProvider!: SolutionProvider;
  googleSearchConsoselProvider!: SolutionProvider;
  youtubeProvider!: SolutionProvider;
  facebookProvider!: SolutionProvider;
  instagramProvider!: SolutionProvider;
  tiktokProvider!: SolutionProvider;

  solutionProvidersLoaded = new BehaviorSubject<boolean>(false);
  brandSelectionChanged = new ReplaySubject<string>();

  missingFBPermissionsSubject$ = new BehaviorSubject<string[]>([]);

  missingPermissionsPropertiesSubject$ = new ReplaySubject<Property[]>();

  missingPermissionsPropertiesData: Property[] = [];

  constructor(private apiservice: ApiService) {
  }

  getSolutionproviders() {
    return this.apiservice.getEntity<ApiResponse>(protectedResourceEndpoints.masterData.url + 'solutionProviderslist')
      .subscribe(res => {
        if (res.isSuccess) {
          let solutionProvidersList = res.result as SolutionProvider[];
          this.googleSearchConsoselProvider = solutionProvidersList
            .find(x => x.solutionProviderId.toLowerCase() === SolutionProviderMasterList.solutionProviders
              .find(x => x.name === 'GOOGLESEARCHCONSOLE')?.id.toLowerCase())!;
          this.googleAnalyticsProvider = solutionProvidersList
            .find(x => x.solutionProviderId.toLowerCase() === SolutionProviderMasterList.solutionProviders
              .find(x => x.name === 'GOOGLEANALYTICS')?.id.toLowerCase())!;
          this.youtubeProvider = solutionProvidersList
            .find(x => x.solutionProviderId.toLowerCase() === SolutionProviderMasterList.solutionProviders
              .find(x => x.name === 'YOUTUBE')?.id.toLowerCase())!;
          this.facebookProvider = solutionProvidersList
            .find(x => x.solutionProviderId.toLowerCase() === SolutionProviderMasterList.solutionProviders
              .find(x => x.name === 'FACEBOOK')?.id.toLowerCase())!;
          this.instagramProvider = solutionProvidersList
            .find(x => x.solutionProviderId.toLowerCase() === SolutionProviderMasterList.solutionProviders
              .find(x => x.name === 'INSTAGRAM')?.id.toLowerCase())!;
          this.tiktokProvider = solutionProvidersList
            .find(x => x.solutionProviderId.toLowerCase() === SolutionProviderMasterList.solutionProviders
              .find(x => x.name === 'TIKTOK')?.id.toLowerCase())!;

          this.solutionProvidersLoaded.next(true);
        }
      });
  }

  authorizeGooglePlatform(solution: Solution, organizationId: string) {
    return this.apiservice.put<ApiResponse>(protectedResourceEndpoints.dataDashboard.url + 'authorizeGooglePlatform' + '?organizationId=' + organizationId, solution);
  }

  revokeGooglePlatform(solution: Solution, organizationId: string) {
    return this.apiservice.put<ApiResponse>(protectedResourceEndpoints.dataDashboard.url + 'revokeGooglePlatform' + '?organizationId=' + organizationId, solution);
  }

  getGoogleAnalyticsAccounts(solution: Solution, organizationId: string) {
    return this.apiservice.getEntity<ApiResponse>(protectedResourceEndpoints.dataDashboard.url + 'googleAnalyticsAccounts?propertyId=' + solution.propertyId + '&organizationId=' + organizationId);
  }

  getGoogleAnalyticsProperties(solution: Solution, accoutnId: string, organizationId: string) {
    return this.apiservice.getEntity<ApiResponse>(protectedResourceEndpoints.dataDashboard.url + 'googleAnalyticsProperties?propertyId=' + solution.propertyId + '&accountId=' + accoutnId + '&organizationId=' + organizationId);
  }

  getGoogleSearchConsoleSites(solution: Solution, organizationId: string) {
    return this.apiservice.getEntity<ApiResponse>(protectedResourceEndpoints.dataDashboard.url + 'googleSearchSites?propertyId=' + solution.propertyId + '&organizationId=' + organizationId);
  }

  setPlatformIdForSolution(solution: Solution, organizationId: string) {
    return this.apiservice.put<ApiResponse>(protectedResourceEndpoints.dataDashboard.url + 'setPlatformIdForSolution' + '?organizationId=' + organizationId, solution);
  }

  setYoutubePlatformIdForSolution(solution: Solution, organizationId: string) {
    return this.apiservice.put<ApiResponse>(protectedResourceEndpoints.dataDashboard.url + 'setYoutubePlatformIdForSolution' + '?organizationId=' + organizationId, solution);
  }

  authorizeFacebookPlatform(solution: Solution, organizationId: string) {
    return this.apiservice.put<string[]>(protectedResourceEndpoints.dataDashboard.url + 'authorizeFacebookPlatform' + '?organizationId=' + organizationId, solution);
  }

  revokeFacebookPlatform(solution: Solution, organizationId: string) {
    return this.apiservice.put(protectedResourceEndpoints.dataDashboard.url + 'revokeFacebookPlatform' + '?organizationId=' + organizationId, solution);
  }

  getFacebookPages(solution: Solution, organizationId: string) {
    return this.apiservice.getEntity<FacebookPage>(protectedResourceEndpoints.dataDashboard.url + 'facebookPages?propertyId=' + solution.propertyId + '&organizationId=' + organizationId);
  }

  getInstagramBusinessAccounts(solution: Solution, organizationId: string) {
    return this.apiservice.getEntity<FacebookPage>(protectedResourceEndpoints.dataDashboard.url + 'instagramBusinessAccounts?propertyId=' + solution.propertyId + '&organizationId=' + organizationId);
  }

  getMissingPermissionsProperties(organizationId: string) {
    this.apiservice.getEntity<Property[]>(protectedResourceEndpoints.dataDashboard.url + 'missingPermissionsProperties?organizationId=' + organizationId).subscribe((response) => {
        this.missingPermissionsPropertiesData = response;
        this.missingPermissionsPropertiesSubject$.next(response);
      }
    );
  }
}
