import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { Report } from '../app-constants/constants';
import { SegmentsContext } from '../models/SegmentsContext';
import { User } from '../models/User';
import { UserOrganization } from '../models/UserOrganization';

@Injectable({
  providedIn: 'root',
})
export class AppContext {
  private _authStatus$ = new BehaviorSubject<boolean>(false);
  private _user$ = new ReplaySubject<User>(1);
  private _organization$ = new ReplaySubject<UserOrganization | null>(1);
  private _countrySegment$ = new BehaviorSubject<string | null>(null);
  private _propertySegment$ = new BehaviorSubject<string | null>(null);
  private _segmentsContext$ = new BehaviorSubject<SegmentsContext | null>(null);
  private _userLocale: string = 'en';

  private readonly OrganizationIdKey = 'OrganizationId';

  public get authStatus(): Observable<boolean> {
    return this._authStatus$;
  }
  public setAuthStatus(isLoggedIn: boolean) {
    this._authStatus$.next(isLoggedIn);
  }

  public get user(): Observable<User> {
    return this._user$;
  }
  public setUser(val: User) {
    this._user$.next(val);
    if (val && val.countryAlpha2Code) {
      this._userLocale = `en-${val.countryAlpha2Code}`;
    }
  }

  public get organization(): Observable<UserOrganization | null> {
    return this._organization$;
  }
  public setOrganization(val: UserOrganization | null) {
    if (val === null) {
      sessionStorage.removeItem(this.OrganizationIdKey);
    } else {
      sessionStorage.setItem(this.OrganizationIdKey, val.organizationId);
    }
    this._organization$.next(val);
  }

  public setOrganizations(val: UserOrganization[]) {
    if (val.length > 0) {
      const sessionOrganizationId = sessionStorage.getItem(
        this.OrganizationIdKey
      );
      const sessionOrganization = val.find(
        (x) => x.organizationId === sessionOrganizationId
      );
      if (sessionOrganization) {
        this.setOrganization(sessionOrganization);
      } else {
        this.setOrganization(val[0]);
      }
    } else {
      this.setOrganization(null);
    }
  }

  public getSegment(report: Report): Observable<string | null> {
    if (report == Report.Country) {
      return this._countrySegment$;
    } else if (report == Report.Property) {
      return this._propertySegment$;
    } else {
      return of(null);
    }
  }
  public setSegment(report: Report, segmentId: string | null) {
    if (report == Report.Country) {
      return this._countrySegment$.next(segmentId);
    } else if (report == Report.Property) {
      return this._propertySegment$.next(segmentId);
    }
  }

  public get segmentsContext(): Observable<SegmentsContext | null> {
    return this._segmentsContext$;
  }
  public setSegmentsContext(val: SegmentsContext | null) {
    this._segmentsContext$.next(val);
  }

  public get userLocale(): string {
    return this._userLocale;
  }
}
