import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { PartnerService } from 'src/app/services/partner.service';
import { PartnerModel } from 'src/app/shared/models/PartnerModel';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { MasterDataService } from 'src/app/shared/services/master-data.service';
import { Language } from 'src/app/shared/models/Language';

@Component({
  selector: 'app-add-partner',
  templateUrl: './add-partner.component.html',
  styleUrls: ['./add-partner.component.css']
})
  export class AddPartnerComponent implements OnInit, OnDestroy {

    @Output() partnerAdded = new EventEmitter();
    private readonly _destroying$ = new Subject<void>();
    addPartnerOrganizationForm!: FormGroup;
    @Input() userId!: string;
    vaidationErrors: string[] = [];
    partnerNameExistsError = false;
    IdentityUserCreateError = false;
    UserNotFoundError = false;
    languages: Language[] = [];

    get partnerName() { return this.addPartnerOrganizationForm.get('partnerName'); }
    get emailDomain() { return this.addPartnerOrganizationForm.get('emailDomain'); }
    get firstName() { return this.addPartnerOrganizationForm.get('primaryUser.firstName'); }
    get lastName() { return this.addPartnerOrganizationForm.get('primaryUser.lastName'); }
    get email() { return this.addPartnerOrganizationForm.get('primaryUser.email'); }
    get languageId() { return this.addPartnerOrganizationForm.get('languageId'); }

    constructor(
      private partnerService: PartnerService,
      public activeOffcanvas: NgbActiveOffcanvas,
      private notificationService: NotificationService,
      private translate: TranslateService,
      private masterDataService: MasterDataService
    ) { }

    ngOnInit(): void {
      this.masterDataService.getLanguages()
      .pipe(
        takeUntil(this._destroying$)
      ).subscribe((languages) => {
        this.languages = languages;
      });

      this.addPartnerOrganizationForm = new FormGroup({
        partnerName: new FormControl('', [Validators.required, Validators.maxLength(30)]),
        emailDomain: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        primaryUser: new FormGroup({
          firstName: new FormControl('', [Validators.required, Validators.maxLength(30)]),
          lastName: new FormControl('', [Validators.required, Validators.maxLength(30)]),
          email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]),
          languageId: new FormControl('', [Validators.required])
        })
      })
    }

    onSubmit() {
      this.addPartnerOrganizationForm.markAllAsTouched();

      if (this.addPartnerOrganizationForm.valid) {
        this.partnerService.addPartner(this.addPartnerOrganizationForm.value as PartnerModel)
        .subscribe({
          next: () => {
            this.notificationService.showSuccessToast(this.translate.instant('MvaSystemAdmin.AddPartner.AddPartnerSucess'))
            this.partnerAdded.emit();
            this.activeOffcanvas.close();
          },
          error: error => {
            if (error.status == '400') {
              error.error.errorCodes.forEach((errorCode: any) => {
                this.vaidationErrors.push(this.translate.instant('ErrorCodes.' + errorCode));
                if (errorCode === 'USER_NOT_FOUND') {
                  this.UserNotFoundError = true;
                }
                if (errorCode === 'PARTNER_NAME_EXISTS') {
                  this.partnerNameExistsError = true;
                }
                if (errorCode === 'IDENTITY_USER_CREATE_ERROR') {
                  this.IdentityUserCreateError = true;
                }
              });
            }
            else if (error.status == '404') {
              let errorMessage: string = "";
              errorMessage = this.translate.instant('ErrorCodes.' + error.error.errorCode);
              this.notificationService.showErrorToast(errorMessage);
            }
          }
        });
      }
    }

    ngOnDestroy(): void {
      this._destroying$.next();
      this._destroying$.complete();
    }
  }
