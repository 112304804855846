import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject, take, takeUntil } from 'rxjs';
import {
  ReportPageNames,
  Roles,
  RoutePath,
} from '../../../app-constants/constants';
import { AppContext } from '../../../context/AppContext';
import { UserOrganization } from '../../../models/UserOrganization';
import {
  MenuCategory,
  MenuItem,
  MenuService,
} from 'src/app/shared/services/menu.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { SwitchOrganizationComponent } from 'src/app/components/organization/switch-organization/switch-organization.component';
import { SegmentService } from 'src/app/services/segment.service';
import { SelectSegmentComponent } from 'src/app/components/segment/select-segment/select-segment.component';
import { Report } from 'src/app/shared/app-constants/constants';
import { SegmentsContext } from 'src/app/shared/models/SegmentsContext';
import { ShepherdDataService } from '../../../services/shepherd-data.service';
import { User } from '../../../models/User';

@Component({
  selector: '[app-sidebar]',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnDestroy, OnInit {
  private readonly _destroying$ = new Subject<void>();
  isLoggedIn = false;
  user!: User;
  organization: UserOrganization | null = null;
  showReports = false;

  userName!: string;
  orgName?: string;
  canChangeOrg: boolean = false;
  menuCategories: MenuCategory[] = [];
  hideReportMenu = true;

  facebookPage = ReportPageNames.Facebook;
  instagramPage = ReportPageNames.Instagram;
  youtubePage = ReportPageNames.YouTube;

  routePath = RoutePath;

  private _report: Report = Report.Property;

  constructor(
    private appContext: AppContext,
    private menuService: MenuService,
    private authService: AuthService,
    private modalService: NgbModal,
    private offcanvasService: NgbOffcanvas,
    private segmentService: SegmentService,
    private shepherdDataService: ShepherdDataService
  ) {}

  ngOnInit() {
    combineLatest([
      this.appContext.user,
      this.appContext.organization,
      this.appContext.authStatus,
    ])
      .pipe(takeUntil(this._destroying$))
      .subscribe(([user, organization, authStatus]) => {
        this.user = user;
        this.isLoggedIn = authStatus;
        this.menuCategories = this.menuService.getUserProfileMenu(
          user,
          organization
        );
        this.orgName = organization?.organizationName;
        this.canChangeOrg = (user.userOrganizations?.length ?? 0) > 1;
        this.userName = user.firstName
          ? `${user.firstName} ${user.lastName}`
          : user.email;

        this.organization = organization;
        this.showReports =
          organization?.roleId === Roles.ORGADMIN ||
          organization?.roleId === Roles.ORGUSER;

        if (user.userOrganizations?.length ?? 0 > 0) {
          this.hideReportMenu = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.appContext.setSegmentsContext(null);
    this._destroying$.next();
    this._destroying$.complete();
  }

  toggleMenu(event: any) {
    const wasOpen = event.target.parentElement.classList.contains('show');

    document.querySelectorAll('.navigation-title').forEach(function (item) {
      item.classList.remove('show');
    });

    if (!wasOpen) {
      event.target.parentElement.classList.add('show');
    }
  }

  openTour() {
    this.shepherdDataService.intializeTour(this.user, this.organization);
  }

  login(): void {
    this.authService.login();
  }

  logout(): void {
    this.authService.logout();
  }

  openOrganizationsList(): void {
    this.canChangeOrg &&
      this.modalService.open(SwitchOrganizationComponent, {
        centered: true,
        size: 'sm',
      });
  }

  handleCompare() {
    this.segmentService
      .getSegments(this.organization!.organizationId, this._report)
      .subscribe((segments) => {
        this.appContext.setSegmentsContext(
          new SegmentsContext(this._report, segments)
        );
        this.offcanvasService.open(SelectSegmentComponent, {
          ariaLabelledBy: 'selectSegment-title',
          position: 'end',
          backdrop: 'static',
        });
      });
  }

  isMenuItem(item: MenuCategory | MenuItem): item is MenuItem {
    return 'path' in item;
  }
}
