<div class="body flex-grow-1 container px-3 text-start">
    <div class="container-lg">
        <div class="row hpm-1">
            <div>
                <h4 class="fw-semibold pb-1" translate>PrivacyPolicy.Title</h4>
                <div class="row">
                    <div class="row mb-2 d-inline-flex col-md-12">
                        <div class="entry-content mb-4" [innerHTML]="'PrivacyPolicy.Content' | translate"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
