<div class="body flex-grow-1 px-3">
  <form name="createSolutionForm" #solutionForm="ngForm">
    <div class="container-lg hpr-1">
      <div class="row">
        <h4
          class="fw-semibold pb-1"
          [translate]="
            isEditMode ? 'Solution.EditSolution' : 'Solution.AddNewSolution'
          "
        ></h4>
        <div class="actionbar mt-1">
          <!-- Name -->
          <div class="mb-3 row">
            <label
              for="solutionName"
              class="col-sm-12 col-form-label fw-semibold"
              translate
              >Solution.Name <span class="text-danger">*</span>
            </label>
            <div class="col-sm-12 col-md-8">
              <input
                class="form-control"
                [placeholder]="'Solution.NamePlaceholder' | translate"
                [(ngModel)]="solutionListing.name"
                name="solutionName"
                type="text"
                id="solutionName"
                required
                maxlength="100"
                #solutionName="ngModel"
                [ngClass]="
                  solutionName.invalid &&
                  (solutionName.dirty || solutionName.touched)
                    ? 'invalid-input'
                    : ''
                "
              />
              <span
                class="text-danger"
                *ngIf="
                  solutionName.invalid &&
                  (solutionName.dirty || solutionName.touched) &&
                  solutionName.errors?.required
                "
              >
                {{ "Common.Required" | translate }}</span
              >
            </div>
          </div>

          <!-- Apply To -->
          <div class="mb-3 row">
            <label
              for="solutionApplyTo"
              class="col-sm-12 col-form-label fw-semibold"
              translate
              >Solution.ApplyTo
            </label>
            <h6 translate class="col-sm-12 col-md-8">Badge.ApplyToDescription</h6>
            <div class="col-sm-12 col-md-8">
              <ng-multiselect-dropdown
                [placeholder]="'Common.SelectOptions' | translate"
                [settings]="applyToSettings"
                [data]="applyTypes"
                [(ngModel)]="solutionListing.applyTo"
                #solutionApplyTo="ngModel"
                name="solutionApplyTo"
                id="solutionApplyTo"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>

          <!-- Type -->
          <div class="mb-3 row">
            <fieldset class="col-sm-12 col-md-12">
              <legend
                class="col-form-label col-sm-12 pt-0 fw-semibold"
                translate
              >
                Solution.PublishFor
              </legend>
              <h6 translate class="col-sm-12 col-md-7">Solution.PublishForDescription</h6>
              <div class="col-sm-12 col-md-7 col-lg-4 pl-1">
                <div class="form-check pt-2" *ngFor="let type of publishTypes">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="organizationTypeId"
                    [id]="type.name"
                    [value]="type.publishTypeId"
                    [(ngModel)]="solutionListing.publishTypeId"
                    checked
                  />
                  <label class="form-check-label" [for]="type.name">
                    {{ type.name }}
                  </label>
                </div>
              </div>
            </fieldset>
          </div>

          <!-- Validity -->
          <div class="row">
            <legend 
            class="col-form-label col-sm-12 pt-0 fw-semibold" translate>Solution.Validity</legend>
            <h6 translate class="col-sm-12 col-md-8">Solution.ValidityDescription</h6>
          <date-range
            [startDate]="validFrom"
            [endDate]="validTo"
            (dateRangeChange)="onDateRangeChange($event)"
          />
        </div>
          <!-- Primary Image -->
          <div class="mb-3 row">
            <label
              for="primaryImage"
              class="col-sm-12 col-form-label fw-semibold"
              translate
              >Solution.PrimaryImage</label
            >
            <h6 translate class="col-sm-12 col-md-8">Badge.PrimaryImageDescription</h6>
            <div
              *ngIf="!solutionListing.primaryImageUrl"
              class="col-sm-12 col-md-8 col-lg-6 d-inline-flex"
            >
              <div class="card brand-upload">
                <div class="card-body mx-auto">
                  <label for="file-input">
                    <i class="bi bi-plus-square-fill brand-upload-i"></i>
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    accept="image/png,image/jpeg"
                    class="d-none"
                    name="primaryImage"
                    (change)="onPrimaryImageSelect($event)"
                  />
                </div>
              </div>
            </div>
            <div
              *ngIf="solutionListing.primaryImageUrl"
              class="col-sm-6 col-md-8 col-lg-6 d-inline-flex"
            >
              <div class="card brand-upload">
                <input
                  id="file-input-preview"
                  #file
                  type="file"
                  accept="image/png,image/jpeg"
                  class="d-none"
                  (change)="onPrimaryImageSelect($event)"
                />
                <div class="card-body mx-auto" (click)="file.click()">
                  <img
                    [src]="solutionListing.primaryImageUrl"
                    class="brand-upload-logo"
                    alt="{{ 'Solution.PrimaryImage' | translate }}"
                  />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="primaryImageErrors.length > 0" class="mb-3 row">
            <div class="col-sm-1 col-form-label"></div>
            <div class="col-sm-4 d-inline-flex">
              <span
                class="text-danger"
                *ngFor="let error of primaryImageErrors"
              >
                {{ error }}
              </span>
            </div>
          </div>

          <!-- Description -->
          <div class="mb-3 row">
            <label
              for="solutionDescription"
              class="col-sm-12 col-form-label fw-semibold"
              translate
              >Solution.Description
            </label>
            <h6 translate class="col-sm-12 col-md-8">Solution.DescriptionText</h6>
            <div class="col-sm-12 col-md-8">
              <textarea
                class="form-control"
                [(ngModel)]="solutionListing.description"
                name="solutionDescription"
                id="solutionDescription"
                [placeholder]="'Solution.DescriptionPlaceholder' | translate"
                #solutionDescription="ngModel"
                rows="4"
              ></textarea>
            </div>
          </div>

          <!-- Languages supported -->
          <div class="mb-3 row">
            <label
              for="solutionLanguages"
              class="col-sm-12 col-form-label fw-semibold"
              translate
              >Solution.LanguagesSupported
            </label>
            <h6 translate>Solution.LanguagesSupportedDescription</h6>
            <div class="col-sm-12 col-md-8">
              <ng-multiselect-dropdown
                [placeholder]="'Solution.SolutionLanguagesSupported' | translate"
                [settings]="languagesSettings"
                [data]="languages"
                [(ngModel)]="solutionListing.languages"
                #solutionLanguages="ngModel"
                name="solutionLanguages"
                id="solutionLanguages"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>

          <!-- Locations supported -->
          <div class="mb-3 row">
            <label
              for="solutionLocations"
              class="col-sm-12 col-form-label fw-semibold"
              translate
              >Solution.LocationsSupported
            </label>
            <h6 translate class="col-sm-12 col-md-8">Solution.LocationsSupportedDescription</h6>
            <div class="col-sm-12 col-md-8">
              <ng-multiselect-dropdown
              [placeholder]="'Solution.SolutionLocationSupported' | translate"
                [settings]="locationsSettings"
                [data]="countries"
                [(ngModel)]="solutionListing.locations"
                #solutionLocations="ngModel"
                name="solutionLocations"
                id="solutionLocations"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>

          <!-- Tags -->
          <div class="mb-3 row">
            <label
              for="solutionTags"
              class="col-sm-12 col-form-label fw-semibold"
              translate
              >Solution.Tags
            </label>
            <h6 translate class="col-sm-12 col-md-8">Solution.TagsDescription</h6>
            <div class="col-sm-12 col-md-8">
              <ng-multiselect-dropdown
                [placeholder]="'Solution.SelectTag' | translate"
                [settings]="tagsSettings"
                [data]="tags"
                [(ngModel)]="solutionListing.tags"
                #solutionLocations="ngModel"
                name="solutionTags"
                id="solutionTags"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>

          <!-- Links -->
          <div class="row">
            <legend 
            class="col-form-label col-sm-12 pt-0 fw-semibold" translate>Solution.Links</legend>
            <h6 translate class="col-sm-12 col-md-8">Solution.SolutionLinkText</h6>
          <url-list
            [values]="solutionListing.links ?? ['']"
            (valuesChange)="onLinksChange($event)"
          />
         </div>
          <!-- Get Description -->
          <div class="mb-3 row">
            <label
              for="solutionGetDescription"
              class="col-sm-12 col-form-label fw-semibold"
              translate
              >Solution.GetDescription
            </label>
            <h6 translate class="col-sm-12 col-md-8">Solution.GetDescriptionText</h6>
            <div class="col-sm-12 col-md-8">
              <textarea
                class="form-control"
                [placeholder]="'Solution.GetDescriptionPlaceholder' | translate"
                [(ngModel)]="solutionListing.getDescription"
                name="solutionGetDescription"
                id="solutionGetDescription"
                #solutionGetDescription="ngModel"
                rows="4"
              ></textarea>
            </div>
          </div>

          <!-- Images -->
          <p>Images</p>

          <!-- Embedded video -->
          <div class="mb-3 row">
            <label
              for="solutionEmbeddedVideoUrl"
              class="col-sm-12 col-form-label fw-semibold"
              translate
              >Solution.EmbeddedVideo
            </label>
            <h6 class="col-sm-12 col-md-8" translate>Solution.EmbeddedVideoDescription</h6>
            <div class="col-sm-12 col-md-8">
              <input
                class="form-control"
                [placeholder]="'Solution.TermsUrlPlaceholder' | translate"
                [(ngModel)]="solutionListing.embeddedVideoUrl"
                name="solutionEmbeddedVideoUrl"
                type="text"
                id="solutionEmbeddedVideoUrl"
                maxlength="255"
                #solutionEmbeddedVideoUrl="ngModel"
                pattern="https?://.+"
                [ngClass]="
                  solutionEmbeddedVideoUrl.invalid &&
                  (solutionEmbeddedVideoUrl.dirty ||
                    solutionEmbeddedVideoUrl.touched)
                    ? 'invalid-input'
                    : ''
                "
              />
            </div>
          </div>

          <!-- Terms -->
          <div class="mb-3 row">
            <label
              for="solutionEmbeddedVideoUrl"
              class="col-sm-12 col-form-label fw-semibold"
              translate
              >Solution.Terms
            </label>
            <h6 class="col-sm-12 col-md-8" translate>Solution.TermsDescription</h6>
            <div class="col-sm-12 col-md-8">
              <select
                class="form-select"
                [(ngModel)]="solutionListing.termTypeId"
                name="termTypeId"
                [attr.aria-label]="'Solution.Terms' | translate"
              >
                <option value="" selected>
                  {{ "Common.SelectOption" | translate }}
                </option>
                <option
                  *ngFor="let termType of termTypes"
                  [value]="termType.termTypeId"
                >
                  {{ termType.name }}
                </option>
              </select>
            </div>
          </div>

          <!-- Terms Url -->
          <div class="mb-3 row">
            <label
              for="solutionTermsUrl"
              class="col-sm-12 col-form-label fw-semibold"
              translate
              >Solution.TermsUrl
            </label>
            <h6 translate class="col-sm-12 col-md-8">Solution.TermsUrlDescription</h6>
            <div class="col-sm-12 col-md-8">
              <input
              [placeholder]="'Solution.TermsUrlPlaceholder' | translate"
                class="form-control"
                [(ngModel)]="solutionListing.termsUrl"
                name="solutionTermsUrl"
                type="text"
                id="solutionTermsUrl"
                maxlength="255"
                #solutionTermsUrl="ngModel"
                pattern="https?://.+"
                [ngClass]="
                  solutionTermsUrl.invalid &&
                  (solutionTermsUrl.dirty || solutionTermsUrl.touched)
                    ? 'invalid-input'
                    : ''
                "
              />
            </div>
          </div>

          <div class="row text-danger" *ngIf="vaidationErrors.length > 0">
            <span>{{ "ErrorCodes.BRAND_ERRORS" | translate }}</span>
            <ul>
              <li *ngFor="let error of vaidationErrors">{{ error }}</li>
            </ul>
          </div>
          <div class="row brand-footer">
            <div class="actionbar mt-3">
              <div class="col-sm-4 d-inline-flex gap-4">
                <button
                  type="button"
                  class="btn btn-secondary"
                  [routerLink]="SOLUTIONS_PATH"
                  translate
                >
                  Common.Cancel
                </button>

                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="onSave()"
                  translate
                >
                  Common.Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div #deleteSolutionModal class="modal fade show overlay" id="myModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" translate>Solution.Confirm</h4>
        <div
          type="button"
          class="btn-off"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="closeDialog()"
        ></div>
      </div>
      <div class="modal-body">
        <p class="pl-013 pt-4 modal-text fw-semibold" translate>
          Solution.DeleteWarning
        </p>
        <p class="p-3 modal-text" translate>Solution.DeleteMessage</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeDialog()"
          translate
        >
          Common.ConfirmNo
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="onDelete()"
          translate
        >
          Common.ConfirmYes
        </button>
      </div>
    </div>
  </div>
</div>
