<li
  class="nav-group navigation-title navigation-title-{{ depth }} pb-001"
  aria-expanded="true"
>
  <a
    [id]="menuCategory.name"
    class="nav-link wrap-text nav-group-toggle main-menu-link"
    [ngClass]="{ 'fw-semibold': depth === 0 }"
    (click)="toggleMenu($event, depth)"
  >
    <img [src]="'/assets/icons/' + menuCategory.icon" class="pr-01" />{{
      menuCategory.name | translate
    }}
  </a>
  <ul class="nav-group-items">
    <ng-template ngFor let-menuItem [ngForOf]="menuCategory.menuItems">
      <li class="nav-item" *ngIf="isMenuItem(menuItem); else menuOther">
        <a
          *ngIf="isMenuItem(menuItem)"
          [id]="menuItem.name"
          class="nav-link wrap-text sub-menu-link"
          [routerLink]="menuItem.path"
          [routerLinkActive]="['selected']"
        >
          <img
            [src]="'/assets/icons/' + menuItem.icon"
            [alt]="menuItem.name | translate"
            class="pr-01"
          />
          {{ menuItem.name | translate }}
          <img
            src="/assets/icons/active-menu.svg"
            aria-hidden="true"
            class="menu-link-icn pl-05"
          />
        </a>
      </li>
      <ng-template #menuOther>
        <app-menu-category
          *ngIf="!isMenuItem(menuItem)"
          [menuCategory]="menuItem"
          [depth]="depth + 1"
        ></app-menu-category>
      </ng-template>
    </ng-template>
  </ul>
</li>
