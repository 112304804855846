import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface FindSolutionsFilter {
  sort: string;
  fav: string;
  tags: string[];
  languages: string[];
  locations: string[];
  terms: string[];
}

@Injectable({ providedIn: 'root' })
export class FindSolutionsFilterService {
  private _filter$ = new BehaviorSubject<FindSolutionsFilter | null>(null);
  private _totalItems$ = new BehaviorSubject<number | null>(null);

  get filter$(): Observable<FindSolutionsFilter | null> {
    return this._filter$.asObservable();
  }

  set filter(val: FindSolutionsFilter | null) {
    this._filter$.next(val);
  }

  get totalItems$(): Observable<number | null> {
    return this._totalItems$.asObservable();
  }

  set totalItems(val: number | null) {
    this._totalItems$.next(val);
  }
}
