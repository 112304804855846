import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Badge } from 'src/app/shared/models/Badge';

@Component({
  selector: 'app-badge-detail-body',
  templateUrl: './badge-detail-body.component.html',
  styleUrls: ['./badge-detail-body.component.css'],
})
export class BadgeDetailBodyComponent implements OnChanges {
  @Input({ required: true }) badge!: Badge;
  videoEmbedUrl?: SafeResourceUrl | null;

  carouselOptions: OwlOptions = {
    items: 1,
    dots: true,
    loop: false,
  };

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.badge) return;
    this.videoEmbedUrl = this.sanitizeVideoUrl(this.badge?.embeddedVideoUrl);
  }

  sanitizeVideoUrl(url?: string): SafeResourceUrl | null {
    if (!url) return null;

    if (url.startsWith('https://www.youtube.com/watch?v=')) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(
        url.replace('watch?v=', 'embed/')
      );
    }

    if (url.startsWith('https://www.youtube.com/embed/')) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
    const vimeoRegex = /^https:\/\/vimeo\.com\/(\d+)/;
    const match = url.match(vimeoRegex);
    if (match) {
        const vimeoEmbedUrl = `https://player.vimeo.com/video/${match[1]}`;
        return this.domSanitizer.bypassSecurityTrustResourceUrl(vimeoEmbedUrl);
    }
    return null;
  }
  hasValidLinks(): boolean {
    return !!this.badge?.links?.some(link => link && link.trim() !== '');
  }
}
