<div class="body flex-grow-1 text-start">
  <div class="container-lg">
    <div class="row hp-1">
      <div>
        <h2 class="wp-block-heading" translate>Contact.Contact</h2>
        <div class="row height-80">
          <iframe class="responsive-iframe"
            [src]="safeContactFormUrl"
            frameborder="0" marginwidth="0" marginheight="0" allowfullscreen webkitallowfullscreen mozallowfullscreen
            scrolling="no"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
