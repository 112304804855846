import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { catchError, finalize, map, Observable, of } from 'rxjs';
import { BadgeService } from 'src/app/services/badge.service';
import { BadgeApplicationService } from 'src/app/services/badgeapplication.service';
import { propertySubTypes } from 'src/app/shared/app-constants/constants';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { BadgeApplication } from 'src/app/shared/models/BadgeApplication';
import { BadgeApplicationSummary } from 'src/app/shared/models/BadgeApplicationSummary';
import {
  ApplicationSummaryFilter,
  OrganizationBadgeApplicationSummary,
} from 'src/app/shared/models/OrganizationBadgeApplicationSummary';
import { PaginatedRequest } from 'src/app/shared/models/PaginatedRequest';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-approval-center',
  templateUrl: './approval-center.component.html',
  styleUrls: ['./approval-center.component.css'],
})
export class ApprovalCenterComponent {
  applications: OrganizationBadgeApplicationSummary[] = [];
  badgeApplications: BadgeApplication[] = [];
  toggledRequest: { [key: number]: boolean } = {};
  dropdownApplications: BadgeApplicationSummary[] = [];
  pageNumber: number = 1;
  badgeId!: string;
  readonly applyTypes = {
    user: 'user',
    brand: 'brand',
    property: 'property',
    organization: 'organization',
  };
  filter: PaginatedRequest<ApplicationSummaryFilter> = {
    pageNumber: this.pageNumber,
    pageSize: 15,
    filter: {
      badgeId: this.badgeId,
    },
  };
  readonly Approved: string = 'Approved';
  readonly Pending: string = 'Pending';
  constructor(
    private badgeApplicationService: BadgeApplicationService,
    private badgeService: BadgeService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private modalService: NgbModal
  ) {}
  ngOnInit(): void {
    this.onInitData();
  }
  onInitData() {
    this.loadApplications(this.filter).subscribe((applications) => {
      this.applications = applications;
    });
    this.badgeService.applicationSummary().subscribe((res) => {
      this.dropdownApplications = res.result;
    });
  }
  onScroll(): void {
    this.filter.pageNumber = ++this.pageNumber;
    this.loadApplications(this.filter).subscribe((res) => {
      this.applications = [...res, ...this.applications];
    });
  }
  loadApplications(
    filter: PaginatedRequest<ApplicationSummaryFilter>
  ): Observable<OrganizationBadgeApplicationSummary[]> {
    return this.badgeApplicationService.requests(filter).pipe(
      map((response) => {
        return response.result.results;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }
  toggleRequest(index: number, organizationId: string, badgeId: string): void {
    const isCurrentlyOpen = this.toggledRequest[index];
    this.toggledRequest = {};

    if (!isCurrentlyOpen) {
      this.toggledRequest[index] = true;
      this.loadOrganizationBadgeRequest(badgeId, organizationId);
    }
  }
  loadOrganizationBadgeRequest(badgeId: string, organizationId: string) {
    this.badgeApplicationService
      .requestsByOrganization(badgeId, organizationId)
      .subscribe((res) => {
        this.badgeApplications = res.result;
      });
  }
  private handleAction(
    applicationId: string,
    assetName: string,
    action: 'Approve' | 'Reject' | 'Revoke',
    apiCall: (id: string) => Observable<ApiResponse<boolean>>,
    additionalText?: string
  ): void {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      centered: true,
    });
    // Modal Content
    modalRef.componentInstance.title = this.translate.instant(
      `ApprovalCenter.${action}Badge`
    );
    const addtionalDescription = additionalText ? additionalText : '';
    modalRef.componentInstance.bodyMessage = `${this.translate.instant(
      'ApprovalCenter.ActionMessage',
      {
        action: this.translate.instant(`ApprovalCenter.${action}`),
        assetName: assetName,
      }
    )} ${addtionalDescription}`;
    modalRef.componentInstance.yesText = this.translate.instant(
      'ApprovalCenter.YesMessage',
      {
        action: this.translate.instant(`ApprovalCenter.${action}`),
      }
    );
    modalRef.componentInstance.noText = this.translate.instant(
      'ApprovalCenter.CancelMessage'
    );
    modalRef.componentInstance.center = true;
    // Yes Action
    modalRef.componentInstance.yesAction.subscribe(() => {
      apiCall(applicationId).subscribe(
        (response) => {
          if (response.isSuccess) {
            this.notificationService.showSuccessToast(
              this.translate.instant('Toaster.SuccessHeader')
            );
            this.modalService.dismissAll();
            this.toggledRequest = {};
            this.onInitData();
            this.toggledRequest = {}
          }
        },
        (error) => {
          this.notificationService.showErrorToast(
            this.translate.instant('Toaster.ErrorHeader')
          );
        }
      );
    });
  }

  // Approve Application
  approveApplication(applicationId: string, assetName: string): void {
    return this.handleAction(applicationId, assetName, 'Approve', (id) =>
      this.badgeApplicationService.approveRequest(id)
    );
  }

  // Reject Application
  rejectApplication(applicationId: string, assetName: string): void {
    return this.handleAction(applicationId, assetName, 'Reject', (id) =>
      this.badgeApplicationService.rejectRequest(id)
    );
  }
  revokeBadge(applicationId: string, assetName: string): void {
    return this.handleAction(
      applicationId,
      assetName,
      'Revoke',
      (id) => this.badgeApplicationService.revokeApplication(id),
      this.translate.instant('ApprovalCenter.AdditionalRevokeText')
    );
  }
  fetchApplications(data: Event) {
    const selectedValue = (data.target as HTMLSelectElement).value;
    this.badgeId = selectedValue;
    this.pageNumber = 1;
    (this.filter.pageNumber = this.pageNumber),
      (this.filter.filter = this.filter.filter ?? {});
    this.filter.filter.badgeId = this.badgeId;
    this.loadApplications(this.filter).subscribe((applications) => {
      this.applications = applications;
    });
  }
  getIcon(propertySubTypeId: string) {
    switch (propertySubTypeId) {
      case propertySubTypes.Facebook:
        return '../../../../assets/icons/fb-icn.svg';
      case propertySubTypes.Instagram:
        return '../../../../assets/icons/insta-icn.svg';
      case propertySubTypes.Youtube:
        return '../../../../assets/icons/yt-icn.svg';
      default:
        return '../../../../assets/icons/website-icn.svg';
    }
  }
}
