<form (ngSubmit)="onSubmit(editUserForm.value)" #editUserForm="ngForm">
  <div class="offcanvas offcanvas-end show">
    <div class="offcanvas-header border-bottom">
      <h4 class="offcanvas-title" id="offcanvas-basic-title" translate>UserManagement.EditUser.EditUser</h4>
      <div type="button" class="btn-off" data-coreui-dismiss="offcanvas" aria-label="Close" (click)="activeOffcanvas.close()"></div>
    </div>
    <div class="offcanvas-body p-4">
      <div class="mb-3 row">
        <label for="userName" class="col-sm-3 col-form-label" translate>UserManagement.EditUser.UserName</label>
        <div class="col-sm-9">
          <input type="text" readonly class="form-control-plaintext" id="userName" [ngModel]="user.firstName + ' ' + user.lastName" name="userName">
        </div>
      </div>
      <div class="mb-3 row">
        <label for="userEmailID" class="col-sm-3 col-form-label" translate>UserManagement.EditUser.UserEmail</label>
        <div class="col-sm-9">
          <input type="text" readonly class="form-control-plaintext" id="userEmailID" [ngModel]="user.email" name="email">
        </div>
      </div>
      <div class="mb-3 row">
        <label for="userLastSeen" class="col-sm-3 col-form-label" translate>UserManagement.EditUser.LastSeen</label>
        <div class="col-sm-9">
          <input type="text" readonly class="form-control-plaintext" id="userLastSeen" [ngModel]="formatDate(user.lastSeen)" name="lastSeen">
        </div>
      </div>
      <div class="mb-3 row">
        <label for="userJoinedDate" class="col-sm-3 col-form-label" translate>UserManagement.EditUser.JoinedDate</label>
        <div class="col-sm-9">
          <input type="text" readonly class="form-control-plaintext" id="userJoinedDate" [ngModel]="formatDate(user.joinDate)" name="joinDate">
        </div>
      </div>
      <div class="row mb-4">
        <legend class="col-form-label col-sm-3 fw-semibold" translate>UserManagement.EditUser.UserRole</legend>
        <div class="col-sm-9">
          <select class="form-select" [(ngModel)]="user.organizationUserRoleId" name="roleId" [attr.aria-label]="'UserManagement.EditUser.UserRoleAriaLabel' | translate">
            <option *ngFor="let role of userRoles" [value]="role.roleId">{{ role.name }}</option>
          </select>
        </div>
      </div>
      <div class="row mb-4">
        <legend class="col-form-label col-sm-3 fw-semibold" translate>UserManagement.EditUser.UserStatus</legend>
        <div class="col-sm-9">
          <div class="form-check pt-2" [attr.aria-label]="'UserManagement.EditUser.UserStatusAriaLabel' | translate" *ngFor="let status of userStatusConstant">
            <input type="radio" class="form-check-input" name="userStatusId" [id]="status" autocomplete="off" [value]="status" [(ngModel)]="user.organizationUserStatusId">
            <label class="form-check-label" [for]="status">{{ status === userStatus.Active ? 'Active' : 'Inactive' }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer p-3">
      <button type="button" class="btn btn-secondary mr-1" data-coreui-dismiss="offcanvasEdit" (click)="activeOffcanvas.dismiss()" aria-controls="offcanvasRightedit" translate>UserManagement.EditUser.Cancel</button>
      <button type="submit" class="btn btn-primary" translate>UserManagement.EditUser.Save</button>
    </div>
  </div>
</form>



