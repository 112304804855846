import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { protectedResourceEndpoints } from "../shared/auth-config/auth-config";
import { FlatUser } from "../shared/models/FlatUser";
import { Partner } from "../shared/models/Partner";
import { PartnerModel } from "../shared/models/PartnerModel";
import { UserInvite } from "../shared/models/UserInvite";
import { ApiResponse } from "../shared/models/ApiResponse";
import { PartnerUser } from "../shared/models/PartnerUser";
import { PartnerUpdate } from "../shared/models/PartnerUpdate";

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(private http: HttpClient) {
  }

  private _partnersUrl = protectedResourceEndpoints.partners.url;

  getPartners() {
    return this.http.get<Partner[]>(this._partnersUrl);
  }

  getPartnerOrganizationUsers() {
    return this.http.get<FlatUser[]>(`${this._partnersUrl}organizations/users`);
  }

  inviteUserToPartner(user: UserInvite, partnerId: string) {
    return this.http.post<ApiResponse>(`${this._partnersUrl}${partnerId}/inviteuser`, user);
  }

  addPartner(partner: PartnerModel) {
    return this.http.post(`${this._partnersUrl}`, partner);
  }

  updatePartnerUser(partnerUser: PartnerUser, partnerId: string, userId: string) {
    return this.http.put(`${this._partnersUrl}${partnerId}/user/${userId}`, partnerUser);
  }

  deletePartnerUser(userId: string) {
    return this.http.delete(`${this._partnersUrl}deletepartneruser/${userId}`);
  }

  updatePartner(partner: PartnerUpdate) {
    return this.http.put(`${this._partnersUrl}`, partner);
  }
}
