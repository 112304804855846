<div class="container p-4" *ngIf="!isLoading">
  <!-- Breadcrumb -->
  <nav aria-label="breadcrumb" class="mb-3 mt-2">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/find-media']">Find Media</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{ organization.name }}
      </li>
    </ol>
  </nav>

  <!-- Organization Header Section -->
  <div class="card mb-3 border-0">
    <div class="d-flex flex-column flex-md-row align-items-center">
      <img [src]="
          organization.logoUrl
            ? organization.logoUrl
            : '../../../../assets/img/nologo.png'
        " alt="Organization Logo" style="width: 60px; height: 60px" class="me-3" />
      <div class="w-100">
        <h5 class="mb-0 custom-header">{{ organization.name }}</h5>
        <p class="text-muted mb-0">{{ "FindMedia.Location" | translate }}:</p>
        <p class="text-muted mb-0">
          {{ "FindMedia.Languages" | translate }}: {{ getAllLanguages }}
        </p>
        <p class="text-muted">
          {{ "FindMedia.OrganizationType" | translate }}:
          {{ organization.organizationType?.name }}
        </p>
      </div>

      <!-- Button container, set to the right on large screens -->
      <div class="mt-2 mt-md-0 d-flex flex-column flex-md-row w-100">
        <button (click)="copyToClipboard()" class="btn btn-primary button-font mb-2 mb-md-0 me-md-2 ms-md-auto"
          translate>
          FindMedia.CopyLink
        </button>
        <button (click)="onOrgFavourite(organization.organizationId)" class="btn btn-primary button-font" translate>
          <img class="button-image" [src]="
              organization.isFavorite
                ? '../../../../assets/img/activefav.png'
                : '../../../../assets/img/fav.png'
            " width="20" height="20" alt="FindMedia.Favorites" />
          {{
          organization.isFavorite
          ? "FindMedia.RemoveFromFavorite"
          : "FindMedia.AddToFavorites"
          }}
        </button>
      </div>
    </div>

    <hr />
    <div class="d-flex align-items-start gap-3 mt-1 p-3" style="background-color: #f8f8f8">
      <!-- Organization Details -->
      <div class="d-flex flex-column justify-content-center" style="width: 330px">
        <p class="my-0" *ngIf="organization.foundationYear">
          <strong translate>FindMedia.Founded</strong>:
          {{ organization.foundationYear }}
        </p>
        <p class="my-0" *ngIf="organization.totalFte">
          <strong translate>FindMedia.Employees</strong>:
          {{ organization.totalFte }}
        </p>
        <p class="my-0" *ngIf="organization.organizationType">
          <strong translate>FindMedia.OrganizationType</strong>:
          {{ organization.organizationType?.name }}
        </p>
      </div>

      <!-- Badges Section -->
      <div class="d-flex flex-column badge-images-container">
        <strong class="" translate>FindMedia.Badges</strong>
        <div class="d-flex flex-row mt-2 align-items-center gap-2">
          <div *ngFor="let badge of organization.badges" (click)="onBadgeClick(badge)">
            <img [src]="badge.image" alt="badge" class="image-container" />
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- Brands Section -->
  <div class="card mb-3 card-custom">
    <div class="card-header d-flex align-items-center">
      <h6 class="mb-0" translate>FindMedia.Brands</h6>
      <span class="ms-auto">
        <i class="bi bi-chevron-down"></i>
      </span>
    </div>
    <div class="card-body p-0">
      <div class="card border-0 mb-4" *ngFor="let brand of organization.brands">
        <div class="d-flex align-items-center justify-content-between p-3 border-bottom">
          <div class="d-flex align-items-center" style="width: 330px">
            <!-- Toggle Button for Brand Properties -->
            <button class="btn btn-link p-0 me-3" (click)="toggleBrand(brand.brandId)">
              <i class="bi me-1" [ngClass]="
                  toggledBrands[brand.brandId]
                    ? 'bi-chevron-up'
                    : 'bi-chevron-down'
                " style="font-size: 0.9rem"></i>
            </button>
            <img [src]="
                brand.logoUrl
                  ? brand.logoUrl
                  : '../../../../assets/img/nologo.png'
              " alt="Brand Logo" style="width: 50px; height: 50px" class="me-2" />
            <div class="ms-2 w-100">
              <h6 class="mb-0 custom-header">{{ brand.name }}</h6>
              <p class="text-muted mb-0">{{ getBrandExile(brand.inExile) }}</p>
            </div>
          </div>

          <div class="d-none d-md-flex d-flex align-items-center badge-images-container">
            <div *ngFor="let badge of brand.badges" (click)="onBadgeClick(badge)">
              <img [src]="badge.image" alt="badge" class="image-container" />
            </div>
          </div>

          <div class="text-end ms-auto" style="margin-right: 2em">
            <button class="btn btn-default" (click)="onBrandFavourite(brand)" aria-label="Toggle Favorite">
              <img [src]="
                  brand.isFavorite
                    ? '../../../../assets/img/activefav.png'
                    : '../../../../assets/img/fav.png'
                " width="20" height="20" alt="FindMedia.Favorites" />
            </button>
          </div>
        </div>

        <!-- Properties Section (Toggles with chevron for each brand) -->
        <div *ngIf="toggledBrands[brand.brandId] && brand.properties.length > 0" class="card m-2 card-custom">
          <div class="card-header">
            <strong translate>FindMedia.Properties</strong>
          </div>
          <div class="card-body" *ngFor="let property of brand.properties">
            <div class="d-flex align-items-center justify-content-between p-3 border-0">
              <div class="d-flex align-items-start ms-2" style="width: 300px">
                <i class="bi bi-globe me-3" style="font-size: 1.5rem"></i>
                <div class="ms-3">
                  <h6 class="mb-0 custom-header">{{ property.name }}</h6>
                  <p class="text-muted mb-0">
                    {{ property.location }}, {{ property.language }}
                  </p>
                  <a class="website-link" href="{{ property.propertyUrl }}" target="_blank"
                    translate>FindMedia.VisitWebsite</a>
                </div>
              </div>

              <div class="d-none d-md-flex d-flex align-items-center badge-images-container img">
                <div *ngFor="let badge of property.badges" (click)="onBadgeClick(badge)">
                  <img [src]="badge.image" alt="badge" class="image-container" />
                </div>
              </div>
              <div class="text-end ms-auto">
                <button class="btn btn-default" (click)="onPropertyFavourite(property)">
                  <img [src]="
                      property.isFavorite
                        ? '../../../../assets/img/activefav.png'
                        : '../../../../assets/img/fav.png'
                    " width="20" height="20" alt="FindMedia.Favorites" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Organization Users Section -->
  <div *ngIf="organization.users.length > 0">
    <div class="bg-white mt-2 pb-3">
      <h6 class="mb-0" translate>FindMedia.OrganizationUsers</h6>
    </div>
    <div class="d-flex align-items-center justify-content-between p-3 border" *ngFor="let user of organization.users">
      <div class="d-flex align-items-center ms-2 me-4" style="width: 300px">
        <i class="bi bi-person-circle me-4" style="font-size: 25px"></i>
        <div>
          <h6 class="mb-0">{{user.firstName}} {{user.lastName}}</h6>
          <p class="text-muted mb-0">{{user.language}}</p>
        </div>
      </div>
      <div class="d-none d-md-flex d-flex align-items-center badge-images-container img">
        <div *ngFor="let badge of user.badges" (click)="onBadgeClick(badge)">
          <img [src]="badge.image" alt="badge" class="image-container" />
        </div>

      </div>
      <div class="text-end ms-auto" style="margin-right: 2em">
        <a [href]="'mailto:' + user.email" class="no-link-style">
          <i class="bi bi-envelope" style="font-size: 1.5rem; cursor: pointer;"></i>
        </a>
      </div>
    </div>
  </div>
</div>