<div class="body flex-grow-1 px-3">
    <div class="container-lg hpm-1">
      <h5 class="fw-semibold pb-4" translate>
        <img
          [src]="'/assets/img/mybadge.png'"
          [alt]="'Brand.BrandTitle' | translate"
          class="pr-01 page-title-icon"
        />
        Badge.BadgeTitle
      </h5>
      <div class="row">
        <div class="row border-bottom mb-4">
          <div class="col-lg-10 col-sm-10">
            <p translate>Badge.BadgeTitleNotes</p>
          </div>
        </div>
      
        <div
        class="row"
        infiniteScroll
        (scrolled)="onScroll()"
        [infiniteScrollDistance]="0.5"
        [infiniteScrollThrottle]="300" >
        <div class="d-flex align-items-center justify-content-between">
    <ul
      ngbNav
      #nav="ngbNav"
      class="nav-tabs sticky-nav"
      destroyOnHide="true"
      [(activeId)]="activeTab"
      (activeIdChange)="onTabChange(activeTab)"
    >
      <li [ngbNavItem]="1" class="me-lg-5">
        <a ngbNavLink translate>Badge.UploadedByOrganization</a>
        <ng-template ngbNavContent>
          <app-manage-badge-list-table [badges]="badges"
           (onBadgeDelete)="deleteBadge($event)"
          ></app-manage-badge-list-table>
        </ng-template>
      </li>
      <li [ngbNavItem]="2" class="me-lg-5">
        <a ngbNavLink translate>Badge.UploadedByMe</a>
        <ng-template ngbNavContent>
            <app-manage-badge-list-table [badges]="badges" 
            (onBadgeDelete)="deleteBadge($event)"
            >
            </app-manage-badge-list-table>
        </ng-template>
      </li>
    </ul>
    
          <button
            class="btn btn-primary"
            type="button"
            (click)="addBadge()"
            translate
          >
            Badge.AddNewBadge
          </button>
        </div>
    <div [ngbNavOutlet]="nav"></div>
    </div>
</div>
</div>
  