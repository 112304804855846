/*! @azure/msal-browser v3.2.0 2024-01-23 */
'use strict';
export { PublicClientApplication } from './app/PublicClientApplication.mjs';
export { DEFAULT_IFRAME_TIMEOUT_MS } from './config/Configuration.mjs';
export { ApiId, BrowserCacheLocation, CacheLookupPolicy, InteractionStatus, InteractionType, WrapperSKU } from './utils/BrowserConstants.mjs';
export { BrowserUtils } from './utils/BrowserUtils.mjs';
export { BrowserAuthError, BrowserAuthErrorMessage } from './error/BrowserAuthError.mjs';
export { BrowserConfigurationAuthError, BrowserConfigurationAuthErrorMessage } from './error/BrowserConfigurationAuthError.mjs';
export { stubbedPublicClientApplication } from './app/IPublicClientApplication.mjs';
export { NavigationClient } from './navigation/NavigationClient.mjs';
export { MemoryStorage } from './cache/MemoryStorage.mjs';
export { BrowserStorage } from './cache/BrowserStorage.mjs';
export { EventMessageUtils } from './event/EventMessage.mjs';
export { EventType } from './event/EventType.mjs';
export { SignedHttpRequest } from './crypto/SignedHttpRequest.mjs';
export { BrowserPerformanceClient } from './telemetry/BrowserPerformanceClient.mjs';
export { BrowserPerformanceMeasurement } from './telemetry/BrowserPerformanceMeasurement.mjs';
export { AccountEntity, AuthError, AuthErrorCodes, AuthErrorMessage, AuthenticationHeaderParser, AuthenticationScheme, AzureCloudInstance, ClientAuthError, ClientAuthErrorCodes, ClientAuthErrorMessage, ClientConfigurationError, ClientConfigurationErrorCodes, ClientConfigurationErrorMessage, InteractionRequiredAuthError, InteractionRequiredAuthErrorCodes, InteractionRequiredAuthErrorMessage, LogLevel, Logger, OIDC_DEFAULT_SCOPES, PerformanceEvents, PromptValue, ProtocolMode, ServerError, ServerResponseType, StringUtils, UrlString } from '@azure/msal-common';
export { version } from './packageMetadata.mjs';
import * as BrowserAuthErrorCodes from './error/BrowserAuthErrorCodes.mjs';
export { BrowserAuthErrorCodes };
import * as BrowserConfigurationAuthErrorCodes from './error/BrowserConfigurationAuthErrorCodes.mjs';
export { BrowserConfigurationAuthErrorCodes };
