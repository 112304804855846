import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataDashbordService } from 'src/app/services/datadashboard.service';
import { SolutionProviderMasterList } from 'src/app/shared/app-constants/constants';
import { SolutionProvider } from 'src/app/shared/models/SolutionProvider';
import { Property } from 'src/app/shared/models/property.model';

@Component({
  selector: 'app-website-properties',
  templateUrl: './website-properties.component.html',
  styleUrls: ['./website-properties.component.css']
})
export class WebsitePropertiesComponent implements OnInit, OnDestroy {
  @Input() properties!: Property[];
  @Input() organizationId!: string;

  solutionProviderSubscription?: Subscription;
  showProviders = false;

  constructor(private dataDashboardService: DataDashbordService) { }

  ngOnDestroy(): void {
    this.solutionProviderSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.solutionProviderSubscription = this.dataDashboardService.solutionProvidersLoaded.subscribe(isloaded => {
      if (isloaded)
        this.showProviders = true;
    });
  }
}
