import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { DateFormatOptions, UserStatus } from 'src/app/shared/app-constants/constants';
import { AppContext } from 'src/app/shared/context/AppContext';
import { FlatUser } from 'src/app/shared/models/FlatUser';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-mvapartner-edit-user',
  templateUrl: './mvapartner-edit-user.component.html',
  styleUrls: ['./mvapartner-edit-user.component.css']
})
export class MvapartnerEditUserComponent implements OnInit, OnDestroy {

  @ViewChild('editUserForm') editUserForm!: NgForm;
  @Input() params!: ICellRendererParams<any, any, any>;
  private readonly _destroying$ = new Subject<void>();
  user?: FlatUser;
  userStatus = UserStatus;
  userStatusConstant = [this.userStatus.Active, this.userStatus.Inactive];

  constructor(private appContext: AppContext,
              private userService: UserService,
              private notificationService: NotificationService,
              public activeOffcanvas: NgbActiveOffcanvas,
              private translate: TranslateService) {}

  ngOnInit(): void {
    this.user = this.params.data;
  }

  getFullName(firstName: string, lastName: string) {
    if (firstName != undefined || lastName != undefined) {
      return firstName + " " + lastName;
    }
    return '';
  }

  formatDate(date: Date) {
    return date.toLocaleDateString(this.appContext.userLocale, DateFormatOptions);
  }

  onSubmit(orgUser: FlatUser) {
    if (orgUser.organizationUserStatusId === this.user?.organizationUserStatusId)
      return this.editUserForm.form.setErrors({ notUnique: true });

    orgUser.userId = this.user!.userId;
    orgUser.organizationId = this.user!.organizationId;
    orgUser.lastSeen = this.user!.lastSeen;
    orgUser.joinDate = this.user!.joinDate;
    this.userService.saveUserStatusChange(orgUser).subscribe((response) => {
      if (response.isSuccess) {
        this.notificationService.showSuccessToast(this.translate.instant('UserManagement.EditUser.UserUpdated'));
        this.params.context.userGridComponent.refreshGrid();
        this.activeOffcanvas.dismiss();
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
