<div class="table-responsive mt-4">
  <table class="table">
    <thead class="table-header-custom">
      <tr>
        <th translate class="ps-2">FindMedia.NameHeader</th>
        <th translate>FindMedia.Location</th>
        <th class="d-none d-md-table-cell" translate>FindMedia.Languages</th>
        <th class="d-none d-md-table-cell" translate>FindMedia.TotalBadges</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr class="custom-row" *ngFor="let property of properties">
        <td>
          <a
            [href]="property.propertyUrl"
            style="display: inline-flex; align-items: center"
          >
            
            <img 
            [src]="getIcon(property.propertySubTypeId)" alt="{{property.name}}"
            class="me-3 mt-1 d-none d-md-inline"
              style="width: 38px" />
            <span style="display: inline-block; padding-left: 4px;margin-top:30px">{{
              property.name
            }}</span>
          </a>
        </td>
        <td>{{ property.location }}</td>
        <td class="d-none d-md-table-cell">
          <p style="margin-top: 1.8em">{{ property.language }}</p>
        </td>
        <td class="d-none d-md-table-cell">
          <div class="badge-custom ms-4 image-container">
            <img
              src="../../../../assets/img/tempbadge.png"
              alt="FindMedia.TotalBadges"
            />
            <div class="centered-text" *ngIf="property.totalBadges>=1000">&#8805;{{property.totalBadges}}</div>
            <div class="centered-text" *ngIf="property.totalBadges<1000">{{property.totalBadges}}</div>
          </div>
        </td>
        <td class="text-end py-4 pe-5">
          <button
            (click)="copyToClipboard(property); $event.stopPropagation()"
            class="btn btn-default icon-link px-2"
          >
            <img src="../../../../assets/img/copyurl.png" />
          </button>
          <a
            class="favorite-icon"
            (click)="$event.stopPropagation(); onFavourite(property)"
            aria-label="Toggle Favorite"
          >
            <img
              [src]="
                property.isFavorite
                  ? '../../../../assets/img/activefav.png'
                  : '../../../../assets/img/fav.png'
              "
              width="20"
              height="20"
              alt="FindMedia.Favorites"
            />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
