import { Inject, Injectable } from "@angular/core";
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, IdTokenClaims, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { getB2CPolicies, getProtectedResources } from "../auth-config/auth-config";
import { AppContext } from "../context/AppContext";
import { AppConfigService } from "./app.config.service";

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
  newUser?: boolean
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private appContext: AppContext,
    private configService: AppConfigService
  ) { }

  private _isLoggedIn = false;

  initialized() {
    return this.authService.initialize();
  }

  registerAuth(destroying$: Subject<void>) {
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      })

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntil(destroying$)
      )
      .subscribe((result: EventMessage) => {
        const b2cPolicies = getB2CPolicies(this.configService.appConfig);
        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (idtoken.acr === b2cPolicies.names.signUpSignIn || idtoken.tfp === b2cPolicies.names.signUpSignIn) {
          if (idtoken.newUser) {
            this.authService.logout({ postLogoutRedirectUri: "/register.html" });
          }
          this.authService.instance.setActiveAccount(payload.account);
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow (see above ln. 74-92).
         */
        //if (idtoken.acr === b2cPolicies.names.passwordReset || idtoken.tfp === b2cPolicies.names.passwordReset) {
        //  let signUpSignInFlowRequest: RedirectRequest = {
        //    authority: b2cPolicies.authorities.signUpSignIn.authority,
        //    prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
        //    scopes: []
        //  };

        //}

        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Checking for the forgot password error. Learn more about B2C error codes at
        // https://learn.microsoft.com/azure/active-directory-b2c/error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          this.changePassword();
        };
        // AADB2C90091 - user cancelled operation
        if (result.error && result.error.message.indexOf('AADB2C90091') > -1) {
          if (this._isLoggedIn) {
            window.location.reload();
          } else {
            this.login();
          }
        }
        //AADB2C90157: User has exceeded the maximum number for retries for a self - asserted step
        if (result.error && result.error.message.indexOf('AADB2C90157') > -1) {
          this.login();
        }
      });
  }

  setLoginDisplay() {
    const isLoggedIn = this.authService.instance.getAllAccounts().length > 0;
    if (this._isLoggedIn != isLoggedIn) {
      this._isLoggedIn = isLoggedIn;
      this.appContext.setAuthStatus(isLoggedIn);
    }
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      // add your code for handling multiple accounts here
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  login(userFlowRequest?: RedirectRequest) {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect(userFlowRequest);
    }
  }

  logout(redirect: boolean = true) {
    const activeAccount =
      this.authService.instance.getActiveAccount() ||
      this.authService.instance.getAllAccounts()[0];

    if (redirect) {
      this.authService.logoutRedirect({ account: activeAccount });
    } else {
      //window.localStorage.clear();
      window.sessionStorage.clear();
    }
    
    this.authService.instance.clearCache();
  }

  changePassword() {
    const b2cPolicies = getB2CPolicies(this.configService.appConfig);
    const protectedResources = getProtectedResources(this.configService.appConfig);
    let resetPasswordFlowRequest: RedirectRequest = {
      authority: b2cPolicies.authorities.passwordReset.authority,
      scopes: protectedResources.scopes.apiAccess,
    };

    this.login(resetPasswordFlowRequest);
  }
}
