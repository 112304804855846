import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { AppRoutingModule } from './app-routing.module';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { OrganizationComponent } from './components/organization/organization.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrandsListComponent } from './components/brandmanagement/brands-list/brands-list.component';
import { AddEditBrandComponent } from './components/brandmanagement/add-edit-brand/add-edit-brand.component';
import { UserProfileComponent } from './components/userprofilemanagement/userprofile/userprofile.component';
import { AddEditPropertyComponent } from './components/brandmanagement/add-edit-property/add-edit-property.component';
import {
  NgbCarouselModule,
  NgbCollapseModule,
  NgbModule,
  NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';
import { UsersListComponent } from './components/usermanagement/users-list/users-list.component';
import { SharedModule } from './shared/shared.module';
import { EditUserComponent } from './components/usermanagement/edit-user/edit-user.component';
import { InviteUserComponent } from './components/usermanagement/invite-user/invite-user.component';
import { SelectSegmentComponent } from './components/segment/select-segment/select-segment.component';
import { AddSegmentComponent } from './components/segment/add-segment/add-segment.component';
import { AgGridModule } from 'ag-grid-angular';
import { AddOrganizationComponent } from './components/userprofilemanagement/add-organization/add-organization.component';
import { UserActionsRendererComponent } from './components/usermanagement/user-actions-renderer/user-actions-renderer.component';
import { UsersGridComponent } from './components/mvapartner/users-grid/users-grid.component';
import { ActionsRendererComponent } from './components/mvapartner/actions-renderer/actions-renderer.component';
import { MvapartnerEditUserComponent } from './components/mvapartner/mvapartner-edit-user/mvapartner-edit-user.component';
import { SwitchOrganizationComponent } from './components/organization/switch-organization/switch-organization.component';
import { ManagePropertyComponent } from './components/propertydatamanagement/manage-property/manage-property.component';
import { WebsitePropertiesComponent } from './components/propertydatamanagement/website-properties/website-properties.component';
import { SocialmediaPropertiesComponent } from './components/propertydatamanagement/socialmedia-properties/socialmedia-properties.component';
import { GoogleSearchConsoleComponent } from './components/propertydatamanagement/google-search-console/google-search-console.component';
import { GoogleAnalyticsComponent } from './components/propertydatamanagement/google-analytics/google-analytics.component';
import { FacebookComponent } from './components/propertydatamanagement/facebook/facebook.component';
import { InstagramComponent } from './components/propertydatamanagement/instagram/instagram.component';
import { TiktokComponent } from './components/propertydatamanagement/tiktok/tiktok.component';
import { YoutubeAnalyticsComponent } from './components/propertydatamanagement/youtube-analytics/youtube-analytics.component';
import { CommonModule } from '@angular/common';
import { PartnersGridComponent } from './components/mvasystemadmin/partners-grid/partners-grid.component';
import { SelectGoogleAnalyticsPropertyComponent } from './components/propertydatamanagement/google-analytics/ga-select-property/ga-select-property.component';
import { SelectGoogleSearchSiteComponent } from './components/propertydatamanagement/google-search-console/gs-select-site/gs-select-site.component';
import { PartnerNameRendererComponent } from './components/mvasystemadmin/partner-name-renderer/partner-name-renderer.component';
import { EditPartnerUserComponent } from './components/mvasystemadmin/edit-partner-user/edit-partner-user.component';
import { AddPartnerComponent } from './components/mvasystemadmin/add-partner/add-partner.component';
import { PrimarypartnerNameRendererComponent } from './components/mvasystemadmin/primarypartner-name-renderer/primarypartner-name-renderer.component';
import { JournalEntryGridComponent } from './components/financialdatamanagement/journal-entry-grid/journal-entry-grid.component';
import { JournalActionsRendererComponent } from './components/financialdatamanagement/journal-actions-renderer/journal-actions-renderer.component';
import { AddEditEntryComponent } from './components/financialdatamanagement/add-edit-entry/add-edit-entry.component';
import { FbSelectPagesComponent } from './components/propertydatamanagement/facebook/fb-select-pages/fb-select-pages.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FacebookLoginProviderService } from './shared/services/facebook-loginprovider.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { InstaSelectAccountsComponent } from './components/propertydatamanagement/instagram/insta-select-accounts/insta-select-accounts.component';
import { SolutionPlaceholderComponent } from './components/propertydatamanagement/solution-placeholder/solution-placeholder.component';
import { ReportComponent } from './components/reports/report/report.component';
import { EmptyComponent } from './components/empty/empty.component';
import { EditPartnerComponent } from './components/mvasystemadmin/edit-partner/edit-partner.component';
import { PartnerActionsRendererComponent } from './components/mvasystemadmin/partner-actions-renderer/partner-actions-renderer.component';
import { JournalLevelCellrendererComponent } from './components/financialdatamanagement/journal-entry-grid/journal-level-cellrenderer/journal-level-cellrenderer.component';
import { AddEditSolutionListingComponent } from './components/solutionmanagement/add-edit-solution/add-edit-solution.component';
import { ManageSolutionsListComponent } from './components/solutionmanagement/solutions-list/solutions-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FindMediaComponent } from './components/media/findmedia/findmedia.component';
import { OrganizationTableComponent } from './components/media/organization-table/organization-table.component';
import { BrandTableComponent } from './components/media/brand-table/brand-table.component';
import { PropertyTableComponent } from './components/media/property-table/property-table.component';
import { SingleOrganizationComponent } from './components/media/single-organization/single-organization.component';
import { FilterComponent } from './components/media/filter/filter.component';
import { SaveToViewModalComponent } from './components/media/save-to-view-modal/save-to-view-modal.component';
import { SolutionsDetailComponent } from './components/solutions/detail/solutions-detail.component';
import { CarouselModule as OwlCarouselModule } from 'ngx-owl-carousel-o';
import { BadgesDetailComponent } from './components/badges/detail/badges-detail.component';
import { BadgeDetailBodyComponent } from './components/badges/detail/body/badge-detail-body.component';
import { SolutionsTableComponent } from './components/solutions/table/solutions-table.component';
import { FindSolutionsComponent } from './components/find-solutions/find-solutions.component';
import { AddEditBadgeComponent } from './components/badgemanagement/add-edit-badge/add-edit-badge.component';
import { ManageBadgesListComponent } from './components/badgemanagement/badges-list/manage-badges-list.component';
import { ManageBadgeListTableComponent } from './components/badgemanagement/badge-list-table/manage-badge-list-table.component';
import { BadgesTableComponent } from './components/badges/table/badges-table.component';
import { ManageBadgeDetailComponent } from './components/badgemanagement/manage-badge-detail/manage-badge-detail.component';
import { VisibilityComponent } from './components/visibility/visibility.component';
import { GetBadgeComponent } from './components/badges/get-badge/get-badge.component';
import { ApprovalCenterComponent } from './components/approvalcenter/approval-center/approval-center.component';
import { BadgeInformationComponent } from './components/media/single-organization/badge-information/badge-information.component';

export function getFacebookLoginConfig(
  facebookLoginProviderService: FacebookLoginProviderService
) {
  return facebookLoginProviderService.getFacebookServiceConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    OrganizationComponent,
    BrandsListComponent,
    AddEditBrandComponent,
    ManageSolutionsListComponent,
    AddEditSolutionListingComponent,
    UserProfileComponent,
    AddEditPropertyComponent,
    UsersListComponent,
    EditUserComponent,
    InviteUserComponent,
    SelectSegmentComponent,
    AddSegmentComponent,
    AddOrganizationComponent,
    UserActionsRendererComponent,
    UsersGridComponent,
    ActionsRendererComponent,
    MvapartnerEditUserComponent,
    SwitchOrganizationComponent,
    UserActionsRendererComponent,
    ManagePropertyComponent,
    WebsitePropertiesComponent,
    SocialmediaPropertiesComponent,
    GoogleSearchConsoleComponent,
    GoogleAnalyticsComponent,
    FacebookComponent,
    InstagramComponent,
    TiktokComponent,
    YoutubeAnalyticsComponent,
    PartnersGridComponent,
    SelectGoogleAnalyticsPropertyComponent,
    SelectGoogleSearchSiteComponent,
    PartnerNameRendererComponent,
    EditPartnerUserComponent,
    AddPartnerComponent,
    PrimarypartnerNameRendererComponent,
    FbSelectPagesComponent,
    InstaSelectAccountsComponent,
    JournalEntryGridComponent,
    JournalLevelCellrendererComponent,
    JournalActionsRendererComponent,
    AddEditEntryComponent,
    SolutionPlaceholderComponent,
    ReportComponent,
    EmptyComponent,
    EditPartnerComponent,
    PartnerActionsRendererComponent,
    PartnerActionsRendererComponent,
    FindMediaComponent,
    OrganizationTableComponent,
    BrandTableComponent,
    PropertyTableComponent,
    SingleOrganizationComponent,
    FilterComponent,
    SaveToViewModalComponent,
    SolutionsDetailComponent,
    SolutionsTableComponent,
    FindSolutionsComponent,
    BadgesDetailComponent,
    BadgeDetailBodyComponent,
    AddEditBadgeComponent,
    ManageBadgesListComponent,
    ManageBadgeListTableComponent,
    BadgesTableComponent,
    ManageBadgeDetailComponent,
    VisibilityComponent,
    GetBadgeComponent,
    ApprovalCenterComponent,
    BadgeInformationComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    AppRoutingModule,
    PowerBIEmbedModule,
    BrowserAnimationsModule, // required animations module
    NgbModule,
    SharedModule,
    AgGridModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    InfiniteScrollModule,
    NgbCollapseModule,
    NgbPopoverModule,
    NgbCarouselModule,
    OwlCarouselModule,
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: getFacebookLoginConfig,
      deps: [FacebookLoginProviderService],
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
