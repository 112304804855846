<div class="body flex-grow-1 px-3">
  <div class="container-lg">
    <div class="row">
      <div class="col-lg-12 col-sm-12 border-bottom mb-4">
        <div class="d-flex justify-content-between pt-4 pb-4">
          <div class="row g-3 align-items-center">
            <div class="col-auto">
              <label class="col-form-label fw-semibold" translate>
                <img [src]="'/assets/icons/sys-admin-icn.svg'" [alt]="'MvaSystemAdmin.PartnersGrid.Partner' | translate"
            class="pr-01 page-title-icon" />
                MvaSystemAdmin.PartnersGrid.Partner
              </label>
            </div>
            <div class="col-auto">
              <select class="form-select" (change)="externalFilterChanged($event)">
                <option selected value="all">All</option>
                <option *ngFor="let partner of partnersFilter" [value]="partner.id">{{ partner.name }}</option>
              </select>
            </div>
          </div>
          <button class="btn btn-primary" type="button" (click)="addPartnerOrganization()" translate>
            MvaSystemAdmin.PartnersGrid.AddPartner
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-4 grid-container-80">
    <div class="col-md-12 col-sm-12 d-inline-flex mb-4">
        <ag-grid-angular
                         class="ag-theme-alpine partners-grid"
                         [rowData]="partnerList"
                         [columnDefs]="columnDefs"
                         [defaultColDef]="defaultColDef"
                         [pagination]="true"
                         [paginationPageSize]="paginationPageSize"
                         [animateRows]="true"
                         [isExternalFilterPresent]="isExternalFilterPresent"
                         [doesExternalFilterPass]="doesExternalFilterPass"
                         (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
  </div>
</div>


