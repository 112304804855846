<div *ngFor="let property of properties; index as i" [ngClass]="i>0?'border-top':''">
  <div class="row pt-2 pb-2">
    <p class="text-start text-decoration-none">{{property.propertyUrl}}</p>
  </div>
  <div class="row mb-4">
    <div class="col-md-12 col-lg-4 col-sm-12 d-inline-flex mb-4">
      <div app-facebook class="card w-100"
        *ngIf="property.propertySubTypeId === facebookSubTypeId && this.showProviders" [property]="property"
        [organizationId]="organizationId"></div>
      <div *ngIf="property.propertySubTypeId === facebookSubTypeId && !this.showProviders" app-solution-placeholder class="card w-100" aria-hidden="true"
        SolutionProviderName='Facebook'></div>
      <div app-instagram class="card w-100"
        *ngIf="property.propertySubTypeId === instagramSubTypeId && this.showProviders" [property]="property"
        [organizationId]="organizationId"></div>
      <div *ngIf="property.propertySubTypeId === instagramSubTypeId && !this.showProviders" app-solution-placeholder class="card w-100" aria-hidden="true"
        SolutionProviderName='Instagram'></div>
      <div app-youtube-analytics class="card w-100"
        *ngIf="property.propertySubTypeId === youtubeSubTypeId && this.showProviders" [property]="property"
        [organizationId]="organizationId">
      </div>
      <div *ngIf="property.propertySubTypeId === youtubeSubTypeId && !this.showProviders" app-solution-placeholder class="card w-100" aria-hidden="true"
        SolutionProviderName='Youtube Analytics'></div>
    </div>
  </div>
</div>
