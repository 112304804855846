<div class="table-responsive mt-4">
  <table class="table">
    <thead class="table-header-custom">
      <tr>
        <th class="text-center" translate>FindMedia.NameHeader</th>
        <th translate>FindMedia.Location</th>
        <th class="d-none d-md-table-cell" translate>FindMedia.Languages</th>
        <th class="d-none d-md-table-cell" translate>FindMedia.TotalBadges</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr class="custom-row" *ngFor="let organization of organizations">
        <td>
          <a
            [routerLink]="[detailsUrl, organization.organizationId]"
            style="display: inline-flex; align-items: center"
          >
            <img
              [src]="
                organization.logoUrl
                  ? organization.logoUrl
                  : '../../../../assets/img/nologo.png'
              "
              alt="Brand Logo"
              class="me-3 d-none d-md-inline"
              style="width: 60px"
            />
            <span style="display: inline-block; padding-left: 4px">{{
              organization.name
            }}</span>
          </a>
        </td>
        <td class="text-center">
          <p style="margin-top: 1.8em">{{ organization.location }}</p>
        </td>
        <td class="d-none d-md-table-cell">
          <p style="margin-top: 1.8em">{{ organization.languages }}</p>
        </td>
        <td class="d-none d-md-table-cell">
          <div class="badge-custom ms-4 image-container">
            <img
              src="../../../../assets/img/tempbadge.png"
              alt="FindMedia.TotalBadges"
            />
            <div class="centered-text" *ngIf="organization.totalBadges>=1000">&#8805;{{organization.totalBadges}}</div>
            <div class="centered-text" *ngIf="organization.totalBadges<1000">{{organization.totalBadges}}</div>
          </div>
        </td>
        <td class="text-end py-4 pe-5">
          <button
            (click)="copyToClipboard(organization); $event.stopPropagation()"
            class="btn btn-default icon-link px-2"
          >
            <img src="../../../../assets/img/copyurl.png" />
          </button>
          <a
            class="favorite-icon px-2"
            (click)="$event.stopPropagation(); onFavorite(organization)"
            *ngIf="organization.isFavorite !== undefined"
          >
            <img
              [src]="
                organization.isFavorite
                  ? '../../../../assets/img/activefav.png'
                  : '../../../../assets/img/fav.png'
              "
              width="20"
              height="20"
              alt="FindMedia.Favorites"
            />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
