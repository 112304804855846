<div class="body flex-grow-1 px-3">
  <div class="container-lg">
    <div class="row">
      <div class="col-lg-12 col-sm-12 border-bottom mb-4">
        <div class="d-flex justify-content-between pt-4">
          <h5 class="card-title mb-0 fw-semibold" translate>
            <img [src]="'/assets/icons/users-icn.svg'" [alt]="'UserManagement.UsersList.ManageUsers' | translate"
            class="pr-01 page-title-icon" />
            UserManagement.UsersList.ManageUsers
          </h5>
        </div>
        <p translate class="pt-07">UserManagement.UsersList.ManageUsersHelpText</p>
        <div class="d-flex justify-content-between pb-03">
          <h5 class="card-title mb-0 fw-semibold"></h5>
          <button class="btn btn-primary" type="button" data-coreui-toggle="offcanvasInvite" data-coreui-target="#offcanvasRightInvite"
            aria-controls="offcanvasRight" (click)="InviteUser()" translate>
            UserManagement.UsersList.InviteUser
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-4 grid-container-65">
    <div class="col-md-12 col-sm-12 d-inline-flex mb-4">
        <ag-grid-angular class="ag-theme-alpine users-grid"
                         [rowData]="userList"
                         [columnDefs]="columnDefs"
                         [defaultColDef]="defaultColDef"
                         [pagination]="true"
                         [paginationPageSize]="paginationPageSize"
                         (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
  </div>
</div>
