import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private readonly mobilePixel = 768;

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
  }


  isMobile() {
    return window.innerWidth <= this.mobilePixel;
  }

  isMobileReportContainer() {
    const div = this.document.getElementById('content-div');
    if (!div)
      return false;
    return div.clientWidth <= this.mobilePixel;
  }

  stringDateComparator(date1: string, date2: string): number {
    const time1: number = new Date(date1).getTime();
    const time2: number = new Date(date2).getTime();
    return time1 - time2;
  }
}


