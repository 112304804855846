<div class="sidebar-brand">
  <img
    src="/assets/icons/group-logo.svg"
    alt="MVA Icon"
    class="pr-013 sidebar-brand-full"
  />
  <img
    src="/assets/icons/group-logo-sm-svg.svg"
    alt="MVA Icon"
    class="pr-013 sidebar-brand-narrow"
  />
</div>
<ul class="sidebar-nav p-01" data-coreui="navigation" data-simplebar="">
  <div class="row pl-1" *ngIf="isLoggedIn">
    <div
      id="orgname-menu"
      class="d-inline-flex gap-2 cursor-pointer"
      *ngIf="orgName"
      (click)="openOrganizationsList()"
    >
      <div class="ft-b">{{ orgName }}</div>
      <div type="button" role="button">
        <img
          src="/assets/icons/dropdown-icn.svg"
          alt="Change Organization"
          *ngIf="canChangeOrg"
          class="pl-1"
        />
      </div>
    </div>
  </div>
  <button
    *ngIf="!hideReportMenu"
    id="compare"
    type="button"
    class="btn m-07 btn-sea-blue fw-bold compare-btn"
    (click)="handleCompare()"
    translate
  >
    Menu.Compare
    <span class="p-2">
      <img
        src="/assets/icons/compare.svg"
        alt="Group Branding Logo Icon"
        class="compare-btn-icon"
      />
    </span>
  </button>

  <li class="nav-item pb-001">
    <a
      class="nav-link wrap-text fw-bold main-menu-link"
      routerLink="/home"
      [routerLinkActive]="['selected']"
      translate
    >
      <img
        src="/assets/icons/home-icn.svg"
        alt="Navigation to Home"
        class="pr-01"
      />Menu.Home
      <img
        src="/assets/icons/active-menu.svg"
        aria-hidden="true"
        class="menu-link-icn pl-05"
      />
    </a>
  </li>

  <!-- Solutions menu category -->
  <ul class="nav-group-items">
    <li class="nav-group navigation-title pb-001" aria-expanded="true">
      <a
        id="Menu.FindSolutions"
        class="nav-link wrap-text nav-group-toggle fw-semibold main-menu-link"
        (click)="toggleMenu($event)"
        translate
      >
        <img
          src="/assets/icons/structure-icn.svg"
          alt="Navigation to Find Solutions"
          class="pr-01"
        />Menu.FindSolutions
      </a>
      <ul class="nav-group-items">
        <li class="nav-item">
          <a
            id="content"
            class="nav-link wrap-text sub-menu-link"
            routerLink="find-solutions"
            routerLinkActive="selected"
            translate
          >
            <img
              src="/assets/icons/find-solutions.png"
              alt="Navigation to Website Content Report"
              class="pr-01"
            />
            Menu.FindSolutions
            <img
              src="/assets/icons/active-menu.svg"
              aria-hidden="true"
              class="menu-link-icn pl-05"
            />
          </a>
        </li>
        <li class="nav-item">
          <a
            id="structure"
            class="nav-link wrap-text sub-menu-link"
            routerLink="find-media"
            routerLinkActive="selected"
            translate
          >
            <img
              src="/assets/icons/find-media.png"
              alt="Navigation to Google Search Console Report"
              class="pr-01"
            />
            Menu.FindMedia
            <img
              src="/assets/icons/active-menu.svg"
              aria-hidden="true"
              class="menu-link-icn pl-05"
            />
          </a>
        </li>
      </ul>
    </li>
  </ul>

  <ul class="nav-group-items" *ngIf="!hideReportMenu">
    <li class="nav-group navigation-title pb-001" aria-expanded="true">
      <a
        id="Menu.Website"
        class="nav-link wrap-text nav-group-toggle fw-semibold main-menu-link"
        (click)="toggleMenu($event)"
        translate
      >
        <img
          src="/assets/icons/website-icn.svg"
          alt="Navigation to Website Summary Report"
          class="pr-01"
        />Menu.Website
      </a>
      <ul class="nav-group-items">
        <li class="nav-item">
          <a
            id="content"
            class="nav-link wrap-text sub-menu-link"
            [routerLink]="['/websitereport']"
            [queryParams]="{ platform: 'Website Content' }"
            [routerLinkActive]="['selected']"
            translate
          >
            <img
              src="/assets/icons/content-icn.svg"
              alt="Navigation to Website Content Report"
              class="pr-01"
            />
            Menu.Content
            <img
              src="/assets/icons/active-menu.svg"
              aria-hidden="true"
              class="menu-link-icn pl-05"
            />
          </a>
        </li>
        <li class="nav-item">
          <a
            id="structure"
            class="nav-link wrap-text sub-menu-link"
            [routerLink]="['/websitereport']"
            [queryParams]="{ platform: 'Structure' }"
            [routerLinkActive]="['selected']"
            translate
          >
            <img
              src="/assets/icons/structure-icn.svg"
              alt="Navigation to Google Search Console Report"
              class="pr-01"
            />
            Menu.Structure
            <img
              src="/assets/icons/active-menu.svg"
              aria-hidden="true"
              class="menu-link-icn pl-05"
            />
          </a>
        </li>
        <li class="nav-item">
          <a
            id="search"
            class="nav-link wrap-text sub-menu-link"
            [routerLink]="['/websitereport']"
            [queryParams]="{ platform: 'Search' }"
            [routerLinkActive]="['selected']"
            translate
          >
            <img
              src="/assets/icons/search-icn.svg"
              alt="Navigation to Google Search Console Report"
              class="pr-01"
            />
            Menu.Search
            <img
              src="/assets/icons/active-menu.svg"
              aria-hidden="true"
              class="menu-link-icn pl-05"
            />
          </a>
        </li>
        <li class="nav-item">
          <a
            id="audience"
            class="nav-link wrap-text sub-menu-link"
            [routerLink]="['/websitereport']"
            [queryParams]="{ platform: 'Audiences' }"
            [routerLinkActive]="['selected']"
            translate
          >
            <img
              src="/assets/icons/audiances-icn.svg"
              alt="Navigation to Audiences Report"
              class="pr-01"
            />
            Menu.Audiences
            <img
              src="/assets/icons/active-menu.svg"
              aria-hidden="true"
              class="menu-link-icn pl-05"
            />
          </a>
        </li>
        <!-- TO DO Get page name from Analytics to pass in platform query param -->
        <li class="nav-item">
          <a
            id="advertising"
            class="nav-link wrap-text sub-menu-link"
            [routerLink]="['/websitereport']"
            [queryParams]="{ platform: 'Advertising' }"
            [routerLinkActive]="['selected']"
            translate
          >
            <img
              src="/assets/icons/advertising-icn.svg"
              alt="Navigation to Advertising Report"
              class="pr-01"
            />
            Menu.Advertising
            <img
              src="/assets/icons/active-menu.svg"
              aria-hidden="true"
              class="menu-link-icn pl-05"
            />
          </a>
        </li>
        <li class="nav-item">
          <a
            id="explorer"
            class="nav-link wrap-text sub-menu-link"
            [routerLink]="['/websitereport']"
            [queryParams]="{ platform: 'Explorer' }"
            [routerLinkActive]="['selected']"
            translate
          >
            <img
              src="/assets/icons/explorer-icn.svg"
              alt="Navigation to Website Summary Report"
              class="pr-01"
            />
            Menu.Explorer
            <img
              src="/assets/icons/active-menu.svg"
              aria-hidden="true"
              class="menu-link-icn pl-05"
            />
          </a>
        </li>
      </ul>
    </li>
  </ul>
  <ul class="nav-group-items" *ngIf="!hideReportMenu">
    <li class="nav-group navigation-title pb-001" aria-expanded="true">
      <a
        id="social"
        class="nav-link wrap-text nav-group-toggle fw-semibold main-menu-link"
        (click)="toggleMenu($event)"
        translate
      >
        <img
          src="/assets/icons/social-icn.svg"
          alt="Navigation to Home"
          class="pr-01"
        /><span class="nav-icon"></span>Menu.Social
      </a>
      <ul class="nav-group-items">
        <li class="nav-item">
          <a
            class="nav-link wrap-text sub-menu-link"
            [routerLink]="['/socialmediareport']"
            [queryParams]="{ platform: facebookPage }"
            [routerLinkActive]="['selected']"
          >
            <img
              src="/assets/icons/fb-icn.svg"
              alt="Navigation to Facebook"
              class="pr-01"
            />
            Facebook
            <img
              src="/assets/icons/active-menu.svg"
              aria-hidden="true"
              class="menu-link-icn pl-05"
            />
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link wrap-text sub-menu-link"
            [routerLink]="['/socialmediareport']"
            [queryParams]="{ platform: instagramPage }"
            [routerLinkActive]="['selected']"
          >
            <img
              src="/assets/icons/insta-icn.svg"
              alt="Navigation to Instagram"
              class="pr-01"
            />
            Instagram
            <img
              src="/assets/icons/active-menu.svg"
              aria-hidden="true"
              class="menu-link-icn pl-05"
            />
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link wrap-text sub-menu-link"
            [routerLink]="['/socialmediareport']"
            [queryParams]="{ platform: youtubePage }"
            [routerLinkActive]="['selected']"
          >
            <img
              src="/assets/icons/yt-icn.svg"
              alt="Navigation to YouTube"
              class="pr-01"
            />
            YouTube
            <img
              src="/assets/icons/active-menu.svg"
              aria-hidden="true"
              class="menu-link-icn pl-05"
            />
          </a>
        </li>
      </ul>
    </li>
  </ul>
  <ul class="nav-group-items" *ngIf="!hideReportMenu">
    <li class="nav-item pb-001">
      <a
        id="nav-countries"
        class="nav-link wrap-text fw-bold main-menu-link"
        routerLink="/countryreport"
        [routerLinkActive]="['selected']"
        translate
      >
        <img
          src="/assets/icons/countries-icn.svg"
          alt="Navigation to Countries"
          class="pr-01"
        />
        Menu.Countries
        <img
          src="/assets/icons/active-menu.svg"
          aria-hidden="true"
          class="menu-link-icn pl-05"
        />
      </a>
    </li>
  </ul>
  <ul class="nav-group-items" *ngIf="isLoggedIn">
    <app-menu-category
      *ngFor="let menuCategory of menuCategories"
      [menuCategory]="menuCategory"
    ></app-menu-category>
  </ul>
  <li class="nav-item pb-001 sign-out-menu" *ngIf="isLoggedIn">
    <a class="nav-link wrap-text sub-menu-link" (click)="logout()" translate>
      <img
        src="/assets/icons/log-out-icn.svg"
        alt="Sign Out"
        class="pr-01"
      />Menu.Signout
      <img
        src="/assets/icons/active-menu.svg"
        aria-hidden="true"
        class="menu-link-icn pl-05"
      />
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link wrap-text sub-menu-link" (click)="openTour()" translate
      >Menu.Tour
      <img
        src="/assets/icons/active-menu.svg"
        aria-hidden="true"
        class="menu-link-icn pl-05"
      />
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link wrap-text sub-menu-link"
      routerLink="/contact"
      [routerLinkActive]="['selected']"
      translate
    >
      Menu.Contact
      <img
        src="/assets/icons/active-menu.svg"
        aria-hidden="true"
        class="menu-link-icn pl-05"
      />
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link wrap-text sub-menu-link"
      [routerLink]="routePath.TermsOfService"
      [routerLinkActive]="['selected']"
      translate
    >
      Menu.TermsOfUse
      <img
        src="/assets/icons/active-menu.svg"
        aria-hidden="true"
        class="menu-link-icn pl-05"
      />
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link wrap-text sub-menu-link"
      [routerLink]="routePath.PrivacyPolicy"
      [routerLinkActive]="['selected']"
      translate
    >
      Menu.Privacy
      <img
        src="/assets/icons/active-menu.svg"
        aria-hidden="true"
        class="menu-link-icn pl-05"
      />
    </a>
  </li>
</ul>
