import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataDashbordService } from 'src/app/services/datadashboard.service';
import { IData, IInstagramBusnissAccount } from 'src/app/shared/models/FacebookPage';
import { Solution } from 'src/app/shared/models/Solution';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-insta-select-accounts',
  templateUrl: './insta-select-accounts.component.html',
  styleUrls: ['./insta-select-accounts.component.css']
})
export class InstaSelectAccountsComponent implements OnInit {

  @Input() solution!: Solution;
  @Input() organizationId!: string;
  @Output() AccuuntsSelected = new EventEmitter<IInstagramBusnissAccount>();
  instagramAccounts: IInstagramBusnissAccount[] = [];
  selectedAccount?: IInstagramBusnissAccount;


  dropdownSettings: any = {};
  constructor(public activeModal: NgbActiveModal,
    private dataDashboardService: DataDashbordService,
    private notificationService: NotificationService) { }

  ngOnInit(): void {

    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "username",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dataDashboardService.getInstagramBusinessAccounts(this.solution, this.organizationId).subscribe({
      next: (successResponse) => {
        if (successResponse?.data) {
          let accounts: IInstagramBusnissAccount[] = [];
          let instaAccountsData = successResponse?.data.filter(x => x.instagram_business_account);
          if (instaAccountsData) {
            instaAccountsData.forEach((acc) => {
              if (acc.instagram_business_account)
                accounts.push(acc.instagram_business_account);
            });
            this.instagramAccounts = accounts;
          }
        }
      },
      error: (errorResponse) => {
        this.activeModal.close();
        this.notificationService.showErrorToast('Get Instgram Business accounts API call failed ,please try again.');
      }
    });
  }

  onAccountSelect(event: any) {
    if (event.target.value != '0') {
      this.selectedAccount = this.instagramAccounts.find(x => x.id === event.target.value)!;
    }
    else {
      this.selectedAccount = undefined;
    }
  }


  selectInstagrmBusinessAccounts() {
    this.AccuuntsSelected.emit(this.selectedAccount);
    this.activeModal.close();
  }

}
