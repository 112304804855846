<div class="mt-3 ms-4 me-4" *ngIf="badge">
    <!-- Breadcrumb -->
    <nav aria-label="breadcrumb" class="mt-1 ms-4">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a [routerLink]="['/manage/badges']" translate="">Badge.BadgeTitle</a>
            </li>
            <li class="breadcrumb-item crumb-text" aria-current="page">
                {{badge.name}}
            </li>
        </ol>
    </nav>
    <!-- Brand Header Section -->
    <div class="card mb-3 border-0">
        <div class="d-flex flex-column flex-md-row align-items-start">
            <img [src]="badge.primaryImageUrl ? badge.primaryImageUrl : '../../../../assets/img/nologo.png'" alt="Badge Logo" style="width: 60px; height: 60px"
                class="me-3" />
            <div class="w-100">
                <h5 class="mb-1 custom-header">{{badge.name}}</h5>
                <p class="text-muted mb-1"><span translate>Badge.TableCreated</span>: {{ badge.createdOn | date : "MMM
                    d, y" }}</p>
                <p class="text-muted">
                    <span translate>Badge.TableUploaded</span>: {{ badge.createdByUser.firstName}}
                    {{badge.createdByUser.lastName }}
                </p>
                <p class="text-muted d-flex align-items-center">
                    <span translate>Badge.TableStatus</span>:
                    <active-status class="ms-2" [active]="badge.active ?? false"></active-status>
                </p>
            </div>

          
            <div class="mt-2 mt-md-0 d-flex flex-column flex-md-row w-100">
                <button class="btn btn-default button-font mb-2 mb-md-0 me-md-4 ms-md-auto" translate (click)="onDeleteBadge(badge.badgeId)">
                    Common.Delete
                </button>
                <button class="btn btn-primary button-font me-md-2" translate (click)="editBadge(badge.badgeId)">
                    Common.Edit
                </button>

            </div>
        </div>
    </div>
    <div class="divider"></div>

    <app-badge-detail-body [badge]="badge"></app-badge-detail-body>
</div>