import { Component, Input } from '@angular/core';
import { MenuCategory, MenuItem } from 'src/app/shared/services/menu.service';

@Component({
  selector: 'app-menu-category',
  templateUrl: './menu-category.component.html',
  styleUrls: ['./menu-category.component.css'],
})
export class MenuCategoryComponent {
  @Input() menuCategory!: MenuCategory;
  @Input() depth: number = 0;

  toggleMenu(event: any, depth: number) {
    let wasOpen = false;
    if (event.target.parentElement.classList.contains('show')) {
      wasOpen = true;
    }

    document
      .querySelectorAll('.navigation-title-' + depth)
      .forEach(function (item) {
        item.classList.remove('show');
      });

    if (!wasOpen) {
      event.target.parentElement.classList.add('show');
    }
  }

  isMenuItem(item: MenuCategory | MenuItem): item is MenuItem {
    return 'path' in item;
  }
}
