import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {
  constructor(
    private translate : TranslateService,
    private notificationService : NotificationService
  ){}
  copyToClipboard(data: string): void {
    navigator.clipboard.writeText(data).then(
        () => {
          this.notificationService.showSuccessToast(this.translate.instant('FindMedia.LinkCopied'));
        },
        (error) => {
          console.error('Copy failed', error);
        }
      );
  }
}
