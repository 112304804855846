import { Component } from '@angular/core';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { UserStatus } from 'src/app/shared/app-constants/constants';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { MvapartnerEditUserComponent } from '../mvapartner-edit-user/mvapartner-edit-user.component';
import { FlatUser } from 'src/app/shared/models/FlatUser';
import { OrganizationService } from '../../../services/organization.service';

@Component({
  selector: 'app-actions-renderer',
  templateUrl: './actions-renderer.component.html',
  styleUrls: ['./actions-renderer.component.css']
})
export class ActionsRendererComponent implements ICellRendererAngularComp {
  user?: FlatUser;
  userStatus = UserStatus;
  params!: ICellRendererParams<any, any, any>;

  constructor(private offcanvasService: NgbOffcanvas,
              private modalService: NgbModal,
              private organizationService: OrganizationService,
              private notificationService: NotificationService,
              private translate: TranslateService) {}


  agInit(params: ICellRendererParams<any, any, any>): void {
    this.user = params.data;
    this.params = params;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return true;
  }

  approveUser(user: FlatUser) {
    this.organizationService.partnerApprovesUser(user.organizationId, user.userId)
      .subscribe(() => {
        this.notificationService.showSuccessToast(this.translate.instant('MvaPartner.UserActions.UserApproved'));
        this.params.context.userGridComponent.refreshGrid();
        this.modalService.dismissAll();
      });
  }

  declineUser(user: FlatUser) {
    this.organizationService.partnerDeclinesUser(user.organizationId, user.userId)
      .subscribe(() => {
        this.notificationService.showSuccessToast(this.translate.instant('MvaPartner.UserActions.UserDeclined'));
        this.params.context.userGridComponent.refreshGrid();
        this.modalService.dismissAll();
      });
  }

  EditUser() {
    const offcanvasRef = this.offcanvasService.open(MvapartnerEditUserComponent, { ariaLabelledBy: 'offcanvas-basic-title', position: 'end', backdrop: 'static' });
    offcanvasRef.componentInstance.params = this.params;
  }

  openApproveModal() {
    const modalRef = this.modalService.open(ConfirmModalComponent, { centered: true });
    modalRef.componentInstance.title = 'UserManagement.UserActions.ApproveUser';
    modalRef.componentInstance.bodyConfirm = 'ConfirmModal.ConfirmBody';
    modalRef.componentInstance.noText = 'ConfirmModal.Close';
    modalRef.componentInstance.yesText = 'ConfirmModal.Yes';
    modalRef.componentInstance.yesAction.subscribe(() => {
      this.approveUser(this.user!);
    });
  }

  openDeclineModal() {
    const modalRef = this.modalService.open(ConfirmModalComponent, { centered: true });
    modalRef.componentInstance.title = 'UserManagement.UserActions.DeclineUser';
    modalRef.componentInstance.bodyConfirm = 'ConfirmModal.ConfirmBody';
    modalRef.componentInstance.noText = 'ConfirmModal.Close';
    modalRef.componentInstance.yesText = 'ConfirmModal.Yes';
    modalRef.componentInstance.yesAction.subscribe(() => {
      this.declineUser(this.user!);
    });
  }
}
