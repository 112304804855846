import { Badge } from './Badge';
import { Property } from './property.model';

export class Brand {
  brandId?: string;
  name?: string;
  organizationId?: string;
  logoUrl?: string;
  inExile?: boolean;
  logoFileExtension?: string;
  fileAsBase64String?: string;
  properties?: Property[];
  badges?: Badge[];
}
