import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { Constants } from 'custom_node_modules/azure-msal-common-14.1.0';
import { OrganizationService } from 'src/app/services/organization.service';
import { propertySubTypes } from 'src/app/shared/app-constants/constants';
import {
  OrganizationUserVisibility,
  OrganizationVisibility,
  PropertyVisibility,
} from 'src/app/shared/models/OrganizationVisibility';
import { UpdateOrganizationVisibility } from 'src/app/shared/models/UpdateOrganizationVisibility';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-visibility',
  templateUrl: './visibility.component.html',
  styleUrls: ['./visibility.component.css'],
})
export class VisibilityComponent implements OnInit {
  form: FormGroup;
  properties: PropertyVisibility[] = [];
  orgusers: OrganizationUserVisibility[] = [];

  constructor(
    private organizationService: OrganizationService,
    private fb: FormBuilder,
    private notificationService: NotificationService
  ) {
    this.form = this.fb.group({
      visible: [null],
      foundationYearVisible: [null],
      totalFteVisible: [null],
      organizationTypeVisible: [null],
      badgesVisible: [null],
      brandBadgesVisible: [null],
      properties: this.fb.array([]),
      organizationUsers: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.organizationService
      .getOrganizationVisibility()
      .subscribe((visibility) => {
        const formValue = this.toFormValue(visibility);

        const propertiesControl = this.form.get('properties') as FormArray;
        for (const visible of formValue.properties) {
          propertiesControl.push(this.fb.control(visible));
        }

        const orgUsersControl = this.form.get('organizationUsers') as FormArray;
        for (const visible of formValue.organizationUsers) {
          orgUsersControl.push(this.fb.control(visible));
        }

        this.form.patchValue(formValue);

        this.properties = visibility.brands.flatMap((b) => b.properties);
        this.orgusers = visibility.organizationUsers;
      });
  }

  get formProperties() {
    return this.form.get('properties') as FormArray;
  }

  get formOrgUsers() {
    return this.form.get('organizationUsers') as FormArray;
  }

  toPropertyTypeIconUrl(id?: string) {
    if (!id) return null;
    switch (id) {
      case propertySubTypes.Facebook:
        return '/assets/icons/facebook-property.svg';
      case propertySubTypes.Instagram:
        return '/assets/icons/instagram-property.svg';
      case propertySubTypes.Youtube:
        return '/assets/icons/youtube-property.svg';
      case propertySubTypes.Website:
        return '/assets/icons/website-property.svg';
      default:
        throw new Error('Invalid property type');
    }
  }

  toFormValue(visibility: OrganizationVisibility) {
    return {
      visible: visibility.visible,
      foundationYearVisible: visibility.foundationYearVisible,
      totalFteVisible: visibility.totalFteVisible,
      organizationTypeVisible: visibility.organizationTypeVisible,
      badgesVisible: visibility.badgesVisible,
      brandBadgesVisible: visibility.brandBadgesVisible,
      properties: visibility.brands
        .flatMap((b) => b.properties)
        .map((p) => p.visible),
      organizationUsers: visibility.organizationUsers.map(
        (u) => u.visibleFromOrganization
      ),
    };
  }

  onSubmit() {
    const formValue = this.form.value;

    const body: UpdateOrganizationVisibility = {
      visible: formValue.visible,
      foundationYearVisible: formValue.foundationYearVisible,
      totalFteVisible: formValue.totalFteVisible,
      organizationTypeVisible: formValue.organizationTypeVisible,
      badgesVisible: formValue.badgesVisible,
      properties: this.properties.map((p, i) => ({
        propertyId: p.propertyId,
        visible: formValue.properties[i],
      })),
      users: this.orgusers.map((u, i) => ({
        organizationUserId: u.organizationUserId,
        visibleFromOrganization: formValue.organizationUsers[i],
      })),
      brandBadgesVisible: formValue.brandBadgesVisible,
    };

    this.organizationService.saveOrganizationVisibility(body).subscribe(() => {
      this.notificationService.showSuccessToast('Visibility updated');
      this.form.markAsPristine();
    });
  }

  getLabel(name: string, index?: number) {
    const val =
      index === undefined
        ? this.form.get(name)?.value
        : this.form.get(name)?.value[index];

    if (val) return 'Visibility.Visible';
    return 'Visibility.NotVisible';
  }
}
