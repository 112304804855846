import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, forkJoin, map, Observable, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { AppContext } from 'src/app/shared/context/AppContext';
import { OrganizationType } from 'src/app/shared/models/OrganizationType';
import { PropertySubType } from 'src/app/shared/models/PropertySubType';
import { Variable } from 'src/app/shared/models/Variable';
import { MasterDataService } from 'src/app/shared/services/master-data.service';
import { SaveToViewModalComponent } from '../save-to-view-modal/save-to-view-modal.component';
import { UserMediaView } from 'src/app/shared/models/UserMediaView';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { UsersavedviewService } from 'src/app/services/usersavedview.service';
import { FilterParams } from 'src/app/shared/models/FindMediaFilterParams';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
})
export class FilterComponent implements OnInit {
  languageId!: string;
  selectedViewId: string = '';
  propertySubTypes: PropertySubType[] = [];
  organizationTypes: OrganizationType[] = [];
  organizationAges: Variable[] = [];
  selectedOrganizationTypes: string[] = [];
  selectedPropertySubTypes: string[] = [];
  selectedOrganizationAges: string[] = [];
  selectedNoOfEmployee: string[] = [];
  sectionsState :{[key:string]:boolean} = { savedView: true, organizationType: true, propertiesType: true, noOfEmployeeType: true, orgAgeType: true };
  singleSaveView: UserMediaView | null = { id: '', filter: '', name: '', isDefault: false };
  
  @Input() SavedView: UserMediaView[] = [];
  @Output() filtersChanged = new EventEmitter<FilterParams>();
  @Output() reloadFilter = new EventEmitter<void>();

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private appContext: AppContext,
    private masterDataService: MasterDataService,
    private offcanvasService: NgbOffcanvas,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private userSavedViewService: UsersavedviewService,
  ) {}

  ngOnInit(): void {
    this.loadInitialData();
    this.applyDefaultViewFilters();
  }

  // Initialization
  private loadInitialData(): void {
      this.loadUserLanguageId().pipe(
        switchMap(() => forkJoin({
          properties: this.loadProperties(),
          organizationTypes: this.loadOrganizationTypes(),
          organizationAges: this.loadOrganizationAges()
        }))
      ).subscribe(({ properties, organizationTypes, organizationAges }) => {
        this.propertySubTypes = properties;
        this.organizationTypes = organizationTypes;
        this.organizationAges = organizationAges;
      });    
  }

  private loadUserLanguageId(): Observable<void> {
    return this.appContext.user.pipe(
      takeUntil(this._destroying$),
      tap(user => {
        this.languageId = user.languageId; 
      }),
      map(() => undefined) 
    )
  }

  private loadProperties(): Observable<any> {
    return this.masterDataService.getPropertySubTypes(this.languageId).pipe(
      takeUntil(this._destroying$)
    );
  }
  
  private loadOrganizationTypes(): Observable<any> {
    return this.masterDataService.getOrganizationTypes(this.languageId).pipe(
      takeUntil(this._destroying$)
    );
  }
  
  private loadOrganizationAges(): Observable<any> {
    return this.masterDataService.getOrganizationAgesList(this.languageId).pipe(
      takeUntil(this._destroying$)
    );
  }
  

  private applyDefaultViewFilters(): void {
    const defaultView = this.SavedView.find(view => view.isDefault);
    if (defaultView) {
      this.setSelectedFilters(JSON.parse(defaultView.filter) as FilterParams);
      this.selectedViewId = defaultView.id;
      this.singleSaveView = defaultView;
    }
  }

  // Filter Logic
  private setSelectedFilters(filter: FilterParams): void {
    this.selectedOrganizationTypes = filter.organizationTypes || [];
    this.selectedPropertySubTypes = filter.propertySubTypes || [];
    this.selectedOrganizationAges = filter.organizationAges || [];
    this.selectedNoOfEmployee = filter.noOfEmployee || [];
  }

  onCheckboxChange(event: any, selectionArray: string[]): void {
    const value = event.target.value;
    if (event.target.checked) {
      selectionArray.push(value);
    } else {
      selectionArray.splice(selectionArray.indexOf(value), 1)
    }
  }

  applyFilter(): void {
    this.closeFilterDrawer();
  }

  resetFilters(): void {
    this.selectedOrganizationTypes = [];
    this.selectedPropertySubTypes = [];
    this.selectedOrganizationAges = [];
    this.selectedNoOfEmployee = [];
    this.applyFilter();
  }

  getFilterData(): FilterParams {
    return {
      organizationTypes: this.selectedOrganizationTypes,
      propertySubTypes: this.selectedPropertySubTypes,
      organizationAges: this.selectedOrganizationAges,
      noOfEmployee: this.selectedNoOfEmployee,
      searchText: "",
      isFavorite : false
    };
  }

  // UI State
  toggleSection(section: string): void {
    this.sectionsState[section] = !this.sectionsState[section];
  }

  get isFilterParamsEmpty(): boolean {
    return !(this.selectedOrganizationTypes.length || this.selectedPropertySubTypes.length || this.selectedOrganizationAges.length || this.selectedNoOfEmployee.length);
  }

  get totalFilteredOrganizations(): number {
    return this.selectedOrganizationTypes.length + this.selectedPropertySubTypes.length + this.selectedOrganizationAges.length + this.selectedNoOfEmployee.length;
  }

  get isNotDefaultView(): boolean {
    return this.singleSaveView ? !this.singleSaveView.isDefault : false;
  }

  // Offcanvas and Modal Operations
  closeFilterDrawer(): void {
    this.offcanvasService.dismiss();
    this.filtersChanged.emit(this.getFilterData());
  }

  showCreateViewModal(): void {
    this.showSaveToViewModal('save');
  }

  showSaveToViewModal(action: string): void {
    const filterData = JSON.stringify(this.getFilterData());
    const modalRef = this.modalService.open(SaveToViewModalComponent, { centered: true });
    modalRef.componentInstance.action = action;
    modalRef.componentInstance.yesAction.subscribe(() => this.modalService.dismissAll());
    modalRef.componentInstance.viewName.subscribe((text: string) => {
     this.createFilterView({ name: text, filter: filterData, id: "", isDefault: false });
     this.applyFilter()
     this.modalService.dismissAll();
    });
    modalRef.componentInstance.renameAction.subscribe((text: any) => {
      this.renameFilterView(this.selectedViewId,{name : text,filter:filterData,id:this.selectedViewId, isDefault:false})
      this.modalService.dismissAll();
   });
   
  }

  showConfirmModal(action: string): void {
    const modalRef = this.modalService.open(ConfirmModalComponent, { centered: true });
    modalRef.componentInstance.title = "Confirm";
    modalRef.componentInstance.bodyMessage = "Are you sure you want to proceed?";
    modalRef.componentInstance.yesText = "Yes"
    modalRef.componentInstance.noText = "No"
    modalRef.componentInstance.yesAction.subscribe(() => {
      action === "delete" ? this.deleteSavedView() : this.makeDefault();
      this.modalService.dismissAll()
    });
  }

  // Saved View Management
  createFilterView(view: UserMediaView): void {
    this.userSavedViewService.createFilterView(view).subscribe({
      next: () => this.notificationService.showSuccessToast(this.translate.instant('FindMedia.SaveToViewSuccess')),
      error: () => this.notificationService.showErrorToast(this.translate.instant('FindMedia.Error'))
    });
  }

  renameFilterView(id: string, view: UserMediaView): void {
    this.userSavedViewService.renameSavedView(id, view).subscribe({
      next: () => {
        this.notificationService.showSuccessToast(this.translate.instant('FindMedia.RenameViewSuccess'));
        this.resetFilters();
      },
      error: () => this.notificationService.showErrorToast(this.translate.instant('FindMedia.Error'))
    });
  }

  deleteSavedView(): void {
    this.userSavedViewService.deleteSavedMediaView(this.selectedViewId).subscribe({
      next: () => {
        this.notificationService.showSuccessToast(this.translate.instant('FindMedia.ViewDeletedSuccess'));
        this.resetFilters();
      },
      error: () => this.notificationService.showErrorToast(this.translate.instant('FindMedia.Error'))
    });
  }

  makeDefault(): void {
    this.userSavedViewService.makeSavedViewAsDefault(this.selectedViewId).subscribe({
      next: () => {
        this.notificationService.showSuccessToast(this.translate.instant('FindMedia.SaveToViewDefaultSuccess'));
        this.resetFilters();
      },
      error: () => this.notificationService.showErrorToast(this.translate.instant('FindMedia.Error'))
    });
  }

  onSelectSavedView(event: Event): void {
    const target = event.target as HTMLSelectElement;
    this.selectedViewId = target.value;
    const selectedView = this.SavedView.find(view => view.id === this.selectedViewId);
    if (selectedView) {
      this.setSelectedFilters(JSON.parse(selectedView.filter) as FilterParams);
    }
  }
  getOrganizationTypeCount() : number{
    return this.selectedOrganizationTypes.length
  }
  getPropertyTypeCount() : number{
    return this.selectedPropertySubTypes.length
  }
  getNoOfEmployeeCount() : number{
    return this.selectedNoOfEmployee.length
  }
  getOrganizationAgeCount() : number{
    return this.selectedOrganizationAges.length
  }
}
