<div class="d-flex justify-content-between border-bottom align-items-center p-2">
  <h6 class="card-title pt-05">Facebook
    <span class="mx-auto pl-02">
      <img src="/assets/icons/info.svg" alt="Details of the google analytics propertoies" class="btn-icon"
        [ngbPopover]="popContent" /></span>
    <ng-template #popContent>{{'DataConnections.ThirdPartyTooltip' | translate}}
      {{'DataConnections.FacebookToolTip'|translate}}</ng-template>
  </h6>
  <img *ngIf="authorized && facebookPage && !reconnect" src="/assets/icons/success.svg" alt="Fetched Successfully">
  <img *ngIf="reconnect" src="/assets/icons/error.svg" [ngbPopover]="'DataConnections.MissingPermissions' | translate"
    alt="Missing permissions">
</div>
<img src="/assets/icons/fb.svg" alt="Facebook Analytics Logo" class="mx-auto h-45 p-3">
<div class="card-body" *ngIf="showAuthorization">
  <div class="row">
    <button type="button" *ngIf="!authorized" a href="#" class="col-10 mx-auto btn btn-primary mb-01"
      (click)="authorize()" translate>DataConnections.Step1Authorize</button>
    <button type="button" *ngIf="reconnect" a href="#" class="col-10 mx-auto btn btn-primary mb-01"
      (click)="authorize()" translate>DataConnections.Reconnect</button>
    <button type="button" *ngIf="authorized && !reconnect" a href="#" class="col-10 mx-auto btn btn-secondary mb-01"
      (click)="showRevokeDialog()">Revoke</button>
    <button type="button" *ngIf="!facebookPage && !reconnect" [disabled]="!authorized || ( authorized && reconnect)" a href="#"
      class="col-10 mx-auto btn btn-primary mb-01" (click)="selectPages()" translate>DataConnections.Step2SelectPage</button>
    <button type="button" *ngIf="authorized && facebookPage && !reconnect" [disabled]="!authorized || ( authorized && reconnect)" a href="#"
      class="col-10 mx-auto btn btn-primary mb-01" (click)="selectPages()" translate>DataConnections.ChangePage</button>
  </div>
  <div *ngIf="facebookPage" class="d-flex g-3 col-sm-12">
    <div class="fw-semibold pr-01" translate>DataConnections.Page</div>
    <div class="text-break">{{facebookPage}}</div>
  </div>
  <div *ngIf="reconnect" class="d-flex g-3 col-sm-12 pt-2">
    <div class="fw-semibold pr-01 invalid-input-message">
      <img src="/assets/icons/error-small.svg" class="pb-03"
        [ngbPopover]="'DataConnections.MissingPermissions' | translate" alt="Missing permissions" translate>
        DataConnections.MissingPermissions
    </div>
  </div>
  <div *ngIf="reconnect" class="d-flex g-3 col-sm-12">
    <span class="text-break" translate> DataConnections.TryReconnecting</span>
  </div>
</div>
<div class="card-body" *ngIf="!showAuthorization">
  <div class="clearfix">
    <span class="sr-only" translate>DataConnections.ProviderNotImplemented</span>
    <div class="spinner-border spinner-border-sm float-right" role="status">
    </div>
  </div>
</div>

<div #revokeFacebookModal class="modal fade show overlay" id="myModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" translate>DataConnections.RevokeConfirmDialogHeader</h4>
        <div type="button" class="btn-off" data-bs-dismiss="modal" aria-label="Close" (click)="closeDialog()"></div>
      </div>
      <div class="modal-body">
        <p class="pl-013 pt-4 modal-text fw-semibold" translate>ConfirmModal.ConfirmBody</p>
        <p class="p-3 modal-text" translate>DataConnections.RevokeMessage<br>DataConnections.RevokeWarning</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDialog()" translate>ConfirmModal.No</button>
        <button type="button" class="btn btn-primary" (click)="revoke()" translate>ConfirmModal.Yes</button>
      </div>
    </div>
  </div>
</div>
