import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataDashbordService } from 'src/app/services/datadashboard.service';
import { propertySubTypes } from 'src/app/shared/app-constants/constants';
import { SolutionProvider } from 'src/app/shared/models/SolutionProvider';
import { Property } from 'src/app/shared/models/property.model';

@Component({
  selector: 'app-socialmedia-properties',
  templateUrl: './socialmedia-properties.component.html',
  styleUrls: ['./socialmedia-properties.component.css']
})
export class SocialmediaPropertiesComponent implements OnInit,OnDestroy {
  @Input() properties!: Property[];
  @Input() organizationId!: string;

  fbSolutionProvider!: SolutionProvider;
  instaSolutionProvider!: SolutionProvider;
  ytSolutionProvider!: SolutionProvider;
  tiktokSolutionProvider!: SolutionProvider;

  facebookSubTypeId!: string;
  instagramSubTypeId!: string;
  tiktokSubTypeId!: string;
  youtubeSubTypeId!: string;

  solutionProviderSubscription?: Subscription;
  showProviders = false;

  constructor(private dataDashboardService: DataDashbordService) {
    this.facebookSubTypeId = propertySubTypes.Facebook;
    this.instagramSubTypeId = propertySubTypes.Instagram;
    this.tiktokSubTypeId = propertySubTypes.Tiktok;
    this.youtubeSubTypeId = propertySubTypes.Youtube;
  }

  ngOnDestroy(): void {
    this.solutionProviderSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.solutionProviderSubscription = this.dataDashboardService.solutionProvidersLoaded.subscribe(isloaded => {
      if (isloaded)
        this.showProviders = true;
    });
  }
}
