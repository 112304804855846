<form [formGroup]="organizationForm" (ngSubmit)="onSubmit()">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="switch organization" translate>SwitchOrganization.Title</h5>
            <div type="button" class="btn-off" data-coreui-dismiss="offcanvas" aria-label="Close" (click)="activeModal.close()"></div>
        </div>
        <div class="modal-body">
            <div class="row align-items-center">
                <div>
                    <select class="form-select" (change)="onSubmit()" formControlName="organizationId" id="switchOrganization" [ngClass]="organizationId?.invalid && (organizationId?.dirty || organizationId?.touched) ? 'invalid-input' : '' ">
                        <option *ngFor="let organization of userOrganizations" [value]="organization.organizationId">{{ organization.organizationName }}</option>
                    </select>
                    <span class="text-danger" *ngIf="organizationId?.invalid && organizationId?.touched && organizationId?.value == undefined">
                        {{ 'SwitchOrganization.OrganizationRequired' | translate }}
                    </span>
                </div>
            </div>
        </div>
        <div class="modal-footer">
        </div>
    </div>
</form>
