import { Injectable } from '@angular/core';
import { Roles, RoutePath, UserStatus } from '../app-constants/constants';
import { User } from '../models/User';
import { UserOrganization } from '../models/UserOrganization';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  isMenuOpen?: boolean;
  menuOpenSubject = new BehaviorSubject<boolean>(true);

  constructor() {}

  getUserProfileMenu(
    user: User,
    currentUserOrganization: UserOrganization | null
  ): MenuCategory[] {
    let menuCategories = this.getUserProfileMenuCategories();

    const userRoles: string[] = [];

    if (user.roleId && user.userStatusId === UserStatus.Active) {
      userRoles.push(user.roleId);
    }

    if (currentUserOrganization) {
      userRoles.push(currentUserOrganization.roleId);
    }

    for (const category of menuCategories) {
      category.menuItems = category.menuItems.filter((menuItem) => {
        const isMenuCategory = menuItem instanceof MenuCategory;
        const isMenuItem = menuItem instanceof MenuItem;

        if (isMenuCategory) {
          return true;
        }

        if (isMenuItem) {
          const isRolesEmpty = menuItem.roles.length === 0;
          const hasMatchingRole = userRoles.some((role) =>
            menuItem.roles.includes(role)
          );

          return isRolesEmpty || hasMatchingRole;
        }

        return false;
      });
    }
    menuCategories = menuCategories.filter((mc) => mc.menuItems.length > 0);

    return menuCategories;
  }

  getSideBarMenuItems(
    currentUserOrganization: UserOrganization | null
  ): MenuItem[] {
    const userRoles: string[] = [];
    currentUserOrganization && userRoles.push(currentUserOrganization.roleId);
    const menuItems = [
      new MenuItem(
        '',
        [Roles.ORGADMIN, Roles.ORGUSER],
        `/${RoutePath.CountryReport}`
      ),
      new MenuItem(
        '',
        [Roles.ORGADMIN, Roles.ORGUSER],
        `/${RoutePath.WebsiteReport}`
      ),
      new MenuItem(
        '',
        [Roles.ORGADMIN, Roles.ORGUSER],
        `/${RoutePath.SocialMediaReport}`
      ),
    ];

    return menuItems.filter((mi) =>
      userRoles.some((r) => mi.roles.includes(r))
    );
  }

  private getUserProfileMenuCategories(): MenuCategory[] {
    return [
      new MenuCategory(
        [
          new MenuItem(
            'Menu.Journal',
            [Roles.ORGADMIN],
            `/${RoutePath.Journal}`,
            'journal-icn.svg'
          ),
          new MenuItem(
            'Menu.ProfitAndLoss',
            [Roles.ORGADMIN],
            `/${RoutePath.ProfitLoss}`,
            'profit-loss-icn.svg'
          ), // TODO add correct route for profit and loss once ready
        ],
        'Menu.Financial',
        'financial-icn.svg'
      ),
      new MenuCategory(
        [
          new MenuItem(
            'Menu.Organization',
            [Roles.ORGADMIN],
            `/${RoutePath.Organization}`,
            'organization-icn.svg'
          ),
          new MenuItem(
            'Menu.BrandsProperties',
            [Roles.ORGADMIN],
            `/${RoutePath.Brands}`,
            'properties-icn.svg'
          ),

          new MenuCategory(
            [
              new MenuItem(
                'Menu.MySolutions',
                [Roles.ORGADMIN],
                `/${RoutePath.Manage}/${RoutePath.Solutions}`,
                'my-solutions.svg'
              ),
              new MenuItem(
                'Menu.MyBadges',
                [Roles.ORGADMIN],
                `/${RoutePath.Manage}/${RoutePath.Badges}`,
                'my-badges.svg'
              ),
              new MenuItem(
                'Menu.Visibility',
                [Roles.ORGADMIN],
                `/${RoutePath.Manage}/${RoutePath.Visibility}`,
                'visibility.svg'
              ),
              new MenuItem(
                'Menu.ApprovalCenter',
                [Roles.ORGADMIN],
                `/${RoutePath.Manage}/${RoutePath.ApprovalCenter}`,
                'approval-center.svg'
              ),
            ],
            'Menu.MVASolutions',
            'mva-solutions.svg'
          ),

          new MenuItem(
            'Menu.PropertyDataManagement',
            [Roles.ORGADMIN],
            `/${RoutePath.DataLinks}`,
            'data-links-icn.svg'
          ),
          new MenuItem(
            'Menu.Users',
            [Roles.ORGADMIN],
            `/${RoutePath.Users}`,
            'users-icn.svg'
          ),
          new MenuItem(
            'Menu.AdminMaintainsPartner',
            [Roles.MVAPARTNER],
            `/${RoutePath.TrustCenter}`,
            'partner-admin-icn.svg'
          ),
          new MenuItem(
            'Menu.PartnerMaintainUsers',
            [Roles.MVASYSADMIN],
            `/${RoutePath.Partners}`,
            'sys-admin-icn.svg'
          ),
          new MenuItem(
            'Menu.UserProfile',
            [],
            `/${RoutePath.UserProfile}`,
            'profile-icn.svg'
          ),
        ],
        'Menu.Manage',
        'manage-icn.svg'
      ),
    ];
  }
}

export class MenuCategory {
  constructor(
    public menuItems: (MenuItem | MenuCategory)[],
    public name: string,
    public icon?: string
  ) {}
}

export class MenuItem {
  constructor(
    public name: string,
    public roles: string[],
    public path: string,
    public icon?: string,
    public queryParams?: Record<string, string>
  ) {}
}
