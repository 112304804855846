import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { filter, map, mergeMap, Subject, takeUntil, tap } from 'rxjs';
import { SegmentService } from '../../../services/segment.service';
import { Report } from '../../../shared/app-constants/constants';
import { AppContext } from '../../../shared/context/AppContext';
import { Segment } from '../../../shared/models/Segment';
import { SegmentsContext } from '../../../shared/models/SegmentsContext';
import { UserOrganization } from '../../../shared/models/UserOrganization';
import { AddSegmentComponent } from '../add-segment/add-segment.component';

@Component({
  selector: 'app-select-segment',
  templateUrl: './select-segment.component.html',
  styleUrls: ['./select-segment.component.css']
})
export class SelectSegmentComponent implements OnInit, OnDestroy {
  segments: Segment[] = [];
  report!: Report;
  selectedSegmentId: string | null = null;
  userOrganization!: UserOrganization;
  private readonly _destroying$ = new Subject<void>();
  showError: boolean = false;

  constructor(
    private appContext: AppContext,
    public activeOffcanvas: NgbActiveOffcanvas,
    private offcanvasService: NgbOffcanvas,
    private segmentService: SegmentService
  ) { }

  ngOnInit() {
    this.appContext.organization.pipe(
      filter((organization): organization is UserOrganization => !!organization),
      tap((organization) => this.userOrganization = organization),
      mergeMap(() => this.appContext.segmentsContext.pipe(
        filter((segmentsContext): segmentsContext is SegmentsContext => !!segmentsContext),
      )),
      mergeMap((segmentsContext) => this.appContext.getSegment(segmentsContext.report).pipe(
        map((segmentId) => { return { segmentsContext, segmentId }; })
      )),
      takeUntil(this._destroying$)
    ).subscribe(({ segmentsContext, segmentId }) => {
      this.segments = segmentsContext.segments;
      this.report = segmentsContext.report;
      this.selectedSegmentId = segmentId;
    });
  }

  openSegment(segment: Segment) {
    this.segmentService.getSegment(segment.segmentId)
      .subscribe((segmentVariables) => {
        const offcanvasRef = this.offcanvasService.open(AddSegmentComponent, { ariaLabelledBy: 'addsegment-title', position: 'end', backdrop: 'static' });
        offcanvasRef.componentInstance.inputSegment = segmentVariables;
      });
  }

  applySegment() {
    if (this.selectedSegmentId) {
      this.showError = false;
      this.appContext.setSegment(this.report, this.selectedSegmentId);
      this.activeOffcanvas.dismiss();
    }
    else {
      this.showError = true;
    }
  }

  addSegment() {
    this.offcanvasService.open(AddSegmentComponent, { ariaLabelledBy: 'addsegment-title', position: 'end', backdrop: 'static' });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  trackBySegment(index: number, item: Segment) {
    return item.segmentId;
  }
}
