<form (submit)="onSubmit()" [formGroup]="addEntryForm">
  <div class="offcanvas offcanvas-end show">
    <div class="offcanvas-header" *ngIf="!financial">
      <h4 class="offcanvas-title" id="offcanvas-basic-title" translate>FinanceData.AddEntry.AddEntry</h4>
      <div type="button" class="btn-off" data-coreui-dismiss="offcanvas" aria-label="Close"
        (click)="activeOffcanvas.close()"></div>
    </div>
    <div class="offcanvas-header" *ngIf="financial">
      <h4 class="offcanvas-title" id="offcanvas-basic-title" translate>FinanceData.AddEntry.EditEntry</h4>
      <div type="button" class="btn-off" data-coreui-dismiss="offcanvas" aria-label="Close"
        (click)="activeOffcanvas.close()"></div>
    </div>
    <p translate class="border-bottom add-entry-text">FinanceData.AddEntry.AddEntryText</p>
    <div class="offcanvas-body delay-help-text">
      <div>
        <fieldset class="row mb-4">
          <legend class="col-form-label col-sm-4 fw-semibold" translate>FinanceData.AddEntry.FromDate<span
              class="text-danger required-field">*</span></legend>
          <div class="dp-hidden position-absolute">
            <input class="form-control dp-hidden" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'"
              (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden"
              [startDate]="startDate!" tabindex="-1" aria-label="From date">
            <ng-template #t let-date let-focused="focused">
              <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null">
                {{ date.day }}
              </span>
            </ng-template>
          </div>
          <div class="input-group journal-offcanvas">
            <input #dpStartDate class="form-control" placeholder="yyyy-mm-dd" name="dpStartDate"
              [value]="formatter.format(startDate)" (input)="startDate = validateInput(startDate, dpStartDate.value)" />
            <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()"
              type="button"></button>
          </div>
        </fieldset>
        <fieldset class="row mb-4">
          <legend class="col-form-label col-sm-4 fw-semibold" translate>FinanceData.AddEntry.ToDate<span
              class="text-danger required-field">*</span></legend>
          <div class="input-group journal-offcanvas">
            <input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate"
              [value]="formatter.format(endDate)" (input)="endDate = validateInput(endDate, dpToDate.value)"
              (blur)="!!isFocused" />
            <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()"
              type="button"></button>
          </div>
          <span class="col-sm-4"></span>
          <span class="text-danger col-sm-8" *ngIf="!endDate && isFocused">
            {{ 'FinanceData.AddEntry.ToDateRequired' | translate }}
          </span>
        </fieldset>
        <fieldset class="row mb-4">
          <legend class="col-form-label col-sm-4 fw-semibold" translate>FinanceData.AddEntry.Account<span
              class="text-danger required-field">*</span></legend>
          <div class="col-sm-8">
            <div class="form-check pt-2" *ngFor="let acount of accounts">
              <input type="radio" class="form-check-input" formControlName="account" [id]="acount.accountId"
                autocomplete="off" [value]="acount.accountId"
                [ngClass]="account?.invalid && (account?.dirty || account?.touched) ? 'invalid-input' : '' ">
              <label class="form-check-label" [for]="acount.accountId">{{ acount.name }}</label>
            </div>
          </div>
          <span class="col-sm-4"></span>
          <span class="text-danger col-sm-8" *ngIf="account?.invalid && account?.touched && account?.value == ''">
            {{ 'FinanceData.AddEntry.AccountRequired' | translate }}
          </span>
        </fieldset>
        <fieldset class="row mb-4" *ngIf="account?.value === revenue">
          <legend class="col-form-label col-sm-4 fw-semibold" translate>FinanceData.AddEntry.Type<span
              class="text-danger required-field">*</span></legend>
          <div class="col-sm-8">
            <select class="form-select" formControlName="revenueType"
              [attr.aria-label]="'UserManagement.EditUser.UserRoleAriaLabel' | translate"
              [ngClass]="revenueType?.invalid && (revenueType?.dirty || revenueType?.touched) ? 'invalid-input' : '' ">
              <option *ngFor="let type of revenueTypes" [value]="type.revenueTypeId">{{ type.name }}</option>
            </select>
            <span class="text-danger" *ngIf="revenueType?.invalid && (revenueType?.dirty || revenueType?.touched)">
              {{ 'FinanceData.AddEntry.TypeRequired' | translate }}
            </span>
          </div>
        </fieldset>
        <fieldset class="row mb-4">
          <legend class="col-form-label col-sm-4 fw-semibold" translate>
            FinanceData.AddEntry.Amount<span>({{baseCurrency}})</span><span class="text-danger required-field">*</span>
          </legend>
          <div class="col-sm-8">
            <input class="form-control" type="text" formControlName="amount" maxlength="15" aria-label="Amount"
              [ngClass]="amount?.invalid && (amount?.dirty || amount?.touched) ? 'invalid-input' : '' ">
            <span class="text-danger" *ngIf="amount?.invalid && amount?.touched && amount?.value == ''">
              {{ 'FinanceData.AddEntry.AmountRequired' | translate }}
            </span>
            <span class="text-danger" *ngIf="amount?.errors?.min || amount?.errors?.max || amount?.errors?.['pattern']">
              {{ 'FinanceData.AddEntry.AmountFormatInvalid' | translate }}
            </span>
          </div>
        </fieldset>
        <fieldset class="row mb-4">
          <legend class="col-form-label col-sm-4 fw-semibold" translate>FinanceData.AddEntry.Level<span
              class="text-danger required-field">*</span></legend>
          <div class="col-sm-8 text-break">
            <div class="form-check pt-2">
              <input class="form-check-input" type="radio" formControlName="level" [id]="organizationId"
                [value]="organizationId"
                [ngClass]="level?.invalid && (level?.dirty || level?.touched) ? 'invalid-input' : '' ">
              <label class="form-check-label" [for]="organizationId">
                <img [src]="'/assets/icons/organization-icn.svg'" [alt]="'Menu.Organization' | translate"  [title]="'Menu.Organization' | translate"/>
                {{ organizationName }}
              </label>
            </div>
            <div class="form-check pt-2" *ngFor="let org of organization">
              <div class="pb-2" *ngFor="let brand of org.brands">
                <input class="form-check-input" type="radio" formControlName="level" [id]="brand.brandId"
                  [value]="brand.brandId"
                  [ngClass]="level?.invalid && (level?.dirty || level?.touched) ? 'invalid-input' : '' ">
                <label class="form-check-label" [for]="brand.brandId" translate>
                  <img [src]="'/assets/icons/properties-icn.svg'" [alt]="'UserProfile.Brand' | translate" [title]="'UserProfile.Brand' | translate" />
                  {{ brand.brandName }}
                </label>
                <div class="pb-2 pl-4 pt-2" *ngFor="let prop of brand.properties">
                  <input class="form-check-input" type="radio" formControlName="level" [id]="prop.propertyId"
                    [value]="prop.propertyId"
                    [ngClass]="level?.invalid && (level?.dirty || level?.touched) ? 'invalid-input' : '' ">
                  <label class="form-check-label" [for]="prop.propertyId" translate>
                    <img [src]="getPropertyIcon(prop.propertySubTypeId)" [alt]="'UserProfile.Property' | translate" [title]="'UserProfile.Property' | translate"/>
                    {{ prop.propertyName }}
                  </label>
                </div>
              </div>
            </div>
            <span class="text-danger" *ngIf="level?.invalid && level?.touched && level?.value == ''">
              {{ 'FinanceData.AddEntry.LevelRequired' | translate }}
            </span>
          </div>
        </fieldset>
      </div>
      <div>
        <span translate>
          <img class="btn-info" src="../../../../assets/icons/info.svg">
          FinanceData.AddEntry.DelayHelptext
        </span>
      </div>
    </div>
    <div class="modal-footer p-3">
      <div class="text-danger row" *ngIf="vaidationErrors.length > 0">
        <span>{{ 'ErrorCodes.ADD_PARTNER_ERRORS' | translate }}</span>
        <ul>
          <li *ngFor="let error of vaidationErrors">{{error}}</li>
        </ul>
      </div>
      <div>
        <button type="button" class="btn btn-secondary mr-1" (click)="activeOffcanvas.dismiss('')"
          translate>FinanceData.AddEntry.Cancel</button>
        <button type="submit" class="btn btn-primary" translate>FinanceData.AddEntry.Save</button>
      </div>
    </div>
  </div>
</form>
