import { Injectable } from '@angular/core';
import { protectedResourceEndpoints } from '../shared/auth-config/auth-config';
import { ApiService } from '../shared/http/api.service';
import { Property } from '../shared/models/property.model';
import { ApiResponse } from '../shared/models/ApiResponse';
import { FilterParams } from '../shared/models/FindMediaFilterParams';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FindMediaProperty } from '../shared/models/FindMediaProperty';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PropertyManagementService {
  constructor(private apiservice: ApiService, private http: HttpClient) {}

  checkPropertyNameExists(
    propertyName: string,
    orgId: string,
    propertyId: string | undefined
  ) {
    return this.apiservice.getEntity<boolean>(
      protectedResourceEndpoints.property.url +
        'CheckPropertyNameExists' +
        '?propertyName=' +
        propertyName +
        '&orgId=' +
        orgId +
        '&propertyId=' +
        propertyId
    );
  }

  removeBadge(propertyId: string, badgeId: string): Observable<boolean> {
    return this.http
      .delete<ApiResponse<boolean>>(
        `${protectedResourceEndpoints.property.url}${propertyId}/badge/${badgeId}`
      )
      .pipe(map((res) => res.result));
  }

  checkPropertyUrlExists(propertyUrl: string, propertyId: string | undefined) {
    return this.apiservice.getEntity<boolean>(
      protectedResourceEndpoints.property.url +
        'CheckPropertyUrlExists' +
        '?propertyUrl=' +
        encodeURI(propertyUrl) +
        '&propertyId=' +
        propertyId
    );
  }

  addProperty(property: Property) {
    return this.apiservice.post<Property>(
      protectedResourceEndpoints.property.url,
      property
    );
  }

  getProperty(propertyId: string, organizationId: string) {
    return this.apiservice.getEntity<ApiResponse>(
      protectedResourceEndpoints.property.url +
        propertyId +
        '?organizationId=' +
        organizationId
    );
  }

  saveProperty(id: string, property: Property) {
    return this.apiservice.put<Property>(
      protectedResourceEndpoints.property.url + id,
      property
    );
  }

  deleteProperty(propertyId: string, organizationId: string) {
    return this.apiservice.delete(
      protectedResourceEndpoints.property.url +
        propertyId +
        '?organizationId=' +
        organizationId
    );
  }
  getMediaProperty(
    page: number,
    pageSize: number,
    filter: FilterParams = {
      organizationTypes: [],
      propertySubTypes: [],
      organizationAges: [],
      noOfEmployee: [],
      searchText: '',
      isFavorite: false,
    }
  ) {
    const params = this.buildQueryParams(page, pageSize, filter);
    return this.apiservice.getEntity<ApiResponse>(
      `${protectedResourceEndpoints.property.url}summary`,
      params
    );
  }
  favouritePropertyMedia(propertyId: string) {
    return this.apiservice.put<ApiResponse>(
      `${protectedResourceEndpoints.property.url}${propertyId}/toggle-favorite`,
      {}
    );
  }
  private buildQueryParams(
    page: number,
    pageSize: number,
    filter: FilterParams
  ): HttpParams {
    let params = new HttpParams()
      .set('pageNumber', page.toString())
      .set('pageSize', pageSize.toString());

    if (filter.searchText) {
      params = params.append('searchText', filter.searchText);
    }

    if (filter.isFavorite) {
      params = params.append('isFavorite', filter.isFavorite.toString());
    }

    if (filter.organizationTypes && filter.organizationTypes.length > 0) {
      filter.organizationTypes.forEach((type) => {
        if (type) params = params.append('organizationTypes', type);
      });
    }

    if (filter.organizationAges && filter.organizationAges.length > 0) {
      filter.organizationAges.forEach((orgAge) => {
        if (orgAge) params = params.append('organizationAges', orgAge);
      });
    }

    if (filter.noOfEmployee && filter.noOfEmployee.length > 0) {
      filter.noOfEmployee.forEach((employee) => {
        if (employee) params = params.append('noOfEmployee', employee);
      });
    }

    if (filter.propertySubTypes && filter.propertySubTypes.length > 0) {
      filter.propertySubTypes.forEach((property) => {
        if (property) params = params.append('propertySubTypes', property);
      });
    }

    return params;
  }
}
