<form (ngSubmit)="onSubmit()" [formGroup]="inviteUserForm">
  <div class="offcanvas offcanvas-end show">
    <div class="offcanvas-header border-bottom" *ngIf="organizationId">
      <h4 class="offcanvas-title" id="offcanvas-basic-title" translate>UserManagement.InviteUser.InviteUser</h4>
      <div type="button" class="btn-off" data-coreui-dismiss="offcanvas" aria-label="Close" (click)="activeOffcanvas.close()"></div>
    </div>
    <div class="offcanvas-header border-bottom" *ngIf="partner">
      <h4 class="offcanvas-title" id="offcanvas-basic-title" translate>UserManagement.InviteUser.InviteToPartnerOrganization</h4>
      <div type="button" class="btn-off" data-coreui-dismiss="offcanvas" aria-label="Close" (click)="activeOffcanvas.close()"></div>
    </div>
    <div class="offcanvas-body p-4">
      <fieldset class="row mb-4" *ngIf="partner">
        <legend class="col-form-label col-sm-4 fw-semibold" translate>UserManagement.InviteUser.PartnerOrganization</legend>
        <div class="col-sm-8">
          <input readonly class="form-control-plaintext" type="text" formControlName="partnerOrganization">
        </div>
      </fieldset>
      <fieldset class="row mb-4">
        <legend class="col-form-label col-sm-4 fw-semibold" translate>UserManagement.InviteUser.FirstName<span class="text-danger required-field">*</span></legend>
        <div class="col-sm-8">
          <input class="form-control" type="text" name="firstName"
                 [attr.aria-label]="'UserManagement.InviteUser.FirstName' | translate" maxlength="30" formControlName="firstName"
                 [ngClass]="firstName?.invalid && (firstName?.dirty || firstName?.touched) ? 'invalid-input' : ''">
          <div *ngIf="firstName?.invalid && (firstName?.dirty || firstName?.touched)">
            <span class="text-danger" *ngIf="firstName?.errors?.['required']">
              {{ 'UserManagement.InviteUser.FirstNameRequired' | translate }}
            </span>
          </div>
        </div>
      </fieldset>
      <fieldset class="row mb-4">
        <legend class="col-form-label col-sm-4 fw-semibold" translate>UserManagement.InviteUser.LastName<span class="text-danger required-field">*</span></legend>
        <div class="col-sm-8">
          <input class="form-control" type="text" name="lastName" [attr.aria-label]="'UserManagement.InviteUser.LastName' | translate"
                 maxlength="30" formControlName="lastName"
                 [ngClass]="lastName?.invalid && (lastName?.dirty || lastName?.touched) ? 'invalid-input' : '' ">
          <div *ngIf="lastName?.invalid && (lastName?.dirty || lastName?.touched)">
            <span class="text-danger" *ngIf="lastName?.errors?.['required']">
              {{ 'UserManagement.InviteUser.LastNameRequired' | translate }}
            </span>
          </div>
        </div>
      </fieldset>
      <fieldset class="row mb-4">
        <legend class="col-form-label col-sm-4 fw-semibold" translate>UserManagement.InviteUser.Email<span class="text-danger required-field">*</span></legend>
        <div class="col-sm-8">
          <input class="form-control" type="email" name="email" [attr.aria-label]="'UserManagement.InviteUser.EmailAreaLabel' | translate" maxlength="50"
                 formControlName="email"
                 [ngClass]="email?.invalid && (email?.dirty || email?.touched) ? 'invalid-input' : ''">
          <div *ngIf="email?.invalid && (email?.dirty || email?.touched)">
            <span class="text-danger" *ngIf="email?.errors?.['required']">
              {{ 'UserManagement.InviteUser.EmailRequired' | translate }}
            </span>
            <span class="text-danger" *ngIf="email?.errors?.['email']">
              {{ 'UserManagement.InviteUser.EmailInvalid' | translate }}
            </span>
          </div>
        </div>
      </fieldset>
      <fieldset class="row mb-4">
        <legend class="col-form-label col-sm-4 fw-semibold" translate>UserManagement.InviteUser.Language<span class="text-danger required-field">*</span></legend>
        <div class="col-sm-8">
            <select class="form-select" formControlName="languageId" name="language" id="language"
                [ngClass]="languageId?.invalid && (languageId?.dirty || languageId?.touched) ? 'invalid-input' : '' ">
                <option [ngValue]="undefined">{{ 'UserManagement.InviteUser.SelectLanguage' | translate }}</option>
                <option *ngFor="let language of languages"
                    [value]="language.languageId">{{ language.name }}</option>
            </select>
            <span class="text-danger"
                *ngIf="languageId?.invalid && languageId?.touched && languageId?.value == undefined">
                {{ 'UserManagement.InviteUser.LanguageRequired' | translate }}
            </span>
        </div>
      </fieldset>
      <div class="row mb-4" *ngIf="organizationId">
        <legend class="col-form-label col-sm-4 fw-semibold" translate>UserManagement.InviteUser.Role<span class="text-danger required-field">*</span></legend>
        <div class="col-sm-8">
          <select class="form-select" name="roleId" [attr.aria-label]="'UserManagement.InviteUser.RoleAreaLabel'" formControlName="roleId">
            <option selected value="" translate>UserManagement.InviteUser.SelectRole</option>
            <option *ngFor="let role of roles" [value]="role.roleId">{{ role.name }}</option>
          </select>
          <div *ngIf="roleId?.invalid && (roleId?.dirty || roleId?.touched)">
              <span class="text-danger" *ngIf="roleId?.errors?.['required']">
                {{ 'UserManagement.InviteUser.RoleRequired' | translate }}
              </span>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer p-3">
      <div class="text-danger row" *ngIf="vaidationErrors.length > 0">
        <span>{{ 'ErrorCodes.INVITE_USER_ERRORS' | translate }}</span>
        <ul>
          <li *ngFor="let error of vaidationErrors">{{ error }}</li>
        </ul>
      </div>
      <div>
        <button type="button" class="btn btn-secondary mr-1" (click)="activeOffcanvas.close()" translate>UserManagement.InviteUser.Cancel</button>
        <button type="submit" class="btn btn-primary" translate>UserManagement.InviteUser.Save</button>
      </div>
    </div>
  </div>
</form>
