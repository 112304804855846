import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpToDateBuildService } from '../../../services/up-to-date.service';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-body-container',
  templateUrl: './body-container.component.html',
  styleUrls: ['./body-container.component.css']
})
export class BodyContainerComponent implements OnInit, OnDestroy  {

  public buildIsUpToDate = true;
  public isOpen = false;
  private readonly _destroying$ = new Subject<void>();
  private readonly _modalDestroying$ = new Subject<void>();

  constructor(private upToDateService: UpToDateBuildService, private modalService: NgbModal) {
  }
  
  ngOnInit(): void {
    this.upToDateService.buildIsUpToDate.pipe(takeUntil(this._destroying$)).subscribe(buildIsUpToDate => {
      this.buildIsUpToDate = buildIsUpToDate;
      if (!buildIsUpToDate && !this.isOpen) {
        const modalRef = this.modalService.open(ConfirmModalComponent, { centered: true });
        this.isOpen = true;
        modalRef.componentInstance.title = 'Reload.Title';
        modalRef.componentInstance.bodyMessage = 'Reload.BodyMessage';
        modalRef.componentInstance.noText = 'ConfirmModal.Cancel';
        modalRef.componentInstance.yesText = 'Reload.RefreshButton';
        modalRef.componentInstance.yesAction.pipe(takeUntil(this._modalDestroying$)).subscribe(() => {
          window.location.reload();
          this.isOpen = false;
        });
        modalRef.result.then((dismissReason) => {
          this.isOpen = false;
          this.modalDestroy();
        },
          (reason) => {
            this.isOpen = false;
            this.modalDestroy();
          }
        );
      }
    });
  }

  modalDestroy(): void {
    this._modalDestroying$.next();
    this._modalDestroying$.complete();
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
