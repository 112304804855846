import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { PartnerService } from 'src/app/services/partner.service';
import { UserStatus, PatternValidator } from 'src/app/shared/app-constants/constants';
import { AppContext } from 'src/app/shared/context/AppContext';
import { FlatPartner } from 'src/app/shared/models/FlatPartner';
import { PartnerUser } from 'src/app/shared/models/PartnerUser';
import { User } from 'src/app/shared/models/User';
import { MasterDataService } from 'src/app/shared/services/master-data.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-edit-partner-user',
  templateUrl: './edit-partner-user.component.html',
  styleUrls: ['./edit-partner-user.component.css']
})
export class EditPartnerUserComponent implements OnInit, OnDestroy {

  @Input() params!: ICellRendererParams<any, any, any>;
  @Input() user!: User;
  private readonly _destroying$ = new Subject<void>();
  partner!: FlatPartner;
  userStatus = UserStatus;
  userStatusConstant = [this.userStatus.Active, this.userStatus.Inactive];
  editPartnerUserForm!: FormGroup;

  constructor(private appContext: AppContext,
              private masterDataService: MasterDataService,
              private partnerService: PartnerService,
              private notificationService: NotificationService,
              public activeOffcanvas: NgbActiveOffcanvas,
              private translate: TranslateService) {}

  get partnerName() { return this.editPartnerUserForm.get('partnerName'); }
  get firstName() { return this.editPartnerUserForm.get('firstName'); }
  get lastName() { return this.editPartnerUserForm.get('lastName'); }
  get email() { return this.editPartnerUserForm.get('email'); }
  get userStatusId() { return this.editPartnerUserForm.get('userStatusId'); }

  ngOnInit(): void {
    this.partner = this.params.data;

    this.editPartnerUserForm = new FormGroup({
      partnerName: new FormControl(this.partner!.partnerName),
      firstName: new FormControl(this.user.firstName, [Validators.required]),
      lastName: new FormControl(this.user.lastName, [Validators.required]),
      email: new FormControl(this.user.email),
      userStatusId: new FormControl(this.user.userStatusId)
    })
  }

  onSubmit() {
    console.log(this.editPartnerUserForm.value);
    this.editPartnerUserForm.markAllAsTouched();

    if (this.editPartnerUserForm.valid) {
      this.partnerService.updatePartnerUser(this.editPartnerUserForm.value as PartnerUser, this.partner.partnerId, this.user.userId)
        .subscribe(() => {
          this.notificationService.showSuccessToast(this.translate.instant('MvaSystemAdmin.EditPartnerUser.PartnerUserUpdated'));
          this.params.context.partnersGridComponent.refreshGrid();
          this.activeOffcanvas.close();
        });
    }
  }

  onDelete() {
    this.partnerService.deletePartnerUser(this.user.userId)
      .subscribe(() => {
        this.notificationService.showSuccessToast(this.translate.instant('MvaSystemAdmin.EditPartnerUser.PartnerUserDeleted'));
        this.params.context.partnersGridComponent.refreshGrid();
        this.activeOffcanvas.close();
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
