import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Brand } from 'src/app/shared/models/brand.model';
import { BrandManagementService } from '../../../services/brandmanagement.service';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AppContext } from 'src/app/shared/context/AppContext';
import { Subject } from 'rxjs';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AddEditPropertyComponent } from '../add-edit-property/add-edit-property.component';
import { PropertySaveEventData } from 'src/app/shared/models/PropertySaveEvent';
import { NgForm } from '@angular/forms';
import { propertySubTypes } from 'src/app/shared/app-constants/constants';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-add-brand',
  templateUrl: './add-edit-brand.component.html',
  styleUrls: ['./add-edit-brand.component.css'],
})
export class AddEditBrandComponent implements OnInit, OnDestroy {
  @ViewChild('app-add-edit-property') addEditProeprtyControl:
    | AddEditPropertyComponent
    | undefined;
  @ViewChild('brandForm') ngForm: NgForm | null | undefined;
  @ViewChild('deleteBrandModal') deleteBrandModal: ElementRef | undefined;
  private readonly _destroying$ = new Subject<void>();
  brandId!: string;
  logoUrl?: string | ArrayBuffer | null | undefined;
  brand!: Brand;
  orgaanizationName?: string;
  orgaanisationId!: string;
  logoFileErrors: string[] = [];
  allowedLogoFileTypes: string[] = ['image/jpeg', 'image/png'];
  selectedPropertyId!: string;

  vaidationErrors: string[] = [];
  brandNameExistsError = false;

  constructor(
    private userService: UserService,
    private bransService: BrandManagementService,
    private appContext: AppContext,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private offcanvasService: NgbOffcanvas,
    private notificationService: NotificationService,
    private modalService: NgbModal
  ) {}

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  ngOnInit(): void {
    this.brand = new Brand();
    this.route.queryParamMap.subscribe((params) => {
      if (params.get('id') != null) this.brandId = params.get('id') as string;
    });

    this.appContext.organization
      .pipe(takeUntil(this._destroying$))
      .subscribe((org) => {
        if (
          this.orgaanisationId &&
          this.orgaanisationId != org?.organizationId
        ) {
          this.router.navigateByUrl('/brands');
          return;
        }

        this.orgaanizationName = org?.organizationName;
        this.orgaanisationId = org!.organizationId;
        if (this.brandId) {
          this.bransService
            .getBrand(this.brandId, org?.organizationId ?? '')
            .subscribe({
              next: (response) => {
                if (response.isSuccess && response.result) {
                  this.brand = response.result;
                  this.logoUrl = this.brand.logoUrl;
                }
              },
              error: (error) => {
                if (!error.error.isSuccess) {
                  if (error.status == '404') {
                    let errorMessage: string = '';
                    errorMessage = this.translate.instant(
                      'ErrorCodes.' + error.error.errorCodes[0]
                    );
                    this.notificationService.showErrorToast(errorMessage);
                    this.router.navigateByUrl('/brands');
                  }
                }
              },
            });
        } else {
          this.brand.inExile = false;
          this.brand.organizationId = this.orgaanisationId;
        }
      });
  }

  onDeleteBadge(badgeId: string) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.title = 'Brand.DeleteBadgeTitle';
    modalRef.componentInstance.bodyMessage = 'Brand.DeleteBadgeBody';
    modalRef.componentInstance.yesText = 'Brand.DeleteBadgeYes';
    modalRef.componentInstance.noText = 'Brand.DeleteBadgeNo';

    modalRef.componentInstance.yesAction
      .pipe(
        switchMap(() => this.bransService.removeBadge(this.brandId, badgeId))
      )
      .subscribe(() => {
        this.brand.badges = this.brand.badges?.filter(
          (x) => x.badgeId != badgeId
        );
        this.notificationService.showSuccessToast('Badge Removed');
        this.modalService.dismissAll();
      });
  }

  onlogoSelect(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.logoFileErrors = [];

      this.brand.logoFileExtension = event.target.files[0].type.split('/')[1];
      if (!this.allowedLogoFileTypes.includes(event.target.files[0].type)) {
        this.logoFileErrors.push(
          this.translate.instant('Brand.SupportedLogoFile')
        );
      }
      if (event.target.files[0].size > 2000000) {
        this.logoFileErrors.push(this.translate.instant('Brand.LogoFileSize'));
      }

      if (this.logoFileErrors.length > 0) {
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.logoUrl = event.target?.result;
        this.brand.logoUrl = this.logoUrl as string;
        this.brand.fileAsBase64String = reader?.result?.toString();
      };
    }
  }

  onSave() {
    if (this.ngForm && this.ngForm.controls) {
      this.markAllControlsAsTouched();
    }
    if (this.ngForm?.valid) {
      if (!this.brandId) {
        this.bransService.addBrand(this.brand).subscribe({
          next: (response) => {
            if ((response as ApiResponse).isSuccess) {
              this.notificationService.showSuccessToast(
                this.translate.instant('Brand.BrandCreated')
              );
              this.router.navigateByUrl('/brands');
            }
          },
          error: (error) => {
            if (!error.error.isSuccess) {
              if (error.status == '400') {
                error.error.errorCodes.forEach((errorCode: any) => {
                  this.vaidationErrors.push(
                    this.translate.instant('ErrorCodes.' + errorCode)
                  );
                  if (errorCode === 'BRAND_NAME_ALREADY_EXISTS') {
                    this.brandNameExistsError = true;
                  }
                });
              } else if (error.status == '404') {
                let errorMessage: string = '';
                errorMessage = this.translate.instant(
                  'ErrorCodes.' + error.error.errorCode
                );
                this.notificationService.showErrorToast(errorMessage);
              }
            }
          },
        });
      } else {
        this.bransService.saveBrand(this.brandId, this.brand).subscribe({
          next: (response) => {
            if ((response as ApiResponse).isSuccess) {
              let brandName = ((response as ApiResponse).result as Brand).name;
              this.notificationService.showSuccessToast(
                this.translate.instant('Brand.BrandSaved')
              );
              this.router.navigateByUrl('/brands');
            }
          },
          error: (error) => {
            if (!error.error.isSuccess) {
              if (error.status == '400') {
                error.error.errorCodes.forEach((errorCode: any) => {
                  this.vaidationErrors.push(
                    this.translate.instant('ErrorCodes.' + errorCode)
                  );
                  if (errorCode === 'BRAND_NAME_ALREADY_EXISTS') {
                    this.brandNameExistsError = true;
                  }
                });
              } else if (error.status == '404') {
                let errorMessage: string = '';
                errorMessage = this.translate.instant(
                  'ErrorCodes.' + error.error.errorCode
                );
                this.notificationService.showErrorToast(errorMessage);
              }
            }
          },
        });
      }
    }
  }

  addEditProperty(event: any, addEditPropertycontent: any) {
    if (event.target.id === 'addPropertyButton') {
      this.selectedPropertyId = '';
    } else {
      this.selectedPropertyId = event.target.id;
    }

    this.offcanvasService.open(addEditPropertycontent, {
      ariaLabelledBy: 'offcanvas-basic-title',
      position: 'end',
      backdrop: 'static',
    });
  }

  onPropertySaved(propertySavedEventData: PropertySaveEventData) {
    if (propertySavedEventData.isCreateOrgUser) {
      this.offcanvasService.dismiss();
    } else if (propertySavedEventData.isCreate) {
      this.brand.properties?.push(propertySavedEventData.property);
    } else if (propertySavedEventData.isDelete) {
      let indexToDelete = this.brand.properties?.findIndex(
        (x) =>
          x.propertyId.toLowerCase() ===
          propertySavedEventData.property.propertyId.toLowerCase()
      );

      if (indexToDelete != undefined && this.brand?.properties) {
        if (this.brand?.properties[indexToDelete]) {
          this.brand.properties.splice(indexToDelete, 1);
        }
      }
    } else {
      let indexToUpdate = this.brand.properties?.findIndex(
        (x) =>
          x.propertyId.toLowerCase() ===
          propertySavedEventData.property.propertyId.toLowerCase()
      );

      if (indexToUpdate != undefined && this.brand?.properties) {
        if (this.brand?.properties[indexToUpdate]) {
          this.brand.properties[indexToUpdate] =
            propertySavedEventData.property;
        }
      }
    }
    this.offcanvasService.dismiss();
  }

  onPropertySaveCancelled() {
    this.offcanvasService.dismiss();
  }
  private markAllControlsAsTouched(): void {
    const formControls = this.ngForm?.controls ?? {};
    Object.values(formControls).forEach((control) => {
      control.markAsTouched();
    });
  }

  onDelete() {
    this.bransService
      .deleteBrand(this.brand?.brandId!, this.orgaanisationId)
      .subscribe({
        next: (response) => {
          if (response.isSuccess) {
            this.notificationService.showSuccessToast(
              'Brand deleted successfully.'
            );
            this.router.navigateByUrl('/brands');
          }
        },
        error: (error) => {
          if (!error.error.isSuccess) {
            if (error.status == '404') {
              let errorMessage: string = '';
              errorMessage = this.translate.instant(
                'ErrorCodes.' + error.error.errorCodes[0]
              );
              this.notificationService.showErrorToast(errorMessage);
            }
          }
        },
      });

    this.closeDialog();
  }

  showDeleteDialog() {
    this.deleteBrandModal!.nativeElement.style.display = 'block';
  }

  closeDialog() {
    this.deleteBrandModal!.nativeElement.style.display = 'none';
  }

  getPropertyIcon(propertySubTypeId: string) {
    switch (propertySubTypeId) {
      case propertySubTypes.Facebook:
        return '../../../../assets/icons/fb-icn.svg';
      case propertySubTypes.Instagram:
        return '../../../../assets/icons/insta-icn.svg';
      case propertySubTypes.Youtube:
        return '../../../../assets/icons/yt-icn.svg';
      default:
        return '../../../../assets/icons/website-icn.svg';
    }
  }
}
