import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { filter, mergeMap, Subject, switchMap, takeUntil } from 'rxjs';
import { Organization } from 'src/app/shared/models/Organization';
import { OrganizationService } from 'src/app/services/organization.service';
import { AppContext } from '../../shared/context/AppContext';
import { OrganizationForEdit } from 'src/app/shared/models/OrganizationForEdit';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserOrganization } from '../../shared/models/UserOrganization';
import { RoutePath } from 'src/app/shared/app-constants/constants';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { Base64File } from '../../shared/models/Base64File';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css'],
})
export class OrganizationComponent implements OnInit, OnDestroy {
  @ViewChild('myModal') myModal: ElementRef | undefined;
  @ViewChild('organizationForm') ngForm: NgForm | null | undefined;
  maxFteInput: number = 8;
  numberInputPattern: RegExp = /^[0-9]*$/;
  organizationForEdit: OrganizationForEdit | null = null;
  validYear: boolean = false;
  rating: number[] = [1, 2, 3, 4, 5];
  currentYear: number = new Date().getFullYear();
  param = { value: this.currentYear };
  emptyGuid: string = '00000000-0000-0000-0000-000000000000';

  primaryImageErrors: string[] = [];

  constructor(
    private organizationService: OrganizationService,
    private appContext: AppContext,
    private notificationService: NotificationService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  private readonly _destroying$ = new Subject<void>();

  ngOnInit() {
    this.appContext.organization
      .pipe(
        filter(
          (organization): organization is UserOrganization => !!organization
        ),
        mergeMap((organization) =>
          this.organizationService.getOrganizationForEdit(
            organization.organizationId
          )
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((organizationForEdit) => {
        this.organizationForEdit = organizationForEdit;
      });
  }

  onFoundationYearBlur() {
    const foundationYear =
      this.organizationForEdit!.organization.foundationYear;
    if (foundationYear! >= 1600 && foundationYear! <= this.currentYear) {
      this.validYear = false;
    } else {
      this.validYear = true;
    }
  }

  onPrimaryImageSelect(event: any) {
    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    this.primaryImageErrors = [];

    if (event.target.files[0].size > 2_000_000) {
      this.primaryImageErrors.push('Common.SupportedImageFileSize');
    }

    if (this.primaryImageErrors.length > 0) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    var filename = event.target.files[0].name;
    var extension = filename.split('.').pop();

    reader.onload = () => {
      if (reader?.result === null || !this.organizationForEdit) return;

      this.organizationForEdit.organization.primaryImageUrl =
        reader?.result?.toString();
      this.organizationForEdit.organization.primaryImageAsBase64 =
        new Base64File(reader?.result?.toString() ?? '', extension);
    };
  }

  onSubmit(orgData: Organization) {
    if (this.ngForm && this.ngForm.controls) {
      this.markAllControlsAsTouched();
    }

    if (+orgData.femaleFte! > +orgData.totalFte!) {
      this.ngForm?.controls.femaleFte.setErrors({ invalid: true });
    }
    if (+orgData.customerServiceRoleFte! > +orgData.totalFte!) {
      this.ngForm?.controls.customerServiceRoleFte.setErrors({ invalid: true });
    }
    if (
      !(
        orgData.foundationYear! >= 1600 &&
        orgData.foundationYear! <= this.currentYear
      ) &&
      orgData.foundationYear != undefined &&
      +orgData.foundationYear! != 0
    ) {
      this.ngForm?.controls.foundationYear.setErrors({ invalid: true });
    }

    orgData.primaryImageAsBase64 =
      this.organizationForEdit!.organization.primaryImageAsBase64;

    if (this.ngForm?.valid) {
      this.organizationService
        .saveOrganization(
          this.organizationForEdit!.organization.organizationId,
          orgData
        )
        .subscribe({
          next: (response) => {
            if (response.isSuccess) {
              this.notificationService.showSuccessToast(
                'Changes saved successfully.'
              );
            }
          },
          error: () => {},
        });
    }
  }

  onCancel() {
    this.router.navigateByUrl('/');
  }

  onDelete() {
    this.organizationService
      .deleteOrganization(this.organizationForEdit!.organization.organizationId)
      .subscribe({
        next: (response) => {
          if (response.isSuccess) {
            this.notificationService.showSuccessToast(
              'Organization deleted successfully.'
            );
            this.myModal!.nativeElement.style.display = 'none';
            this.organizationForEdit = null;
            window.location.href = RoutePath.Empty;
          }
        },
        error: () => {},
      });
  }

  onDeleteBadge(badgeId: string) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.title = 'Organization.DeleteBadgeTitle';
    modalRef.componentInstance.bodyMessage = 'Organization.DeleteBadgeBody';
    modalRef.componentInstance.yesText = 'Organization.DeleteBadgeYes';
    modalRef.componentInstance.noText = 'Organization.DeleteBadgeNo';

    modalRef.componentInstance.yesAction
      .pipe(switchMap(() => this.organizationService.removeBadge(badgeId)))
      .subscribe(() => {
        this.notificationService.showSuccessToast('Badge Removed');
        this.modalService.dismissAll();
      });
  }

  onDeleteDialog() {
    this.myModal!.nativeElement.style.display = 'block';
  }

  closeDialog() {
    this.myModal!.nativeElement.style.display = 'none';
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
  private markAllControlsAsTouched(): void {
    const formControls = this.ngForm?.controls ?? {};
    Object.values(formControls).forEach((control) => {
      control.markAsTouched();
    });
  }
}
