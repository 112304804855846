import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { AppConfig } from "../models/AppConfig";

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private configFileUrl = 'assets/configs/config.json';

  public appConfig: AppConfig = new AppConfig();
  public appConfig$: Observable<AppConfig> | null = null;

  private httpClient!: HttpClient;
  constructor(private readonly httpHandler: HttpBackend) {
    this.httpClient = new HttpClient(httpHandler);
  }

  init(): Observable<AppConfig> {
    if (this.appConfig$ == null) {
      const options = {
        headers: new HttpHeaders({ 'content-type': 'application/json', 'skip-spinner': 'true' }),
      };
      this.appConfig$ = this.httpClient.get<AppConfig>(this.configFileUrl, options).pipe(
        map(response => Object.assign(this.appConfig, response))
      );
    }
    return this.appConfig$;
  }

  loadConfig(): Observable<AppConfig> {
    this.appConfig$ = null;
    return this.init();
  }

}

