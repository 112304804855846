import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { protectedResourceEndpoints } from '../shared/auth-config/auth-config';
import { AddEntry } from '../shared/models/AddEntry';
import { FlatFinancial } from '../shared/models/FlatFinancial';

@Injectable({
  providedIn: 'root'
})
export class FinancialService {

  constructor(private http: HttpClient) { }

  private _financialUrl = protectedResourceEndpoints.financial.url;

  getFinancialData(orgId: string) {
    return this.http.get<FlatFinancial[]>(`${this._financialUrl}${orgId}`)
  }

  addEntry(addEntry: AddEntry, orgId: string) {
    return this.http.post(`${this._financialUrl}addentry/${orgId}`, addEntry);
  }

  updateEntry(addEntry: AddEntry, financialId: string, orgId: string) {
    return this.http.put(`${this._financialUrl}${financialId}/updateentry?organizationId=${orgId}`, addEntry)
  }

  deleteEntry(financialId: string, orgId: string) {
    return this.http.delete(`${this._financialUrl}${financialId}/deleteentry?organizationId=${orgId}`);
  }
}
