import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { BadgeService } from 'src/app/services/badge.service';
import { RoutePath } from 'src/app/shared/app-constants/constants';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { Badge } from 'src/app/shared/models/Badge';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-manage-badge-detail',
  templateUrl: './manage-badge-detail.component.html',
  styleUrls: ['./manage-badge-detail.component.css']
})
export class ManageBadgeDetailComponent implements OnInit, OnDestroy {
  badge?: Badge
  private readonly _destroying$ = new Subject<void>();
  readonly BADGE_PATH = `/${RoutePath.Manage}/${RoutePath.Badges}`;
  constructor(
    private badgeService: BadgeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private modalService: NgbModal,
    private notificationService: NotificationService
  ) { }
  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        switchMap((params) =>
          this.loadData(params.id)
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((badge) => {
        this.badge = badge.result
      });
  }
  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
  loadData(id: string): Observable<ApiResponse<Badge>> {
    return this.badgeService.getManageBadge(id)
  }
  editBadge(badgeId: string) {
    this.router.navigate([`${this.BADGE_PATH}/edit/${badgeId}`]);
  }

  onDeleteBadge(badgeId: string) {
    this.showConfirmModal(
      this.translate.instant('Badge.Confirm'),
      this.translate.instant('Common.DeleteWarning')
    ).then(() => {
      this.badgeService
        .deleteBadge(badgeId)
        .subscribe(() => {
          this.notificationService.showSuccessToast(
            this.translate.instant('Common.Deleted')
          );
          this.router.navigate([`${this.BADGE_PATH}/edit/${badgeId}`]);
        });
    });
  }
  showConfirmModal(title: string, message: string) {
    return new Promise<void>((resolve) => {
      this.modalService.dismissAll();
      const modalRef = this.modalService.open(ConfirmModalComponent, {
        centered: true,
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.bodyMessage = message;
      modalRef.componentInstance.yesText =
        this.translate.instant('Common.ConfirmYes');
      modalRef.componentInstance.noText =
        this.translate.instant('Common.ConfirmNo');
      modalRef.componentInstance.yesAction.subscribe((text: any) => {
        this.modalService.dismissAll();
        resolve();
      });
    });
  }
}

