import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { ShepherdService } from "angular-shepherd";
import { TranslateService } from '@ngx-translate/core';
import Step from 'shepherd.js/src/types/step';
import Shepherd from 'shepherd.js';
import { User } from "../models/User";
import { UserOrganization } from "../models/UserOrganization";
import { Roles } from "../app-constants/constants";


@Injectable({
  providedIn: 'root'
})
export class ShepherdDataService {

  constructor(
    private shepherdService: ShepherdService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
  ) { }
  addProgressToFooter() {
    const currentStep = Shepherd.activeTour?.getCurrentStep();
    const currentStepElement = currentStep?.getElement();
    const footer = currentStepElement?.querySelector('.shepherd-footer');
    const progress = document.createElement('span') as HTMLSpanElement;
    progress.className = 'shepherd-progress';
    const totalSteps = Shepherd.activeTour?.steps.length ?? 0;
    if (currentStep !== undefined && currentStep !== null) {
      const stepIndex = Shepherd.activeTour?.steps.indexOf(currentStep);
      if (stepIndex !== undefined && stepIndex !== -1 && totalSteps !== undefined) {
        const progressText = `${stepIndex + 1}/${totalSteps}`;
        progress.innerText = progressText;
      } else {
        progress.innerText = '–';
      }
    } else {
      progress.innerText = '–';
    }
    const buttons = footer?.querySelectorAll('.shepherd-button');
    progress.className = 'tour-number';

    if (footer && buttons && buttons.length > 0) {
      footer.insertBefore(progress, buttons[0]);
    }
  }

  getButtons() {
    return {
      cancel: {
        classes: 'btn btn-secondary',
        text: this.translate.instant('Buttons.Exit'),
        type: 'cancel'
      },
      next: {
        classes: 'btn btn-primary',
        text: this.translate.instant('Buttons.Next'),
        type: 'next'
      },
      back: {
        classes: 'btn btn-secondary',
        text: this.translate.instant('Buttons.Previous'),
        type: 'back'
      }
    };
  }

  getPageButtons() {
    const buttons = this.getButtons();
    return {
      StartPageButtons: [
        buttons.cancel,
        buttons.next
      ],
      MiddlePageButtons: [
        buttons.back,
        buttons.next
      ],
      LastPageButtons: [
        buttons.back,
        buttons.cancel
      ]
    };
  }

  getCommonSteps() {
    const pageButtons = this.getPageButtons();
    const steps: Step.StepOptions[] = [
      {
        id: 'starttour',
        title: this.translate.instant('Tour.StartTour.Title'),
        text: this.translate.instant('Tour.StartTour.Text'),
        buttons: pageButtons.StartPageButtons,
      },
      {
        id: 'switch-organizations',
        title: this.translate.instant('Tour.SwitchOrganizations.Title'),
        text: this.translate.instant('Tour.SwitchOrganizations.Text'),
        attachTo: {
          element: '#orgname-menu',
          on: 'bottom'
        },
        buttons: pageButtons.MiddlePageButtons
      },
      {
        id: 'Manage-UserProfile',
        title: this.translate.instant('Tour.ManageUserProfile.Title'),
        text: this.translate.instant('Tour.ManageUserProfile.Text'),
        attachTo: {
          element: '#Menu\\.UserProfile',
          on: 'bottom'
        },
        beforeShowPromise: () => this.clickElementPromise("#Menu\\.Manage", "#Menu\\.UserProfile"),
        buttons: pageButtons.MiddlePageButtons
      },
      {
        id: 'endtour',
        title: this.translate.instant('Tour.EndTour.Title'),
        text: this.translate.instant('Tour.EndTour.Text'),
        buttons: pageButtons.LastPageButtons
      }
    ];
    return steps;
  }

  getJournalToManageUersSteps() {
    const pageButtons = this.getPageButtons();
    const steps: Step.StepOptions[] = [
      {
        id: 'Financial-Journal',
        title: this.translate.instant('Tour.FinancialJournal.Title'),
        text: this.translate.instant('Tour.FinancialJournal.Text'),
        attachTo: {
          element: '#Menu\\.Journal',
          on: 'bottom'
        },
        beforeShowPromise: () => this.clickElementPromise("#Menu\\.Financial", "#Menu\\.Journal"),
        buttons: pageButtons.MiddlePageButtons
      },
      {
        id: 'Financial-ProfitAndLoss',
        title: this.translate.instant('Tour.FinancialProfitAndLoss.Title'),
        text: this.translate.instant('Tour.FinancialProfitAndLoss.Text'),
        attachTo: {
          element: '#Menu\\.ProfitAndLoss',
          on: 'bottom'
        },
        beforeShowPromise: () => this.clickElementPromise("#Menu\\.Financial", "#Menu\\.ProfitAndLoss"),
        buttons: pageButtons.MiddlePageButtons
      },
      {
        id: 'Manage-Organization',
        title: this.translate.instant('Tour.ManageOrganization.Title'),
        text: this.translate.instant('Tour.ManageOrganization.Text'),
        attachTo: {
          element: '#Menu\\.Organization',
          on: 'bottom'
        },
        beforeShowPromise: () => this.clickElementPromise("#Menu\\.Manage", "#Menu\\.Organization"),
        buttons: pageButtons.MiddlePageButtons
      },
      {
        id: 'Manage-Properties',
        title: this.translate.instant('Tour.ManageProperties.Title'),
        text: this.translate.instant('Tour.ManageProperties.Text'),
        attachTo: {
          element: '#Menu\\.BrandsProperties',
          on: 'bottom'
        },
        beforeShowPromise: () => this.clickElementPromise("#Menu\\.Manage", "#Menu\\.BrandsProperties"),
        buttons: pageButtons.MiddlePageButtons
      },
      {
        id: 'Manage-PropertyDataManagement',
        title: this.translate.instant('Tour.ManagePropertyDataManagement.Title'),
        text: this.translate.instant('Tour.ManagePropertyDataManagement.Text'),
        attachTo: {
          element: '#Menu\\.PropertyDataManagement',
          on: 'bottom'
        },
        beforeShowPromise: () => this.clickElementPromise("#Menu\\.Manage", "#Menu\\.PropertyDataManagement"),
        buttons: pageButtons.MiddlePageButtons
      },
      {
        id: 'Manage-Users',
        title: this.translate.instant('Tour.ManageUsers.Title'),
        text: this.translate.instant('Tour.ManageUsers.Text'),
        attachTo: {
          element: '#Menu\\.Users',
          on: 'bottom'
        },
        beforeShowPromise: () => this.clickElementPromise("#Menu\\.Manage", "#Menu\\.Users"),
        buttons: pageButtons.MiddlePageButtons
      }

    ];

    return steps;
  }

  getTrustCenterSteps() {
    const pageButtons = this.getPageButtons();
    const steps: Step.StepOptions[] = [
      {
        id: 'Manage-AdminMaintainsPartner',
        title: this.translate.instant('Tour.ManageAdminMaintainsPartner.Title'),
        text: this.translate.instant('Tour.ManageAdminMaintainsPartner.Text'),
        attachTo: {
          element: '#Menu\\.AdminMaintainsPartner',
          on: 'bottom'
        },
        beforeShowPromise: () => this.clickElementPromise("#Menu\\.Manage", "#Menu\\.AdminMaintainsPartner"),
        buttons: pageButtons.MiddlePageButtons
      }
    ];

    return steps;
  }

  getMVAPartnerSteps() {
    const pageButtons = this.getPageButtons();
    const steps: Step.StepOptions[] = [
      {
        id: 'Manage-PartnerMaintainUsers',
        title: this.translate.instant('Tour.ManagePartnerMaintainUsers.Title'),
        text: this.translate.instant('Tour.ManagePartnerMaintainUsers.Text'),
        attachTo: {
          element: '#Menu\\.PartnerMaintainUsers',
          on: 'bottom'
        },
        beforeShowPromise: () => this.clickElementPromise("#Menu\\.Manage", "#Menu\\.PartnerMaintainUsers"),
        buttons: pageButtons.MiddlePageButtons
      }
    ];

    return steps;
  }

  getStepsCompareTocountriesreport() {
    const pageButtons = this.getPageButtons();
    const steps: Step.StepOptions[] = [
      {
        id: 'compare',
        title: this.translate.instant('Tour.Compare.Title'),
        text: this.translate.instant('Tour.Compare.Text'),
        attachTo: {
          element: '#compare',
          on: 'bottom'
        },
        buttons: pageButtons.MiddlePageButtons
      },
      {
        id: 'content',
        title: this.translate.instant('Tour.Content.Title'),
        text: this.translate.instant('Tour.Content.Text'),
        attachTo: {
          element: '#content',
          on: 'bottom'
        },
        beforeShowPromise: () => this.clickElementPromise("#Menu\\.Website", "#content"),
        buttons: pageButtons.MiddlePageButtons
      },
      {
        id: 'structure',
        title: this.translate.instant('Tour.Structure.Title'),
        text: this.translate.instant('Tour.Structure.Text'),
        attachTo: {
          element: '#structure',
          on: 'bottom'
        },
        beforeShowPromise: () => this.clickElementPromise("#Menu\\.Website", "#structure"),
        buttons: pageButtons.MiddlePageButtons
      },
      {
        id: 'search',
        title: this.translate.instant('Tour.Search.Title'),
        text: this.translate.instant('Tour.Search.Text'),
        attachTo: {
          element: '#search',
          on: 'bottom'
        },
        beforeShowPromise: () => this.clickElementPromise("#Menu\\.Website", "#search"),
        buttons: pageButtons.MiddlePageButtons
      },
      {
        id: 'audience',
        title: this.translate.instant('Tour.Audience.Title'),
        text: this.translate.instant('Tour.Audience.Text'),
        attachTo: {
          element: '#audience',
          on: 'bottom'
        },
        beforeShowPromise: () => this.clickElementPromise("#Menu\\.Website", "#audience"),
        buttons: pageButtons.MiddlePageButtons
      },
      {
        id: 'advertising',
        title: this.translate.instant('Tour.Advertising.Title'),
        text: this.translate.instant('Tour.Advertising.Text'),
        attachTo: {
          element: '#advertising',
          on: 'bottom'
        },
        beforeShowPromise: () => this.clickElementPromise("#Menu\\.Website", "#advertising"),
        buttons: pageButtons.MiddlePageButtons
      },
      {
        id: 'explorer',
        title: this.translate.instant('Tour.Explorer.Title'),
        text: this.translate.instant('Tour.Explorer.Text'),
        attachTo: {
          element: '#explorer',
          on: 'bottom'
        },
        beforeShowPromise: () => this.clickElementPromise("#Menu\\.Website", "#explorer"),
        buttons: pageButtons.MiddlePageButtons
      },
      {
        id: 'social',
        title: this.translate.instant('Tour.Social.Title'),
        text: this.translate.instant('Tour.Social.Text'),
        attachTo: {
          element: '#social',
          on: 'bottom'
        },
        buttons: pageButtons.MiddlePageButtons
      },
      {
        id: 'countries-report',
        title: this.translate.instant('Tour.CountriesReport.Title'),
        text: this.translate.instant('Tour.CountriesReport.Text'),
        attachTo: {
          element: '#nav-countries',
          on: 'bottom'
        },
        beforeShowPromise: () => this.clickElementPromise("#nav-reports", "#nav-countries"),
        buttons: pageButtons.MiddlePageButtons
      }
    ];

    return steps;
  }

  isElementHidden(element: HTMLElement) {
    return element.offsetHeight === 0;
  }

  clickElementPromise(clickSelector: string, visibleSelector: string) {
    const _this = this;
    return new Promise(function (resolve) {
      const element = _this.document.querySelector(visibleSelector) as HTMLElement;
      if (_this.isElementHidden(element)) {
        const clickElement = _this.document.querySelector(clickSelector) as HTMLElement;
        clickElement.click();
      }
      resolve(true);
    });
  }

  defaultStepOptions: Step.StepOptions = {
    scrollTo: {
      behavior: 'smooth',
    },
    canClickTarget: false,
    cancelIcon: {
      enabled: true
    },
    arrow: true,
    when: {
      show: () => this.addProgressToFooter()
    }
  };

  addOrganizationSteps(userOrganization: UserOrganization, allSteps: Step.StepOptions[], user: User) {
    const steps: Step.StepOptions[] = [];
    steps.push(...this.getStepsCompareTocountriesreport());
    if (userOrganization.roleId === Roles.ORGADMIN) {
      steps.push(...this.getJournalToManageUersSteps());
      if (user.roleId === Roles.MVASYSADMIN) {
        steps.push(...this.getMVAPartnerSteps());
      }
      if (user.roleId === Roles.MVAPARTNER) {
        steps.push(...this.getTrustCenterSteps());
      }

    }
    steps.push(...allSteps.filter(s => s.id === 'Manage-UserProfile'));
    return steps;
  }


  intializeTour(user: User, userOrganization: UserOrganization | null) {
    const steps = this.getCommonSteps();
    var permissionSteps: Step.StepOptions[] = [];
    //Start tour
    permissionSteps.push(...steps.filter(s => s.id === 'starttour'));
    if (userOrganization) {
      if (user.userOrganizations.length > 1) {
        //Only for organization drop-down list
        permissionSteps.push(...steps.filter(s => s.id === 'switch-organizations'));
      }
      permissionSteps.push(...this.addOrganizationSteps(userOrganization, steps, user));
    }
    else {
      if (user.roleId === Roles.MVASYSADMIN) {
        permissionSteps.push(...this.getMVAPartnerSteps());
      }
      if (user.roleId === Roles.MVAPARTNER) {
        permissionSteps.push(...this.getTrustCenterSteps());
      }
      //2. User who has no access to ANY organization
      permissionSteps.push(...steps.filter(s => s.id === 'Manage-UserProfile'));
    }
    //end tour
    permissionSteps.push(...steps.filter(s => s.id === 'endtour'));

    //add all steps here

    this.shepherdService.defaultStepOptions = this.defaultStepOptions;
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;
    this.shepherdService.addSteps(permissionSteps);
    this.shepherdService.start();
  }
}
