import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { PropertyManagementService } from 'src/app/services/propertymanagement.service';
import { propertySubTypes } from 'src/app/shared/app-constants/constants';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { FavouriteMedia } from 'src/app/shared/models/FavouriteMedia';
import { FilterParams } from 'src/app/shared/models/FindMediaFilterParams';
import { FindMediaProperty } from 'src/app/shared/models/FindMediaProperty';
import { PaginatedResponse } from 'src/app/shared/models/PaginatedResponse';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-property-table',
  templateUrl: './property-table.component.html',
  styleUrls: ['./property-table.component.css'],
})
export class PropertyTableComponent {
  private readonly _destroying$ = new Subject<void>();
  @Input() pageNumber: number = 1;
  @Input() pageSize: number = 15;
  @Input() filters!: FilterParams;
  @Output() totalRecordsChange = new EventEmitter<number>();
  properties: FindMediaProperty[] = [];
  detailsUrl: string = '/find-media/single';
  totalRecords = 0;

  constructor(
    private clipboardService: ClipboardService,
    private propertyService: PropertyManagementService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private router: Router
  ) {}

  getProperties() {
    if (this.pageNumber === 1) {
      this.properties = [];
    }
    this.propertyService
      .getMediaProperty(this.pageNumber, this.pageSize, this.filters)
      .pipe(takeUntil(this._destroying$))
      .subscribe((response) => {
        const properties = (response as ApiResponse)
          .result as PaginatedResponse<FindMediaProperty>;
        this.totalRecords = properties.totalItems;
        this.totalRecordsChange.emit(this.totalRecords);
        this.properties = [...this.properties, ...properties.results];
      });
  }

  ngOnDestroy() {
    this._destroying$.next();
    this._destroying$.complete();
  }

  copyToClipboard(property: FindMediaProperty) {
    const copyUrl = `${property.propertyUrl}`;
    this.clipboardService.copyToClipboard(copyUrl);
  }

  goToDetails(property: FindMediaProperty) {
    this.router.navigate([property.propertyUrl]);
  }

  onFavourite(property: FindMediaProperty): void {
    this.propertyService.favouritePropertyMedia(property.propertyId).subscribe({
      next: (res: ApiResponse) => {
        const response = res.result as unknown as boolean;
        property.isFavorite = response;
        this.properties = this.updatePropertyInArray(property);
        response
          ? this.notificationService.showSuccessToast(
              this.translate.instant('FindMedia.AddedToFavorites')
            )
          : this.notificationService.showSuccessToast(
              this.translate.instant('FindMedia.RemovedFromFavorites')
            );
      },
      error: (error) => {
        this.notificationService.showSuccessToast(
          this.translate.instant('FindMedia.Error')
        );
      },
    });
  }

  updatePropertyInArray(
    updatedProperties: Partial<FindMediaProperty> & { propertyId: string }
  ): FindMediaProperty[] {
    return this.properties.map((prop) =>
      prop.propertyId === updatedProperties.propertyId
        ? { ...prop, ...updatedProperties }
        : prop
    );
  }
  getIcon(propertySubTypeId: string) {
    switch (propertySubTypeId) {
      case propertySubTypes.Facebook:
        return '../../../../assets/icons/fb-icn.svg';
      case propertySubTypes.Instagram:
        return '../../../../assets/icons/insta-icn.svg';
      case propertySubTypes.Youtube:
        return '../../../../assets/icons/yt-icn.svg';
      default:
        return '../../../../assets/icons/website-icn.svg';
    }
  }
}
