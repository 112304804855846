<div class="modal-header">
  <h4 class="modal-title" translate>DataConnections.InstagramSelection</h4>
  <div type="button" class="btn-off" data-coreui-dismiss="offcanvas" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></div>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="mb-3 row" *ngIf="instagramAccounts.length > 0">
        <label for="subType" class="col-sm-4 col-form-label fw-semibold" translate>DataConnections.Pages<span class="text-danger required-field">*</span></label>
        <div class="col-sm-8">
          <select class="form-select" name="account" (change)="onAccountSelect($event)">
            <option value="0" translate>DataConnections.SelectOption</option>
            <option *ngFor="let account of instagramAccounts" [value]="account.id">
              {{account.username}}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark btn-primary" (click)="selectInstagrmBusinessAccounts()"
    [disabled]="!selectedAccount" translate>DataConnections.Select</button>
  <button type="button" class="btn btn-outline-dark btn-secondary" (click)="activeModal.close('Close click')" translate>DataConnections.SelectOption</button>
</div>
