import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactFormUrl: string = "https://forms.office.com/Pages/ResponsePage.aspx?id=OTuh5Py1H0yoQMCrZqrq5Ziys1krT5hJq_hyRifZxd1URjRWSkM3SEgyQVlQSjJUSjRNQ1hHSkFKSy4u&embed=tru";
  safeContactFormUrl?: SafeResourceUrl;

  constructor(private domSanitizer: DomSanitizer,
              private translate: TranslateService) {}


  ngOnInit(): void {
    if(this.translate.currentLang=="es") {
      this.contactFormUrl += "&lang=es";
    }
    this.safeContactFormUrl = this.domSanitizer
      .bypassSecurityTrustResourceUrl(this.contactFormUrl);

  }

}
