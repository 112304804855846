<div class="d-flex justify-content-between border-bottom align-items-center p-2">
  <h6 class="card-title pt-05">YouTube Analytics
    <span class="mx-auto pl-02">
      <img src="/assets/icons/info.svg" alt="Details of the google analytics propertoies"
        class="btn-icon" [ngbPopover]="'DataConnections.ThirdPartyTooltip' | translate" /></span>
  </h6>
  <img *ngIf="authorized && googlePropertyId" src="/assets/icons/success.svg" alt="Fetched Successfully">
</div>
<img src="/assets/icons/yt.svg" alt="YouTube Channel Logo" class="mx-auto p-3">
<div class="card-body" *ngIf="showAuthorization ">
  <div class="row">
      <button type="button" *ngIf="!authorized" a href="#" class="col-10 mx-auto btn btn-primary mb-01 yt-btn" (click)="authorize()" translate>
        DataConnections.Authorize
      </button>
      <button type="button" *ngIf="authorized" a href="#" class="col-10 mx-auto btn btn-secondary mb-01 yt-btn" (click)="showRevokeDialog()" translate>
        DataConnections.Revoke
      </button>
      <button type="button" *ngIf="retryGetChannelInfo" a href="#" class="col-10 mx-auto btn btn-primary mb-01 yt-btn" (click)="getChannelInfo()" translate>
        DataConnections.GetChannelInfo
      </button>
  </div>
  <div *ngIf="googlePropertyId" class="d-flex g-3 col-sm-12">
    <div class="fw-semibold pr-01" translate>DataConnections.ChannelId</div>
    <div class="text-break">{{googlePropertyId}}</div>
  </div>
</div>
<div class="card-body" *ngIf="!showAuthorization">
  <div class="clearfix">
    <span class="sr-only" translate>DataConnections.ProviderLoading</span>
    <div class="spinner-border spinner-border-sm float-right" role="status">
    </div>
  </div>
</div>
<div #revokeGoogleModal class="modal fade show overlay" id="myModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" translate>DataConnections.RevokeConfirmDialogHeader</h4>
        <div type="button" class="btn-off" data-bs-dismiss="modal" aria-label="Close" (click)="closeDialog()"></div>
      </div>
      <div class="modal-body">
        <p class="pl-013 pt-4 modal-text fw-semibold" translate>ConfirmModal.ConfirmBody</p>
        <p class="p-3 modal-text" translate>DataConnections.RevokeMessage<br>DataConnections.RevokeWarning</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDialog()"
          translate>ConfirmModal.No</button>
        <button type="button" class="btn btn-primary" (click)="revoke()" translate>ConfirmModal.Yes</button>
      </div>
    </div>
  </div>
</div>
