import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, combineLatest, filter, map, mergeMap, takeUntil, tap } from 'rxjs';
import { DataDashbordService } from 'src/app/services/datadashboard.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { propertySubTypes } from 'src/app/shared/app-constants/constants';
import { AppContext } from 'src/app/shared/context/AppContext';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { SolutionProvider } from 'src/app/shared/models/SolutionProvider';
import { User } from 'src/app/shared/models/User';
import { UserOrganization } from 'src/app/shared/models/UserOrganization';
import { Brand } from 'src/app/shared/models/brand.model';
import { Property } from 'src/app/shared/models/property.model';

@Component({
  selector: 'app-manage-property',
  templateUrl: './manage-property.component.html',
  styleUrls: ['./manage-property.component.css']
})
export class ManagePropertyComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  organizationName?: string;
  brands!: Brand[];
  solutionProviders!: SolutionProvider[];
  orgId!: string;
  user!: User;
  showProperties: boolean = false;
  websiteProperties: Property[] = [];
  socialMediaProperties: Property[] = [];
  noBrandsAvailable = false;

  constructor(
    private router: Router,
    private appContext: AppContext,
    private organizationService: OrganizationService,
    private dataDashboardService: DataDashbordService,
    private route: ActivatedRoute) { }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  ngOnInit(): void {
    //call getsolutionproviders when data dashboard is loaded
    this.dataDashboardService.getSolutionproviders();

    combineLatest([
      this.appContext.organization.pipe(
        filter((organization): organization is UserOrganization => !!organization),
        tap((organization) => { this.organizationName = organization!.organizationName; }),
        mergeMap((organization) => {
          this.orgId = organization!.organizationId;
          return this.organizationService.getOrganizationBrands(organization!.organizationId)
        })
      )
    ]).pipe(
      takeUntil(this._destroying$)
    ).subscribe(([brandsAPIResponse]) => {
      this.brands = ((brandsAPIResponse as ApiResponse).result) as Brand[];

      if (this.brands && this.brands.length > 0 && this.brands[0].brandId) {
        this.onBrandSelect(this.brands[0].brandId);
        this.noBrandsAvailable = false;
      }
      else {
        this.noBrandsAvailable = true;
      }
    })
  }

  onBrandSelect(selectedBrandId: string) {

    const websiteSubtypeId = propertySubTypes.Website;
    const websiteProps = this.brands.find(x => x.brandId === selectedBrandId)?.properties?.filter(x => x.propertySubTypeId.toLowerCase() === websiteSubtypeId!.toLowerCase());
    const socialMediaProps = this.brands.find(x => x.brandId === selectedBrandId)?.properties?.filter(x => x.propertySubTypeId.toLowerCase() != websiteSubtypeId!.toLowerCase());

    if (websiteProps || socialMediaProps) {
      this.showProperties = true;
      if (websiteProps) {
        this.websiteProperties = websiteProps;
      }
      if (socialMediaProps) {
        this.socialMediaProperties = socialMediaProps;
      }
    }
    this.dataDashboardService.brandSelectionChanged.next(selectedBrandId);
  }
}
