<div class="body flex-grow-1 px-3">
    <div class="container-lg">
        <div class="row hpm-1">
            <div>
                <h4 class="fw-semibold pb-4" translate>TermsOfUse.Title</h4>
                <div class="col-lg-12 col-sm-12" [innerHTML]="'TermsOfUse.Content' | translate"></div>
            </div>
        </div>
    </div>
</div>
