<div *ngFor="let property of properties; index as i" [ngClass]="i>0?'border-top':''">
  <div class="row pt-2 pb-2">
    <p class="text-start text-decoration-none">{{property.propertyUrl}}</p>
  </div>
  <div class="row mb-4">
    <div class="col-md-12 col-lg-4 col-sm-12 d-inline-flex mb-4">
      <div *ngIf="this.showProviders" app-google-analytics class="card w-100" [property]="property" [organizationId]="organizationId"></div>
      <div *ngIf="!this.showProviders" app-solution-placeholder class="card w-100" aria-hidden="true" SolutionProviderName='Google Analytics'></div>
    </div>
    <div class="col-md-12 col-lg-4 col-sm-12 d-inline-flex mb-4">
      <div *ngIf="this.showProviders" app-google-search-console class="card w-100" [property]="property" [organizationId]="organizationId"></div>
      <div *ngIf="!this.showProviders" app-solution-placeholder class="card w-100" aria-hidden="true" SolutionProviderName='Google Search Console'>
      </div>
    </div>
  </div>
</div>
