import { Injectable } from '@angular/core';
import { protectedResourceEndpoints } from '../shared/auth-config/auth-config';
import { ApiService } from '../shared/http/api.service';
import { CreateBadgeApplication } from '../shared/models/CreateBadgeApplication';
import { Observable } from 'rxjs';
import { ApiResponse } from '../shared/models/ApiResponse';
import { PaginatedResponse } from '../shared/models/PaginatedResponse';
import {
  ApplicationSummaryFilter,
  OrganizationBadgeApplicationSummary,
} from '../shared/models/OrganizationBadgeApplicationSummary';
import { HttpParams } from '@angular/common/http';
import { BadgeApplication } from '../shared/models/BadgeApplication';
import { BadgeApplicationSummary } from '../shared/models/BadgeApplicationSummary';
import { PaginatedRequest } from '../shared/models/PaginatedRequest';

@Injectable({
  providedIn: 'root',
})
export class BadgeApplicationService {
  private readonly badgeApplicationUrl =
    protectedResourceEndpoints.badgeApplication.url;
  constructor(private apiService: ApiService) {}
  apply(
    application: CreateBadgeApplication
  ): Observable<ApiResponse<CreateBadgeApplication>> {
    return this.apiService.postData(
      `${this.badgeApplicationUrl}apply`,
      application
    );
  }
  requests(
    filter: PaginatedRequest<ApplicationSummaryFilter>
  ): Observable<
    ApiResponse<PaginatedResponse<OrganizationBadgeApplicationSummary>>
  > {
    const params = this.buildQueryParams(filter);
    return this.apiService.getEntity<
      ApiResponse<PaginatedResponse<OrganizationBadgeApplicationSummary>>
    >(`${this.badgeApplicationUrl}list`, params);
  }
  approveRequest(applicationId: string): Observable<ApiResponse<boolean>> {
    return this.apiService.put<ApiResponse<boolean>>(
      `${this.badgeApplicationUrl}${applicationId}/approve`,
      {}
    );
  }
  rejectRequest(applicationId: string): Observable<ApiResponse<boolean>> {
    return this.apiService.put<ApiResponse<boolean>>(
      `${this.badgeApplicationUrl}${applicationId}/reject`,
      {}
    );
  }
  revokeApplication(applicationId: string): Observable<ApiResponse<boolean>> {
    return this.apiService.put<ApiResponse<boolean>>(
      `${this.badgeApplicationUrl}${applicationId}/revoke`,
      {}
    );
  }
  requestsByOrganization(
    badgeId: string,
    organizationId: string
  ): Observable<ApiResponse<BadgeApplication[]>> {
    let params = new HttpParams();
    params = params.append('OrgId', organizationId);
    return this.apiService.getEntity<ApiResponse<BadgeApplication[]>>(
      `${this.badgeApplicationUrl}${badgeId}/list`,
      params
    );
  }
  private buildQueryParams(
    queryParams: PaginatedRequest<ApplicationSummaryFilter>
  ): HttpParams {
    let params = new HttpParams();
    if (queryParams.filter?.badgeId) {
      params = params.append('badgeId', queryParams.filter.badgeId);
    }
    params = params.append('pageNumber', queryParams.pageNumber?.toString() ?? '');
    params = params.append('pageSize', queryParams.pageSize?.toString() ?? '');
    return params;
  }
}
