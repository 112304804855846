import { Injectable } from "@angular/core";
import { AppConfigService } from "./app.config.service";
import { FacebookLoginProvider, SocialAuthServiceConfig } from "@abacritt/angularx-social-login";


@Injectable({
  providedIn: 'root'
})
export class FacebookLoginProviderService {
  constructor(private config: AppConfigService) { }

  getFacebookServiceConfig(): SocialAuthServiceConfig {
   return  {
      autoLogin: false,
      providers: [
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider(this.config.appConfig.facebook?.appId!, {}),
        },
      ],
      onError: (err: any) => {
        console.error(err);
      },
    } as SocialAuthServiceConfig
  }
}
