<div class="body flex-grow-1 px-3">
  <div class="container-lg hpm-1">
    <h4 class="fw-semibold pb-4" translate>
      <img [src]="'/assets/icons/data-links-icn.svg'" [alt]="'DataConnections.Title' | translate"
        class="pr-01 page-title-icon" />
      DataConnections.Title
    </h4>
    <p translate>DataConnections.TitleNotes</p>
    <div class="row">
      <div class="row mb-4">
        <div *ngIf="!noBrandsAvailable" class="row g-3 align-items-center">
          <div class="col-auto">
            <label for="inputPassword6" class="col-form-label" translate>DataConnections.SelectBrandLabel</label>
          </div>
          <div class="col-auto">
            <select #brandSelect class="form-select form-control" aria-label="brand selection"
              (change)="onBrandSelect(brandSelect.value)">
              <option *ngFor="let brand of brands" [value]="brand.brandId" [selected]="!brand">{{brand.name}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="noBrandsAvailable" class="row g-3 align-items-center">
          <label class="col-form-label no-brands" translate>DataConnections.NoBrandsLabel</label>
        </div>
      </div>
      <div class="row mb-5" *ngIf="showProperties">
        <div class="col">
          <div class="accordion" id="accordionSegment">
            <div class="accordion-item pb-1">
              <h2 class="accordion-header">
                <button class="accordion-button" type="button" data-coreui-toggle="collapse"
                  data-coreui-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne" translate>
                  DataConnections.Websites
                </button>
              </h2>
              <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show">
                <div class="accordion-body">
                  <app-website-properties [properties]="websiteProperties"
                    [organizationId]="orgId"></app-website-properties>
                </div>
              </div>
            </div>
            <div class="accordion-item pb-1">
              <h2 class="accordion-header">
                <button class="accordion-button" type="button" data-coreui-toggle="collapse"
                  data-coreui-target="#panelsStayOpen-collapseTwo" aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseTwo" translate>
                  DataConnections.SocialMedia
                </button>
              </h2>
              <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show">
                <div class="accordion-body">
                  <app-socialmedia-properties [properties]="socialMediaProperties"
                    [organizationId]="orgId"></app-socialmedia-properties>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
