import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'active-status',
  templateUrl: './active-status.component.html',
  styleUrls: ['./active-status.component.css'],
})
export class ActiveStatusComponent implements OnInit, OnDestroy {
  @Input() active!: boolean;

  private readonly _destroying$ = new Subject<void>();

  constructor() {}

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  ngOnInit(): void {}
}
