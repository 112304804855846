<div class="body flex-grow-1 px-3">
  <!-- Breadcrumb -->
  <nav aria-label="breadcrumb" class="mt-1 ms-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/manage/badges']" translate>Badge.BadgeTitle</a>
      </li>
      <li class="breadcrumb-item crumb-text" aria-current="page">
        {{ (isEditMode ? 'Badge.EditBadge' : 'Badge.AddNewBadge') | translate }}
      </li>
    </ol>
  </nav>
  <form name="createBadgeForm" #badgeForm="ngForm">
    <div class="container-lg hpr-1">

      <div class="row">
        <h4 class="fw-semibold pb-1" [translate]="
            isEditMode ? 'Badge.EditBadge' : 'Badge.AddNewBadge'
          "></h4>
        <div class="actionbar mt-1">
          <!-- Name -->
          <div class="mb-3 row">
            <label for="badgeName" class="col-sm-12 col-form-label fw-semibold" translate>Badge.Name <span
                class="text-danger">*</span>
            </label>
            <div class="col-sm-12 col-md-8">
              <input class="form-control" [placeholder]="'Badge.NamePlaceholder' | translate"
                [(ngModel)]="badgeListing.name" name="badgeName" type="text" id="badgeName" required maxlength="50"
                #badgeName="ngModel" [ngClass]="
                  badgeName.invalid &&
                    (badgeName.dirty || badgeName.touched)
                      ? 'invalid-input'
                      : ''
                  " />
              <span class="text-danger" *ngIf="
                  badgeName.invalid &&
                    (badgeName.dirty || badgeName.touched) &&
                    badgeName.errors?.required
                  ">
                {{ "Common.Required" | translate }}</span>
            </div>
          </div>

          <!-- Apply To -->
          <div class="mb-3 row">
            <label for="badgeApplyTo" class="col-sm-12 col-form-label fw-semibold" translate>Badge.ApplyTo <span
                class="text-danger">*</span>
            </label>
            <h6 translate class="col-sm-12 col-md-8">Solution.ApplyToDescription</h6>
            <div class="col-sm-12 col-md-8">
              <ng-multiselect-dropdown [placeholder]="'Common.SelectOptions' | translate" [settings]="applyToSettings"
                [data]="applyTypes" required [(ngModel)]="badgeListing.applyTo" #badgeApplyTo="ngModel"
                name="badgeApplyTo" id="badgeApplyTo">
              </ng-multiselect-dropdown>
              <span class="text-danger" *ngIf="
                  isFormSubmitted && badgeApplyTo.invalid &&
                    (badgeApplyTo.dirty || badgeApplyTo.touched) &&
                    badgeApplyTo.errors?.required
                  ">
                {{ "Common.Required" | translate }}</span>
            </div>
          </div>

          <!-- Type -->
          <div class="mb-3 row">
            <fieldset class="col-sm-12 col-md-12">
              <legend class="col-form-label col-sm-12 pt-0 fw-semibold" translate>
                Badge.PublishFor
              </legend>
              <h6 translate class="col-sm-12 col-md-7">Badge.PublishForDescription</h6>
              <div class="col-sm-12 col-md-7 col-lg-4 pl-1">
                <div class="form-check pt-2" *ngFor="let type of publishTypes">
                  <input class="form-check-input" type="radio" name="organizationTypeId" [id]="type.name"
                    [value]="type.publishTypeId" [(ngModel)]="badgeListing.publishTypeId" checked />
                  <label class="form-check-label" [for]="type.name">
                    {{ type.name }}
                  </label>
                </div>
              </div>
            </fieldset>
          </div>

          <!-- Validity -->
          <div class="row">
            <legend class="col-form-label col-sm-12 pt-0 fw-semibold" translate>Badge.Validity</legend>
            <h6 translate class="col-sm-12 col-md-8">Badge.ValidityDescription</h6>
            <date-range [startDate]="validFrom" [endDate]="validTo" (dateRangeChange)="onDateRangeChange($event)" />
          </div>

          <!-- Primary Image -->
          <div class="mb-3 row">
            <label for="primaryImage" class="col-sm-12 col-form-label fw-semibold" translate>Badge.PrimaryImage</label>
            <h6 translate class="col-sm-12 col-md-8">Badge.PrimaryImageDescription</h6>
            <div *ngIf="!badgeListing.primaryImageUrl" class="col-sm-12 col-md-8 col-lg-6 d-inline-flex">
              <div class="card brand-upload mt-1">
                <div class="card-body mx-auto">
                  <label for="file-input">
                    <i class="bi bi-plus-square-fill brand-upload-i"></i>
                  </label>
                  <input id="file-input" type="file" accept="image/png,image/jpeg" class="d-none" name="primaryImage"
                    (change)="onPrimaryImageSelect($event)" />
                </div>
              </div>
            </div>
            <div *ngIf="badgeListing.primaryImageUrl" class="col-sm-6 col-md-8 col-lg-6 d-inline-flex">
              <div class="card brand-upload">
                <input id="file-input-preview" #file type="file" accept="image/png,image/jpeg" class="d-none"
                  (change)="onPrimaryImageSelect($event)" />
                <div class="card-body mx-auto" (click)="file.click()">
                  <img [src]="badgeListing.primaryImageUrl" class="brand-upload-logo"
                    alt="{{ 'Badge.PrimaryImage' | translate }}" />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="primaryImageErrors.length > 0" class="mb-3 row">
            <div class="col-sm-4 d-inline-flex">
              <span class="text-danger" *ngFor="let error of primaryImageErrors">
                {{ error }}
              </span>
            </div>
          </div>

          <!-- Description -->
          <div class="mb-3 row">
            <label for="badgeDescription" class="col-sm-12 col-form-label fw-semibold" translate>Badge.Description
            </label>
            <h6 translate class="col-sm-12 col-md-8">Badge.DescriptionText</h6>
            <div class="col-sm-12 col-md-8">
              <textarea class="form-control" [placeholder]="'Badge.DescriptionPlaceholder' | translate"
                [(ngModel)]="badgeListing.description" name="badgeDescription" id="badgeDescription"
                #badgeDescription="ngModel" rows="4"></textarea>
            </div>
          </div>

          <!-- Languages supported -->
          <div class="mb-3 row">
            <label for="badgeLanguages" class="col-sm-12 col-form-label fw-semibold" translate>Badge.LanguagesSupported
            </label>
            <h6 translate>Badge.LanguagesSupportedDescription</h6>
            <div class="col-sm-12 col-md-8">
              <ng-multiselect-dropdown [placeholder]="'Badge.BagdgeLanguagesSupported' | translate"
                [settings]="languagesSettings" [data]="languages" [(ngModel)]="badgeListing.languages"
                #badgeLanguages="ngModel" name="badgeLanguages" id="badgeLanguages">
              </ng-multiselect-dropdown>
            </div>
          </div>

          <!-- Locations supported -->
          <div class="mb-3 row">
            <label for="badgeLocations" class="col-sm-12 col-form-label fw-semibold" translate>Badge.LocationsSupported
            </label>
            <h6 translate class="col-sm-12 col-md-8">Badge.LocationsSupportedDescription</h6>
            <div class="col-sm-12 col-md-8">
              <ng-multiselect-dropdown [placeholder]="'Badge.BadgeLocationsSupported' | translate"
                [settings]="locationsSettings" [data]="countries" [(ngModel)]="badgeListing.locations"
                #badgeLocations="ngModel" name="badgeLocations" id="badgeLocations">
              </ng-multiselect-dropdown>
            </div>
          </div>

          <!-- Tags -->
          <div class="mb-3 row">
            <label for="badgeTags" class="col-sm-12 col-form-label fw-semibold" translate>Badge.Tags
            </label>
            <h6 translate class="col-sm-12 col-md-8">Badge.TagsDescription</h6>
            <div class="col-sm-12 col-md-8">
              <ng-multiselect-dropdown [placeholder]="'Badge.SelectTag' | translate" [settings]="tagsSettings"
                [data]="tags" [(ngModel)]="badgeListing.tags" #badgeLocations="ngModel" name="badgeTags" id="badgeTags">
              </ng-multiselect-dropdown>
            </div>
          </div>

          <!-- Links -->
          <div class="row">
            <legend class="col-form-label col-sm-12 pt-0 fw-semibold" translate>Badge.Links</legend>
            <h6 translate class="col-sm-12 col-md-8">Badge.LinksDescription</h6>
            <url-list  
            (isValid)="onUrlValidationStatus($event)"
            [values]="normalizedLinks" 
            (valuesChange)="onLinksChange($event)" />
          </div>
          <!-- Get Description -->
          <div class="mb-3 row">
            <label for="badgeGetDescription" class="col-sm-12 col-form-label fw-semibold" translate>Badge.GetDescription
            </label>
            <h6 translate class="col-sm-12 col-md-8">Badge.GetDescriptionText</h6>
            <div class="col-sm-12 col-md-8">
              <textarea class="form-control" [placeholder]="'Badge.GetDescriptionPlaceholder' | translate"
                [(ngModel)]="badgeListing.getDescription" name="badgeGetDescription" id="badgeGetDescription"
                #badgeGetDescription="ngModel" rows="4"></textarea>
            </div>
          </div>

          <!-- Images -->

          <div class="row mb-3">
            <label class="col-sm-12 col-form-label fw-semibold" translate>Badge.Images</label>
            <h6 class="col-sm-12 col-md-8" translate>Badge.ImagesDescription</h6>
            <div class="col-sm-12 col-md-8">
              <input class="form-control" #multiFileInput type="file" (change)="onImagesSelect($event)"
                accept="image/jpeg, image/png" multiple>
            </div>
            <div *ngIf="(badgeListing.images ?? []).length > 0" class="row mt-2">
              <div
                *ngFor="let imageUrl of badgeListing.images; let i = index"
                class="col-sm-6 col-md-2 col-lg-2 d-inline-flex position-relative"
              >
                <div>
                  <img [src]="imageUrl" class="brand-upload-logo" alt="{{ 'Badge.Images' | translate }}" />
                  <!-- Remove Button -->
                  <button
                    type="button"
                    class="btn btn-danger btn-sm position-absolute top-0 end-0"
                    (click)="removeImage(i)"
                  >
                    &times;
                  </button>
                </div>
              </div>
            </div>   
            <div *ngIf="badgesImageErrors.length > 0" class="mb-2 row mt-1">
              <div class="col-sm-4 d-inline-flex">
                <span class="text-danger" *ngFor="let error of badgesImageErrors">
                  {{ error }}
                </span>
              </div>
            </div>
          </div>
          <!-- Embedded video -->
          <div class="mb-3 row">
            <label for="badgeEmbeddedVideoUrl" class="col-sm-12 col-form-label fw-semibold"
              translate>Badge.EmbeddedVideo
            </label>
            <h6 class="col-sm-12 col-md-8" translate>Badge.EmbeddedVideoDescription</h6>
            <div class="col-sm-12 col-md-8">
              <input class="form-control" [placeholder]="'Badge.EmbeddedVideoPlaceholder' | translate"
                [(ngModel)]="badgeListing.embeddedVideoUrl" name="badgeEmbeddedVideoUrl" type="text"
                id="badgeEmbeddedVideoUrl" maxlength="255" #badgeEmbeddedVideoUrl="ngModel"
                appUrlValidator
                [ngClass]="
                    badgeEmbeddedVideoUrl.invalid &&
                    (badgeEmbeddedVideoUrl.dirty ||
                    badgeEmbeddedVideoUrl.touched)
                      ? 'invalid-input'
                      : ''
                  " />
                  <span class="text-danger" *ngIf="badgeEmbeddedVideoUrl.errors?.invalidUrl" translate>
                 Common.InvalidUrl
              </span>
            </div>
          </div>

          <!-- Terms -->
          <div class="mb-3 row">
            <label for="badgeEmbeddedVideoUrl" class="col-sm-12 col-form-label fw-semibold" translate>Badge.Terms
            </label>
            <h6 class="col-sm-12 col-md-8" translate>Badge.TermsDescription</h6>
            <div class="col-sm-12 col-md-8">
              <select class="form-select" [(ngModel)]="badgeListing.termTypeId" name="termTypeId"
                [attr.aria-label]="'Badge.Terms' | translate">
                <option [value]="null">
                  {{ "Badge.SelectTerms" | translate }}
                </option>
                <option *ngFor="let termType of termTypes" [value]="termType.termTypeId">
                  {{ termType.name }}
                </option>
              </select>
            </div>
          </div>

          <!-- Terms Url -->
          <div class="mb-3 row">
            <label for="badgeTermsUrl" class="col-sm-12 col-form-label fw-semibold" translate>Badge.TermsUrl
            </label>
            <h6 translate class="col-sm-12 col-md-8">Badge.TermsUrlDescription</h6>
            <div class="col-sm-12 col-md-8">
              <input class="form-control" [(ngModel)]="badgeListing.termsUrl" name="badgeTermsUrl" type="text"
                [placeholder]="'Badge.TermsUrlPlaceholder' | translate" id="badgeTermsUrl" maxlength="255"
                #badgeTermsUrl="ngModel" appUrlValidator [ngClass]="
                  badgeTermsUrl.invalid &&
                    (badgeTermsUrl.dirty || badgeTermsUrl.touched)
                      ? 'invalid-input'
                      : ''
                  " />
                 <span class="text-danger"  *ngIf="badgeTermsUrl.errors?.invalidUrl" 
                  translate>
                 Common.InvalidUrl
              </span>
            </div>
          </div>

          <div class="row text-danger" *ngIf="vaidationErrors.length > 0">
            <span>{{ "ErrorCodes.BRAND_ERRORS" | translate }}</span>
            <ul>
              <li *ngFor="let error of vaidationErrors">{{ error }}</li>
            </ul>
          </div>
          <div class="row brand-footer">
            <div class="actionbar mt-3">
              <div class="col-sm-4 d-inline-flex gap-4">
                <button type="button" class="btn btn-secondary" [routerLink]="BADGE_PATH" translate>
                  Common.Cancel
                </button>

                <button type="button" class="btn btn-primary" (click)="onSave()" translate>
                  Common.Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div #deleteBadgeModal class="modal fade show overlay" id="myModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" translate>Badge.Confirm</h4>
        <div type="button" class="btn-off" data-bs-dismiss="modal" aria-label="Close" (click)="closeDialog()"></div>
      </div>
      <div class="modal-body">
        <p class="pl-013 pt-4 modal-text fw-semibold" translate>
          Badge.DeleteWarning
        </p>
        <p class="p-3 modal-text" translate>Badge.DeleteMessage</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDialog()" translate>
          Common.ConfirmNo
        </button>
        <button type="button" class="btn btn-primary" (click)="onDelete()" translate>
          Common.ConfirmYes
        </button>
      </div>
    </div>
  </div>
</div>