<div class="container-fluid">
  <button class="header-toggler px-md-0 me-md-3" type="button" (click)="toggleMenu()">
    <img src="assets/icons/menu_close.svg" alt="Open Main Navigation" class="btn-icon" *ngIf="menuService.isMenuOpen">
    <img src="assets/icons/menu_open.svg" alt="Close Main Navigation" class="btn-icon" *ngIf="!menuService.isMenuOpen">
  </button>

  <!-- INEWS-363 - Code for Missing permissions list on header commented  this  because Internews do not want it as of now -->
  <!-- <div *ngIf="missingPermissionsProperties && missingPermissionsProperties.length > 0"
    class="alert alert-danger missing-permissions margin-05" role="alert">
    <img src="/assets/icons/error-small.svg" alt="Missing permissions">
    Few properties are missing permissions , please go to <a routerLink="/datalinks">"Data links"</a> to reconnect.
    check the <button class="btn btn-primary" (click)="showMissingPermissionsProperties()">Property list</button>
  </div> -->
</div>
