import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, mergeMap, takeUntil } from 'rxjs';
import { DateFormatOptions, Roles, UserStatus } from 'src/app/shared/app-constants/constants';
import { AppContext } from 'src/app/shared/context/AppContext';
import { Role } from 'src/app/shared/models/Role';
import { MasterDataService } from 'src/app/shared/services/master-data.service';
import { ICellRendererParams } from 'ag-grid-community';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { FlatUser } from '../../../shared/models/FlatUser';
import { OrganizationUser } from '../../../shared/models/OrganizationUser';
import { OrganizationService } from '../../../services/organization.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit, OnDestroy {

  @Input() params!: ICellRendererParams<any, any, any>;
  private readonly _destroying$ = new Subject<void>();
  user!: FlatUser;
  userRoles: Role[] = [];
  userStatus = UserStatus;
  userStatusConstant = [this.userStatus.Active, this.userStatus.Inactive];
  userRoleConstants = [Roles.ORGUSER, Roles.ORGADMIN];

  constructor(private appContext: AppContext,
              private masterDataService: MasterDataService,
              private userService: UserService,
              private organizationService: OrganizationService,
              private notificationService: NotificationService,
              public activeOffcanvas: NgbActiveOffcanvas,
              private translate: TranslateService) {}

  ngOnInit(): void {
    this.appContext.user.pipe(
      mergeMap((user) => this.masterDataService.getRoles(user.languageId)),
      takeUntil(this._destroying$)
    ).subscribe((roles) => {
      this.userRoles = roles.filter(item => this.userRoleConstants.includes(item.roleId));
    });
    this.user = { ...this.params.data };
  }

  onSubmit(orgUser: OrganizationUser) {
    orgUser.organizationUserId = this.user.organizationUserId;
    this.organizationService.saveOrganizationUser(this.user.organizationId, this.user.userId, orgUser)
      .subscribe(() => {
        this.notificationService.showSuccessToast(this.translate.instant('UserManagement.EditUser.UserUpdated'));
        this.params.context.userListComponent.refreshGrid();
        this.activeOffcanvas.dismiss();
      });
  }

  formatDate(date: Date) {
    return date.toLocaleDateString(this.appContext.userLocale, DateFormatOptions);
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
