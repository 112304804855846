import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { AppContext } from '../../../shared/context/AppContext';
import { UserOrganization } from '../../../shared/models/UserOrganization';
import { MenuItem, MenuService } from 'src/app/shared/services/menu.service';
import { User } from '../../../shared/models/User';

@Component({
  selector: 'app-switch-organization',
  templateUrl: './switch-organization.component.html',
  styleUrls: ['./switch-organization.component.css'],
})
export class SwitchOrganizationComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();
  userOrganizations!: UserOrganization[];
  user!: User;

  constructor(
    public activeModal: NgbActiveModal,
    private appContext: AppContext,
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuService
  ) {}

  organizationForm = new FormGroup({
    organizationId: new FormControl(
      undefined as unknown as string | undefined,
      [Validators.required]
    ),
  });

  get organizationId() {
    return this.organizationForm.get('organizationId');
  }

  ngOnInit(): void {
    combineLatest([this.appContext.user, this.appContext.organization])
      .pipe(takeUntil(this._destroying$))
      .subscribe(([user, organization]) => {
        this.user = user;
        this.userOrganizations = user.userOrganizations;
        this.userOrganizations.sort((a, b) =>
          a.organizationName.localeCompare(b.organizationName)
        );
        this.organizationId?.setValue(organization?.organizationId);
      });
  }

  onSubmit() {
    this.organizationForm.markAllAsTouched();
    if (this.organizationForm.valid) {
      const newOrganization =
        this.userOrganizations.find(
          (uo) => uo.organizationId == this.organizationId?.value
        ) ?? null;
      const menuCategories = this.menuService.getUserProfileMenu(
        this.user,
        newOrganization
      );
      const url = this.router.url.toLowerCase();
      const menuItems = menuCategories.flatMap((mc) => mc.menuItems);
      menuItems.push(...this.menuService.getSideBarMenuItems(newOrganization));
      const menuItem = menuItems.find(
        (mi) =>
          mi instanceof MenuItem &&
          (mi.path.toLowerCase().startsWith(url) ||
            url.startsWith(mi.path.toLowerCase()))
      );
      if (menuItem == null) {
        this.router.navigateByUrl('', {
          state: { organization: newOrganization },
        });
      } else {
        this.appContext.setOrganization(newOrganization);
      }
      this.activeModal.close();
    }
  }
}
