import { Component, Input } from '@angular/core';
import { SolutionProvider } from 'src/app/shared/models/SolutionProvider';

@Component({
  selector: '[app-tiktok]',
  templateUrl: './tiktok.component.html',
  styleUrls: ['./tiktok.component.css']
})
export class TiktokComponent {
  @Input() solutionProvider!: SolutionProvider;
  showAuthorization = false;
}
