<div class="container mt-4">
  <div class="d-flex gap-2 align-items-center mb-4">
    <img src="/assets/icons/visibility-page.svg" style="max-height: 20px" />
    <h2 translate>Visibility.Title</h2>
  </div>
  <div class="row">
    <p class="mb-4 col-8" translate>Visibility.Subtitle</p>
  </div>

  <hr />

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- Organization Visibility -->
    <div class="row">
      <div class="col">
        <h3 translate>Visibility.SolutionVisibleTitle</h3>
        <p translate>Visibility.SolutionVisibleSubtitle</p>
      </div>
      <div class="col">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="visible"
          />
          <label class="form-check-label">
            {{ getLabel("visible") | translate }}
          </label>
        </div>
      </div>
    </div>

    <hr />

    <fieldset [disabled]="!form.get('visible')?.value">
      <!-- Organization Details -->
      <div class="row"><h3 translate>Visibility.OrganizationDetails</h3></div>

      <!-- Year of Foundation  -->
      <div class="row">
        <div class="col"><p translate>Visibility.YearOfFoundation</p></div>
        <div class="col">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="foundationYearVisible"
            />
            <label class="form-check-label">
              {{ getLabel("foundationYearVisible") | translate }}
            </label>
          </div>
        </div>
      </div>

      <!-- Organization Type -->
      <div class="row">
        <div class="col"><p translate>Visibility.OrganizationType</p></div>
        <div class="col">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="organizationTypeVisible"
            />
            <label class="form-check-label">
              {{ getLabel("organizationTypeVisible") | translate }}
            </label>
          </div>
        </div>
      </div>

      <!-- Number of emplyees -->
      <div class="row">
        <div class="col"><p translate>Visibility.NumberOfEmployees</p></div>
        <div class="col">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="totalFteVisible"
            />
            <label class="form-check-label">
              {{ getLabel("totalFteVisible") | translate }}
            </label>
          </div>
        </div>
      </div>

      <!-- Organization Badges -->
      <div class="row">
        <div class="col"><p translate>Visibility.OrganizationBadges</p></div>
        <div class="col">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="badgesVisible"
            />
            <label class="form-check-label">
              {{ getLabel("badgesVisible") | translate }}
            </label>
          </div>
        </div>
      </div>

      <hr />

      <!-- Brand -->
      <div class="row"><h3 translate>Visibility.Brand</h3></div>
      <div class="row">
        <div class="col" translate>Visibility.BrandBadges</div>
        <div class="col">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="brandBadgesVisible"
            />
            <label class="form-check-label">
              {{ getLabel("brandBadgesVisible") | translate }}
            </label>
          </div>
        </div>
      </div>

      <hr />

      <!-- Properties -->
      <div class="row"><h3 translate>Visibility.Properties</h3></div>
      <div
        class="row mb-4"
        *ngFor="let p of formProperties.controls; let i = index"
        formArrayName="properties"
      >
        <div class="col d-flex gap-2 align-items-center">
          <img
            [src]="toPropertyTypeIconUrl(properties[i].propertySubTypeId)"
            alt=""
          />
          <p class="mb-0">{{ properties[i].propertyName ?? "Untitled" }}</p>
        </div>
        <div class="col">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              [formControlName]="i"
            />
            <label class="form-check-label" translate>
              {{ getLabel("properties", i) }}
            </label>
          </div>
        </div>
      </div>

      <hr />

      <!-- Contacts -->
      <div class="row"><h3 translate>Visibility.Contacts</h3></div>
      <div class="row mb-2">
        <p class="col-8" translate>Visibility.ContactsSubtitle</p>
      </div>
      <div
        class="row mb-2"
        formArrayName="organizationUsers"
        *ngFor="let u of formOrgUsers.controls; let i = index"
      >
        <div
          class="col d-flex gap-2 align-items-center"
          *ngIf="orgusers[i].user; let user"
        >
          <img src="/assets/icons/user.svg" alt="" />
          <p class="mb-0">{{ user.firstName }} {{ user.lastName }}</p>
        </div>
        <div class="col">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              [formControlName]="i"
            />
            <label class="form-check-label" translate>
              {{ getLabel("organizationUsers", i) }}
            </label>
          </div>
        </div>
      </div>
    </fieldset>

    <hr />

    <!-- Save Button -->
    <button
      type="submit"
      class="btn btn-primary mt-4 px-5"
      [disabled]="!form.dirty"
      translate
    >
      Common.Save
    </button>
  </form>
</div>
