<div class="body flex-grow-1 px-3">
  <div class="container-lg">
    <div class="row">
      <div class="col-lg-12 col-sm-12 pt-4">
        <div class="d-flex justify-content-start">
          <h5 class="card-title mb-0 fw-semibold" translate>
            <img [src]="'/assets/icons/partner-admin-icn.svg'" [alt]="'MvaPartner.UsersGrid.TrustCenter' | translate"
            class="pr-01 page-title-icon" />
            MvaPartner.UsersGrid.TrustCenter
          </h5>
        </div>
        <p [innerHTML]="'MvaPartner.UsersGrid.TrustCenterHelpText' | translate" class="pt-07"></p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-sm-12 mb-4 border-bottom">
        <div class="d-flex justify-content-start mb-01">
          <label class="col-sm-12 col-md-5 col-lg-auto col-form-label fw-semibold" translate>MvaPartner.UsersGrid.Organization</label>
          <div class="col-sm-12 col-md-7 col-lg-2 pl-05">
            <select class="form-select" (change)="externalFilterChanged($event)">
              <option selected value="all" translate>MvaPartner.UsersGrid.All</option>
              <option *ngFor="let org of organizationList" [value]="org.id">{{ org.name }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-4 grid-container-65">
    <div class="col-md-12 col-sm-12 d-inline-flex mb-4">
        <ag-grid-angular class="ag-theme-alpine users-grid"
                         [rowData]="userList"
                         [columnDefs]="columnDefs"
                         [defaultColDef]="defaultColDef"
                         [pagination]="true"
                         [paginationPageSize]="paginationPageSize"
                         [animateRows]="true"
                         [isExternalFilterPresent]="isExternalFilterPresent"
                         [doesExternalFilterPass]="doesExternalFilterPass"
                         (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
  </div>
</div>

