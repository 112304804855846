import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Organization } from '../shared/models/Organization';
import { OrganizationForEdit } from '../shared/models/OrganizationForEdit';
import { protectedResourceEndpoints } from '../shared/auth-config/auth-config';
import { ApiResponse } from '../shared/models/ApiResponse';
import { UserInvite } from '../shared/models/UserInvite';
import { CanRemoveUserFromOrg } from '../shared/models/CanRemoveUserFromOrg';
import { FlatUser } from '../shared/models/FlatUser';
import { OrganizationUser } from '../shared/models/OrganizationUser';
import { OrganizationForJournalEntry } from '../shared/models/OrganizationForJournalEntry';
import { OrganizationUserReport } from '../shared/models/OrganizationUserReport';
import { ApiService } from '../shared/http/api.service';
import { FilterParams } from '../shared/models/FindMediaFilterParams';
import { FindMediaOrganization } from '../shared/models/OrganizationMedia';
import {
  OrganizationUserVisibility,
  OrganizationVisibility,
} from '../shared/models/OrganizationVisibility';
import { UpdateOrganizationVisibility } from '../shared/models/UpdateOrganizationVisibility';
import { map, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  private _getOrganizationEditUrl =
    protectedResourceEndpoints.organizationEdit.url;
  private _organizationUrl = protectedResourceEndpoints.organization.url;

  getOrganizationForEdit(orgId: string) {
    return this.http.get<OrganizationForEdit>(
      this._getOrganizationEditUrl + orgId
    );
  }

  removeBadge(badgeId: string): Observable<boolean> {
    return this.http
      .delete<ApiResponse<boolean>>(`${this._organizationUrl}badge/${badgeId}`)
      .pipe(map((response) => response.result));
  }

  saveOrganization(id: string, orgData: Organization) {
    const organization: Organization = {
      organizationId: id,
      name: orgData.name,
      foundationYear: orgData.foundationYear ? +orgData.foundationYear : null,
      baseCurrencyId: orgData.baseCurrencyId ? orgData.baseCurrencyId : null,
      totalFte: orgData.totalFte ? +orgData.totalFte : null,
      femaleFte: orgData.femaleFte ? +orgData.femaleFte : null,
      customerServiceRoleFte: orgData.customerServiceRoleFte
        ? +orgData.customerServiceRoleFte
        : null,
      audienceDiversity: orgData.audienceDiversity
        ? orgData.audienceDiversity
        : null,
      opsAndProd: orgData.opsAndProd ? orgData.opsAndProd : null,
      organizationTypeId: orgData.organizationTypeId
        ? orgData.organizationTypeId
        : null,
      website: orgData.website ? orgData.website : undefined,
      primaryImageUrl: orgData.primaryImageUrl
        ? orgData.primaryImageUrl
        : undefined,
      primaryImageAsBase64: orgData.primaryImageAsBase64
        ? orgData.primaryImageAsBase64
        : undefined,
    };

    return this.http.put<ApiResponse>(this._organizationUrl + id, organization);
  }

  getOrganizationVisibility() {
    return this.http
      .get<ApiResponse<OrganizationVisibility>>(
        `${this._organizationUrl}visibility`
      )
      .pipe(map((res) => res.result));
  }

  saveOrganizationVisibility(visibility: UpdateOrganizationVisibility) {
    return this.http.put<ApiResponse<OrganizationUserVisibility>>(
      `${this._organizationUrl}visibility`,
      visibility
    );
  }

  deleteOrganization(id: string) {
    return this.http.delete<ApiResponse>(this._organizationUrl + id);
  }

  getOrganizationBrands(orgId: string) {
    return this.http.get(this._organizationUrl + orgId + '/brands');
  }

  inviteUser(user: UserInvite, orgId: string) {
    return this.http.post<ApiResponse>(
      `${this._organizationUrl}${orgId}/inviteUser`,
      user
    );
  }

  getRemoveUserValidations(orgId: string) {
    return this.http.get<CanRemoveUserFromOrg>(
      `${this._organizationUrl}${orgId}/removeuser`
    );
  }

  removeUser(orgId: string) {
    return this.http.delete(`${this._organizationUrl}${orgId}/removeuser`);
  }

  getUsers(orgId: string) {
    return this.http.get<FlatUser[]>(`${this._organizationUrl}${orgId}/users`);
  }

  approveUser(orgId: string, userId: string) {
    return this.http.put(
      `${this._organizationUrl}${orgId}/user/${userId}/approve`,
      null
    );
  }

  declineUser(orgId: string, userId: string) {
    return this.http.put(
      `${this._organizationUrl}${orgId}/user/${userId}/decline`,
      null
    );
  }

  partnerApprovesUser(orgId: string, userId: string) {
    return this.http.put(
      `${this._organizationUrl}${orgId}/user/${userId}/partnerapproves`,
      null
    );
  }

  partnerDeclinesUser(orgId: string, userId: string) {
    return this.http.put(
      `${this._organizationUrl}${orgId}/user/${userId}/partnerdeclines`,
      null
    );
  }

  saveOrganizationUser(
    orgId: string,
    userId: string,
    organizationUser: OrganizationUser
  ) {
    return this.http.put(
      `${this._organizationUrl}${orgId}/user/${userId}`,
      organizationUser
    );
  }

  getOrganizationBrandsAndProperties(orgId: string) {
    return this.http.get<OrganizationForJournalEntry[]>(
      `${this._organizationUrl}${orgId}/brandsandproperties`
    );
  }

  getUserReportsData(orgId: string) {
    return this.apiService.getEntity<OrganizationUserReport[]>(
      `${this._organizationUrl}${orgId}/userreports`,
      new HttpParams(),
      true
    );
  }
  getOrganizationSummary(
    page: number,
    pageSize: number,
    filter: FilterParams = {
      organizationTypes: [],
      propertySubTypes: [],
      organizationAges: [],
      noOfEmployee: [],
      searchText: '',
      isFavorite: false,
    }
  ) {
    const params = this.buildQueryParams(page, pageSize, filter);
    return this.apiService.getEntity<ApiResponse>(
      `${protectedResourceEndpoints.organization.url}summary`,
      params
    );
  }
  favouriteOrganizationMedia(orgId: string) {
    return this.http.put<ApiResponse>(
      `${protectedResourceEndpoints.organization.url}${orgId}/toggle-favorite`,
      {}
    );
  }
  getOrganizationMediaById(orgId: string) {
    return this.http.get<FindMediaOrganization>(
      `${protectedResourceEndpoints.organization.url}${orgId}/summary`
    );
  }
  private buildQueryParams(
    page: number,
    pageSize: number,
    filter: FilterParams
  ): HttpParams {
    let params = new HttpParams()
      .set('pageNumber', page.toString())
      .set('pageSize', pageSize.toString());

    if (filter.searchText) {
      params = params.append('searchText', filter.searchText);
    }

    if (filter.isFavorite) {
      params = params.append('isFavorite', filter.isFavorite.toString());
    }

    if (filter.organizationTypes && filter.organizationTypes.length > 0) {
      filter.organizationTypes.forEach((type) => {
        if (type) params = params.append('organizationTypes', type);
      });
    }

    if (filter.organizationAges && filter.organizationAges.length > 0) {
      filter.organizationAges.forEach((orgAge) => {
        if (orgAge) params = params.append('organizationAges', orgAge);
      });
    }

    if (filter.noOfEmployee && filter.noOfEmployee.length > 0) {
      filter.noOfEmployee.forEach((employee) => {
        if (employee) params = params.append('noOfEmployee', employee);
      });
    }

    if (filter.propertySubTypes && filter.propertySubTypes.length > 0) {
      filter.propertySubTypes.forEach((property) => {
        if (property) params = params.append('propertySubTypes', property);
      });
    }

    return params;
  }
}
