import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";
export function DomainValidator(propertysubtype: string | undefined): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    if (!isValidUrl(value)) {
      return { invalidUrl: { message: 'Please enter a valid URL.' } };
    }

    const isUrlValid = isUrlHttp(value, propertysubtype);

    if (isUrlValid) {
      return null;
    } else {
      const newUrl = new URL(value);
      let message = '';

      if (!(newUrl.protocol === 'http:' || newUrl.protocol === 'https:')) {
        message += 'Please enter a valid URL. ';

      }
      else {
        if (!checkValidDomainForSelectedProperty(newUrl.hostname, propertysubtype)) {
          message += 'URL does not match selected type. ';
        }
      }
      return { invalidUrl: { message: message.trim() } };
    }
  };
}
function isValidUrl(value: any): boolean {
  try {
    new URL(value);
    return true;
  } catch (err) {
    return false;
  }
}
function isUrlHttp(value: any, propertysubtype: string | undefined): boolean {
  try {
    const newUrl = new URL(value);
    return (newUrl.protocol === 'http:' || newUrl.protocol === 'https:') && checkValidDomainForSelectedProperty(newUrl.hostname, propertysubtype);
  } catch (err) {
    return false;
  }
}
function checkValidDomainForSelectedProperty(hostname: string, propertysubtype: string | undefined): boolean {
  if (propertysubtype) {
    const specialDomains: string[] = ["Facebook", "Instagram", "YouTube"];
    if (specialDomains.includes(propertysubtype)) {
      return (hostname.toLowerCase().startsWith(propertysubtype!.toLowerCase()) || hostname.toLowerCase().startsWith("www." + propertysubtype!.toLowerCase()));
    } else {
      return !specialDomains.some(domain => hostname.toLowerCase().startsWith(domain.toLowerCase()) || hostname.toLowerCase().startsWith("www." + domain.toLowerCase()));
    }
  }
  else return true;
}
