import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatestWith, Subject, takeUntil } from 'rxjs';
import { Roles, RoutePath, UserStatus } from '../../shared/app-constants/constants';
import { AppContext } from '../../shared/context/AppContext';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.css']
})
export class EmptyComponent implements OnInit, OnDestroy {

  private readonly _destroying$ = new Subject<void>();

  constructor(private appContext: AppContext, private _router: Router) {
    const state = this._router.getCurrentNavigation()?.extras?.state;
    if (state && state.organization) {
      this.appContext.setOrganization(history.state.organization);
    }
  }

  ngOnInit(): void {
    this.appContext.organization.pipe(
      combineLatestWith(this.appContext.user),
      takeUntil(this._destroying$)
    ).subscribe(([organization, user]) => {
      if (organization == null) {
        if ((user.roleId === Roles.MVASYSADMIN && user.userStatusId === UserStatus.Active) ||
          (user.roleId === Roles.MVAPARTNER && user.userStatusId === UserStatus.Active)) {
          this._router.navigateByUrl(RoutePath.Home);
        }
        else {
          this._router.navigateByUrl(RoutePath.OrgNotAssigned);
        }
      } else {
        this._router.navigateByUrl(RoutePath.Home);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
