import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SingleFindMediaBadge } from 'src/app/shared/models/OrganizationMedia';

@Component({
  selector: 'app-badge-information',
  templateUrl: './badge-information.component.html',
  styleUrls: ['./badge-information.component.css'],
})
export class BadgeInformationComponent {
  @Input() badge!: SingleFindMediaBadge;
  constructor(public activeModal: NgbActiveModal) {}
}
