import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Report } from "../shared/app-constants/constants";
import { protectedResourceEndpoints } from "../shared/auth-config/auth-config";
import { Segment } from "../shared/models/Segment";
import { SegmentVariables } from "../shared/models/SegmentVariables";

@Injectable({
  providedIn: 'root'
})
export class SegmentService {

  constructor(private http: HttpClient) {
  }

  private _segmentsUrl = protectedResourceEndpoints.segments.url;
  private masterDataUrl = protectedResourceEndpoints.masterData.url;

  getSegments(organizationId: string, report: Report) {
    return this.http.get<Segment[]>(`${this._segmentsUrl}?organizationId=${organizationId}&reportId=${report}`);
  }

  getSegment(segmentId: string) {
    return this.http.get<Segment[]>(`${this._segmentsUrl}/${segmentId}`);
  }

  saveSegment(segment: SegmentVariables) {
    return this.http.post<Segment>(this._segmentsUrl, segment);
  }

  deleteSegment(segmentId: string) {
    return this.http.delete<boolean>(`${this._segmentsUrl}/${segmentId}`);
  }
}
