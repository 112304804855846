<form (submit)="onSubmit()" [formGroup]="addPartnerOrganizationForm">
  <div class="offcanvas offcanvas-end show">
    <div class="offcanvas-header border-bottom">
      <h4 class="offcanvas-title" id="offcanvas-basic-title" translate>MvaSystemAdmin.AddPartner.AddPartner</h4>
      <div type="button" class="btn-off" data-coreui-dismiss="offcanvas" aria-label="Close" (click)="activeOffcanvas.close()"></div>
    </div>
    <div class="offcanvas-body">
      <fieldset class="row mb-4">
        <legend class="col-form-label col-sm-3 fw-semibold" translate>MvaSystemAdmin.AddPartner.PartnerOrganization<span class="text-danger required-field">*</span></legend>
        <div class="col-sm-9">
          <input class="form-control" type="text" formControlName="partnerName"
                aria-label="organization name" maxlength="30" [ngClass]="partnerName?.invalid && (partnerName?.dirty || partnerName?.touched) ? 'invalid-input' : '' ">
          <span class="text-danger" *ngIf="partnerName?.invalid && partnerName?.touched && partnerName?.value == ''">
            {{ 'MvaSystemAdmin.AddPartner.OrganizationNameRequired' | translate }}
          </span>
          <span class="text-danger" *ngIf="vaidationErrors && partnerNameExistsError">
            {{ 'ErrorCodes.PARTNER_NAME_EXISTS' | translate }}
          </span>
        </div>
      </fieldset>
      <div class="row mb-4"> 
        <label class="col-form-label col-sm-3 fw-semibold" translate>MvaSystemAdmin.AddPartner.EmailDomain<span class="text-danger required-field">*</span></label> 
        <div class="col-sm-9"> 
          <input class="form-control" type="text" maxlength="50" formControlName="emailDomain" [attr.aria-label]="'MvaSystemAdmin.AddPartner.EmailDomain' | translate"> 
          <span class="text-danger" *ngIf="emailDomain?.invalid && emailDomain?.touched && emailDomain?.value == ''"> 
            {{ 'MvaSystemAdmin.AddPartner.EmailDomainRequired' | translate}} 
          </span> 
        </div> 
      </div>       
      <div formGroupName="primaryUser">
        <fieldset class="row mb-4">
          <legend class="col-form-label col-sm-9 fw-semibold mb-2" translate>MvaSystemAdmin.AddPartner.PrimaryContact</legend>
          <div class="row mb-4">
            <label class="col-form-label col-sm-3 fw-semibold" translate>MvaSystemAdmin.AddPartner.FirstName<span class="text-danger required-field">*</span></label>
            <div class="col-sm-9">
              <input class="form-control" type="text" formControlName="firstName"
                    aria-label="organization name" maxlength="30" [ngClass]="firstName?.invalid && (firstName?.dirty || firstName?.touched) ? 'invalid-input' : '' ">
              <span class="text-danger" *ngIf="firstName?.invalid && firstName?.touched && firstName?.value == ''">
                {{ 'MvaSystemAdmin.AddPartner.FirstNameRequired' | translate}}
              </span>
            </div>
            </div>
            <div class="row mb-4">
              <label class="col-form-label col-sm-3 fw-semibold" translate>MvaSystemAdmin.AddPartner.LastName<span class="text-danger required-field">*</span></label>
              <div class="col-sm-9">
                <input class="form-control" type="text" formControlName="lastName"
                      aria-label="organization name" maxlength="30" [ngClass]="lastName?.invalid && (lastName?.dirty || lastName?.touched) ? 'invalid-input' : '' ">
                <span class="text-danger" *ngIf="lastName?.invalid && lastName?.touched && lastName?.value == ''">
                  {{ 'MvaSystemAdmin.AddPartner.LastNameRequired' | translate}}
                </span>
              </div>
            </div>
            <div class="row mb-4">
              <label class="col-form-label col-sm-3 fw-semibold" translate>MvaSystemAdmin.AddPartner.Email<span class="text-danger required-field">*</span></label>
              <div class="col-sm-9">
                <input class="form-control" type="text" maxlength="50" formControlName="email" [attr.aria-label]="'MvaSystemAdmin.AddPartner.ReviewerEmailAreaLabel' | translate" >
                <span class="text-danger" *ngIf="email?.invalid && email?.touched && email?.value == ''">
                  {{ 'MvaSystemAdmin.AddPartner.EmailRequired' | translate}}
                </span>
                <span class="text-danger" *ngIf="email?.errors?.['email']">
                    {{ 'MvaSystemAdmin.AddPartner.EmailInvalid' | translate }}
                </span>
              </div>
            </div>
            <div class="row mb-4">
              <label class="col-form-label col-sm-3 fw-semibold" translate>MvaSystemAdmin.AddPartner.Language<span class="text-danger required-field">*</span></label>
              <div class="col-sm-9">
                  <select class="form-select" formControlName="languageId" name="language" id="language"
                      [ngClass]="languageId?.invalid && (languageId?.dirty || languageId?.touched) ? 'invalid-input' : '' ">
                      <option [ngValue]="undefined">{{ 'MvaSystemAdmin.AddPartner.SelectLanguage' | translate }}</option>
                      <option *ngFor="let language of languages"
                          [value]="language.languageId">{{ language.name }}</option>
                  </select>
                  <span class="text-danger"
                      *ngIf="languageId?.invalid && languageId?.touched && languageId?.value == undefined">
                      {{ 'MvaSystemAdmin.AddPartner.LanguageRequired' | translate }}
                  </span>
              </div>
            </div>
        </fieldset>
      </div>
    </div>
    <div class="modal-footer p-3">
      <div class="text-danger row" *ngIf="vaidationErrors.length > 0">
        <span>{{ 'ErrorCodes.ADD_PARTNER_ERRORS' | translate }}</span>
        <ul>
          <li *ngFor="let error of vaidationErrors">{{ error }}</li>
        </ul>
      </div>
      <div>
        <button type="button" class="btn btn-secondary mr-1" (click)="activeOffcanvas.dismiss('')" translate>MvaSystemAdmin.AddPartner.Cancel</button>
        <button type="submit" class="btn btn-primary" translate>MvaSystemAdmin.AddPartner.Save</button>
      </div>
    </div>
  </div>
</form>


