import { Component, OnDestroy, OnInit } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { Subject, mergeMap, takeUntil } from 'rxjs';
import { AppContext } from 'src/app/shared/context/AppContext';
import { OrganizationUserListForManage } from 'src/app/shared/models/OrganizationUserListForManage';
import { ActionsRendererComponent } from '../actions-renderer/actions-renderer.component';
import { FlatUser } from 'src/app/shared/models/FlatUser';
import { PartnerService } from '../../../services/partner.service';
import { DateFormatOptions } from 'src/app/shared/app-constants/constants';
import { UtilityService } from 'src/app/shared/services/utility.service';

var selectedOrganization = 'all';
@Component({
  selector: 'app-users-grid',
  templateUrl: './users-grid.component.html',
  styleUrls: ['./users-grid.component.css']
})
export class UsersGridComponent implements OnInit, OnDestroy {

  private readonly _destroying$ = new Subject<void>();
  private gridApi!: GridApi<FlatUser>;
  userList: FlatUser[] = [];
  paginationPageSize = 50;
  organizationList!: any[];
  userId: string = '';

  columnDefs: ColDef[] = [
    { headerName: this.translate.instant('MvaPartner.UsersGrid.UserEmail'), field: 'email', width: 250, filter: true },
    { headerName: this.translate.instant('MvaPartner.UsersGrid.Organization'), field: 'organizationName', width: 200, filter: 'agMultiColumnFilter' },
    { headerName: this.translate.instant('MvaPartner.UsersGrid.Status'), field: 'organizationUserStatusName', width: 200 },
    { headerName: this.translate.instant('MvaPartner.UsersGrid.LastSeen'), field: 'lastSeen', valueGetter: param => this.formatDate(param.data.lastSeen), width: 200, comparator: this.utilityService.stringDateComparator },
    { headerName: this.translate.instant('MvaPartner.UsersGrid.JoinedDate'), field: 'joinDate', valueGetter: param => this.formatDate(param.data.joinDate), width: 200, comparator: this.utilityService.stringDateComparator },
    { headerName: this.translate.instant('MvaPartner.UsersGrid.Actions'), cellRenderer: ActionsRendererComponent, cellRendererParams: {context: { userGridComponent: this }}, width: 150 },
  ];

  defaultColDef = {
    sortable: true,
    resizable:true,
  };

  constructor(private appContext: AppContext,
    private partnerService: PartnerService,
    private utilityService: UtilityService,
    private translate: TranslateService) {}

  ngOnInit(): void {
    this.appContext.user.pipe(
    mergeMap((user) => {
      this.userId = user.userId;
      return this.partnerService.getPartnerOrganizationUsers();
    }),
    takeUntil(this._destroying$)
    ).subscribe((usersList) => {
      selectedOrganization = 'all';
      this.userList = usersList;
      this.userList.forEach((u) => {
        u.joinDate = new Date(u.joinDate);
        u.lastSeen = new Date(u.lastSeen);
      });
      this.organizationList = Array.from(new Map(
        usersList.map(x => [
        x.organizationId, { id: x.organizationId, name: x.organizationName }
      ])).values());
    });
  }

  onGridReady(params: GridReadyEvent<FlatUser>) {
    this.gridApi = params.api;
  }

  refreshGrid() {
    this.partnerService.getPartnerOrganizationUsers().subscribe((usersList) => {
      this.userList = usersList;
      this.userList.forEach((u) => {
        u.joinDate = new Date(u.joinDate);
        u.lastSeen = new Date(u.lastSeen);
      });
    });
    this.gridApi.refreshCells();
  }

  isExternalFilterPresent(): boolean {
    return selectedOrganization !== 'all';
  }

  doesExternalFilterPass(node: IRowNode<OrganizationUserListForManage>): boolean {
    if (node.data) {
      if (selectedOrganization === 'all') {
        return true;
      } else {
        return node.data.organizationId === selectedOrganization;
      }
    }
    return true;
  }

  externalFilterChanged(event: Event) {
    selectedOrganization = ((event.target) as HTMLSelectElement).value;
    this.gridApi.onFilterChanged();
  }

  formatDate(date: Date) {
    return date.toLocaleDateString(this.translate.currentLang, DateFormatOptions);
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
