import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'url-list',
  templateUrl: './url-list.component.html',
  styleUrls: ['./url-list.component.css'],
})
export class UrlListComponent implements OnInit, OnDestroy {
  @Input() label!: string;
  @Input() values: string[] = [''];
  @Output() valuesChange = new EventEmitter<string[]>();

  private readonly _destroying$ = new Subject<void>();

  constructor() {}

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  ngOnInit(): void {}

  valuesDidChange() {
    this.valuesChange.emit(this.values);
  }

  addUrl() {
    this.values.push(''); // Add a new empty URL
    this.valuesDidChange();
  }

  removeUrl(index: number) {
    if (this.values.length > 1) {
      this.values.splice(index, 1); // Remove URL at the specified index
      this.valuesDidChange();
    }
  }

  trackByIndex(index: number, item: string): number {
    return index;
  }
}
