<div class="">
  <span
    class="badge rounded-pill font-normal fw-normal py-2 px-3"
    [ngClass]="{
      'bg-success-subtle text-success-emphasis': active,
      'bg-danger-subtle text-danger-emphasis': !active
    }"
  >
    <i
      class="bi bi-eye"
      [ngClass]="{ 'bi-eye': active, 'bi-eye-slash': !active }"
    ></i>
    {{ (active ? "Active" : "Inactive") | translate }}</span
  >
</div>
