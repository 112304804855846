import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumericInput]'
})
export class NumericInputDirective {

  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const inputValue = inputElement.value;

    const numericValue = inputValue.replace(/[^\d]/g, '').slice(0, 8);

    if (numericValue !== inputValue) {
      inputElement.value = numericValue;
      this.control.control!.setValue(numericValue);
    }
  }
}
