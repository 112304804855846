import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { BrandManagementService } from 'src/app/services/brandmanagement.service';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { FindMediaBrand } from 'src/app/shared/models/FindMediaBrand';
import { FilterParams } from 'src/app/shared/models/FindMediaFilterParams';
import { PaginatedResponse } from 'src/app/shared/models/PaginatedResponse';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
@Component({
  selector: 'app-brand-table',
  templateUrl: './brand-table.component.html',
  styleUrls: ['./brand-table.component.css'],
})
export class BrandTableComponent implements OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  @Input() pageNumber: number = 1;
  @Input() pageSize: number = 15;
  @Input() filters!: FilterParams;
  @Output() totalRecordsChange = new EventEmitter<number>();
  brands: FindMediaBrand[] = [];
  totalRecords = 0;
  detailsUrl: string = '/find-media/single';

  constructor(
    private clipboardService: ClipboardService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private brandService: BrandManagementService
  ) {}

  getBrands() {
    if (this.pageNumber === 1) {
      this.brands = [];
    }
    this.brandService
      .getMediaBrand(this.pageNumber, this.pageSize, this.filters)
      .pipe(takeUntil(this._destroying$))
      .subscribe((response) => {
        const brands = (response as ApiResponse)
          .result as PaginatedResponse<FindMediaBrand>;
        this.totalRecords = brands.totalItems;
        this.totalRecordsChange.emit(this.totalRecords);
        this.brands = [...this.brands, ...brands.results];
      });
  }

  ngOnDestroy() {
    this._destroying$.next();
    this._destroying$.complete();
  }

  getLanguages(brand: FindMediaBrand): string {
    const languages = new Set<string>();
    if (brand.languages) {
      brand.languages.forEach((lang) => {
        if (lang) {
          languages.add(lang);
        }
      });
    }
    return Array.from(languages).join(', ');
  }

  copyToClipboard(brand: FindMediaBrand) {
    const baseUrl = window.location.origin;
    const copyUrl = `${baseUrl}${this.detailsUrl}/${brand.organizationId}`;
    this.clipboardService.copyToClipboard(copyUrl);
  }

  onFavorite(brand: FindMediaBrand): void {
    this.brandService.favouriteBrandMedia(brand.brandId).subscribe({
      next: (res: ApiResponse) => {
        var response = res.result as unknown as boolean;
        brand.isFavorite = response;
        this.brands = this.updateBrandInArray(brand);
        response
          ? this.notificationService.showSuccessToast(
              this.translate.instant('FindMedia.AddedToFavorites')
            )
          : this.notificationService.showSuccessToast(
              this.translate.instant('FindMedia.RemovedFromFavorites')
            );
      },
      error: (error) => {
        this.notificationService.showSuccessToast(
          this.translate.instant('FindMedia.Error')
        );
      },
    });
  }

  updateBrandInArray(
    updatedBrand: Partial<FindMediaBrand> & { brandId: string }
  ): FindMediaBrand[] {
    return this.brands.map((brand) =>
      brand.brandId === updatedBrand.brandId
        ? { ...brand, ...updatedBrand }
        : brand
    );
  }
}
