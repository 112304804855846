<div class="container sol-container" *ngIf="solution">
  <!-- Breadcrumbs -->
  <div class="mb-3">
    <a routerLink="/find-solutions/solutions" translate
      >SolutionDetail.FindSolutions</a
    >
    &#47; {{ solution.name.length > 25 ? (solution.name | slice:0:25) + '...' : solution.name }}
  </div>
  <!-- Title Row -->
  <div class="d-flex justify-content-between title-row">
    <div class="d-flex align-items-center sol-title">
      <img
      [src]="solution.primaryImageUrl
      ? solution.primaryImageUrl
          : '../../../../assets/img/nologo.png'
                  "
       width="50px" height="50px" />
      <h2 class="ms-2">{{ solution.name }}</h2>
    </div>
    <!-- Action Buttons -->
    <div class="d-flex">
      <button translate class="btn btn-primary" (click)="copyLink()">
        SolutionDetail.CopyLink
      </button>
      <button
        translate
        class="btn btn-primary mx-3"
        (click)="toggleFavourite()"
      >
        <ng-container *ngIf="!solution.isFavourite">
          <img src="/assets/icons/favorite.png" />
          SolutionDetail.AddToFavorites
        </ng-container>

        <ng-container *ngIf="solution.isFavourite">
          <img src="/assets/icons/favorite-filled.png" />
          SolutionDetail.RemoveFromFavorites
        </ng-container>
      </button>
    </div>
  </div>

  <div class="divider"></div>

  <div>
    <div class="row" *ngIf="userLanguageId != solution.originalLanguageId">
    <p class="py-2 translation-text">
      {{ 'Common.TranslationDisclaimer' | translate }}
      <a (click)="ViewOriginal(solution)" translate *ngIf="!showOriginalText">Common.OriginalText</a>
      <a (click)="ViewTranslated(solution)" translate *ngIf="showOriginalText">Common.TranslatedText</a>
    </p>
    </div>
    <!-- Description -->
    <div class="row">
      <p>{{ solution.description }}</p>
    </div>

    <!-- Valid From/To -->
    <div class="row">
      <p *ngIf="solution.validFrom || solution.validTo">
        <b translate> SolutionDetail.ValidFrom</b> <b>:</b>
        {{ solution.validFrom | date }}
        {{ "SolutionDetail.ValidUntil" | translate }}
        {{ solution.validTo | date }}
      </p>
      <p *ngIf="!solution.validFrom && !solution.validTo">
        <b translate> SolutionDetail.ValidFrom</b> <b>:</b>
        {{ "Solution.NoValidity" | translate }}
      </p>
    </div>

    <!-- Applicable To -->
    <div class="row">
      <p>
        <b translate>SolutionDetail.ApplicableTo</b><b>:</b>
        <ng-container *ngIf="solution.applyTo?.length == 0"> - </ng-container>
        <ng-container *ngFor="let val of solution.applyTo; let last = last">
          {{ val.name }}{{ !last ? ", " : "" }}
        </ng-container>
      </p>
    </div>

    <!-- Languages -->
    <div class="row">
      <p>
        <b translate>SolutionDetail.Languages</b><b>:</b>
        <ng-container *ngIf="solution.languages?.length == 0"> - </ng-container>
        <ng-container *ngFor="let lang of solution.languages; let last = last">
          {{ lang.englishName }}{{ !last ? ", " : "" }}
        </ng-container>
      </p>
    </div>

    <!-- Locations -->
    <div class="row">
      <p>
        <b translate>SolutionDetail.Locations</b><b>:</b>
        <ng-container *ngIf="solution.locations?.length == 0"> - </ng-container>
        <ng-container *ngFor="let val of solution.locations; let last = last">
          {{ val.name }}{{ !last ? ", " : "" }}
        </ng-container>
      </p>
    </div>

    <!-- Tags -->
    <div class="row" *ngIf="solution.tags">
      <p>
        <b translate>SolutionDetail.Tags</b><b>: </b>
        <span *ngIf="solution.tags?.length == 0" translate> Solution.NoTag </span>
        <span
          *ngFor="let tag of solution.tags"
          class="badge badge-pill badge-pill-primary"
        >
          {{ tag.name }}
        </span>
      </p>
    </div>

    <!-- Terms -->
    <div class="row" *ngIf="solution.termType; let termType">
      <p>
        <b translate>SolutionDetail.Terms</b><b>:</b>
        {{ termType.name }}
      </p>
    </div>

    <!-- Link to T&C -->
    <div class="row">
      <p>
        <b translate>SolutionDetail.TermsAndConditions</b><b>:</b>
        <span *ngIf="!solution.termsUrl" translate> Solution.NoTermUrl </span>
        <a href="{{ solution.termsUrl }}" target="_blank">
          {{ solution.termsUrl }}
        </a>
      </p>
    </div>

    <!-- How to get solution -->
    <div class="row">
      <p>
        <b translate>SolutionDetail.HowToGet</b><b>:</b>
        <ng-container *ngIf="!solution.getDescription"> - </ng-container>
        {{ solution.getDescription }}
      </p>
    </div>

    <!-- Solution Links -->
    <div class="row">
      <p class="mb-2">
        <b translate>SolutionDetail.Links</b><b>:</b>
        <!-- Show 'No links' if there are no valid links -->
      <span *ngIf="!solution.links || solution.links.length === 0 || !hasValidLinks()" translate>
        Solution.NoLinks
      </span>
      </p>
      <!-- Display valid links -->
      <ul *ngIf="solution.links">
        <ng-container *ngFor="let link of solution.links">
          <li *ngIf="link.length > 0">
            <a *ngIf="link && link.trim() !== ''" href="{{ link }}" target="_blank">{{ link }}</a>
          </li>
        </ng-container>
      </ul>
    </div>
    

    <!-- Media -->
    <div class="row">
      <p><b translate>SolutionDetail.Media</b><b>:</b>
        <span *ngIf="!videoEmbedUrl && solution.images?.length === 0" translate> 
          Solution.NoMedia
        </span>
      </p>
      <owl-carousel-o [options]="carouselOptions">
        <ng-template carouselSlide *ngIf="videoEmbedUrl; let url">
          <div class="sol-media-slide">
            <iframe
              width="560"
              height="320"
              [src]="url"
              title="YouTube video player"
              frameborder="0"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </ng-template>
        <ng-template
          carouselSlide
          *ngFor="let img of solution.images; let i = index"
        >
          <div class="sol-media-slide">
            <img [src]="img" />
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>
