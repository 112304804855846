<div *ngIf="addEditPropertyForm">
  <form
    name="createBranForm"
    [formGroup]="addEditPropertyForm"
    (submit)="saveProperty()"
  >
    <div class="offcanvas offcanvas-end show">
      <div class="offcanvas-header border-bottom">
        <h4 class="offcanvas-title" id="offcanvas-basic-title" translate>
          Property.Property
        </h4>
        <div
          type="button"
          class="btn-off"
          data-coreui-dismiss="offcanvas"
          aria-label="Close"
          (click)="onCancel()"
        ></div>
      </div>
      <div class="offcanvas-body">
        <div class="mb-3 row">
          <label
            for="subType"
            class="col-sm-4 col-form-label fw-semibold"
            translate
            >Property.SubType<span class="text-danger required-field">*</span>
            <span class="pl-05">
              <img
                src="../../../../assets/icons/info.svg"
                [alt]="'Property.SubTypeIconAltText' | translate"
                class="btn-icon"
                type="button"
                placement="bottom"
                [ngbPopover]="'Property.SubTypeHelpText' | translate"
                popoverClass="property-type-popover"
              />
            </span>
          </label>
          <div class="col-sm-8">
            <select
              class="form-select"
              (change)="onSelectChange($event)"
              name="propSubType"
              formControlName="propSubType"
              required
              [ngClass]="
                propSubType?.invalid &&
                (propSubType?.dirty || propSubType?.touched)
                  ? 'invalid-input'
                  : ''
              "
            >
              <option
                *ngFor="let subtype of propertySubTypes"
                [value]="subtype.propertySubtypeId"
              >
                {{ subtype.name }}
              </option>
            </select>
            <div
              *ngIf="
                propSubType?.invalid &&
                (propSubType?.dirty || propSubType?.touched)
              "
            >
              <span
                class="text-danger"
                *ngIf="propSubType?.errors?.['required']"
                >{{ "Property.SubTypeRequired" | translate }}</span
              >
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <label
            for="propName"
            class="col-sm-4 col-form-label fw-semibold"
            translate
            >Property.Name<span class="text-danger required-field">*</span>
            <span class="pl-05">
              <img
                src="../../../../assets/icons/info.svg"
                [alt]="'Property.NameIconAltText' | translate"
                class="btn-icon"
                type="button"
                placement="top"
                [ngbPopover]="'Property.NameHelpText' | translate"
                popoverClass="property-name-popover"
              />
            </span>
          </label>
          <div class="col-sm-8">
            <input
              class="form-control"
              name="propName"
              type="text"
              required
              maxlength="30"
              formControlName="propName"
              required
              [ngClass]="
                (propName?.invalid && (propName?.dirty || propName?.touched)) ||
                propertyNameExistsError
                  ? 'invalid-input'
                  : ''
              "
            />
            <div
              *ngIf="
                propName?.invalid && (propName?.dirty || propName?.touched)
              "
            >
              <span
                class="text-danger"
                *ngIf="propName?.errors?.['required']"
                >{{ "Property.PropertyNameRequired" | translate }}</span
              >
            </div>
            <div *ngIf="vaidationErrors">
              <span
                class="text-danger"
                *ngIf="propName?.errors?.['PROPERTY_NAME_ALREADY_EXISTS']"
              >
                {{ "Property.PropertyNameAlreadyExists" | translate }}
              </span>
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <label
            for="propUrl"
            class="col-sm-4 col-form-label fw-semibold"
            translate
            >Property.Url<span class="text-danger required-field">*</span>
            <span class="pl-05">
              <img
                src="../../../../assets/icons/info.svg"
                [alt]="'Property.UrlIconAltText' | translate"
                class="btn-icon"
                type="button"
                placement="top"
                [ngbPopover]="'Property.UrlHelpText' | translate"
                popoverClass="property-url-popover"
              />
            </span>
          </label>
          <div class="col-sm-8">
            <input
              class="form-control"
              name="propUrl"
              type="text"
              required
              maxlength="50"
              formControlName="propUrl"
              *ngIf="!propertyData?.propertyUrl"
              [ngClass]="
                (propUrl?.invalid && (propUrl?.dirty || propUrl?.touched)) ||
                propertyUrlExistsError
                  ? 'invalid-input'
                  : ''
              "
            />
            <input
              class="form-control-plaintext"
              name="propUrl"
              type="text"
              readonly
              required
              maxlength="50"
              formControlName="propUrl"
              *ngIf="propertyData?.propertyUrl"
            />
            <div
              *ngIf="propUrl?.invalid && (propUrl?.dirty || propUrl?.touched)"
            >
              <span class="text-danger" *ngIf="propUrl?.errors?.['required']">
                {{ "Property.PropertyUrlRequired" | translate }}
              </span>
              <span class="text-danger" *ngIf="propUrl?.errors?.['invalidUrl']">
                {{ propUrl?.errors?.['invalidUrl']?.message | translate }}
              </span>
            </div>
            <div *ngIf="vaidationErrors">
              <span
                class="text-danger"
                *ngIf="propUrl?.errors?.['PROPERTY_URL_ALREADY_EXISTS']"
              >
                {{ "Property.PropertyUrlAlreadyExists" | translate }}
              </span>
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <label
            for="primaryLang"
            class="col-sm-4 col-form-label fw-semibold"
            translate
            >Property.PrimaryLanguage<span class="text-danger required-field"
              >*</span
            >
            <span class="pl-05">
              <img
                src="../../../../assets/icons/info.svg"
                [alt]="'Property.PrimaryLanguageIconAltText' | translate"
                class="btn-icon"
                type="button"
                placement="top"
                [ngbPopover]="'Property.PrimaryLanguageHelpText' | translate"
              />
            </span>
          </label>
          <div class="col-sm-8">
            <select
              class="form-select"
              name="primaryLang"
              formControlName="primaryLang"
              [ngClass]="
                primaryLang?.invalid &&
                (primaryLang?.dirty || primaryLang?.touched)
                  ? 'invalid-input'
                  : ''
              "
              (change)="onLanguageChange($event)"
            >
              <option
                *ngFor="let lang of globalLanguages"
                [value]="lang.englishName"
              >
                {{ lang.englishName }}
              </option>
            </select>
            <div
              *ngIf="
                primaryLang?.invalid &&
                (primaryLang?.dirty || primaryLang?.touched)
              "
            >
              <span
                class="text-danger"
                *ngIf="primaryLang?.errors?.['required']"
                >{{ "Property.PrimaryLanguageRequired" | translate }}</span
              >
            </div>
          </div>
        </div>
        <div class="mb-3 row" *ngIf="otherLanguage">
          <label
            for="customLanguage"
            class="col-sm-4 col-form-label fw-semibold"
          ></label>
          <div class="col-sm-8">
            <input
              class="form-control"
              name="customLanguage"
              type="text"
              maxlength="30"
              id="customLanguage"
              formControlName="customLanguage"
              [ngClass]="
                customLanguage?.invalid &&
                (customLanguage?.dirty || customLanguage?.touched)
                  ? 'invalid-input'
                  : ''
              "
            />
            <div
              *ngIf="
                customLanguage?.invalid &&
                (customLanguage?.dirty || customLanguage?.touched)
              "
            >
              <span
                class="text-danger"
                *ngIf="customLanguage?.errors?.['required']"
                >{{ "Property.PrimaryLanguageRequired" | translate }}</span
              >
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <label
            for="propCountry"
            class="col-sm-4 col-form-label fw-semibold"
            translate
            >Property.PrimaryCountry<span class="text-danger required-field"
              >*</span
            >
            <span class="pl-05">
              <img
                src="../../../../assets/icons/info.svg"
                [alt]="'Property.PrimaryCountryIconAltText' | translate"
                class="btn-icon"
                type="button"
                placement="top"
                [ngbPopover]="'Property.PrimaryCountryHelpText' | translate"
              />
            </span>
          </label>
          <div class="col-sm-8">
            <select
              class="form-select"
              name="propCountry"
              formControlName="propCountry"
              [ngClass]="
                propCountry?.invalid &&
                (propCountry?.dirty || propCountry?.touched)
                  ? 'invalid-input'
                  : ''
              "
            >
              <option
                *ngFor="let country of countries"
                [value]="country.countryId"
              >
                {{ country.name }}
              </option>
            </select>
            <div
              *ngIf="
                propCountry?.invalid &&
                (propCountry?.dirty || propCountry?.touched)
              "
            >
              <span
                class="text-danger"
                *ngIf="propCountry?.errors?.['required']"
                >{{ "Property.PrimaryCountryRequired" | translate }}</span
              >
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <label
            for="propName"
            class="col-sm-4 col-form-label fw-semibold"
            translate
            >Property.GeographicReach<span class="text-danger">*</span></label
          >
          <div class="col-sm-8">
            <select
              class="form-select"
              name="geographicReach"
              id="geographicReach"
              formControlName="geographicReach"
              [ngClass]="
                geographicReach?.invalid &&
                (geographicReach?.dirty || geographicReach?.touched)
                  ? 'invalid-input'
                  : ''
              "
            >
              <option
                *ngFor="let geoReach of geographicalReaches"
                [value]="geoReach.geographicReachId"
              >
                {{ geoReach.name }}
              </option>
            </select>
            <div
              *ngIf="
                geographicReach?.invalid &&
                (geographicReach?.dirty || geographicReach?.touched)
              "
            >
              <span
                class="text-danger"
                *ngIf="geographicReach?.errors?.['required']"
                >{{ "Property.GeographicReachRequired" | translate }}</span
              >
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label fw-semibold" translate>
            Property.Badges
          </label>
          <div class="col-sm-8">
            <div
              *ngFor="let badge of propertyData?.badges"
              class="badge-container"
            >
              <img [src]="badge.primaryImageUrl" alt="" />
              <button type="button" (click)="onDeleteBadge(badge.badgeId)">
                -
              </button>
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <label
            for="propName"
            class="col-sm-4 col-form-label fw-semibold"
            translate
            >Property.GoalsText
            <span>
              <img
                src="../../../../assets/icons/info.svg"
                [alt]="'Property.GoalsIconAltText' | translate"
                class="btn-icon"
                type="button"
                placement="top"
                [ngbPopover]="'Property.GoalsHelpText' | translate"
                popoverClass="property-goal-popover"
              />
            </span>
          </label>
          <div class="col-sm-8" formGroupName="goals">
            <div
              class="form-check pb-2"
              *ngFor="let goal of goalsMasterList; trackBy: trackByGoalId"
            >
              <input
                [formControlName]="goal.goalId"
                class="form-check-input"
                type="checkbox"
                [id]="goal.goalId"
              />
              <label class="form-check-label" [for]="goal.goalId">
                {{ goal.name }}
              </label>
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <label
            for="propName"
            class="col-sm-4 col-form-label fw-semibold"
            translate
            >Property.PrimaryContentPriorityText</label
          >
          <div class="col-sm-8" formGroupName="contentPriority">
            <div
              class="form-check pb-2"
              *ngFor="
                let priority of propertyContentPriorities;
                trackBy: trackByContentPriorityId
              "
            >
              <input
                class="form-check-input"
                type="radio"
                [formControlName]="priority.propertyContentPriorityId"
                [id]="priority.propertyContentPriorityId"
                [value]="priority.propertyContentPriorityId"
                [(ngModel)]="selectedPropertyContentPriorityId"
              />
              <label
                class="form-check-label"
                [for]="priority.propertyContentPriorityId"
              >
                {{ priority.name }}
              </label>
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <label
            for="propName"
            class="col-sm-4 col-form-label fw-semibold"
            translate
            >Property.TrackProperty
            <span>
              <img
                src="../../../../assets/icons/info.svg"
                [alt]="'Property.TrackPropertyAltText' | translate"
                class="btn-icon"
                type="button"
                placement="top"
                [ngbPopover]="'Property.TrackPropertyHelpText' | translate"
                popoverClass="property-track-popover"
              />
            </span>
          </label>
          <div class="col-sm-8">
            <div class="form-check form-switch pt-2">
              <input
                class="form-check-input"
                name="trackPropertyControl"
                type="checkbox"
                role="switch"
                id="trackPropertyControl"
                formControlName="trackPropertyControl"
              />
              <label class="form-check-label" for="trackPropertyControl">{{
                trackPropertyControl?.value ? "Yes" : "No"
              }}</label>
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <label
            for="propName"
            class="col-sm-4 col-form-label fw-semibold"
            translate
            >Property.TrackFinancialPerformance
            <span>
              <img
                src="../../../../assets/icons/info.svg"
                [alt]="'Property.TrackFinancialPerformanceAltText' | translate"
                class="btn-icon"
                type="button"
                placement="top"
                [ngbPopover]="
                  'Property.TrackFinancialPerformanceHelpText' | translate
                "
                popoverClass="property-track-financial-popover"
              />
            </span>
          </label>
          <div class="col-sm-8">
            <div class="form-check form-switch pt-2">
              <input
                class="form-check-input"
                name="trackFinancialPerformanceControl"
                type="checkbox"
                role="switch"
                id="trackFinancialPerformanceControl"
                formControlName="trackFinancialPerformanceControl"
              />
              <label
                class="form-check-label"
                for="trackFinancialPerformanceControl"
                >{{
                  trackFinancialPerformanceControl?.value ? "Yes" : "No"
                }}</label
              >
            </div>
          </div>
        </div>
        <div class="row">
          <span translate>
            <img class="btn-info" src="../../../../assets/icons/info.svg" />
            Property.TrackFinancialPerformanceText
          </span>
        </div>
        <div class="row pt-05">
          <span translate>
            <img class="btn-info" src="../../../../assets/icons/info.svg" />
            Property.PropertyReviewHelpText
          </span>
        </div>
        <div class="row pt-05">
          <span translate>
            <img class="btn-info" src="../../../../assets/icons/info.svg" />
            Property.PropertyDataCollectionHelpText
          </span>
        </div>
      </div>
      <div class="p-3 modal-footer">
        <div class="row mb-2" *ngIf="propSubType?.value == website">
          <span translate>
            <img class="btn-info" src="../../../../assets/icons/info.svg" />
            Property.PropertyWebsiteHelpText
          </span>
        </div>
        <div
          class="row text-danger"
          *ngIf="addEditPropertyForm?.errors?.['Errors']"
        >
          <span translate>ErrorCodes.PROPERTY_ERRORS</span>
          <ul>
            <li
              *ngFor="let error of addEditPropertyForm.errors?.['ErrorCodes']"
              translate
            >
              {{ error }}
            </li>
          </ul>
        </div>
        <button
          type="button"
          class="btn btn-secondary mr-1"
          translate
          (click)="onCancel()"
        >
          Brand.Cancel
        </button>
        <button
          type="button"
          class="btn btn-secondary mr-1"
          [ngClass]="!propertyId ? 'd-none' : ''"
          translate
          (click)="showDeleteDialog()"
        >
          Property.Delete
        </button>
        <button type="submit" class="btn btn-primary" translate>
          Brand.Save
        </button>
      </div>
    </div>
  </form>
</div>

<div #deletePropertyModal class="modal fade show overlay" id="myModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" translate>Property.Confirm</h4>
        <div
          type="button"
          class="btn-off"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="closeDialog()"
        ></div>
      </div>
      <div class="modal-body">
        <p class="pl-013 pt-4 modal-text fw-semibold" translate>
          Property.DeleteWarning
        </p>
        <p class="p-3 modal-text" translate>Property.DeleteMessage</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeDialog()"
          translate
        >
          Organization.ConfirmNo
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="onDelete()"
          translate
        >
          Organization.ConfirmYes
        </button>
      </div>
    </div>
  </div>
</div>
