import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollStateService {
  private state: { [url: string]: { scrollPosition: number; data: any, additonalInfo?:any } } = {};

  saveState<T>(url: string, scrollPosition: number, data: T,additonalInfo?:any): void {
    this.state[url] = { scrollPosition, data, additonalInfo };
  }

  getScrollPosition(url: string): number {
    return this.state[url]?.scrollPosition || 0;
  }

  getData<T>(url: string): T | null {
    return this.state[url]?.data || null;
  }
  getAdditonalInfo<T>(url: string): T | null {
    return this.state[url]?.additonalInfo || null;
  }
}
