import { AfterViewInit, Component, OnInit } from '@angular/core';
import { filter, map, mergeMap, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FindMediaOrganization,
  SingleFindMediaBadge,
} from 'src/app/shared/models/OrganizationMedia';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { FindMediaBrand } from 'src/app/shared/models/FindMediaBrand';
import { FindMediaProperty } from 'src/app/shared/models/FindMediaProperty';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { FavouriteMedia } from 'src/app/shared/models/FavouriteMedia';
import { OrganizationService } from 'src/app/services/organization.service';
import { BrandManagementService } from 'src/app/services/brandmanagement.service';
import { PropertyManagementService } from 'src/app/services/propertymanagement.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { BadgeInformationComponent } from './badge-information/badge-information.component';
@Component({
  selector: 'app-single-organization',
  templateUrl: './single-organization.component.html',
  styleUrls: ['./single-organization.component.css'],
})
export class SingleOrganizationComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();
  organization!: FindMediaOrganization;
  id: string | null = null;
  isLoading: boolean = true;
  toggledBrands: { [key: string]: boolean } = {};
  constructor(
    private route: ActivatedRoute,
    private organizationService: OrganizationService,
    private router: Router,
    private notificationService: NotificationService,
    private clipboardService: ClipboardService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private brandService: BrandManagementService,
    private propertyService: PropertyManagementService,
    private modalService: NgbModal
  ) {}
  ngOnInit(): void {
    this.loadOrganization();
  }
  loadOrganization() {
    this.spinner.show();
    this.route.paramMap
      .pipe(
        takeUntil(this._destroying$),
        map((params: { get: (arg0: string) => any }) => params.get('id')),
        filter((id) => id !== null),
        mergeMap((id) => this.organizationService.getOrganizationMediaById(id!))
      )
      .subscribe((org) => {
        this.organization = org;
        this.isLoading = false;
        this.spinner.hide();
      }),
      (error: any) => {
        this.isLoading = false;
        this.spinner.hide();
      };
  }

  get getAllLanguages(): string {
    const languagesSet = new Set<string>();

    if (this.organization && this.organization.languages) {
      for (const lang of this.organization.languages) {
        if (lang) {
          languagesSet.add(lang);
        }
      }
    }

    return Array.from(languagesSet).join(', ');
  }
  getBrandExile(value?: boolean): string {
    const brandExile: { [key: string]: string } = {
      false: this.translate.instant('FindMedia.NotinExile'),
      true: this.translate.instant('FindMedia.OperatesinExile'),
    };

    return (
      brandExile[String(value)] ||
      this.translate.instant('FindMedia.NotApplicable')
    );
  }
  toggleBrand(brandId: string): void {
    this.toggledBrands[brandId] = !this.toggledBrands[brandId];
  }
  copyToClipboard() {
    const baseUrl = window.location.origin;
    const copyUrl = `${baseUrl}${this.router.url}`;
    this.clipboardService.copyToClipboard(copyUrl);
  }
  onOrgFavourite(orgId: string) {
    this.organizationService.favouriteOrganizationMedia(orgId).subscribe({
      next: (res: ApiResponse) => {
        const response = res.result as unknown as boolean;
        this.organization.isFavorite = response;
        response
          ? this.notificationService.showSuccessToast(
              this.translate.instant('FindMedia.AddedToFavorites')
            )
          : this.notificationService.showSuccessToast(
              this.translate.instant('FindMedia.RemovedFromFavorites')
            );
        this.loadOrganization();
      },
      error: (error) => {
        this.notificationService.showSuccessToast(
          this.translate.instant('FindMedia.Error')
        );
      },
    });
  }
  onBrandFavourite(brand: FindMediaBrand) {
    this.brandService.favouriteBrandMedia(brand.brandId).subscribe({
      next: (res: ApiResponse) => {
        const response = res.result as unknown as boolean;
        brand.isFavorite = response;
        this.organization.brands = this.updateBrandInArray(brand);
        response
          ? this.notificationService.showSuccessToast(
              this.translate.instant('FindMedia.AddedToFavorites')
            )
          : this.notificationService.showSuccessToast(
              this.translate.instant('FindMedia.RemovedFromFavorites')
            );
      },
      error: (error) => {
        this.notificationService.showSuccessToast(
          this.translate.instant('FindMedia.Error')
        );
      },
    });
  }
  onPropertyFavourite(property: FindMediaProperty) {
    this.propertyService.favouritePropertyMedia(property.propertyId).subscribe({
      next: (res: ApiResponse) => {
        const response = res.result as unknown as boolean;
        response
          ? this.notificationService.showSuccessToast(
              this.translate.instant('FindMedia.AddedToFavorites')
            )
          : this.notificationService.showSuccessToast(
              this.translate.instant('FindMedia.RemovedFromFavorites')
            );
        this.loadOrganization();
      },
      error: (error) => {
        this.notificationService.showSuccessToast(
          this.translate.instant('FindMedia.Error')
        );
      },
    });
  }
  updateBrandInArray(
    updatedBrand: Partial<FindMediaBrand> & { brandId: string }
  ): FindMediaBrand[] {
    return this.organization.brands.map((br) =>
      br.brandId === updatedBrand.brandId ? { ...br, ...updatedBrand } : br
    );
  }
  onBadgeClick(badge: SingleFindMediaBadge) {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(BadgeInformationComponent, {
      centered: true,
    });
    modalRef.componentInstance.badge = badge;
  }
}
