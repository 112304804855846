import {
  LogLevel,
  Configuration,
  BrowserCacheLocation,
} from '@azure/msal-browser';
import { AppConfig } from '../models/AppConfig';

export function getB2CPolicies(appConfig: AppConfig) {
  return {
    names: {
      signUpSignIn: 'B2C_1_Singup_Signin',
      passwordReset: 'B2C_1_PasswordReset',
    },
    authorities: {
      signUpSignIn: {
        authority: appConfig.azureb2cClient.signUpSignInAuthority,
      },
      passwordReset: {
        authority: appConfig.azureb2cClient.passwordResetAuthority,
      },
    },
    authorityDomain: appConfig.azureb2cClient.authorityDomain,
  };
}

export function getMsalConfig(appConfig: AppConfig): Configuration {
  const b2cPolicies = getB2CPolicies(appConfig);
  return {
    auth: {
      clientId: appConfig.azureb2cClient.clientId,
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [b2cPolicies.authorityDomain],
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (logLevel, message, containsPii) => {
          //console.log(message);
        },
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false,
      },
    },
  };
}

export interface Endpoint {
  httpMethods: string[];
  url: string;
}
export interface Endpoints {
  currentUser: Endpoint;
  userProfile: Endpoint;
  users: Endpoint;
  organizationEdit: Endpoint;
  organization: Endpoint;
  report: Endpoint;
  brand: Endpoint;
  masterData: Endpoint;
  property: Endpoint;
  segments: Endpoint;
  partners: Endpoint;
  dataDashboard: Endpoint;
  financial: Endpoint;

  solutionListingsManage: Endpoint;
  solutionListings: Endpoint;

  badges: Endpoint;

  userSavedView: Endpoint;

  manageBadge: Endpoint;
  badgeApplication: Endpoint;
}
export interface ProtectedResources {
  endpoints: Endpoints;
  scopes: Record<string, string[]>;
}

export const protectedResourceEndpoints: Endpoints = {
  currentUser: {
    httpMethods: ['GET'],
    url: 'api/users/current',
  },
  userProfile: {
    httpMethods: ['GET', 'PUT'],
    url: 'api/users/userprofile',
  },
  users: {
    httpMethods: ['GET', 'PUT', 'DELETE', 'POST'],
    url: 'api/users/',
  },
  organizationEdit: {
    httpMethods: ['GET'],
    url: 'api/organizations/edit/',
  },
  organization: {
    httpMethods: ['PUT', 'GET', 'DELETE', 'POST'],
    url: 'api/organizations/',
  },
  report: {
    httpMethods: ['GET'],
    url: 'api/powerbi/',
  },
  brand: {
    httpMethods: ['POST', 'GET', 'PUT', 'DELETE'],
    url: 'api/brands/',
  },
  masterData: {
    httpMethods: ['GET'],
    url: 'api/masterdata/',
  },
  segments: {
    httpMethods: ['GET', 'POST', 'DELETE'],
    url: 'api/segments',
  },
  property: {
    httpMethods: ['GET', 'POST', 'PUT', 'DELETE'],
    url: 'api/properties/',
  },
  partners: {
    httpMethods: ['GET', 'POST', 'PUT', 'DELETE'],
    url: 'api/partners/',
  },
  dataDashboard: {
    httpMethods: ['GET', 'PUT'],
    url: 'api/dataDashboard/',
  },
  financial: {
    httpMethods: ['GET', 'POST', 'PUT', 'DELETE'],
    url: 'api/financials/',
  },

  solutionListingsManage: {
    httpMethods: ['GET', 'POST', 'PUT', 'DELETE'],
    url: 'api/manage/solutions/',
  },

  solutionListings: {
    httpMethods: ['GET', 'POST', 'PUT', 'DELETE'],
    url: 'api/solutions/',
  },
  userSavedView: {
    httpMethods: ['GET', 'POST', 'PUT', 'DELETE'],
    url: 'api/usersavedview/',
  },
  badges: {
    httpMethods: ['GET', 'POST', 'PUT', 'DELETE'],
    url: 'api/badges/',
  },
  manageBadge: {
    httpMethods: ['GET', 'POST', 'PUT', 'DELETE'],
    url: 'api/manage/badges/',
  },
  badgeApplication: {
    httpMethods: ['GET', 'POST', 'PUT', 'DELETE'],
    url: 'api/badgeapplication/',
  },
};

export function getProtectedResources(
  appConfig: AppConfig
): ProtectedResources {
  return {
    endpoints: protectedResourceEndpoints,
    scopes: {
      apiAccess: [appConfig.azureb2cClient.apiScope],
    },
  };
}
