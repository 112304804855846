import { Component } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification.service';


@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.css'],
  host: { class: 'toast-container align-items-center alert d-flex position-fixed top-0 end-0 p-3' }
})
export class ToasterComponent {

  constructor(public notificationService: NotificationService) { }
}
