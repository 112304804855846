<div class="modal-header">
  <h4 class="modal-title" translate>DataConnections.SiteSelection</h4>
  <div type="button" class="btn-off" data-coreui-dismiss="offcanvas" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></div>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="mb-3 row" *ngIf="googleSites.length > 0">
        <label for="subType" class="col-sm-4 col-form-label fw-semibold">Account<span
            class="text-danger">*</span></label>
        <div class="col-sm-8">
          <select class="form-select" name="account" (change)="onSiteSelect($event)">
            <option value="0" translate>DataConnections.SelectOption</option>
            <option *ngFor="let site of googleSites" [value]="site.siteUrl">
              {{site.siteUrl}}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark btn-primary" [disabled]="!selectedSite || selectedSite==='0'"
    (click)="selectGoogleSearchConsoleSite()" translate>DataConnections.Select</button>
  <button type="button" class="btn btn-outline-dark btn-secondary" (click)="activeModal.close('Close click')" translate>Buttons.Close</button>
</div>
