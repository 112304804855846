import { Component, Input } from '@angular/core';

@Component({
  selector: '[app-solution-placeholder]',
  templateUrl: './solution-placeholder.component.html',
  styleUrls: ['./solution-placeholder.component.css']
})
export class SolutionPlaceholderComponent {
  @Input() SolutionProviderName!: string;
}
