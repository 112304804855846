import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, filter, mergeMap, Subject, Subscription, take, takeUntil } from 'rxjs';
import { AppContext } from './shared/context/AppContext';
import { AuthService } from './shared/services/auth.service';
import { UserService } from './services/user.service';
import { IdleService } from './shared/services/idle.service';
import { MenuService } from './shared/services/menu.service';
import { ShepherdDataService } from './shared/services/shepherd-data.service';
import { UtilityService } from './shared/services/utility.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly _destroying$ = new Subject<void>();
  isLoggedIn = false;
  menuSubscription?: Subscription;

  constructor(
    private appContext: AppContext,
    private authService: AuthService,
    private userService: UserService,
    private translate: TranslateService,
    private idleService: IdleService,
    public menuService: MenuService,
    private shepherdDataService: ShepherdDataService,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.authService.registerAuth(this._destroying$);

    this.appContext.authStatus.pipe(
      filter((isLoggedIn) => { this.isLoggedIn = isLoggedIn; return isLoggedIn }),
      mergeMap(() => this.userService.getCurrentUser()),
      takeUntil(this._destroying$)
    ).subscribe((user) => {
      this.appContext.setUser(user);
    });

    this.appContext.user.pipe(
      takeUntil(this._destroying$)
    ).subscribe((user) => {
      if (user) {
        this.translate.use(user.languageCode);
        this.appContext.setOrganizations(user.userOrganizations);
      } else {
        this.translate.use("en");
        this.appContext.setOrganization(null);
      }
    });

    this.idleService.register();

    this.menuSubscription = this.menuService.menuOpenSubject.subscribe(res => {
      this.menuService.isMenuOpen = res;
    });

    //Hide menu for mobile devices by default
    if (this.utilityService.isMobile())
      this.menuService.menuOpenSubject.next(false);

    //Open menu on mobile devices if tour not seen
    if (this.utilityService.isMobile() && localStorage.getItem("seen_tour") !== "true")
      this.menuService.menuOpenSubject.next(true);
  }

  ngAfterViewInit() {
    combineLatest([this.appContext.user, this.appContext.organization])
      .pipe(take(1)).subscribe(([user, organization]) => {
        if (localStorage.getItem("seen_tour") !== "true") {
          this.shepherdDataService.intializeTour(user, organization);
          localStorage.setItem("seen_tour", "true");
        }
      });
  }

  hideMenu() {
    this.menuService.menuOpenSubject.next(false);
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();

    if (this.menuSubscription && !this.menuSubscription.closed)
      this.menuSubscription.unsubscribe();
  }
}
