import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormRecord, Validators } from '@angular/forms';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { catchError, EMPTY, filter, map, mergeMap, Subject, Subscription, takeUntil, zip } from 'rxjs';
import { SegmentService } from '../../../services/segment.service';
import { Report } from '../../../shared/app-constants/constants';
import { AppContext } from '../../../shared/context/AppContext';
import { Segment } from '../../../shared/models/Segment';
import { SegmentsContext } from '../../../shared/models/SegmentsContext';
import { SegmentVariables } from '../../../shared/models/SegmentVariables';
import { UserOrganization } from '../../../shared/models/UserOrganization';
import { Variable } from '../../../shared/models/Variable';
import { VariableCategory } from '../../../shared/models/VariableCategory';
import { MasterDataService } from '../../../shared/services/master-data.service';
import { NotificationService } from '../../../shared/services/notification.service';

@Component({
  selector: 'app-add-segment',
  templateUrl: './add-segment.component.html',
  styleUrls: ['./add-segment.component.css']
})
export class AddSegmentComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  variableCategories: VariableCategory[] | null = null;
  variables!: Variable[];
  formGroup!: FormGroup;
  variablesControlSubscription!: Subscription;
  selectedVariables!: Variable[];
  organization!: UserOrganization;
  segments!: Segment[];
  report!: Report;
  private readonly _maxVariablesSelection = 3;

  get segmentName() { return this.formGroup.get('segmentName'); }
  errorCodesMap = new Map<string, () => (AbstractControl<any, any>|null)>([
    ["SEGMENT_NAME_EXISTS", () => this.segmentName]
  ]);

  constructor(
    private segmentService: SegmentService,
    private masterDataService: MasterDataService,
    private appContext: AppContext,
    public activeOffcanvas: NgbActiveOffcanvas,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private translate: TranslateService,
  ) { }

  @Input() inputSegment: SegmentVariables | null = null;

  ngOnInit(): void {
    zip(
      this.appContext.user,
      this.appContext.organization.pipe(
        filter((organization): organization is UserOrganization => !!organization)
      ),
      this.appContext.segmentsContext.pipe(
        filter((segmentsContext): segmentsContext is SegmentsContext => !!segmentsContext),
      )
    ).pipe(
      mergeMap(([user, organization, segmentsContext]) =>
        this.masterDataService.getVariableCategories(user.languageId, segmentsContext.report).pipe(
          map((variableCategories) => {
            return { variableCategories, organization, segmentsContext };
          }
        )
      )),
      takeUntil(this._destroying$)
    ).subscribe(({ variableCategories, organization, segmentsContext }) => {
      this.segments = segmentsContext.segments;
      this.report = segmentsContext.report;
      this.organization = organization;
      this.variableCategories = variableCategories;
      this.variables = variableCategories.flatMap((cat) => cat.variables);

      const segmentNameValue = this.inputSegment?.name ?? '';
      const variableIdValues = this.inputSegment?.variables ?? [];
      this.selectedVariables = this.variables.filter((variable) => variableIdValues.includes(variable.variableId));

      this.formGroup = this.formBuilder.group({
        segmentName: [segmentNameValue, [Validators.required, Validators.pattern('^[a-zA-Z0-9\\W_]*$')]],
        searchVariable: new FormControl(''),
        variables: this.formBuilder.record(Object.assign({}, ...this.variables.map((variable) => {
          return { [variable.variableId]: variableIdValues.includes(variable.variableId) };
        })))
      });

      const variablesControl = this.formGroup.controls.variables as FormRecord<FormControl<boolean>>;
      this.variablesControlSubscription = variablesControl.valueChanges.subscribe(values => {
        const checkedVariableIds: string[] = [];
        for (const key in values) {
          if (values[key]) {
            checkedVariableIds.push(key);
          }
        }
        if (checkedVariableIds.length > this._maxVariablesSelection) {
          for (const key of checkedVariableIds) {
              const index = this.selectedVariables.findIndex((sv) => sv.variableId == key);
              if (index == -1) {
                variablesControl.get(key)?.setValue(false);
              }
          }
        } else {
          this.selectedVariables = this.variables.filter((variable) => values[variable.variableId]);
        }
      });

      this.formGroup.controls.searchVariable.valueChanges.subscribe(searchText => {
        const lowerSearchText = searchText.toLowerCase();
        this.variableCategories = variableCategories.map((vc) => {
          const vcClone = { ...vc };
          vcClone.variables = vc.variables.filter((v) => v.name.toLowerCase().includes(lowerSearchText));
          return vcClone;
        });
      });
    });
  }

  saveSegment() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      const createSegment = new SegmentVariables(
        this.inputSegment?.segmentId ?? null,
        this.segmentName?.value,
        this.organization.organizationId,
        this.report,
        this.selectedVariables.map(v => v.variableId)
      );
      this.segmentService.saveSegment(createSegment)
        .pipe(
          catchError((error) => {
            if (error?.status === 400 && error.error?.errorCodes) {
              error.error.errorCodes.forEach((errorCode: string) => {
                this.errorCodesMap.get(errorCode)?.call(this)?.setErrors({ [errorCode]: true });
              });
              this.formGroup.setErrors({ Errors: true, ErrorCodes: error.error.errorCodes.map((c: string) => `Segment.Save.${c}`) });
              return EMPTY;
            }
            throw error;
          })
        )
        .subscribe((segment) => {
          if (this.inputSegment == null) {
            this.segments.push(segment);
          } else {
            const existingSegment = this.segments.find(x => x.segmentId == segment.segmentId);
            existingSegment && (existingSegment.name = segment.name);
          }
          this.appContext.setSegmentsContext(new SegmentsContext(this.report, this.segments));
          this.activeOffcanvas.close();
        });
    }
  }

  deleteSegment() {
    if (this.inputSegment?.segmentId) {
      this.segmentService.deleteSegment(this.inputSegment.segmentId).subscribe((success) => {
        if (success) {
          this.segments = this.segments.filter(x => x.segmentId != this.inputSegment?.segmentId);
          this.appContext.setSegmentsContext(new SegmentsContext(this.report, this.segments));
          this.activeOffcanvas.close();
        } else {
          this.notificationService.showErrorToast(this.translate.instant('Segment.Save.DeleteError'));
        }
      });
    }
  }

  trackByCategory(index: number, item: VariableCategory) {
    return item.variableCategoryId;
  }

  trackByVariable(index: number, item: Variable) {
    return item.variableId;
  }

  ngOnDestroy(): void {
    this.variablesControlSubscription && this.variablesControlSubscription.unsubscribe();
    this._destroying$.next();
    this._destroying$.complete();
  }
}
