import { Component } from '@angular/core';
import { NgbOffcanvas, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { UserService } from 'src/app/services/user.service';
import { PartnerStatus, UserStatus } from 'src/app/shared/app-constants/constants';
import { FlatPartner } from 'src/app/shared/models/FlatPartner';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { EditPartnerUserComponent } from '../edit-partner-user/edit-partner-user.component';
import { User } from 'src/app/shared/models/User';

@Component({
  selector: 'app-primarypartner-name-renderer',
  templateUrl: './primarypartner-name-renderer.component.html',
  styleUrls: ['./primarypartner-name-renderer.component.css']
})
export class PrimarypartnerNameRendererComponent implements ICellRendererAngularComp {
  partner!: FlatPartner;
  isPartnerActive: boolean = false;
  userStatus = UserStatus;
  params!: ICellRendererParams<any, any, any>;

  constructor(private offcanvasService: NgbOffcanvas,
              private modalService: NgbModal,
              private userService: UserService,
              private notificationService: NotificationService,
              private translate: TranslateService) {}


  agInit(params: ICellRendererParams<any, any, any>): void {
    this.partner = params.data;
    this.isPartnerActive = this.partner.partnerStatusId === PartnerStatus.Active;
    this.params = params;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return true;
  }

  getOtherUserNames(otherUsers: User[]) {
    return otherUsers.map(otherUser => `${otherUser.firstName} ${otherUser.lastName}`).join(', ');
  }

  EditPartnerUser(user: User) {
    if (!this.isPartnerActive) return;

    const offcanvasRef = this.offcanvasService.open(EditPartnerUserComponent, { ariaLabelledBy: 'offcanvas-basic-title', position: 'end', backdrop: 'static' });
    offcanvasRef.componentInstance.params = this.params;
    offcanvasRef.componentInstance.user = user;
  }
}
