<div class="">
  <span
    class="badge rounded-pill font-normal fw-normal py-2 px-3 d-flex align-items-center"
    [ngClass]="{
      'bg-success-subtle text-success-emphasis': active,
      'bg-danger-subtle text-danger-emphasis': !active
    }"
    [style.width.px]="100"
  >
    <i
      class="bi me-1"
      [ngClass]="{ 'bi-eye': active, 'bi-eye-slash': !active }"
    ></i>
    <span class="text-center">
      {{ (active ? "Active" : "Inactive") | translate }}
    </span>
  </span>
</div>
