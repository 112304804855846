import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { protectedResourceEndpoints } from '../shared/auth-config/auth-config';
import { User } from '../shared/models/User';
import { UserProfile } from '../shared/models/UserProfile';
import { OrganizationUserListForManage } from '../shared/models/OrganizationUserListForManage';
import { ApiResponse } from '../shared/models/ApiResponse';
import { AddToOrganization } from '../shared/models/AddToOrganization';
import { CanRemoveUserFromOrg } from '../shared/models/CanRemoveUserFromOrg';
import { FlatUser } from '../shared/models/FlatUser';
import { FlatPartner } from '../shared/models/FlatPartner';
import { map , Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  private _currentUserUrl = protectedResourceEndpoints.currentUser.url;
  private _userProfileUrl = protectedResourceEndpoints.userProfile.url;
  private _usersUrl = protectedResourceEndpoints.users.url;

  getCurrentUser() {
    return this.http.get<User>(this._currentUserUrl);
  }

  getUserProfileForEdit() {
    return this.http.get<UserProfile>(this._userProfileUrl);
  }

  saveUserProfile(user: User) {
    return this.http.put<UserProfile>(this._userProfileUrl, user);
  }

  removeUserBadge(badgeId: string): Observable<boolean> {
    return this.http
      .delete<ApiResponse<boolean>>(`${this._usersUrl}badge/${badgeId}`)
      .pipe(map((response) => response.result));
  }

  saveUserVisibility(
    body: Array<{
      organizationId: string;
      visibleFromUser: boolean;
    }>
  ) {
    return this.http.put<ApiResponse>(`${this._usersUrl}visibility`, body);
  }

  saveUserStatusChange(user: FlatUser) {
    return this.http.put<ApiResponse>(`${this._usersUrl}partner`, user);
  }

  addUserToOrganization(addToOrganization: AddToOrganization) {
    return this.http.post(
      `${this._usersUrl}organization/register`,
      addToOrganization
    );
  }

  getDeleteUserValidation() {
    return this.http.get<CanRemoveUserFromOrg>(`${this._usersUrl}deleteuser`);
  }

  deleteUser() {
    return this.http.delete(`${this._usersUrl}deleteuser`);
  }

  getPartnersForManage(userId: string) {
    return this.http.get<FlatPartner[]>(`${this._usersUrl}systemadmin`);
  }
}
