import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IReportEmbedConfiguration, models, Page, service, Embed } from 'powerbi-client';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { IBasicFilter } from 'powerbi-models';
import { combineLatest, combineLatestWith, filter, interval, merge, mergeMap, of, Subject, switchMap, takeUntil, takeWhile, tap } from 'rxjs';
import { AppContext } from '../../../shared/context/AppContext';
import { PbiService } from '../../../services/pbi.service';
import { ActivatedRoute, Params } from '@angular/router';
import { UserOrganization } from '../../../shared/models/UserOrganization';
import { Report, ReportPagePropertySubTypeMap, ReportType, Roles, RoutePath } from '../../../shared/app-constants/constants';
import { EmbedToken } from '../../../shared/models/EmbedToken';
import { OrganizationService } from '../../../services/organization.service';
import { OrganizationUserReport } from '../../../shared/models/OrganizationUserReport';
import { propertySubTypes } from 'src/app/shared/app-constants/constants';
import { UtilityService } from '../../../shared/services/utility.service';

const MINUTES_BEFORE_EXPIRATION = 5;
const REFRESH_TOKEN_INTERVAL_TIME = 60000;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit, OnDestroy {
  phasedEmbeddingFlag = false;
  embedToken: EmbedToken|null = null;
  reportType!: ReportType;
  reportPages!: Page[];
  segmentFilterReports: ReportType[] = [ReportType.SocialMedia, ReportType.Website];
  dataRequiredReports: ReportType[] = [ReportType.SocialMedia, ReportType.Website];
  isDataRequiredReport: boolean = false;
  userReportsData!: OrganizationUserReport[];
  isOrgAdmin: boolean = false;
  hideReport: boolean = false;
  brandsLink = `/${RoutePath.Brands}`;

  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: {
      layoutType: models.LayoutType.MobilePortrait
    }
  };

  constructor(
    private pbiService: PbiService,
    private appContext: AppContext,
    private activatedRoute: ActivatedRoute,
    private organizationService: OrganizationService,
    private utilityService: UtilityService
  ) { }

  private readonly _destroying$ = new Subject<void>();
  private _destroyingReport$ = new Subject<void>();
  userOrganization!: UserOrganization;
  private _report: Report = Report.Property;

  @ViewChild(PowerBIReportEmbedComponent)
  reportObj!: PowerBIReportEmbedComponent;
  eventHandlersMap = new Map([
    ['loaded', () => {
      //this.applyOrganizationFilter(this.userOrganization.organizationId);

      merge(this.appContext.getSegment(this._report), this.appContext.organization)
        .pipe(
          takeWhile(() => this.reportObj != null),
          takeUntil(this._destroyingReport$)
        ).subscribe((value) => {
          if (typeof value === 'string' && this.segmentFilterReports.includes(this.reportType)) {
            this.applySegmentFilter(value as string);
          }
          const userOrganization: UserOrganization | null = value as unknown as UserOrganization | null;
          if (userOrganization?.organizationId) {
            this.applyOrganizationFilter((value as UserOrganization).organizationId);
          }
        });

      this.activatedRoute.queryParams.pipe(
        takeWhile(() => this.reportObj != null),
        takeUntil(this._destroyingReport$)
      ).subscribe(params => {
        const pageDisplayName = params['platform'];
        if (pageDisplayName) {
          this.setPage(pageDisplayName);
        }
      });
    }],
  ]) as Map<
    string,
    (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null
  >;

  getReportObservable() {
    if (this.reportType === ReportType.Country) {
      return this.pbiService.getCountryReportParams(this.userOrganization.organizationId);
    } else if (this.reportType === ReportType.SocialMedia) {
      return this.pbiService.getPropertySocialMediaReportParams(this.userOrganization.organizationId);
    } else if (this.reportType === ReportType.Website) {
      return this.pbiService.getPropertyWebsiteReportParams(this.userOrganization.organizationId);
    }
    return this.pbiService.getJournalReport(this.userOrganization.organizationId);
  }

  ngOnInit(): void {
    combineLatest([
      combineLatest([
        this.appContext.organization.pipe(
          filter((organization): organization is UserOrganization => !!organization)
        ),
        this.activatedRoute.data
      ]).pipe(
        switchMap(([organization, routeData]) => {
          this.userOrganization = organization;
          this.isOrgAdmin = this.userOrganization.roleId === Roles.ORGADMIN;

          this.reportType = routeData.reportType;
          this.isDataRequiredReport = this.dataRequiredReports.includes(this.reportType);
          return combineLatest([
            (this.isDataRequiredReport ? this.organizationService.getUserReportsData(this.userOrganization.organizationId) : of([] as OrganizationUserReport[])),
            this.getReportObservable()
          ]);
        })
      ),
      this.activatedRoute.queryParams
    ]).subscribe(([[userReportsData, params], queryParams]) => {
      this.userReportsData = userReportsData;
      const pageName = queryParams['platform'];
      this.hideReport = !this.isDataAvailableForReport(pageName);

      if (this.hideReport) {
        this.embedToken = null;
        this._destroyingReport$.next();
        this._destroyingReport$.complete();
        this._destroyingReport$ = new Subject<void>();
        return;
      }

      this.embedToken = params.embedToken;
      this.reportConfig = {
        type: "report",
        id: params.embedReport[0].reportId,
        embedUrl: params.embedReport[0].embedUrl,
        accessToken: params.embedToken.token,
        tokenType: models.TokenType.Embed,
        filters: [this.getOrganizationFilter(this.userOrganization.organizationId)],
        settings: {
          layoutType: this.utilityService.isMobileReportContainer() && this.hasMobileLayout() ? models.LayoutType.MobilePortrait : models.LayoutType.Custom,
          customLayout: {
            displayOption: models.DisplayOption.FitToWidth,
          },
          filterPaneEnabled: false,
          navContentPaneEnabled: false,
          panes: {
            filters: {
              visible: false
            }
          },
          background: models.BackgroundType.Transparent,
        }
      };
    });

    interval(REFRESH_TOKEN_INTERVAL_TIME).pipe(
      filter(() => this.embedToken != null),
      takeUntil(this._destroying$)
    ).subscribe(() => this.checkTokenAndUpdate());
  }

  hasMobileLayout() {
    return this.reportType !== ReportType.Journal;
  }

  isDataAvailableForReport(pageName: string) {
    if (this.isDataRequiredReport) {
      if (this.reportType === ReportType.Website && !this.userReportsData.find(x => x.propertySubTypeId === propertySubTypes.Website)) {
        // show message
        return false;
      } else if (this.reportType === ReportType.SocialMedia) {
        const pagePropertySubType = ReportPagePropertySubTypeMap.get(pageName);
        if (!this.userReportsData.find(x => x.propertySubTypeId === pagePropertySubType)) {
          // show message
          return false;
        }
      }
    }
    return true;
  }

  checkTokenAndUpdate() {
    // Get the current time
    const currentTime = Date.now();
    const expiration = Date.parse(this.embedToken!.expiration.toString());

    // Time until token expiration in milliseconds
    const timeUntilExpiration = expiration - currentTime;
    const timeToUpdate = MINUTES_BEFORE_EXPIRATION * 60 * 1000;

    // Update the token if it is about to expired
    if (timeUntilExpiration <= timeToUpdate) {
      this.getReportObservable().pipe(
        takeUntil(this._destroying$)
      ).subscribe(async (params) => {
        this.embedToken = params.embedToken;
        await this.reportObj.getReport().setAccessToken(params.embedToken.token);
      });
    }
  }

  //@HostListener('window:resize', ['$event'])
  //async onWindowResize() {
  //  const isMobileWidth = this.utilityService.isMobileReportContainer();
  //  if (this.isMobileWidth != isMobileWidth) {
  //    this.isMobileWidth = isMobileWidth;
  //    await this.updateLayoutSettings();
  //  }
  //}

  //async updateLayoutSettings() {
  //  const newSettings = {
  //    layoutType: this.isMobileWidth ? models.LayoutType.MobilePortrait : models.LayoutType.Custom,
  //    customLayout: {
  //      displayOption: models.DisplayOption.FitToWidth
  //    }
  //  };
  //  await this.reportObj.getReport().updateSettings(newSettings);
  //}

  getOrganizationFilter(organizationId: string) {
    const filter: IBasicFilter = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "Organization",
        column: "Organization ID"
      },
      operator: "In",
      values: [organizationId],
      filterType: models.FilterType.Basic,
    }
    return filter;
  }

  async applyOrganizationFilter(organizationId: string) {
    const filter = this.getOrganizationFilter(organizationId);
    await this.reportObj.getReport().updateFilters(models.FiltersOperations.Replace, [filter]);
  }

  async applySegmentFilter(segmentId: string) {
    const filter: IBasicFilter = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "Segments",
        column: "Segment Id"
      },
      operator: "In",
      values: [segmentId],
      filterType: models.FilterType.Basic,
    }
    await this.reportObj.getReport().updateFilters(models.FiltersOperations.Replace, [filter]);
  }

  async setPage(pageDisplayName: string) {
    if (!this.reportPages) {
      this.reportPages = await this.reportObj.getReport().getPages();
    }
    const pageName = this.reportPages.find(x => x.displayName === pageDisplayName)?.name;
    if (pageName) {
      await this.reportObj.getReport().setPage(pageName);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();

    this._destroyingReport$.next();
    this._destroyingReport$.complete();
  }
}
