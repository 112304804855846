<div>
  <!-- Description -->
  <div class="row">
    <p>{{ badge.description }}</p>
  </div>

  <!-- Valid From/To -->
  <div class="row">
    <p *ngIf="badge.validFrom || badge.validTo">
      <b translate> BadgeDetail.ValidFrom</b> <b>:</b>
      {{ badge.validFrom | date }}
      {{ "BadgeDetail.ValidUntil" | translate }}
      {{ badge.validTo | date }}
    </p>
    <p *ngIf="!badge.validFrom && !badge.validTo">
      <b translate> BadgeDetail.ValidFrom</b> <b>:</b>
      {{ "Badge.NoValidity" | translate }}
    </p>
  </div>

  <!-- Applicable To -->
  <div class="row">
    <p>
      <b translate>BadgeDetail.ApplicableTo</b><b>:</b>
      <ng-container *ngFor="let val of badge.applyTo; let last = last">
        {{ val.name }}{{ !last ? ", " : "" }}
      </ng-container>
    </p>
  </div>

  <!-- Languages -->
  <div class="row">
    <p>
      <b translate>BadgeDetail.Languages</b><b>:</b>
      <ng-container *ngIf="badge.languages?.length == 0"> - </ng-container>
      <ng-container *ngFor="let lang of badge.languages; let last = last">
        {{ lang.englishName }}{{ !last ? ", " : "" }}
      </ng-container>
    </p>
  </div>

  <!-- Locations -->
  <div class="row">
    <p>
      <b translate>BadgeDetail.Locations</b><b>:</b>
      <ng-container *ngIf="badge.locations?.length == 0"> - </ng-container>
      <ng-container *ngFor="let val of badge.locations; let last = last">
        {{ val.name }}{{ !last ? ", " : "" }}
      </ng-container>
    </p>
  </div>

  <!-- Tags -->
  <div class="row" *ngIf="badge.tags">
    <p>
      <b translate>BadgeDetail.Tags</b><b>: </b>
      <span *ngIf="badge.tags?.length == 0" translate> Badge.NoTag </span>
      <span
        *ngFor="let tag of badge.tags"
        class="badge badge-pill badge-pill-primary"
      >
        {{ tag.name }}
      </span>
    </p>
  </div>

  <!-- Terms -->
  <div class="row" *ngIf="badge.termType; let termType">
    <p>
      <b translate>BadgeDetail.Terms</b><b>:</b>
      {{ termType.name }}
    </p>
  </div>

  <!-- Link to T&C -->
  <div class="row">
    <p>
      <b translate>BadgeDetail.TermsAndConditions</b><b>:</b>
      <span *ngIf="!badge.termsUrl" translate> Badge.NoTermUrl </span>
      <a href="{{ badge.termsUrl }}" target="_blank">
        {{ badge.termsUrl }}
      </a>
    </p>
  </div>

  <!-- How to get badge -->
  <div class="row" *ngIf="badge.active">
    <p>
      <b translate>BadgeDetail.HowToGet</b><b>:</b>
      <ng-container *ngIf="!badge.getDescription"> - </ng-container>
      {{ badge.getDescription }}
    </p>
  </div>

  <!-- badge Links -->
  <div class="row">
    <p class="mb-2"><b translate>BadgeDetail.Links</b><b>:</b>
       <!-- Show 'No links' if there are no valid links -->
       <span *ngIf="!badge.links || badge.links.length === 0 || !hasValidLinks()" translate>
        Badge.NoLinks
      </span>
    </p>
    <ul>
      <ng-container *ngFor="let link of badge.links">
        <li *ngIf="link.length > 0">
          <a href="{{ link }}" target="_blank">{{ link }}</a>
        </li>
      </ng-container>
    </ul>
  </div>

  <!-- Media -->
  <div class="row">
    <p><b translate>BadgeDetail.Media</b><b>:</b>
      <span *ngIf="!videoEmbedUrl && badge.images?.length === 0" translate> 
        Badge.NoMedia
      </span>
    </p>
    <owl-carousel-o [options]="carouselOptions">
      <ng-template carouselSlide *ngIf="videoEmbedUrl; let url">
        <div class="badge-media-slide embed-responsive">
          <iframe
            width="560"
            height="320"
            [src]="url"
            title="YouTube video player"
            frameborder="0"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </ng-template>
      <ng-template
        carouselSlide
        *ngFor="let img of badge.images; let i = index"
      >
        <div class="badge-media-slide">
          <img [src]="img" />
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</div>
