import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { PartnerService } from '../../../services/partner.service';
import { PartnerStatus } from '../../../shared/app-constants/constants';
import { FlatPartner } from '../../../shared/models/FlatPartner';
import { PartnerUpdate } from '../../../shared/models/PartnerUpdate';
import { NotificationService } from '../../../shared/services/notification.service';

@Component({
  selector: 'app-edit-partner',
  templateUrl: './edit-partner.component.html',
  styleUrls: ['./edit-partner.component.css']
})
export class EditPartnerComponent implements OnInit, OnDestroy {
  @Input() params!: ICellRendererParams<any, any, any>;
  partner!: FlatPartner;
  private readonly _destroying$ = new Subject<void>();
  statuses = [{ name: 'Status.Active', id: PartnerStatus.Active }, { name: 'Status.Inactive', id: PartnerStatus.Inactive }];

  form = new FormGroup({
    partnerStatusId: new FormControl(''),
  });

  constructor(    
    public activeOffcanvas: NgbActiveOffcanvas,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private partnerService: PartnerService
  ) { }

  ngOnInit(): void {
    this.partner = this.params.data;
    this.form.controls['partnerStatusId'].setValue(this.partner.partnerStatusId);
  }

  onSubmit() {
    const partnerUpdate = { partnerId: this.partner.partnerId, ... this.form.value } as PartnerUpdate;
    this.partnerService.updatePartner(partnerUpdate).subscribe(() => {
      this.partner.partnerStatusId = partnerUpdate.partnerStatusId;
      this.notificationService.showSuccessToast(this.translate.instant('MvaSystemAdmin.EditPartner.PartnerUpdated'));
        this.params.context.partnersGridComponent.refreshGrid();
        this.activeOffcanvas.dismiss();
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
