import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FindMediaOrganization } from 'src/app/shared/models/OrganizationMedia';
import { ClipboardService } from '../../../shared/services/clipboard.service';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { FilterParams } from 'src/app/shared/models/FindMediaFilterParams';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { OrganizationService } from 'src/app/services/organization.service';
import { PaginatedResponse } from 'src/app/shared/models/PaginatedResponse';
@Component({
  selector: 'app-organization-table',
  templateUrl: './organization-table.component.html',
  styleUrls: ['./organization-table.component.css'],
})
export class OrganizationTableComponent {
  private readonly _destroying$ = new Subject<void>();
  @Input() pageNumber: number = 1;
  @Input() pageSize: number = 15;
  @Input() filters!: FilterParams;
  @Output() totalRecordsChange = new EventEmitter<number>();
  totalRecords: number = 0;
  organizations: FindMediaOrganization[] = [];
  popoverMessage: string = '';
  detailsUrl: string = '/find-media/single';
  constructor(
    private clipboardService: ClipboardService,
    private organizationService: OrganizationService,
    private translate: TranslateService,
    private notificationService: NotificationService
  ) {}

  ngOnDestroy() {
    this._destroying$.next();
    this._destroying$.complete();
  }

  getOrganizations() {
    if (this.pageNumber === 1) {
      this.organizations = [];
    }
    this.organizationService
      .getOrganizationSummary(this.pageNumber, this.pageSize, this.filters)
      .pipe(takeUntil(this._destroying$))
      .subscribe((response) => {
        const orgs = (response as ApiResponse)
          .result as PaginatedResponse<FindMediaOrganization>;
        this.totalRecords = orgs.totalItems;
        this.totalRecordsChange.emit(this.totalRecords);
        this.organizations = [...this.organizations, ...orgs.results];
      });
  }

  copyToClipboard(organization: FindMediaOrganization) {
    const baseUrl = window.location.origin;
    const copyUrl = `${baseUrl}${this.detailsUrl}/${organization.organizationId}`;
    this.clipboardService.copyToClipboard(copyUrl);
  }

  onFavorite(organization: FindMediaOrganization): void {
    this.organizationService
      .favouriteOrganizationMedia(organization.organizationId)
      .subscribe({
        next: (res: ApiResponse) => {
          const response = res.result as unknown as boolean;
          organization.isFavorite = response;
          this.organizations = this.updateOrganizationInArray(organization);
          response
            ? this.notificationService.showSuccessToast(
                this.translate.instant('FindMedia.AddedToFavorites')
              )
            : this.notificationService.showSuccessToast(
                this.translate.instant('FindMedia.RemovedFromFavorites')
              );
        },
        error: (error) => {
          this.notificationService.showSuccessToast(
            this.translate.instant('FindMedia.Error')
          );
        },
      });
  }

  updateOrganizationInArray(
    updatedOrganization: Partial<FindMediaOrganization> & {
      organizationId: string;
    }
  ): FindMediaOrganization[] {
    return this.organizations.map((org) =>
      org.organizationId === updatedOrganization.organizationId
        ? { ...org, ...updatedOrganization }
        : org
    );
  }
}
