<div class="modal-header">
  <h4 *ngIf="title" class="modal-title" translate>{{ title }}</h4>
  <div type="button" class="btn-off" data-coreui-dismiss="offcanvas" aria-label="Close" (click)="activeModal.dismiss()"></div>
</div>
<div class="modal-body">
  <p *ngIf="bodyConfirm" class="pl-013 pt-4 modal-text fw-semibold" translate>{{ bodyConfirm }}</p>
  <p *ngIf="bodyMessage" class="p-3 modal-text" translate [translateParams]="bodyMessageParams">{{ bodyMessage }}</p>
</div>
<div class="modal-footer" [ngClass]="{ 'justify-content-center': center }">
  <button *ngIf="noText" type="button" class="btn btn-secondary" (click)="activeModal.close()" translate>{{ noText }}</button>
  <button *ngIf="yesText" type="button" class="btn btn-primary" (click)="handleConfirm()" translate>{{ yesText }}</button>
</div>
