import { SolutionMasterData } from '../models/SolutionMasterData';

import { Injectable } from '@angular/core';
import { protectedResourceEndpoints } from '../auth-config/auth-config';
import { Role } from '../models/Role';
import { Language } from '../models/Language';
import { ApiService } from '../http/api.service';
import { ApiResponse } from '../models/ApiResponse';
import { VariableCategory } from '../models/VariableCategory';
import { Report } from '../app-constants/constants';
import { HttpClient } from '@angular/common/http';
import { PropertyMasterData } from '../models/PropertyMasterData';
import { PropertySubType } from '../models/PropertySubType';
import { FinanceMasterData } from '../models/FinanceMasterData';
import { OrganizationType } from '../models/OrganizationType';
import { Variable } from '../models/Variable';
import { Tag } from '../models/Tag';
import { TermType } from '../models/TermType';
import { Country } from '../models/Country';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  getRoles(languageId: string) {
    return this.apiService.getEntity<Role[]>(
      `${protectedResourceEndpoints.masterData.url}roles?languageId=${languageId}`
    );
  }

  getLanguages() {
    return this.apiService.getEntity<Language[]>(
      `${protectedResourceEndpoints.masterData.url}languages`
    );
  }

  getPropertyMasterData(languageId: string) {
    return this.apiService.getEntity<PropertyMasterData>(
      `${protectedResourceEndpoints.masterData.url}property?languageId=${languageId}`
    );
  }

  getPropertySubTypes(languageId: string) {
    return this.apiService.getEntity<PropertySubType[]>(
      `${protectedResourceEndpoints.masterData.url}propertySubType?languageId=${languageId}`
    );
  }

  getVariableCategories(languageId: string, report: Report) {
    return this.http.get<VariableCategory[]>(
      `${protectedResourceEndpoints.masterData.url}variableCategories/?languageId=${languageId}&reportId=${report}`
    );
  }

  getFinanceMasterData(languageId: string) {
    return this.apiService.getEntity<FinanceMasterData>(
      `${protectedResourceEndpoints.masterData.url}finance?languageId=${languageId}`
    );
  }

  getSolutionMasterData(languageId: string) {
    return this.apiService.getEntity<SolutionMasterData>(
      `${protectedResourceEndpoints.masterData.url}solution?languageId=${languageId}`
    );
  }

  getOrganizationTypes(languageId: string) {
    return this.http.get<OrganizationType[]>(
      `${protectedResourceEndpoints.masterData.url}organizationTypes?languageId=${languageId}`
    );
  }
  getOrganizationAgesList(languageId: string) {
    return this.http.get<Variable[]>(
      `${protectedResourceEndpoints.masterData.url}organizationAges?languageId=${languageId}`
    );
  }

  getTags(languageId: string) {
    return this.http.get<Tag[]>(
      `${protectedResourceEndpoints.masterData.url}tags?languageId=${languageId}`
    );
  }

  getLocations(languageId: string) {
    return this.apiService.getEntity<Country[]>(
      `${protectedResourceEndpoints.masterData.url}locations/?languageId=${languageId}`
    );
  }

  getTerms(languageId: string) {
    return this.apiService.getEntity<TermType[]>(
      `${protectedResourceEndpoints.masterData.url}terms/?languageId=${languageId}`
    );
  }
}
