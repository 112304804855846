import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataDashbordService } from 'src/app/services/datadashboard.service';
import { GoogleProperty } from 'src/app/shared/models/GoogleProperty';
import { GoogleSite } from 'src/app/shared/models/GoogleSite';
import { Solution } from 'src/app/shared/models/Solution';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-gs-select-site',
  templateUrl: './gs-select-site.component.html',
  styleUrls: ['./gs-select-site.component.css']
})
export class SelectGoogleSearchSiteComponent implements OnInit {

  @Input() solution!: Solution;
  @Input() organizationId!: string;
  @Output() SiteSelected = new EventEmitter<string>();
  googleSites: GoogleSite[] = [];

  selectedSite?: string;

  constructor(public activeModal: NgbActiveModal,
    private dataDashboardService: DataDashbordService,
    private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.dataDashboardService.getGoogleSearchConsoleSites(this.solution, this.organizationId).subscribe({
      next: (successResponse) => {
        if (successResponse.isSuccess) {
          if (successResponse.result) {
            this.googleSites = successResponse.result as GoogleSite[];
          }
          else{
            this.notificationService.showErrorToast('No sites(properties) available in Google Search Console.');
          }
        }
      },
      error: (errorResponse) => {
        this.activeModal.close();
        this.notificationService.showErrorToast('Get search console sites API call failed ,please try again.');
      }
    });
  }

  onSiteSelect(event: any) {
    if (event.target.value != '0') {
      this.selectedSite = event.target.value;
    }
    else {
      this.selectedSite = undefined;
    }
  }

  selectGoogleSearchConsoleSite() {
    this.SiteSelected.emit(this.selectedSite);
    this.activeModal.close();
  }
}
