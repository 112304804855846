import { Component } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { PartnerStatus } from '../../../shared/app-constants/constants';
import { FlatPartner } from '../../../shared/models/FlatPartner';
import { InviteUserComponent } from '../../usermanagement/invite-user/invite-user.component';
import { EditPartnerComponent } from '../edit-partner/edit-partner.component';

@Component({
  selector: 'app-partner-actions-renderer',
  templateUrl: './partner-actions-renderer.component.html',
  styleUrls: ['./partner-actions-renderer.component.css']
})
export class PartnerActionsRendererComponent implements ICellRendererAngularComp {
  partner!: FlatPartner;
  isPartnerActive: boolean = false;
  params!: ICellRendererParams<any, any, any>;

  constructor(private offcanvasService: NgbOffcanvas) { }


  agInit(params: ICellRendererParams<any, any, any>): void {
    this.partner = params.data;
    this.isPartnerActive = this.partner.partnerStatusId === PartnerStatus.Active;
    this.params = params;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return true;
  }

  inviteUser() {
    const offcanvasRef = this.offcanvasService.open(InviteUserComponent, { ariaLabelledBy: 'offcanvas-basic-title', position: 'end', backdrop: 'static' });
    offcanvasRef.componentInstance.params = this.params;
  }

  editPartner() {
    const offcanvasRef = this.offcanvasService.open(EditPartnerComponent, { ariaLabelledBy: 'offcanvas-basic-title', position: 'end', backdrop: 'static' });
    offcanvasRef.componentInstance.params = this.params;
  }
}
