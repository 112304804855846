<div class="body flex-grow-1 px-3">
  <div class="container-lg mb-4">
    <div class="row border-bottom">
      <div
        class="d-flex justify-content-between align-items-center pt-07 pb-012"
      >
        <h5 class="card-title mb-0 fw-semibold" translate>
          <img
            [src]="'/assets/icons/organization-icn.svg'"
            [alt]="'Organization.Title' | translate"
            class="pr-01 page-title-icon"
          />
          Organization.Title
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="hpm-1">
        <div
          *ngIf="
            !organizationForEdit ||
            organizationForEdit.organization.organizationId == emptyGuid
          "
        >
          <span>{{ "Organization.NoOrganization" | translate }}</span>
        </div>
        <form
          *ngIf="
            organizationForEdit &&
            organizationForEdit.organization.organizationId != emptyGuid
          "
          (ngSubmit)="onSubmit(organizationForm.value)"
          #organizationForm="ngForm"
        >
          <div class="col-sm-12">
            <div class="row">
              <div class="mb-3 row">
                <label
                  for="name"
                  class="col-sm-12 col-md-5 col-lg-2 col-form-label fw-semibold"
                  translate
                  >Organization.Name<span class="text-danger required-field"
                    >*</span
                  ></label
                >
                <div class="col-sm-12 col-md-7 col-lg-4">
                  <input
                    type="text"
                    id="name"
                    class="form-control"
                    [(ngModel)]="organizationForEdit.organization.name"
                    maxlength="100"
                    name="name"
                    #inputField="ngModel"
                    [ngClass]="
                      inputField.invalid &&
                      (inputField.dirty || inputField.touched)
                        ? 'invalid-input'
                        : ''
                    "
                    required
                  />
                  <span class="text-danger" *ngIf="inputField.value == ''">
                    {{ "Organization.NameRequiredText" | translate }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="mb-3 row">
                <fieldset class="row">
                  <legend
                    class="col-form-label col-sm-12 col-md-5 col-lg-2 pt-0 fw-semibold"
                    translate
                  >
                    Organization.Type
                  </legend>
                  <div class="col-sm-12 col-md-7 col-lg-4 pl-1">
                    <div
                      class="form-check pb-2 form-check-inline"
                      *ngFor="let type of organizationForEdit.organizationTypes"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="organizationTypeId"
                        [id]="type.name"
                        [value]="type.organizationTypeId"
                        [(ngModel)]="
                          organizationForEdit.organization.organizationTypeId
                        "
                        checked
                      />
                      <label class="form-check-label" [for]="type.name">
                        {{ type.name }}
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="row">
              <div class="mb-3 row">
                <label
                  for="yearOfFoundation"
                  class="col-sm-12 col-md-5 col-lg-2 col-form-label fw-semibold"
                  translate
                  >Organization.Foundation</label
                >
                <div class="col-sm-12 col-md-7 col-lg-2">
                  <input
                    type="text"
                    id="yearOfFoundation"
                    class="form-control col-sm-3"
                    [(ngModel)]="
                      organizationForEdit.organization.foundationYear
                    "
                    maxlength="4"
                    (blur)="onFoundationYearBlur()"
                    name="foundationYear"
                    #yearField="ngModel"
                    [pattern]="numberInputPattern"
                    [ngClass]="validYear ? 'invalid-input' : ''"
                    appNumericInput
                  />
                  <span
                    class="text-danger"
                    *ngIf="yearField.invalid || validYear"
                  >
                    {{ "Organization.YearWarning" | translate : param }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="mb-3 row">
                <label
                  for="baseCurrencyId"
                  class="col-sm-12 col-md-5 col-lg-2 col-form-label fw-semibold"
                  translate
                  >Organization.Currency</label
                >
                <div class="col-sm-12 col-md-7 col-lg-2">
                  <select
                    class="form-select"
                    [(ngModel)]="
                      organizationForEdit.organization.baseCurrencyId
                    "
                    name="baseCurrencyId"
                    id="baseCurrencyId"
                  >
                    <option [ngValue]="undefined" value="">
                      {{ "Organization.SelectCurrency" | translate }}
                    </option>
                    <option
                      *ngFor="
                        let currency of organizationForEdit.baseCurrencies
                      "
                      [value]="currency.baseCurrencyId"
                    >
                      {{ currency.code }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="mb-3 row">
                <label
                  for="totalFte"
                  class="col-sm-12 col-md-5 col-lg-2 col-form-label fw-semibold"
                  translate
                  >Organization.TotalFTE
                  <span class="pl-05">
                    <img
                      src="../../../../assets/icons/info.svg"
                      [alt]="'Property.NameIconAltText' | translate"
                      class="btn-icon"
                      type="button"
                      [ngbPopover]="'Organization.TotalFTEHelpText' | translate"
                      popoverClass="org-totalFTE-popover"
                    />
                  </span>
                </label>
                <div class="col-sm-12 col-md-7 col-lg-2">
                  <input
                    type="text"
                    id="totalFte"
                    class="form-control"
                    [(ngModel)]="organizationForEdit.organization.totalFte"
                    name="totalFte"
                    [maxlength]="maxFteInput"
                    #totalFteField="ngModel"
                    [pattern]="numberInputPattern"
                    appNumericInput
                  />
                  <span
                    class="text-danger"
                    *ngIf="totalFteField.invalid && totalFteField.value != ''"
                  >
                    {{ "Organization.FteWarning" | translate }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="mb-3 row">
                <label
                  for="femaleFte"
                  class="col-sm-12 col-md-5 col-lg-2 col-form-label fw-semibold"
                  translate
                  >Organization.FemaleFTE
                  <span class="pl-05">
                    <img
                      src="../../../../assets/icons/info.svg"
                      [alt]="'Property.NameIconAltText' | translate"
                      class="btn-icon"
                      type="button"
                      [ngbPopover]="
                        'Organization.FemaleFTEHelpText' | translate
                      "
                      popoverClass="org-totalFemaleFTE-popover"
                    />
                  </span>
                </label>
                <div class="col-sm-12 col-md-7 col-lg-2">
                  <input
                    type="text"
                    id="femaleFte"
                    class="form-control"
                    [(ngModel)]="organizationForEdit.organization.femaleFte"
                    name="femaleFte"
                    [maxlength]="maxFteInput"
                    #femaleFteField="ngModel"
                    [pattern]="numberInputPattern"
                    [ngClass]="femaleFteField.invalid ? 'invalid-input' : ''"
                    appNumericInput
                  />
                  <span class="text-danger" *ngIf="femaleFteField.invalid">
                    {{ "Organization.FemaleFteWarning" | translate }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="mb-3 row">
                <label
                  for="fteCustomer"
                  class="col-sm-12 col-md-5 col-lg-2 col-form-label fw-semibold"
                  translate
                  >Organization.CustomerServiceRole
                  <span class="pl-05">
                    <img
                      src="../../../../assets/icons/info.svg"
                      [alt]="'Property.NameIconAltText' | translate"
                      class="btn-icon"
                      type="button"
                      [ngbPopover]="
                        'Organization.CustomerServiceRoleHelpText' | translate
                      "
                      popoverClass="org-customerservicerole-popover"
                    />
                  </span>
                </label>
                <div class="col-sm-12 col-md-7 col-lg-2">
                  <input
                    type="text"
                    id="fteCustomer"
                    class="form-control"
                    [(ngModel)]="
                      organizationForEdit.organization.customerServiceRoleFte
                    "
                    name="customerServiceRoleFte"
                    #customerFteField="ngModel"
                    [maxlength]="maxFteInput"
                    [pattern]="numberInputPattern"
                    [ngClass]="customerFteField.invalid ? 'invalid-input' : ''"
                    appNumericInput
                  />
                  <span class="text-danger" *ngIf="customerFteField.invalid">
                    {{ "Organization.CustomerFteWarning" | translate }}
                  </span>
                </div>
              </div>
            </div>

            <div class="mb-3 row">
              <label
                for="primaryImage"
                class="col-sm-12 col-md-4 col-lg-2 col-form-label fw-semibold"
                translate
                >Organization.Logo</label
              >
              <div
                *ngIf="!organizationForEdit.organization.primaryImageUrl"
                class="col-sm-12 col-md-8 col-lg-6 d-inline-flex"
              >
                <div class="card brand-upload mt-1">
                  <div class="card-body mx-auto">
                    <label for="file-input">
                      <i class="bi bi-plus-square-fill brand-upload-i"></i>
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept="image/png,image/jpeg"
                      class="d-none"
                      name="primaryImage"
                      (change)="onPrimaryImageSelect($event)"
                    />
                  </div>
                </div>
              </div>
              <div
                *ngIf="organizationForEdit.organization.primaryImageUrl"
                class="col-sm-6 col-md-8 col-lg-6 d-inline-flex"
              >
                <div class="card brand-upload">
                  <input
                    id="file-input-preview"
                    #file
                    type="file"
                    accept="image/png,image/jpeg"
                    class="d-none"
                    name="primaryImage"
                    (change)="onPrimaryImageSelect($event)"
                  />
                  <div class="card-body mx-auto" (click)="file.click()">
                    <img
                      [src]="organizationForEdit.organization.primaryImageUrl"
                      class="brand-upload-logo"
                      alt="{{ 'Badge.PrimaryImage' | translate }}"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Website -->
            <div class="row">
              <div class="mb-3 row">
                <label
                  for="description"
                  class="col-sm-12 col-md-5 col-lg-2 col-form-label fw-semibold"
                  translate
                >
                  Organization.Website
                </label>
                <div class="col-sm-12 col-md-4 col-lg-4">
                  <input
                    id="description"
                    class="form-control"
                    name="website"
                    [(ngModel)]="organizationForEdit.organization.website"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <label
                class="col-sm-12 col-md-4 col-lg-2 col-form-label fw-semibold"
                translate
                >UserProfile.Badges</label
              >
              <div class="col-sm-12 col-md-7 col-lg-4 d-flex gap-3">
                <div
                  *ngFor="let badge of organizationForEdit.organization.badges"
                  class="badge-container"
                >
                  <img [src]="badge.primaryImageUrl" alt="" />
                  <button type="button" (click)="onDeleteBadge(badge.badgeId)">
                    -
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row border-top pt-4">
            <div class="mb-3 row border-bottom pb-4">
              <fieldset class="row">
                <h5 class="col-form-label pt-0 fw-semibold" translate>
                  Organization.DiversityEquityInclusion
                </h5>
                <p translate>Organization.DiversityOfAudience</p>
                <legend
                  class="col-form-label col-sm-12 col-md-12 col-lg-2 pt-0 fw-semibold"
                  translate
                >
                  Organization.AudienceDiversity
                </legend>
                <div class="col-sm-12 col-md-12 col-lg-10 pb-4">
                  <div
                    class="form-check pb-2 form-check-inline"
                    *ngFor="let rate of rating"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="audienceDiversity"
                      id="{{ rate }}"
                      [value]="rate"
                      [(ngModel)]="
                        organizationForEdit.organization.audienceDiversity
                      "
                      checked
                    />
                    <label class="form-check-label" for="{{ rate }}">{{
                      "Organization.AudienceDiversityRatings." + rate
                        | translate
                    }}</label>
                  </div>
                </div>
                <p translate>Organization.OperationsProductionsText</p>
                <legend
                  class="col-form-label col-sm-12 col-md-12 col-lg-2 pt-0 fw-semibold"
                  translate
                >
                  Organization.OperationsProductions
                </legend>
                <div class="col-sm-12 col-md-12 col-lg-10">
                  <div
                    class="form-check pb-2 form-check-inline"
                    *ngFor="let rate of rating; index as i"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="opsAndProd"
                      id="{{ i + 6 }}"
                      [value]="rate"
                      [(ngModel)]="organizationForEdit.organization.opsAndProd"
                      checked
                    />
                    <label class="form-check-label" for="{{ i + 6 }}">{{
                      "Organization.OperationsProductionRatings." + rate
                        | translate
                    }}</label>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>

          <button
            class="btn btn-secondary mx-2"
            (click)="onCancel()"
            type="button"
            translate
          >
            Organization.Cancel
          </button>
          <button
            class="btn btn-secondary mx-2"
            (click)="onDeleteDialog()"
            type="button"
            translate
          >
            Organization.Delete
          </button>
          <button class="btn btn-primary mx-2" type="submit" translate>
            Organization.Save
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<div #myModal class="modal fade show overlay" id="myModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" translate>Organization.Confirm</h4>
        <div
          type="button"
          class="btn-off"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="closeDialog()"
        ></div>
      </div>
      <div class="modal-body">
        <p class="pl-013 pt-4 modal-text fw-semibold" translate>
          Organization.DeleteMessage
        </p>
        <p class="p-3 modal-text" translate>Organization.DeleteMessage2</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeDialog()"
          translate
        >
          Organization.ConfirmNo
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="onDelete()"
          translate
        >
          Organization.ConfirmYes
        </button>
      </div>
    </div>
  </div>
</div>
