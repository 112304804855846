import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { propertySubTypes } from 'src/app/shared/app-constants/constants';
import { FlatFinancial } from 'src/app/shared/models/FlatFinancial';

@Component({
  selector: 'app-journal-level-cellrenderer',
  templateUrl: './journal-level-cellrenderer.component.html',
})
export class JournalLevelCellrendererComponent implements ICellRendererAngularComp {
  iconPath: string | undefined;
  labelText: string | undefined;
  isOrg: boolean = false;
  isBrand: boolean = false;
  isProperty: boolean = false;

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.labelText = this.getLevel(params.data);
  }
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    throw new Error('Method not implemented.');
  }

  private getLevel(data: FlatFinancial) {
    if (data.propertyName) {
      this.isProperty = true;
      this.iconPath = this.getPropertyIcon(data.propertySubTypeId);
      return data.propertyName;
    } else if (data.brandName) {
      this.isBrand = true;
      this.iconPath = "/assets/icons/properties-icn.svg";
      return data.brandName;
    } else {
      this.isOrg = true;
      this.iconPath = "/assets/icons/organization-icn.svg";
      return data.organizationName;
    }
  }

  private getPropertyIcon(propertySubTypeId: string | undefined) {
    switch (propertySubTypeId) {
      case propertySubTypes.Facebook:
        return "/assets/icons/fb-icn.svg";
      case propertySubTypes.Instagram:
        return "/assets/icons/insta-icn.svg";
      case propertySubTypes.Youtube:
        return "/assets/icons/yt-icn.svg";
      default:
        return "../../../../assets/icons/website-icn.svg";
    }
  }

}
