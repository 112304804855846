import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  combineLatest,
  mergeMap,
  Subject,
  Subscription,
  takeUntil,
} from 'rxjs';
import { AppContext } from '../../../context/AppContext';
import { MenuCategory, MenuService } from '../../../services/menu.service';
import { DataDashbordService } from 'src/app/services/datadashboard.service';
import { Property } from 'src/app/shared/models/property.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MissingPermissionsPropertiesComponent } from '../../missing-permissions-properties/missing-permissions-properties.component';
import { error } from 'console';
import { Roles } from 'src/app/shared/app-constants/constants';

@Component({
  selector: '[app-body-topbar]',
  templateUrl: './body-topbar.component.html',
  styleUrls: ['./body-topbar.component.css'],
})
export class BodyTopbarComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  isLoggedIn = false;
  userName!: string;
  orgId?: string;
  canChangeOrg: boolean = false;
  menuSubscription?: Subscription;

  // INEWS-363 - Code for Missing permissions list on header commented  this  because Internews do not want it as of now
  // missingPermissionsProperties: Property[] = [];
  // missingPermissionsSubscription?: Subscription;
  // missingPermissionsPropDataSubscription?: Subscription;

  constructor(
    private appContext: AppContext,
    public menuService: MenuService // INEWS-363 - Code for Missing permissions list on header commented  this  because Internews do not want it as of now // private dataDashboardService: DataDashbordService, // private ngModal: NgbModal
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.appContext.organization,
      this.appContext.user,
      this.appContext.authStatus,
    ])
      .pipe(takeUntil(this._destroying$))
      .subscribe(([org, user, authStatus]) => {
        this.orgId = org?.organizationId;
        this.isLoggedIn = authStatus;
        this.canChangeOrg = (user.userOrganizations?.length ?? 0) > 1;
        this.userName = user.firstName
          ? `${user.firstName} ${user.lastName}`
          : user.email;

        // INEWS-363 - Code for Missing permissions list on header commented  this  because Internews do not want it as of now
        // if (org?.roleId == Roles.ORGADMIN) {
        //   this.registerMissingPermissionsSubscription();
        //   this.dataDashboardService.getMissingPermissionsProperties(this.orgId!);
        // }
      });

    this.menuSubscription = this.menuService.menuOpenSubject.subscribe(
      (res) => {
        this.menuService.isMenuOpen = res;
      }
    );
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();

    if (this.menuSubscription && !this.menuSubscription.closed)
      this.menuSubscription.unsubscribe();

    // INEWS-363 - Code for Missing permissions list on header commented  this  because Internews do not want it as of now
    // if (this.missingPermissionsSubscription && !this.missingPermissionsSubscription.closed)
    //   this.missingPermissionsSubscription.unsubscribe();

    // if (this.missingPermissionsPropDataSubscription && !this.missingPermissionsPropDataSubscription.closed)
    //   this.missingPermissionsPropDataSubscription.unsubscribe();
  }

  toggleMenu() {
    this.menuService.menuOpenSubject.next(!this.menuService.isMenuOpen);
  }

  // INEWS-363 - Code for Missing permissions list on header commented  this  because Internews do not want it as of now
  // registerMissingPermissionsSubscription() {

  //   this.missingPermissionsPropDataSubscription = this.dataDashboardService.missingPermissionsPropertiesSubject$.subscribe({
  //     next: missingPermissionsProps => this.missingPermissionsProperties = missingPermissionsProps,
  //   });

  //   this.missingPermissionsSubscription = this.dataDashboardService.missingFBPermissionsSubject$.subscribe((res: string[]) => {
  //     if (res && res.length > 0) {
  //       this.dataDashboardService.getMissingPermissionsProperties(this.orgId!);
  //     }
  //     else {
  //       this.missingPermissionsProperties = [];
  //     }
  //   });
  // }

  // showMissingPermissionsProperties() {
  //   console.log(this.missingPermissionsProperties);
  //   const modalRef = this.ngModal.open(MissingPermissionsPropertiesComponent, { size: 'lg' });
  //   modalRef.componentInstance.missingPermissionsProperties = this.missingPermissionsProperties;
  // }
}
